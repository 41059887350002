import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TrashWindowComponent } from "../trash-window/trash-window.component";
import { ArchiveWindowComponent } from "../archive-window/archive-window.component";
import { Router } from "@angular/router";
import { Broadcaster } from "../shared/providers";

@Component({
  selector: "vp-archive-trash-window-mobile",
  templateUrl: "./archive-trash-window-mobile.component.html",
  styleUrls: ["./archive-trash-window-mobile.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ArchiveTrashWindowMobileComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ArchiveTrashWindowMobileComponent>,
    private matDialog: MatDialog, private router: Router, private dialog: MatDialog,
    private broadcaster: Broadcaster,
  ) { }

  ngOnInit(): void {
  }

  openTrashWindow() {
    // this.router.navigateByUrl("/talk");
    this.dialog.closeAll();
    this.matDialog.open(TrashWindowComponent, {
      backdropClass: ["archive-contacts-backdrop", "trash-window-scroll"],
      panelClass: ["mobile-dialog", "trash-topics-overflow-fix"],
      width: "100vw",
      height: "100vh",
      maxHeight: "100vh",
      maxWidth: "100vw",
      autoFocus: true,
      data: { isMobileScreen: true }
    });
    this.close();
  }

  openArchiveWindow() {
    this.dialog.closeAll();
    this.matDialog.open(ArchiveWindowComponent, {
      backdropClass: ["archive-contacts-backdrop", "trash-window-scroll"],
      panelClass: "mobile-dialog",
      width: "100vw",
      height: "100vh",
      maxHeight: "100vh",
      maxWidth: "100vw",
      autoFocus: true,
      data: { isMobileScreen: true }
    });
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
  closeAll() {
    this.matDialog.closeAll();
  }
}
