<div class="subscription-center-view">
    <vnc-header style="width:100%;" size="l" title="{{ 'SUBSCRIPTIONS' | translate }}" [iconTemplate]="channelIconTemplate" (onLeftIconClick)="goBack()" actionLink="download all" actionIcon="mdi-download" type="back"></vnc-header>
    <ng-template #channelIconTemplate>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g fill="none" fill-rule="evenodd">
                <g fill="#000">
                    <g>
                        <g>
                            <g>
                                <path d="M10.667 4.667L2.553 4.667 6.28 0.94 5.333 0 0 5.333 5.333 10.667 6.273 9.727 2.553 6 10.667 6z" transform="translate(-20 -18) translate(16 14) translate(4 4) translate(2.667 2.667)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </ng-template>
    <div class="tabs-section">
        <div class="all-tabs">
          <ng-container *ngFor="let tab of subscriptionTabs">
            <vnc-chips
              *ngIf="(tab?.checkIfSocialEnabled && isSocialModuleEnabled) || !tab?.checkIfSocialEnabled"
              text="{{ tab.label | translate | lowercase }}"
              type="filter"
              size="s"
              (clickEvent)="activateTab(tab.value)"
              [active]="tab.active"
            ></vnc-chips>
          </ng-container>
        </div>
        <ng-template #blankIcon>
        </ng-template>
        <ng-template #subscribeIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
                <defs>
                    <filter id="zl3hgwdb7a" width="157.5%" height="256.8%" x="-28.8%" y="-78.4%" filterUnits="objectBoundingBox">
                        <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"/>
                        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
                        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6"/>
                        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0"/>
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1"/>
                            <feMergeNode in="shadowMatrixOuter2"/>
                            <feMergeNode in="SourceGraphic"/>
                        </feMerge>
                    </filter>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g fill="#337CBD">
                        <g>
                            <g filter="url(#zl3hgwdb7a)" transform="translate(-824.000000, -152.000000) translate(389.500000, 39.000000) translate(228.000000, 96.000000)">
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M4.5 8.377L1.373 5.25 0.307 6.308 4.5 10.5 13.5 1.5 12.443 0.443z" transform="translate(0.000000, 8.000000) translate(204.000000, 6.000000) translate(3.000000, 3.000000) translate(2.250000, 3.750000)"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
          </ng-template>
        <div class="group-sort">
            <vnc-action-link
                [matMenuTriggerFor]="sortMenu"
                #sortMenuTrigger="matMenuTrigger"
                (actionClicked)="$event.stopPropagation(); sortMenuTrigger.openMenu()"
                [type]="'icon-right'"
                [trailingIconTemplate]="downIcon"
                [linkColor]="'blue'"
                label="{{ groupControl.value === 'created_on:asc' ? ('DATE' | translate) : ('AUTHOR' | translate)}}"
            ></vnc-action-link>
        </div>
    </div>
    <div class="subscriptions-list">
        <div class="channel-search-box">
            <vnc-input placeholder="{{ 'SEARCH_LABEL' | translate }}" leadingIconName="mdi-magnify" placeholder="Search" [form]="searchControl" [showClear]="true"></vnc-input>
        </div>
        <div class="results">
            <div class="list-wrap" *ngIf="searchControl.value !== ''">
                <div *ngIf="subscriptionTabs[0].active && subscriptionTabs[0].value === 'channels'" class="channel-list-scroll vnc-scroll-bar--small--vertical" vpInfiniteScroll scrollPercentage="0.8" (onUserScroll)="onUserScroll('channels')">
                    <span *ngFor="let item of subscribedChannels" class="cursor-pointer">
                        <vnc-menu-search-item *ngIf="item.subscribed" [searchText]="searchControl.value" [totalUnreadChannelCount]="item.unread_topics_count" [isMobile]="false" [item]="item" [type]="'search-highlight-subscribe'" (unSubscribeEvent)="unsubscribeChannel(item)" unsubscribeTooltip="{{ 'UNSUBSCRIBE' | translate }}"></vnc-menu-search-item>
                    </span>
                    <div class="subscription-lbl">{{ 'SUBSCRIPTION_AVAIABLE' | translate }}</div>
                    <span *ngFor="let item of subscribedChannels" class="cursor-pointer">
                        <vnc-menu-search-item [showSubscribeIcon]="true" *ngIf="!item.subscribed" [searchText]="searchControl.value" [totalUnreadChannelCount]="item.unread_topics_count" [isMobile]="false" [item]="item" [type]="'search-highlight-subscribe'" (subscribeEvent)="subscribeChannel(item)" (itemClick)="subscribeChannel(item)"></vnc-menu-search-item>
                    </span>
                </div>
                <div *ngIf="subscriptionTabs[1].active && subscriptionTabs[1].value === 'topics'" class="topic-list-scroll vnc-scroll-bar--small--vertical" vpInfiniteScroll scrollPercentage="0.8" (onUserScroll)="onUserScroll('topic')">
                    <span *ngFor="let item of subscribedTopics" class="cursor-pointer">
                        <vnc-menu-search-item [searchText]="searchControl.value" *ngIf="item.subscribed" [item]="item" [type]="'search-highlight-topic'" [isMobile]="false" (unSubscribeEvent)="unsubscribeTopic(item)" unsubscribeTooltip="{{ 'UNSUBSCRIBE' | translate }}"></vnc-menu-search-item>
                    </span>
                    <div class="subscription-lbl">{{ 'SUBSCRIPTION_AVAIABLE' | translate }}</div>
                    <span *ngFor="let item of subscribedTopics" class="cursor-pointer">
                        <vnc-menu-search-item [showSubscribeIcon]="true" [searchText]="searchControl.value" (subscribeEvent)="subscribeTopic(item)" *ngIf="!item.subscribed" [item]="item" [type]="'search-highlight-topic'" [isMobile]="false" (itemClick)="subscribeTopic(item)"></vnc-menu-search-item>
                    </span>
                </div>

              <!--********** FOLLOWINGS *********-->

              <div class="followers-following vnc-scroll-bar--small--vertical" *ngIf="subscriptionTabs[2].active && subscriptionTabs[2].value === 'followings'">
                  <div *ngFor="let user of searchUsers" class="follow-unfollow-box">
                    <div class="left">
                      <div>
                        <vnc-avatar customSize="36px" [imgSrc]="user.avatar_url"></vnc-avatar>
                      </div>
                      <div class="user-name">{{user.name}}</div>
                    </div>
                    <div class="right">
                      <span *ngIf="user?.following">
                        <vnc-primary-button fixedSize="96" [isFixedSize]="true" size="xs" label="{{'UN_FOLLOW_LBL' | translate}}" (onClick)="unfollowUser(user)"></vnc-primary-button>
                      </span>
                      <span *ngIf="!user?.following && !user?.followed_by">
                        <vnc-secondary-button size="xs" label="{{'FOLLOW_LBL' | translate}}" (onClick)="followUser(user)" [leadingIconTemplate]="checkMark"></vnc-secondary-button>
                      </span>
                      <span *ngIf="!user?.following && user?.followed_by">
                        <vnc-secondary-button size="xs" label="{{'FOLLOW_BACK_LBL' | translate}}" (onClick)="followUser(user)" [leadingIconTemplate]="checkMark"></vnc-secondary-button>
                      </span>
                    </div>
                  </div>
                </div>

              <!--********** FOLLOWERS *********-->

              <div class="followers-following vnc-scroll-bar--small--vertical" *ngIf="subscriptionTabs[3].active && subscriptionTabs[3].value === 'followers'">
                <div *ngFor="let user of searchUsers" class="follow-unfollow-box">
                  <div class="left">
                    <div>
                      <vnc-avatar customSize="36px" [imgSrc]="user.avatar_url"></vnc-avatar>
                    </div>
                    <div class="user-name">{{user.name}}</div>
                  </div>
                  <div class="right">
                      <span *ngIf="user?.following">
                        <vnc-primary-button fixedSize="96" [isFixedSize]="true" size="xs" label="{{'UN_FOLLOW_LBL' | translate}}" (onClick)="unfollowUser(user)"></vnc-primary-button>
                      </span>
                    <span *ngIf="!user?.following && !user?.followed_by">
                        <vnc-secondary-button size="xs" label="{{'FOLLOW_LBL' | translate}}" (onClick)="followUser(user)" [leadingIconTemplate]="checkMark"></vnc-secondary-button>
                      </span>
                    <span *ngIf="!user?.following && user?.followed_by">
                        <vnc-secondary-button size="xs" label="{{'FOLLOW_BACK_LBL' | translate}}" (onClick)="followUser(user)" [leadingIconTemplate]="checkMark"></vnc-secondary-button>
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-wrap" *ngIf="searchControl.value === ''">
                <div *ngIf="subscriptionTabs[0].active && subscriptionTabs[0].value === 'channels'" class="channel-list-scroll vnc-scroll-bar--small--vertical" vpInfiniteScroll scrollPercentage="0.8" (onUserScroll)="onUserScroll($event, 'channels')">
                    <span *ngFor="let item of subscribedChannels" class="cursor-pointer">
                        <vnc-menu-search-item [totalUnread]="item.unread_topics_count" [isMobile]="false" [item]="item" [type]="'search-highlight-subscribe'" unsubscribeTooltip="{{ 'UNSUBSCRIBE' | translate }}" (unSubscribeEvent)="unsubscribeChannel(item)" [totalCount]="item.topics_count" [totalChildCount]="item.unread_topics_count"></vnc-menu-search-item>
                    </span>
                </div>
                <div *ngIf="subscriptionTabs[1].active && subscriptionTabs[1].value === 'topics'" class="topic-list-scroll vnc-scroll-bar--small--vertical" vpInfiniteScroll scrollPercentage="0.8" (onUserScroll)="onUserScroll($event, 'topic')">
                    <span *ngFor="let item of subscribedTopics" class="cursor-pointer">
                      <vnc-menu-search-item [item]="item" [type]="'search-highlight-topic'" [isMobile]="false" (unSubscribeEvent)="unsubscribeTopic(item)" unsubscribeTooltip="{{ 'UNSUBSCRIBE' | translate }}" (itemClick)="redirectToTopic(item)"></vnc-menu-search-item>
                    </span>
                </div>

              <!--********** FOLLOWINGS *********-->

              <div class="followers-following vnc-scroll-bar--small--vertical" *ngIf="subscriptionTabs[2].active && subscriptionTabs[2].value === 'followings'">
                <div *ngFor="let user of followingsList" class="follow-unfollow-box">
                  <div class="left">
                    <div>
                        <vnc-avatar [imgSrc]="user?.avatarFailedToLoad ? '' :  user?.avatar_url"
                                    customSize="36px"
                                    (imgLoadError)="userAvatarFailedToLoad(user.id, 'followings')"
                        ></vnc-avatar>
                    </div>
                    <div class="user-name">{{user.name}}</div>
                  </div>
                  <div class="right">
                    <vnc-primary-button size="xs" label="{{'UN_FOLLOW_LBL' | translate}}" (onClick)="unfollowUser(user)"></vnc-primary-button>
                  </div>
                </div>
              </div>

              <!--********** FOLLOWERS *********-->

              <div class="followers-following vnc-scroll-bar--small--vertical" *ngIf="subscriptionTabs[3].active && subscriptionTabs[3].value === 'followers'">
                <div *ngFor="let user of followersList" class="follow-unfollow-box">
                  <div class="left">
                    <div>
                        <vnc-avatar [imgSrc]="user?.avatarFailedToLoad ? '' :  user?.avatar_url"
                                    customSize="36px"
                                    (imgLoadError)="userAvatarFailedToLoad(user.id, 'followers')"
                        ></vnc-avatar>
                    </div>
                    <div class="user-name">{{user.name}}</div>
                  </div>
                  <div class="right">
                      <span *ngIf="user?.following">
                        <vnc-primary-button fixedSize="96" [isFixedSize]="true" size="xs" label="{{'UN_FOLLOW_LBL' | translate}}" (onClick)="unfollowUser(user)"></vnc-primary-button>
                      </span>
                    <span *ngIf="!user?.following">
                        <vnc-secondary-button size="xs" label="{{'FOLLOW_BACK_LBL' | translate}}" (onClick)="followUser(user)" [leadingIconTemplate]="checkMark"></vnc-secondary-button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <mat-menu #sortMenu="matMenu">
        <div (click)="$event.stopPropagation();changeSelection('date')">
          <vp-dropdown-item  itemType="drop" label="{{ 'DATE' | translate }}" [sufixIconTemplate]="groupControl.value === 'created_on:asc' ? subscribeIcon : blankIcon"></vp-dropdown-item>
        </div>
        <div (click)="$event.stopPropagation();changeSelection('author')">
            <vp-dropdown-item itemType="drop" label="{{ 'AUTHOR' | translate }}" [sufixIconTemplate]="groupControl.value === 'author:asc' ? subscribeIcon : blankIcon"></vp-dropdown-item>
        </div>
    </mat-menu>
</div>
<ng-template #downIcon>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <defs>
            <filter id="xjktr0wqpa" width="128.8%" height="121.6%" x="-14.4%" y="-10.8%" filterUnits="objectBoundingBox">
                <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
                <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"/>
                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
                <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="6"/>
                <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0"/>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"/>
                    <feMergeNode in="shadowMatrixOuter2"/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g fill="#337CBD">
                <g>
                    <g filter="url(#xjktr0wqpa)" transform="translate(-1333.000000, -108.000000) translate(886.000000, 39.000000) translate(0.000000, 9.000000)">
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <path d="M0 0L3.75 3.75 7.5 0z" transform="translate(0.000000, 0.000000) translate(387.000000, 57.000000) translate(57.000000, 0.000000) translate(3.000000, 3.000000) translate(5.250000, 7.500000)"/>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</ng-template>

<ng-template #checkMark>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <path d="M6 10.78 3.22 8l-.947.94L6 12.667l8-8-.94-.94z" fill="#8B96A0" fill-rule="evenodd"/>
  </svg>
</ng-template>
