/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    OnDestroy,
    ViewEncapsulation
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConferenceRepository } from "app/talk/repositories/conference.repository";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: "vp-common-dialog",
    templateUrl: "./common-dialog.html",
    styleUrls: ["./common-dialog.scss"],
    encapsulation: ViewEncapsulation.None
})
export class VNCCommonDialogComponent implements OnDestroy {
    actionTranslationKey: any;
    headerTranslationKey: any;
    bodyTranslationKey: any;
    cancelTranslationKey: any;
    hideAction: boolean;
    isLocaitionDialog: boolean = false;
    isMobileScreen: boolean = false;
    private isAlive$ = new Subject<boolean>();
    constructor(
        private breakpointObserver: BreakpointObserver,
        private changeDetectionRef: ChangeDetectorRef,
        private conferenceRepo: ConferenceRepository,
        private matDialogRef: MatDialogRef<VNCCommonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.conferenceRepo.toggleHideVideoIOS(true);
        if (this.data) {
            this.headerTranslationKey = data.headerTranslationKey;
            this.hideAction = data?.hideAction || false;
            this.bodyTranslationKey = data.bodyTranslationKey;
            this.actionTranslationKey = data.actionTranslationKey;
            this.cancelTranslationKey = data.cancelTranslationKey || "CANCEL";
            if (data.headerTranslationKey === "SEND_LOCATION") this.isLocaitionDialog = true;
            this.changeDetectionRef.markForCheck();
        }

        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
            this.breakpointObserver
              .observe(["(max-width: 767px)"])
              .pipe(takeUntil(this.isAlive$))
              .subscribe((state: BreakpointState) => {
                if (state.matches) {
                  this.isMobileScreen = true;
                } else {
                  this.isMobileScreen = false;
                }
              });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.conferenceRepo.toggleHideVideoIOS(false);
    }

    @HostListener("document:keydown.esc")
    close(): void {
        this.matDialogRef.close();
    }

    action() {
        this.matDialogRef.close({action: this.actionTranslationKey});
    }
}
