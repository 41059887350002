import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DEFAULT_CHANNEL_THUMBNAIL_URL, SmartLinkData, SmartLinkService} from "../../smart-link.service";
import {map, take, takeUntil} from "rxjs";
import {BehaviorSubject} from "rxjs";
import {noop, Observable, Subject} from "rxjs";
import {SmartObjectActions, SmartObjectsService} from "../smart-objects.service";
import {UntypedFormControl} from "@angular/forms";
import {StickyNoteActions, StickyNoteService} from "../../sticky-note.service";
import {ChannelRepository} from "../../repository/channel.repository";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {TalkRootState} from "../../../talk/reducers";
import {getUserConfig} from "../../../reducers";
import {Utils} from "../../../common";
import {RemarkService} from "../../remark.service";
import {Broadcaster} from "../../../talk/shared/providers";
import {CloseSmartObjectDialog} from "../../../root.component";
import format from "date-fns/format";
import { environment } from "app/environments/environment";

@Component({
  selector: "vp-smart-objects-preview",
  templateUrl: "./smart-objects-preview.component.html",
  styleUrls: ["./smart-objects-preview.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SmartObjectsPreviewComponent implements OnInit, OnDestroy {
  isAlive$ = new Subject();
  menuOpened = false;
  defaultChannelAvatar = DEFAULT_CHANNEL_THUMBNAIL_URL;
  smartObjectActions = SmartObjectActions;

  $editHighlightName = new BehaviorSubject<boolean>(false);
  $fullScreen = new BehaviorSubject<boolean>(false);
  allSmartLinkData = [];
  $filteredSmartSearchResults = new BehaviorSubject<any[]>([]);

  $showFooterButtons = new BehaviorSubject<boolean>(false);

  highlightControl = new UntypedFormControl("");

  $allStickyNotesData = new BehaviorSubject<any[]>([]);
  $allReferencesData = new BehaviorSubject<any[]>([]);
  userConfig = {};

  smartObjectMenuOpen = false;

  showActions = false;
  swipe = {
    LEFT: "left",
    RIGHT: "right"
  };

  swipeLeftActive = "";
  swipeRightActive = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {previewData: { highlightData?: any, smartLinkData?: any[], stickyNotesData?: any[], referencesData?: any[], referenceIndexes: any}, isEditable?: boolean, linkText?: string, shouldNavigate?: boolean, isMobileView?: boolean },
    private _smartObjectsService: SmartObjectsService,
    private _smartLinkService: SmartLinkService,
    private _stickyNoteService: StickyNoteService,
    private _remarkService: RemarkService,
    private _dialogRef: MatDialogRef<SmartObjectsPreviewComponent>,
    private _channelRepository: ChannelRepository,
    private _router: Router,
    private _talkRootStore: Store<TalkRootState>,
    private _broadcaster: Broadcaster
  ) {
    this._broadcaster.on<any>(CloseSmartObjectDialog.CLOSE_SMART_OBJECTS_PREVIEW_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this._dialogRef.close();
    });
  }

  ngOnInit(): void {
    this._talkRootStore.select(getUserConfig)
      .pipe(
        takeUntil(this.isAlive$),
        map(config => ({avatar_url: config.avatar_url, name: `${config.firstname} ${config.lastname}`}))
      )
      .subscribe(config => this.userConfig = config);

    this.highlightControl.setValue(this.data?.previewData?.highlightData?.name);

    this.allSmartLinkData = [...(this.data?.previewData?.smartLinkData || [])];
    this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData?.resultData));

    this.$allStickyNotesData.next(this._stickyNoteService.getMappedStickyNoteDropdownData(this.data?.previewData?.stickyNotesData || [], this.userConfig));
    this.$allReferencesData.next(this._remarkService.getMappedReferenceDropdownData(this.data?.previewData?.referencesData || [], true, this.data.previewData.referenceIndexes));
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  onClick() {
    this._smartObjectsService.underDevelopment();
  }

  smartObjectPopupClass() {
    return {
      "smart-objects-preview-component": this.$fullScreen.value === false,
      "vnc-scroll-bar--small--vertical": this.$fullScreen.value === false
    };
  }

  onToggleFullScreen() {
    const dialogContainer = document.querySelector(".smartlink-preview-container");
    const matDialog = document.querySelector(".smartlink-preview-container .mat-dialog-container") as HTMLElement;
    const isCurrentlyFullScreen = this.$fullScreen.getValue();
    isCurrentlyFullScreen ? dialogContainer.classList.remove(FULL_SCREEN_DIALOG) : dialogContainer.classList.add(FULL_SCREEN_DIALOG);
    isCurrentlyFullScreen ? matDialog.classList.remove("vnc-scroll-bar--small--vertical") : matDialog.classList.add("vnc-scroll-bar--small--vertical");
    isCurrentlyFullScreen ? matDialog.style.overflow = "hidden" : matDialog.style.overflow = "auto";
    this.$fullScreen.next(!isCurrentlyFullScreen);
  }

  onSmartLinkHoverActionClick(event: { clickEvent: MouseEvent, actionType: "edit" | "delete" }, searchResult: SmartLinkData["resultData"]) {
    event?.clickEvent?.preventDefault();
    event?.clickEvent?.stopPropagation();
    switch (event.actionType) {
      case "edit": {
        const selectedSmartLinkData = this.allSmartLinkData.find(item => item.resultData.resultType === searchResult?.resultType && item.resultData.id === searchResult?.id);
        this.getSmartLinkPopupData(this.data.linkText, event.clickEvent.clientX, event.clickEvent.clientY, true, searchResult, {title: selectedSmartLinkData?.linkText, description: selectedSmartLinkData?.description}).pipe(takeUntil(this.isAlive$)).subscribe((smartLinkData: SmartLinkData) => {
          if (!!smartLinkData) {
            // @ts-ignore
            const {data} = smartLinkData;
            const {title: linkText, description} = data;
            const updatedData = {...selectedSmartLinkData, linkText, description};
            this.allSmartLinkData = this.allSmartLinkData
              .map(item => (item.resultData["object_type"] === searchResult["object_type"] && item.resultData.id === searchResult.id) ? updatedData : item);
            this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(item => item.resultData));
            this.$showFooterButtons.next(true);
          }
        });
        break;
      }
      case "delete": {
        this.allSmartLinkData = this.allSmartLinkData
          .filter(item => !(item.resultData["object_type"] === searchResult["object_type"] && item.resultData.id === searchResult.id));
        this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
        this.$showFooterButtons.next(true);
        break;
      }
    }
  }

  private getSmartLinkPopupData(selectedText: string, clientX, clientY, skipFirstStep = false, searchResult = null, selectedSmartLinkData = null, alreadyLinkedObjects = null, isMobileView = false): Observable<any> {
    const smartLinkPopupRef = this._smartLinkService.getSmartLinkPopupRef(selectedText, clientX, clientY, true, skipFirstStep, searchResult, selectedSmartLinkData, alreadyLinkedObjects, this.data?.isMobileView);
    return smartLinkPopupRef.afterClosed().pipe(map(value => isMobileView ? value?.data?.previewData : !!skipFirstStep ? value : value?.data?.previewData[0]), take(1));
  }

  openSmartLinkPreview(event, item) {
    const smartLinkData = this.allSmartLinkData.find(s => s?.resultData?.id === item?.id);
    const data = {
      smartLinkData: [smartLinkData],
      smartLinkName: this.data.linkText,
      isEditable: false,
      shouldNavigate: this.data.shouldNavigate,
      isMobileView: this.data?.isMobileView,
      openedFromSmartObject: true
    };
    const smartLinkPreviewRef = this._smartLinkService.getSmartLinkPreviewRef(data, event?.clientX, event?.clientY, data.isMobileView);
    smartLinkPreviewRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        !!res?.closePopup ? this._dialogRef.close() : noop();
      });
  }

  onHighlightHoverActionClick(event: { clickEvent: MouseEvent, actionType: "edit" | "delete" }) {
    switch (event?.actionType) {
      case "edit": return this.$editHighlightName.next(true);
      case "delete":
        delete this.data.previewData.highlightData;
        this.highlightControl.setValue("");
        this.$showFooterButtons.next(true);
        break;
    }
  }

  completeHighlightEdit() {
    this.$editHighlightName.next(false);
    if (this.data?.previewData?.highlightData?.name !== this.highlightControl.value) {
      this.$showFooterButtons.next(true);
    }
    this.removeRightSwipes();
  }

  onStickyNoteHoverActionClick(event: { clickEvent: MouseEvent, actionType: "edit" | "delete" | "preview" }, selectedNote) {
    event?.clickEvent?.preventDefault();
    event?.clickEvent?.stopPropagation();
    switch (event?.actionType) {
      case "edit":
        const stickyNotePopupRef = this._stickyNoteService.getStickyNotePopupRef(
          selectedNote?.uid,
          event?.clickEvent?.clientX,
          event?.clickEvent?.clientY,
          this._stickyNoteService.getMappedStickyNoteDataForEditMode(selectedNote),
          false,
          true,
          false,
          this.data?.isMobileView
        );
        stickyNotePopupRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(res => {
            if (res?.action === StickyNoteActions.SAVE) {
              this.onStickyNoteAddedOrEdited(res);
            }
          });
        break;
      case "preview":
        const stickyNotePopupRefForPreview = this._stickyNoteService.getStickyNotePopupRef(
          selectedNote?.uid,
          event?.clickEvent?.clientX,
          event?.clickEvent?.clientY,
          this._stickyNoteService.getMappedStickyNoteDataForEditMode(selectedNote),
          this.data?.isMobileView ? true : !this.data.isEditable,
          false,
          false,
          this.data?.isMobileView
        );
        stickyNotePopupRefForPreview
          .afterClosed()
          .pipe(take(1))
          .subscribe(res => {
            if (res?.action === StickyNoteActions.DELETE) {
              this.$allStickyNotesData.next(this.$allStickyNotesData.getValue().filter(note => note?.uid !== selectedNote?.uid));
              this.$showFooterButtons.next(true);
            }
            if (res?.action === StickyNoteActions.SAVE) {
              this.onStickyNoteAddedOrEdited(res);
            }
          });
        break;
      case "delete":
        this.$allStickyNotesData.next(this.$allStickyNotesData.getValue().filter(note => note?.uid !== selectedNote?.uid));
        this.$showFooterButtons.next(true);
        break;
    }
  }

  async onReferenceHoverActionClick(event: { clickEvent: MouseEvent, actionType: "edit" | "delete" | "preview" }, selectedReference) {
    event?.clickEvent?.preventDefault();
    event?.clickEvent?.stopPropagation();
    switch (event?.actionType) {
      case "edit":
        const {uid, description: remarkText} = selectedReference;
        const remarkPopupRef = await this._remarkService.getRemarkPopupRef({uid, remarkText}, event?.clickEvent?.clientX, event?.clickEvent?.clientY, this.data?.isMobileView);
        remarkPopupRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(data => {
            if (data?.action === "save") {
              const {selectedData} = data?.data;
              let allReferences = this.$allReferencesData.getValue();
              const indexOfSelectedReference = allReferences.findIndex(ref => ref.uid === selectedData.uid);
              const referenceData = this._remarkService.getMappedReferenceDropdownData([data], false)?.[0];
              referenceData.reference_index = selectedReference.reference_index;
              this.$allReferencesData.next([...allReferences.slice(0, indexOfSelectedReference), referenceData, ...allReferences.slice(indexOfSelectedReference + 1)]);
              this.$showFooterButtons.next(true);
            }
          });
        break;
      case "preview":
        return this._smartObjectsService.underDevelopment();
      case "delete":
        this.$allReferencesData.next(this.$allReferencesData.getValue().filter(ref => ref?.uid !== selectedReference?.uid));
        this.$showFooterButtons.next(true);
        break;
    }
  }

  onHeaderAndFooterActionClick(event: { clickEvent: MouseEvent, actionType: SmartObjectActions }) {
    switch (event.actionType) {
      case SmartObjectActions.ADD_SMART_LINK: return this.onAddSmartLink(event);
      case SmartObjectActions.ADD_STICKY_NOTE: return this.onAddStickyNote(event);
      case SmartObjectActions.ADD_REFERENCE: return this.onAddReference(event);
      case SmartObjectActions.ADD_HIGHLIGHT: return this.onAddHighlight();
      case SmartObjectActions.UPDATE: return this.onUpdateSmartObject();
      case SmartObjectActions.DELETE: return this.onDeleteSmartObject();
      case SmartObjectActions.CANCEL: return this.onDiscardChanges();
    }
  }

  private onAddSmartLink(event) {
    const smartLinkName = this.data.linkText;
    let alreadyLinkedObjects = {};
    this.allSmartLinkData.forEach(s => {
      alreadyLinkedObjects[s?.resultData?.id] = s?.resultType;
    });
    this.getSmartLinkPopupData(smartLinkName, event.clickEvent.clientX, event.clickEvent.clientY, false, null, null, alreadyLinkedObjects, this.data.isMobileView).pipe(takeUntil(this.isAlive$)).subscribe((smartLinkData) => {
      if (this.data.isMobileView && smartLinkData?.length > 0) {
        this.$showFooterButtons.next(true);
        this.allSmartLinkData = [...this.allSmartLinkData, ...smartLinkData];
        this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
      }
      if (!this.data.isMobileView && !!smartLinkData) {
        this.$showFooterButtons.next(true);
        this.allSmartLinkData.push(smartLinkData);
        this.$filteredSmartSearchResults.next(this.allSmartLinkData.map(smartLinkData => smartLinkData.resultData));
      }
    });
  }

  private onAddStickyNote(event) {
    const stickyNotePopupRef = this._stickyNoteService.openStickyNotePopup(event.clickEvent, "", false, true, this.data?.isMobileView);
    stickyNotePopupRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res?.action === StickyNoteActions.SAVE) {
          this.onStickyNoteAddedOrEdited(res);
        }
      });
  }

  private async onAddReference(event) {
    const remarkPopupRef = await this._remarkService.openRemarkPopup(event.clickEvent, this.data?.isMobileView);
    remarkPopupRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(data => {
        if (data?.action === "save") {
          const allReferences = this.$allReferencesData.getValue();
          const referenceData = this._remarkService.getMappedReferenceDropdownData([data], true)?.[0];
          this.$allReferencesData.next([...allReferences, referenceData]);
          this.$showFooterButtons.next(true);
        }
      });
  }

  private onAddHighlight() {
    this.highlightControl.setValue(this.data.linkText);
    this.$showFooterButtons.next(true);
  }

  private onUpdateSmartObject() {
    const stickyNotes = this.$allStickyNotesData.getValue();
    const data = {
      smartLinkData: this.allSmartLinkData,
      highlightData: {name: this.highlightControl.value},
      stickyNotesData: [...this._stickyNoteService.getMappedStickyNotesForReqPayload(stickyNotes)],
      referencesData: this._remarkService.getMappedReferencesForReqPayload(this.$allReferencesData.getValue())
    };
    const newData = {...data};

    if (this.allSmartLinkData.length  === 0) {
      delete newData.smartLinkData;
    }

    if (!this.highlightControl.value) {
      delete newData.highlightData;
    }

    if (this.$allStickyNotesData.getValue().length === 0) {
      delete newData.stickyNotesData;
    }

    if (this.$allReferencesData.getValue().length === 0) {
      delete newData.referencesData;
    }

    this._dialogRef.close({ data: {...newData}, action: Object.keys(newData)?.length > 0 ? SmartObjectActions.UPDATE : SmartObjectActions.DELETE } );
  }

  private async onDeleteSmartObject() {
    const dialog = await this._smartObjectsService.getConfirmationPopupRef(
      "DELETE_SMART_OBJECT",
      "DELETE_SMART_OBJECT_MSG",
      "DELETE"
    );
    dialog.afterClosed()
      .pipe(take(1))
      .subscribe((res: any) => {
        if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this._dialogRef.close({ data: {}, action: SmartObjectActions.DELETE } );
          }
        }
      });
  }

  private async onDiscardChanges() {
    const changeDetected = this.$showFooterButtons.getValue();
    if (changeDetected) {
      const dialog = await this._smartObjectsService.getConfirmationPopupRef();
        dialog.afterClosed()
        .pipe(take(1))
        .subscribe((res: any) => {
          if (!!res && res.confirmation) {
            if (res.confirmation === "yes") {
              this.onUpdateSmartObject();
            } else {
              return this._dialogRef.close();
            }
          }
        });
    } else {
      return this._dialogRef.close();
    }
  }

  private onStickyNoteAddedOrEdited(data) {
    const { noteData } = data;
    let allStickyNotes = this.$allStickyNotesData.getValue();
    if (noteData?.uid) {
      allStickyNotes = allStickyNotes.filter(note => note.uid !== noteData.uid);
    }
    const newNote = {
      uid: Utils.makeid(15),
      description: noteData?.note_description,
      color_scheme: this._stickyNoteService.getColorSchemeByBgColor(noteData?.bg_color_hex),
      date: format(new Date(), "dd.MM.yyyy"),
      author: {...this.userConfig}
    };
    this.$allStickyNotesData.next([...allStickyNotes, newNote]);
    this.$showFooterButtons.next(true);
  }

  handleSwipeForEntity(uid: string, direction: string, disableEdit = false) {
    const smartEntity = document.getElementById(uid);
    switch (direction) {
      case (this.swipe.LEFT):
        if (this.swipeRightActive === uid) {
          this.removeRightSwipes();
          this.swipeRightActive = "";
          return;
        }
        this.removeSwipeActions();
        smartEntity.classList.add("wrapper-swiped-left");
        this.swipeLeftActive = uid;
        break;
      case (this.swipe.RIGHT):

        if (this.swipeLeftActive === uid) {
          this.removeLeftSwipes();
          this.swipeLeftActive = "";
          return;
        }
        if (disableEdit) {
          return;
        }
        this.removeSwipeActions();
        smartEntity.classList.add("wrapper-swiped-right");
        this.swipeRightActive = uid;
        break;
    }
  }

  private removeLeftSwipes() {
    const alreadySwipedEntities = document.getElementsByClassName("wrapper-swiped-left");
    Array.from(alreadySwipedEntities).forEach(el => el.classList.remove("wrapper-swiped-left"));
  }

  private removeRightSwipes() {
    const alreadySwipedEntities = document.getElementsByClassName("wrapper-swiped-right");
    Array.from(alreadySwipedEntities).forEach(el => el.classList.remove("wrapper-swiped-right"));
  }

  removeSwipeActions() {
    this.removeLeftSwipes();
    this.removeRightSwipes();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

}
export const FULL_SCREEN_DIALOG = "full-screen-dialog";
