
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createAction, props } from "@ngrx/store";
import {
  PAD__CREATE_CURRENT_TARGET,
  PAD__CREATE_NEW_PAD,
  PAD__CREATE_NEW_PAD_SUCCESS,
  PAD__HIDE_PAD_WINDOW,
  PAD__LOAD_PAD_URL,
  PAD__LOAD_PAD_URL_SUCCESS,
  PAD__LOAD_PADS,
  PAD__LOAD_PADS_SUCCESS, PAD__SAVE_CURRENT_TARGET,
  PAD__SHOW_PAD_WINDOW,
  PAD__IS_LOADING,
  PAD__RESET,
  PAD__DELETE_PAD,
  PAD__DELETE_PAD_REQUEST,
  PAD__RENAME_PAD,
  PAD__RENAME_PAD_REQUEST,
  PAD__SELECT_PAD,
} from "./pad.types";
import { ResponseCreatedPad } from "../interfaces/response-created-pad.interface";
import { LoadPadsSuccess } from "../interfaces/load-pads-success.interface";
import { PadUrlData } from "../interfaces/pad-url-data.interface";
import { LoadPadsActionProps } from "../interfaces/load-pads.interface";

export const loadPads: any = createAction(
  PAD__LOAD_PADS,
);

export const loadPadsSuccess: any = createAction(
  PAD__LOAD_PADS_SUCCESS,
  props<LoadPadsSuccess>()
);

export const loadPadUrl: any = createAction(
  PAD__LOAD_PAD_URL,
  props<PadUrlData>()
);

export const loadPadUrlSuccess: any = createAction(
  PAD__LOAD_PAD_URL_SUCCESS,
  props<{ responseCreatedPad: ResponseCreatedPad }>()
);

export const createNewPad: any = createAction(
  PAD__CREATE_NEW_PAD,
  props<{ target: string, name: string, clearTarget: string, plainTarget: string , sourceId?: string}>()
);

export const renamePad: any = createAction(
  PAD__RENAME_PAD,
  props<{ padid: string, padname: string }>()
);

export const renamePadRequest: any = createAction(
  PAD__RENAME_PAD_REQUEST,
  props<{ padid: string, padname: string }>()
);

export const deletePad: any = createAction(
  PAD__DELETE_PAD,
  props<{ padID: string }>()
);

export const selectPad: any = createAction(
  PAD__SELECT_PAD,
  props<{ padID: string }>()
);

export const deletePadRequest: any = createAction(
  PAD__DELETE_PAD_REQUEST,
  props<{ padID: string }>()
);

export const createNewPadSuccess: any = createAction(
  PAD__CREATE_NEW_PAD_SUCCESS,
);

export const isLoadingPads: any = createAction(
  PAD__IS_LOADING,
);

export const showPadWindow: any = createAction(
  PAD__SHOW_PAD_WINDOW,
);

export const hidePadWindow: any = createAction(
  PAD__HIDE_PAD_WINDOW,
);

export const resetPads: any = createAction(
  PAD__RESET,
);

export const saveCurrentTarget: any = createAction(
  PAD__SAVE_CURRENT_TARGET,
  props<{ target: string, plainTarget: string }>()
);

export const createCurrentTarget: any = createAction(
  PAD__CREATE_CURRENT_TARGET,
  props<LoadPadsActionProps>(),
);
