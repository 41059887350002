
<!--
  ~ VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vt-notification" *ngIf="item.type !== 'withIcon' && item.type !== 'mention' && item.type !== 'meeting' && item.type !== 'videocall' &&
this.item.type !== 'audiocall' && !item.type.startsWith('wakeup')
&& item.type !== 'custom' && item.type !== 'screen' && item.type !== 'info' && item.type !== 'allowed' && item.type !== 'rejected' && this.item.type !== 'rejoin' && item.type !== 'muted'
&& item.type !== 'recording' && item.type !== 'startedRecording' && item.type !== 'stoppedRecording' && item.type !== 'channels'
"
    [@enterLeave]="item.state"
    (click)="onClick($event)"
    [class]="theClass"
    (mouseenter)="onEnter()"
    (mouseleave)="onLeave()">
    <div [attr.id]="item.type !== 'active' && (item.type === 'video' || item.type === 'audio' || item.type.startsWith('screen')) ? 'incomingCallContainer':''" *ngIf="!item.html" class="vt-notification-container {{ item.type }}"
    [style.visibility]="isScreenSharing ? 'hidden':'visible'"
    [ngClass]="{'active-call': item.type === 'active', 'active-meeting': isActiveMeeting, 'incoming-call': item.type !== 'active' && (item.type === 'video' || item.type === 'audio' || item.type.startsWith('screen')), 'whiteboard': item.type === 'whiteboard'}">
     <div class="tap-to-return-call own-thumbnail" *ngIf="item.type === 'active' && floatingParticipant">
      <vp-participant
      videoOnly="true"
      [size]="'preview'" [participant]="floatingParticipant">
      </vp-participant>
     </div>
     <ng-container *ngIf="item.type !== 'active'">
      <div class="vt-notification-top {{ item.type }}" [ngClass]="{'active-call': item.type === 'active', 'whiteboard': item.type === 'whiteboard'}">
        <div class="vt-notification-avatar">
          <vp-avatar *ngIf="item.override && item.type === 'active'" [jid]="item.override.conversationTarget" [hidestatus]="true" [size]="'notification'" [type]="item.override.chatType"></vp-avatar>
          <vp-avatar *ngIf="item.type !== 'active' && item.override && item.override.type === 'groupchat'" [jid]="item.override.room" [hidestatus]="true" [size]="'notification'" [type]="item.override.chatType"></vp-avatar>
          <vp-avatar *ngIf="item.type !== 'active' && item.override  && item.override.type !== 'groupchat'" [jid]="item.bare" [hidestatus]="true" [size]="'notification'" ></vp-avatar>
          <vp-avatar *ngIf="item.type !== 'active' && !item.override" [jid]="item.bare" [hidestatus]="true" [size]="'notification'" ></vp-avatar>
        </div>
        <div class="vt-notification-close-icon">
          <svg _ngcontent-kef-c374="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs _ngcontent-kef-c374="">
              <filter _ngcontent-kef-c374="" id="y97glj2rma" width="125.6%" height="171.9%" x="-12.8%" y="-35.9%" filterUnits="objectBoundingBox">
                <feOffset _ngcontent-kef-c374="" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur _ngcontent-kef-c374="" in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"></feGaussianBlur>
                <feColorMatrix _ngcontent-kef-c374="" in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0"></feColorMatrix><feMerge _ngcontent-kef-c374=""><feMergeNode _ngcontent-kef-c374="" in="shadowMatrixOuter1"></feMergeNode><feMergeNode _ngcontent-kef-c374="" in="SourceGraphic"></feMergeNode>
                </feMerge>
              </filter>
            </defs>
            <g _ngcontent-kef-c374="" fill="none" fill-rule="evenodd">
              <g _ngcontent-kef-c374="" fill="#8B96A0" filter="url(#y97glj2rma)" transform="translate(-320 -16)">
                <g _ngcontent-kef-c374="">
                  <path _ngcontent-kef-c374="" d="M16.95 7.05c.39.39.39 1.024 0 1.414L13.414 12l3.536 3.536c.39.39.39 1.023 0 1.414-.39.39-1.024.39-1.414 0L12 13.414 8.464 16.95c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414L10.586 12 7.05 8.464c-.39-.39-.39-1.023 0-1.414.39-.39 1.024-.39 1.414 0L12 10.586l3.536-3.536c.39-.39 1.023-.39 1.414 0z" transform="translate(320 16)">
                  </path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="vt-notification-content-wrapper" *ngIf="item.override && item.type !== 'rfc'">
            <div class="vt-notification-title" *ngIf="item.override && item.type !== 'whiteboard'"><span class="notification-title-content">{{ item.content|translate }}</span></div>
            <div class="vt-notification-title" *ngIf="item.override && item.type === 'whiteboard'"><span class="notification-title-content">{{ item.content|translate }}</span></div>
          <div class="vt-notification-content" *ngIf="item.override && item.type === 'active' && item.override.chatType === 'groupchat'">{{ 'BY'|translate }} {{ roomName }}</div>
          <div class="vt-notification-content" *ngIf="item.override && item.type === 'active' && item.override.chatType !== 'groupchat'">{{ 'BY'|translate }} {{ item.override.conversationTarget | vpGetFullName }}</div>
          <div class="vt-notification-content" *ngIf="item.override && item.override.chatType === 'groupchat' && item.type !== 'active'">{{ 'BY'|translate }} {{ roomName }}</div>
          <div class="vt-notification-content" *ngIf="item.override && item.override.chatType !== 'groupchat' && item.type !== 'active'">{{ 'BY'|translate }} {{ item.title | vpGetFullName }}</div>
        </div>
        <div class="vt-notification-content-wrapper" *ngIf="item.override && item.type === 'rfc'">
            <div class="vt-notification-title"><span class="notification-title-content">{{ item.title | translate }}</span></div>
            <div class="vt-notification-content" [innerHTML]="item.content"></div>
        </div>
        <div class="vt-notification-content-wrapper" *ngIf="!item.override">
          <div class="vt-notification-title" *ngIf="!item.override">
            <!-- <mat-icon>chat_bubble</mat-icon> -->
            <vnc-icon type='custom' name='chat' width='16px' height='16px' [color]="'#000'"></vnc-icon>
             <span class="notification-title-content" *ngIf="item.type !== 'groupchat'">{{ item.bare | vpGetFullName }}</span>
             <span class="notification-title-content" *ngIf="item.type === 'groupchat'">{{ roomName || item.title }}</span>
            </div>
          <!--NG_TODO: figure out from shorten pipe was coming earlier magically and add it back.-->
          <div class="vt-notification-content" *ngIf="!item.override" id="notificationText" [innerHTML]="item.content|vpProcessText"></div>
        </div>
      </div>
     </ng-container>
      <!--Audio/Video Call-->
      <div id="incomingCallActionMenu" class="vt-notification-actions {{ item.type }}" *ngIf="item.type === 'audio' || item.type === 'video' || item.type === 'screen'">
        <div class="actions-inner">
          <button id="declineIncCallBtn" class="decline-call" mat-mini-fab color="primary" (click)="$event.stopPropagation();decline()"><mat-icon>call_end</mat-icon></button>
          <button id="joinVideoCallBtn" *ngIf="item.type === 'audio' || item.type === 'video'" class="call-video-on" mat-mini-fab color="primary"
                  (click)="$event.stopPropagation();startVideo()"
                  [disabled]="isOnSafari"
                  [ngStyle]="{'opacity': isOnSafari ? 0.5 : 1}"><mat-icon>videocam</mat-icon></button>
          <button id="joinAudioCallBtn" *ngIf="item.type === 'audio' || item.type === 'video'" class="call-audio-on" mat-mini-fab color="primary" (click)="$event.stopPropagation();startAudio()"><mat-icon>call</mat-icon></button>
          <button id="joinScreenShareSession" *ngIf="item.type === 'screen'" class="call-audio-on" mat-mini-fab color="primary" (click)="$event.stopPropagation();acceptScreenShareSession()"><mat-icon>call</mat-icon></button>
        </div>
      </div>
      <!--Whiteboard Invitation-->
      <div class="vt-notification-actions {{ item.type }}" *ngIf="item.override && item.type === 'whiteboard'">
        <div class="actions-inner whiteboard">
          <button class="deny-whiteboard" mat-button (click)="$event.stopPropagation();denyWhiteboardInvitation()"><mat-icon>close</mat-icon></button>
          <button class="accept-whiteboard" mat-button (click)="$event.stopPropagation();acceptWhiteboardInvitation()"><mat-icon>check</mat-icon></button>
          </div>
      </div>
      <!-- RFC-->
      <div class="vt-notification-actions {{ item.type }}" *ngIf="item.override && item.type === 'rfc'">
          <div class="actions-inner whiteboard">
            <button class="deny-whiteboard" mat-button (click)="$event.stopPropagation();denyRfcInvitation()"><mat-icon>close</mat-icon></button>
            <button class="accept-whiteboard" mat-button (click)="$event.stopPropagation();acceptRfcInvitation()"><mat-icon>check</mat-icon></button>
            </div>
      </div>
    </div>
    <div *ngIf="item.html" class="vt-notification-html vt-notification-content-wrapper {{ item.type }}">
      <div *ngIf="item.title" class="vt-notification-title">{{ item.title }}</div>
      <div class="vt-notification-content" [innerHTML]="item.html"></div>
    </div>
</div>
<div class="vt-notification mention-notification" *ngIf="item.type === 'mention'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          <span class="notification-title-content mention-text">
            <svg style="width: 16px; fill:rgb(0, 0, 0)" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"></path></svg>
            {{ roomName || item.title }}</span>
          </div>
        <div class="vt-notification-content mention-text" [innerHTML]="item.content|vpProcessText"></div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification video-meeting-notification" *ngIf="item.type === 'meeting'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          {{ item.title }} {{ 'VIDEO_MEETING' | translate }} "{{ meetingName }}"
          </div>
        <div class="meeting-actions">
          <button (click)="remove()">{{ 'CLOSE' | translate }}</button>
          <button (click)="goToMeeting()" class="go-meeting-btn">{{ 'GO_TO_MEETING' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification recording-notification" *ngIf="item.type === 'recording'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top recording-notification-wrapper">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          <mat-icon fontSet="mdi" fontIcon="mdi-record-rec"></mat-icon>
          {{ 'RECORDING_IS_COMPLETED' | translate }}
          <vnc-icon-container class="close-notification" matTooltip="{{ 'CLOSE' | translate }}" (click)="closeNotification()">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Header/close" transform="translate(-600.000000, -14.000000)" fill="#7A858F"><g id="Icon/product/close-new" transform="translate(600.000000, 14.000000)"><path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path"></path></g></g></g></svg>
          </vnc-icon-container>
        </div>
        <div class="vt-notification-content">
          {{ 'THE_RECORDING_IS_UPLOADED_TO_FILES' | translate: {'fileName': item.override?.fileName } }}
        </div>
        <div class="recording-actions">
          <button (click)="openFiles()" class="open-file-btn">{{ 'OPEN_IN_FILES' | translate }}</button>
          <button (click)="downloadFile()" class="open-file-btn">{{ 'DOWNLOAD' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification recording-notification" *ngIf="item.type === 'startedRecording'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top recording-notification-wrapper started-recording">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          <mat-icon fontSet="mdi" fontIcon="mdi-record-rec"></mat-icon>
          <span>{{ 'RECORDING_STARTED' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification recording-notification" *ngIf="item.type === 'stoppedRecording'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top recording-notification-wrapper started-recording">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          <mat-icon fontSet="mdi" fontIcon="mdi-record-rec"></mat-icon>
          <span>{{ 'STOPPED_RECORDING' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification screenshare-notification" *ngIf="item.type === 'screen'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top recording-notification-wrapper">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          <div class="presenter-avatar">
            <vp-avatar [jid]="item.bare" size="notification" hidestatus="true"></vp-avatar>
            <div class="screenshare-icon">
              <svg class="screen-icon" xmlns="http://www.w3.org/2000/svg" width="51.393" height="46.721" viewBox="0 0 51.393 46.721">
                <path fill="#4d4d4f" d="M52.393 6.672V34.7a4.656 4.656 0 0 1-4.672 4.672H33.7V34.7h14.021V6.672H5.672V34.7h14.016v4.672H5.672A4.671 4.671 0 0 1 1 34.7V6.672A4.656 4.656 0 0 1 5.672 2h42.049a4.671 4.671 0 0 1 4.672 4.672M29.032 27.7h7.008l-9.34-9.348-9.348 9.348h7.008v16.349h-7.008v4.672h18.689v-4.672h-7.009z" transform="translate(-1 -2)"/>
            </svg>
            </div>
          </div>
        </div>
        <div class="vt-notification-content">
          <div class="content">
            {{ 'SHARE_SCREEN_WITH_YOU' | translate: {fullName: fullName } }}
          </div>
          <div class="screen-actions">
            <button (click)="decline()" class="decline-btn">{{ 'DECLINE' | translate }}</button>
            <button (click)="startCall('screen-receive');remove()" class="accept-screenshare">{{ 'ACCEPT' | translate }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div class="vt-notification video-meeting-notification raise-hand-message" *ngIf="item.type === 'custom'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          <span *ngIf="item.override.bare" class="notification-avatar" style="padding: 0px 10px 0px 2px;">
            <vp-avatar [jid]="item.override.bare" atComponent="tile-view" size="tile-view" hidestatus="true"></vp-avatar>
          </span>
          {{ item.html }}
          </div>
        <button class="close-notification" (click)="$event.stopPropagation();remove()">
          <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
      </button>
      </div>
    </div>
  </div>
</div>

<div class="vt-notification video-meeting-notification" *ngIf="item.type === 'info'"
(click)="onClick($event)">
  <div class="vt-notification-container ">
    <div class="vt-notification-top">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-title">
          {{ item.html }}
          </div>
      </div>
    </div>
  </div>
</div>

<div class="vt-notification video-meeting-notification" *ngIf="item.type === 'allowed' || item.type === 'rejected' || item.type === 'muted'"
(click)="remove()">
  <div class="vt-notification-container ">
    <div class="vt-notification-top">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-content raise-notification">
          <div *ngIf="item.type  === 'allowed'"  class="status-icon-notification allowed">
            <mat-icon fontSet="mdi" fontIcon="mdi-volume-high"></mat-icon>
          </div>
          <div *ngIf="item.type  === 'rejected'"  class="status-icon-notification rejected">
            <mat-icon fontSet="mdi" fontIcon="mdi-volume-mute"></mat-icon>
          </div>
          <div *ngIf="item.type  === 'muted'"  class="status-icon-notification muted">
            <mat-icon fontSet="mdi" fontIcon="mdi-microphone"></mat-icon>
          </div>
          <span [innerHTML]="item.html"></span>
          <mat-icon (click)="remove()" class="close-raise" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification video-meeting-notification" *ngIf="item.type.startsWith('wakeup') || item.type === 'withIcon'"
(click)="remove()">
  <div class="vt-notification-container ">
    <div class="vt-notification-top">
      <div class="vt-notification-content-wrapper">
        <div class="vt-notification-content raise-notification" *ngIf="item.type !== 'withIcon'">
          <mat-icon class="wake-up-icon" *ngIf="!item.override.participantId" fontIcon="mdi-sleep-off" fontSet="mdi"></mat-icon>
          <span>{{ item.html }}</span>
        </div>
        <div class="vt-notification-content icon-notification" *ngIf="item.type === 'withIcon'">
          <mat-icon class="notify-icon" fontIcon="{{ item.override.iconName }}" fontSet="mdi"></mat-icon>
          <span>{{ item.html | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="vt-notification channel-notification" *ngIf="item.type === 'channels'">
  <vnc-notification-push
  [type]="item?.override?.type"
  [desctiption]="item.content"
  [imgSrc]="item?.override?.imgSrc"
  [authorAvatar]="item?.override?.authorAvatar"
  [title]="item.title"
  [isPrivate]="item?.override?.isPrivate"
  [object]="item?.override?.object"
  [defaultImageIndex]="item?.override?.defaultImageIndex"
  (onNotificationClick)="clickOnNotificationItem($event)"
  (onClose)="closeNotification()">
  </vnc-notification-push>
</div>