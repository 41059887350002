
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable, SecurityContext } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { MiddlewareService } from "./middleware.service";
import { Conversation } from "../models/conversation.model";
import { environment } from "../../environments/environment";
import { MessageSearchResponse } from "../../responses/message-search.response";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Message } from "../models/message.model";
import { SearchMessage } from "../models/search-message.model";
import { CommonUtil, MediaType } from "../utils/common.util";
import { Subject } from "rxjs";
import { MessageUtil } from "../utils/message.util";
import { ConfigService } from "app/config.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { Whiteboard } from "../models/whiteboard.model";
import { XmppService } from "app/talk/services/xmpp.service";
import { catchError, map, take, tap, timeout } from "rxjs/operators";
import { of } from "rxjs";
import formatISO from "date-fns/formatISO";
import { LoggerService } from "app/shared/services/logger.service";
import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";

@Injectable()
export class ConversationService {
  lastEtagOfgetConversations = null;

  constructor(
    private middlewareService: MiddlewareService,
    private configService: ConfigService,
    private http: HttpClient,
    private electronService: ElectronService,
    private logger: LoggerService,
    private readonly dompurifySanitizer: NgDompurifySanitizer,
    private xmppService: XmppService) {
  }

  getConversations(offset?: number, limit: number = environment.conversationsCount, updatedAfter: number = 0): Observable<{ eod: boolean, conversations: Conversation[], ownLastAvatarUpdate?: number, error: boolean, isModified: boolean }> {
    const rs = new Subject<any>();
    this.logger.info(new Date().toISOString() + " [conversation.service][convHistory][getConversations]  updatedAfter", updatedAfter);
    let params = {
      "resultCount": limit,
      "offset": offset,
    };
    const updatedTemp = (updatedAfter < 0) ? 0 : updatedAfter;
    const ul = updatedTemp.toString().length;
    this.logger.info(new Date().toISOString() + " [conversation.service][convHistory][getConversations]  updatedAfterLength", ul);
    if (offset < 0) {
      params["offset"] = 0;
      params["updated_before"] = (ul > 12) ? Math.floor(updatedTemp / 1000) + 1 : updatedTemp + 1;
    } else {
      params["updated_after"] = (ul > 12) ? Math.floor(updatedTemp / 1000) : updatedTemp;
    }

    this.middlewareService.getWithStatusInterception("/api/convHistory", true, params).pipe(map(res => {
      const isModified = (CommonUtil.isOnIOS() || CommonUtil.isOnAndroid()) ? true : this.lastEtagOfgetConversations !== res.etag && res.status >= 200;
      this.lastEtagOfgetConversations = res.etag;

      this.logger.info(new Date().toISOString() + " [conversation.service][convHistory][getConversations]", this.lastEtagOfgetConversations, res.etag, res.status, isModified);

      if (!this.xmppService.getIsConnectedXMPP()) {
        this.logger.info("[conversationService] staleNetworkInformation - reconnect?");
        this.xmppService.tryToForceReconnect();
      }

      return {
        conversations: isModified ? res.conversations.map(conv => {
          return this.mapMiddlewareConversationToConversation(conv);
        }) : [],
        eod: res.eod,
        ownLastAvatarUpdate: res.ownLastAvatarUpdate,
        isModified,
        error: false
      };
    })).subscribe((data) => {
      this.logger.info(new Date().toISOString() + " [conversation.service][convHistory][getConversations] handle res data: ", data);
      rs.next(data);
    }, (err) => {
      this.logger.info(new Date().toISOString() + " [conversation.service][convHistory][getConversations] handle err: ", err);
      const jsonResponse = {
        conversations: [],
        status: 200,
        eod: true
      };
      rs.next(jsonResponse);
    });
    return rs.asObservable();
  }

  setE2EEForPrivateConv(target: string, enableE2EE: boolean): Observable<any> {
    const data = {
      target,
      e2e: enableE2EE
    };

    return this.middlewareService.post("/api/setdirecte2e", true, data);
  }

  createFileCopy(data): Observable<any> {
    return this.middlewareService.post("/api/copyfile", true, data);
  }

  getSupportUrl(): Observable<string> {
    return this.middlewareService.get<string>("/api/supportssotoken", true, {}).pipe(map(res => {
      return res;
    }));
  }

  getContactForShare(id): Observable<string> {
    return this.middlewareService.get<string>(`/api/getcontactForShare?id=${id}`, true, {}).pipe(map(res => {
      return res;
    }));
  }

  importContact(blob): Observable<any> {
    return this.http.post(`/api/contactsplusImport`, blob, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "text/vcard")
    });
    // return this.middlewareService.post("/api/contactsplusImport?content_type=text/x-vcard", true, blob)
  }


  getPreviewUrl(url: string): Observable<any> {
    return this.middlewareService.get<any>("/api/urlpreview", true, {url: url});
  }

  getSurveys(target: string): Observable<any> {
    return this.middlewareService.get<any>(`/api/surveys/${target}`, true);
  }

  createSurvey(name: string, target: string, value: any, expiry: number, config?: string, status?: string): Observable<any> {
    const settings = config || "{}";
    return this.middlewareService.post<any>(`/api/surveys/${target}`, true, {name, value, expiry, settings, status: status || "new"});
  }

  deleteSurvey(target: string, id: any): Observable<any> {
    return this.middlewareService.delete<any>(`/api/surveys/${target}/${id}`, true);
  }

  getSurveyResults(id: any): Observable<any> {
    return this.middlewareService.getWithError<any>(`/api/surveyresults/${id}`, true);
  }

  deleteSurveyResult(id: any): Observable<any> {
    return this.middlewareService.delete<any>(`/api/surveyresults/${id}`, true);
  }

  updateSurveyResultStatus(id: any, status: string): Observable<any> {
    return this.middlewareService.put<any>(`/api/surveyresults/${id}/status`, true, {status});
  }

  addSurveyResult(id: any, value: any, status = "new"): Observable<any> {
    return this.middlewareService.post<any>(`/api/surveyresults/${id}`, true, {value, status});
  }

  checkPreviewEditable(issueId: string): Observable<any> {
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.get<any>(`/api/issues/${issueId}/edit`, {headers});
    }
    return this.middlewareService.get<any>(`/api/issues/${issueId}/edit`, true);
  }

  updateIssues(issueId: string, changes: any): Observable<any> {
    const data = {
      issue: changes
    };
    if (!!localStorage.getItem("redmineAuthorization")) {
      const token = localStorage.getItem("redmineAuthorization");
      let headers: HttpHeaders = new HttpHeaders({"Authorization": token, "Content-Type": "application/json"});
      return this.http.put<any>(`/api/issues/${issueId}`, data, {headers});
    }
    return this.middlewareService.put<any>(`/api/issues/${issueId}`, true, data);
  }

  getRoomMembers(target: string): Observable<any> {
    // console.trace("[ConversationService][getRoomMembers]", target);

    return this.middlewareService.get<any>("/api/roomMembers/" + encodeURIComponent(target), true, {});
  }

  getAllRoomsMembers(updatedAfter?: number): Observable<any> {
    // console.trace("[ConversationService][getAllRoomsMembers]");

    const params = {};
    if (updatedAfter) {
      params["updated_after"] = Math.floor(updatedAfter / 1000);
    }
    return this.middlewareService.get<any>("/api/roomMembers/all", true, params);
  }

  deleteConversation(target: string): Observable<any> {
    return this.middlewareService.delete("/api/conversationHistory", true, {
      "target": target
    });
  }

  archiveConversation(conv: Conversation): Observable<Conversation> {
    this.logger.info("[ConversationService][archiveConversation]", conv);
    return this.updateConversationStatus(conv, {
      archived: true
    });
  }

  toggleConversationFavorite(conv: Conversation, flag?: boolean): Observable<Conversation> {
    const signal = {
        type: "favourite",
        data: JSON.stringify(flag),
        target: conv.Target
      };
    this.xmppService.sendSignalToMyself(signal);
    return this.middlewareService.post("/api/setfavchat", true, {target: conv.Target, isfav: flag});
  }

  togglePinConversation(conv: Conversation, flag?: boolean): Observable<Conversation> {
    const signal = {
        type: "pin",
        data: JSON.stringify(flag),
        target: conv.Target
      };
    this.xmppService.sendSignalToMyself(signal);
    return this.middlewareService.post("/api/setpinchat", true, {target: conv.Target, ispinned: flag});
  }

  changeOrderPinConversation(pinnedOrder): Observable<Conversation> {
    return this.middlewareService.post("/api/pinorder", true, pinnedOrder);
  }

  setRetention(conv: Conversation, value: number): Observable<Conversation> {
    const signal = {
        type: "setRetention",
        data: value,
        target: conv.Target
      };
    this.xmppService.sendSignalToMyself(signal);
    return this.middlewareService.post("/api/setretention", true, {target: conv.Target, retention_time: value});
  }

  unArchiveConversation(conv: Conversation, lastConvoTimestamp?: number): Observable<Conversation> {
    this.logger.info("[ConversationService][unArchiveConversation]", conv);
    return this.updateConversationStatus(conv, {
      archived: false
    }, lastConvoTimestamp);
  }

  createInactiveConversation(conv: Conversation): Observable<any> {
    this.logger.info("[ConversationService][archiveConversation] createInactiveConversation", conv);
    return this.updateConversationStatus(conv, {
      state: "inactive"
    });
  }

  createActiveConversation(conv: Conversation): Observable<any> {
    this.logger.info("[ConversationService][archiveConversation] createActiveConversation", conv);
    return this.updateConversationStatus(conv, {
      state: "active"
    });
  }

  deleteJitsiRoom(confKey: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    return this.middlewareService.delete("/api/confMap/" + encodeURIComponent(confKey), true);
  }

  getJitsiRoom(confKey: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    return this.middlewareService.get("/api/confMap/" + encodeURIComponent(confKey), true);
  }

  checkActiveConference(roomId: string): Observable<any> {
    return this.middlewareService.get("/api/confactive/" + encodeURIComponent(roomId), false);
  }

  checkJoinableConference(roomId: string, iomDomain?: string, sentBy?: string): Observable<any> {
    let api = "/api/check-joinable-conference/" + encodeURIComponent(roomId);
    if (!!iomDomain) {
      api += "?iomDomain=" + iomDomain;
    }
    if (!!sentBy) {
      if (api.indexOf("?") !== -1) {
        api += "&sentBy=" + sentBy;
      } else {
        api += "?sentBy=" + sentBy;
      }
    }
    return this.middlewareService.get(api, false);
  }

  createJitsiRoom(confKey: string, jitsiRoom: string, jitsiURLparam?: string): Observable<any> {
    confKey = confKey.replace("\n", "");
    let jitsiURL: string = jitsiURLparam || this.configService.get("jitsiURL") || "";
    const lastIndex = jitsiURL.lastIndexOf("/");
    if (lastIndex > 0) {
      jitsiURL = jitsiURL.slice(0, lastIndex + 1);
    }
    const data = {
      value: jitsiRoom,
      jitsiurl: jitsiURL + jitsiRoom
    };

    this.logger.info("[ConversationService][createJitsiRoom]", data, this.configService.get("jitsiURL"));

    return this.middlewareService.post("/api/confMap/" + encodeURIComponent(confKey), true, data);
  }

  getAccountStillValid(): Observable<any> {
    return this.middlewareService.getWithError("/api/getAccountStillValid", true, {});
  }

  getMessageTags(messageId: string): Observable<string[] | null> {
    return this.middlewareService.getWithError("/api/messages/" + encodeURIComponent(messageId) + "/tags", true, {});
  }

  addMessageFavorite(messageId: string): Observable<null> {
    return this.middlewareService.post("/api/messages/" + encodeURIComponent(messageId) + "/tags", true, {
      "add": ["*"]
    });
  }

  removeMessageFavorite(messageId: string): Observable<null> {
    return this.middlewareService.post("/api/messages/" + encodeURIComponent(messageId) + "/tags", true, {
      "remove": ["*"]
    });
  }

  fetchAvatarUpdateInfo(data: any): Observable<any> {
    return this.middlewareService.fetchAvatarUpdateInfo(data);
  }

  globalSearch(keyword: string, offset: number, params?: {
    nb?: string,
    na?: string,
    tags?: string[],
    users?: string[],
    mention?: string
  }, select?: string): Observable<MessageSearchResponse> {
    let data: {[key: string]: any} = {
      // added empty quotes for keyword
      // https://redmine.vnc.biz/issues/63607#note-3
      // textMatch: "\"" + keyword + "\"",
      textMatch: keyword,
      offset: offset,
      rows: 20,
    };

    if (params) {
      data["na"] = params.na;
      data["nb"] = params.nb;
      data["tags"] = params.tags;
      if (params.mention) {
        data["mention"] = params.mention;
        delete data["textMatch"];
      }
    }

    let key = "peerMatch";
    let isGroupChat = false;

    // PIYUSH_COMMENT adding select temporarily. It should work later on after removing this whe APIs are done.
    if (select) {
      isGroupChat = select.indexOf("@conference") !== -1;
      const key = isGroupChat ? "roomMatch" : "peerMatch";

      data[key] = select;
    } else if (params) {
      data[key] = params.users;
    }
    this.logger.info("[conversation.service] globalSearch with: ", select, isGroupChat, data);
    if (!isGroupChat) {
      data["excludeGroupchat"] = true;
    }

    if (!select) {
      data["excludeGroupchat"] = false;
    }
    data["excludeConference"] = true;
    return this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/search-messages", true, data).pipe(map(data => {
      return {
        ...data,
        docs: data.docs.map(doc => this.mapServerMessage(doc))
        // lost: originalCount - data.docs.length, // should not use this way
      };
    }));
  }

  solrSearch(keyword: string, offset: number, params?: any): Observable<any> {
    let data: {[key: string]: any} = {
      textMatch: keyword,
      offset: offset,
      limit: 30,
    };

    if (params) {
      data = {...data, ...params};
    }
    return this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/v2/search", true, data)
    .pipe(map((v: any) => v.response));
  }

  recentSearch(body: any): Observable<any> {
    return this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/v4/recent-search", true, body);
  }

  searchContacts(body: any): Observable<any> {
    return this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/v2/search-contacts", true, body);
  }

  joinGroupRequest(target: string): Observable<any> {
    return this.middlewareService.post<MiddlewareMessageSearchResponse>(`/api/joingrouprequest/${target}`, true, {});
  }

  grantGroupRequest(target: string, grantee: string): Observable<any> {
    return this.middlewareService.post<MiddlewareMessageSearchResponse>(`/api/grantgrouprequest/${target}`, true, {grantee});
  }

  getPendingJoinRequests(target: string): Observable<any> {
    return this.middlewareService.get<MiddlewareMessageSearchResponse>(`/api/pendingjoinrequests/${target}`, true);
  }


  /// Messages

  loadMessages(bare: string, maxItems: number, type: string, paginateFromEnd: boolean = true, offset: number = 0, startTimestamp?: number, endTimestamp?: number): Observable<any> {

    let data = {};
    data["rows"] = maxItems;
    data["offset"] = 0;
    data["tags"] = [];

    if (bare.indexOf("@conference") !== -1) {
      data["roomMatch"] = bare;
      data["excludeGroupchat"] = false;
      data["confId"] = bare;
    } else {
      data["peerMatch"] = bare;
      data["excludeGroupchat"] = true;
    }

    if (startTimestamp) {
      // https://stackoverflow.com/questions/58561169/date-fns-how-do-i-format-to-utc
      const date = new Date(startTimestamp - 30 * 60 * 1000);
      data["nb"] = formatISO(date);
      // data["nb"] = `${format(date, "yyyy-MM-dd")}T${format(date, "HH:mm:ss")}Z`;
    }
    if (endTimestamp) {
      const date = new Date(endTimestamp);
      data["na"] = formatISO(date);
      // data["na"] = `${format(date, "yyyy-MM-dd")}T${format(date, "HH:mm:ss")}Z`;
    }

    if (offset) {
      data["offset"] = offset;
    }

    const response = new Subject<any>();

    this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/sync-messages", true, data).subscribe(res => {
      const messages = this.parseMessages(res.docs);

      response.next({ messages : messages, total : res.numFound });
    }, err => {
      this.logger.error("[ConversationService][loadMessages] err", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  syncMessagesAfter(after?: number): Observable<any> {
    const data = { timestamp: after };
    const response = new Subject<any>();

    this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/sync-after", true, data).subscribe(res => {
      const messages = this.parseMessages(res.docs);

      response.next({ messages : messages, total : messages.length });
    }, err => {
      this.logger.error("[ConversationService][sync-after] err", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  backgroundSyncMessagesAfter(after?: number): Observable<any> {
    const data = { timestamp: after };
    const response = new Subject<any>();

    this.middlewareService.backgroundPost("/api/sync-after", true, data).subscribe(res => {
      const messages = this.parseMessages(res);

      response.next({ messages : messages});
    }, err => {
      this.logger.error("[ConversationService][sync-after] err", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  backgroundLoadMessages(convTarget: string, convType: string, nb: number) : Observable<any> {
    const response = new Subject<any>();

    const body: any = {
      "rows": 1000,
      "offset": 0,
      "nb": formatISO(nb)
    };

    if (convType === "groupchat") {
      body["roomMatch"] = convTarget;
      body["excludeGroupchat"] = false;
      body["confId"] = convTarget;
    } else {
      body["peerMatch"] = convTarget;
      body["excludeGroupchat"] = true;
    }

    // this.logger.info("[ConversationService][backgroundLoadMessages] body:", body);
    this.middlewareService.backgroundPost("/api/sync-messages", true, body).subscribe(res => {
      const messages = this.parseMessages(res.docs);
      this.logger.info("[ConversationService][backgroundLoadMessages] parse messages:", messages.length, messages);

      response.next({ messages : messages, total : res.numFound });
    }, err => {
      this.logger.error("[ConversationService][backgroundLoadMessages] err", err);
      response.error(err);
    });


    return response.asObservable().pipe(take(1));
  }


  getMessagesAround(target: string, id: string, rows: number = 100): Observable<any> {
    this.logger.info("[ConversationService][getMessagesAround]", target, id);

    let data = {id, target, rows};

    const response = new Subject<any>();

    this.middlewareService.postWithHeadersInterception("/api/around-msgid", true, data).subscribe(res => {
      const messages = this.parseMessages(res.docs);
      response.next({ messages : messages, total : res.numFound });
    }, err => {
      this.logger.error("[ConversationService][getMessagesAround] err", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesFrom(target: string, id: string, rows: number = 100): Observable<any> {
    this.logger.info("[ConversationService][getMessagesFrom(]", target, id);

    let data = { id, target, rows };

    const response = new Subject<any>();

    this.middlewareService.postWithHeadersInterception("/api/from-msgid", true, data).subscribe(res => {
      const messages = this.parseMessages(res.docs);
      response.next({ messages: messages, total: res.numFound });
    }, err => {
      this.logger.error("[ConversationService][getMessagesFrom(] err", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }


  private parseMessages(docs: any[]) {
    this.logger.info("[ConversationService][parseMessages]", {docs});

    const t1 = performance.now();

    const deletedMessages = [];
    const messages = [];
    docs.forEach(doc => {
      const mappedDoc = this.mapServerMessage(doc);
      const message = MessageUtil.convertToXmppMessage(mappedDoc);

      // deleted message
      if (message.replace?.id && message.body?.trim() === "") {
        deletedMessages.push(message.replace.id);
      } else {
        message.isDeleted = false;
        messages.push(message);
      }
    });

    // process deleted messages, if any
    if (deletedMessages.length > 0) {
      messages.forEach(msg => {
        if (deletedMessages.indexOf(msg.id) !== -1) {
          msg.isDeleted = true;
          msg.body = null;
        }
      });
    }

    const t2 = performance.now();
    this.logger.info(`[PERFORMANCE][ConversationService][parseMessages] took ${t2 - t1} milliseconds.`);

    this.logger.info("[ConversationService][parseMessages]", {messages, deletedMessages});

    return messages;
  }

  getMessagesAroundFirstUnread(target: string, rows: number = 100): Observable<any> {
    this.logger.info("[ConversationService][getMessagesAroundFirstUnread]", {target, rows});

    const data = {target, rows};

    const response = new Subject<any>();

    this.middlewareService.postWithHeadersInterception("/api/around-first-unread", true, data).subscribe(res => {
      const messages = this.parseMessages(res.docs);
      response.next({ messages : messages, total : res.numFound });
    }, err => {
      let unAuthState = (!!err.status && (err.status === 401 || err.status === 403)) ? true : false;
      if (!unAuthState || environment.isElectron || environment.isCordova) {
        this.logger.info("[ConversationService][getMessagesAround] err", err);
      } else {
        this.logger.info("[ConversationService][getMessagesAround] err - redirect", err);
        this.configService.clearStorage();
        this.configService.redirectToLoginScreen();
      }

    });

    return response.asObservable().pipe(take(1));
  }

  ////

  getMedia(type: MediaType, convTarget: string, keyword?: string, offset = 0, nb?: number, limit: number = environment.conversationsCount, users?: any[]): Observable<any> {
    if (!convTarget) {
      return of({docs: [], numFound: 0});
    }
    const isGroupChat = convTarget.indexOf("@conference") !== -1;
    const isBroadcastChat = convTarget.startsWith("broadcast-");
    const key = isGroupChat || isBroadcastChat ? "roomMatch" : "peerMatch";

    const params: {[key: string]: any} = {
      start: offset,
      offset: offset,
      limit: limit,
      rows: limit,
      fileType: type,
      textMatch: keyword,
      [key]: convTarget
    };

    if (nb) {
      params["nb"] = nb;
    }
    if (users && users.length > 0) {
      params["users"] = users;
    }

    if (!isGroupChat) {
      params["excludeGroupchat"] = true;
    }
    if (this.configService.get("useOldSearch")) {
      params.type = type;
      params.offset = offset;
      return this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/search-messages", true, params).pipe(map(data => {
        return {
          ...data,
          docs: data.docs.map(doc => this.mapServerMessage(doc))
        };
      }));
    }

    return this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/v3/search", true, params).pipe(map((data: any) => {
      const parser = new DOMParser();
      if (!data || !data.response) {
        return of([]);
      }
      const response = data.response;
      return {
        ...response,
        docs: response.docs.map((doc: any) => {
          let mappedDoc: any = {
            body: doc.content_txt[0],
            date: doc.created_dt,
            from: doc.from_s,
            id: doc.talk_id_s,
            owner: doc.owner_s,
            sort_id: 176224,
            to: doc.to_s,
            x_attachment: doc.talk_attachment_s
          };
          if (doc.talk_attachment_s) {
            const attachment = doc.talk_attachment_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
            const attachmentDoc = parser.parseFromString(attachment, "text/html");
            if (!!attachmentDoc.querySelector("url")) {
              mappedDoc.x_attachment = JSON.stringify({
                url: (<HTMLElement>attachmentDoc.querySelector("url")).innerText,
                fileSize: !!attachmentDoc.querySelector("fileSize") ? (<HTMLElement>attachmentDoc.querySelector("fileSize")).innerText : 0,
                fileName: !!attachmentDoc.querySelector("fileName") ? (<HTMLElement>attachmentDoc.querySelector("fileName")).innerText : "",
                fileType: attachmentDoc.querySelector("fileType") ? (<HTMLElement>attachmentDoc.querySelector("fileType")).innerText : "",
              });
            } else {
              mappedDoc.x_attachment = attachment.replace("<attachment xmlns=\"xmpp:vnctalk\">", "").replace("</attachment>", "");
            }
          }
          return this.mapServerMessage(mappedDoc);
        })
      };
    }));
  }

  searchGroups(searchString: string, memberName?: string, members?: string[]): Observable<any> {
    const params: any = {
      searchstring: searchString
    };

    if (memberName) {
      params["membername"] = memberName;
    }

    if (members) {
      params["members"] = members;
    }

    return this.middlewareService.post<any>("/api/searchgroup", true, params);
  }

  updateConversationStatus(conv: Conversation, data: {
    archived?: boolean,
    state?: string
  }, lastConvoTimestamp?: number): Observable<Conversation> {
    const timestamp = lastConvoTimestamp ? lastConvoTimestamp : new Date().getTime();
    const conversationObject = {
      "Target": conv.Target,
      "Timestamp": Math.ceil(timestamp / 1000),
      "Content": JSON.stringify({
        content: conv.content || "",
        type: conv.type,
        archived: data.archived || conv.archived,
        state: data.state || conv.state,
      }),
      "unreadids": conv.unreadIds,
      "mute_notification": conv.mute_notification,
      "broadcast_title": conv.broadcast_title,
      "mute_sound": conv.mute_sound,
    };

    return this.middlewareService.post("/api/conversationHistory", true, conversationObject)
      .pipe(map(() => this.mapMiddlewareConversationToConversation(conversationObject)));
  }

  public updateSoundSetting(target: string, type: number): Observable<any> {
    return this.middlewareService.put(`/api/convMute/${target}/${type}`, true);
  }

  public updateNotificationSettings(target: string, type: number): Observable<any> {
    return this.middlewareService.put(`/api/notify/${target}/${type}`, true);
  }

  public sendBotResponse(body): Observable<any> {
    return this.middlewareService.post(`/api/send_bot_response`, true, body);
  }


  public insertPreviousMessage(conv: Conversation, message: Message): Observable<Conversation> {
    let body = message.body;
    if (message.originalMessage) {
      body = message.originalMessage.replyMessage;
    }
    const conversationObject = {
      "Target": conv.Target,
      "Timestamp": Math.ceil(new Date().getTime() / 1000),
      "Content": JSON.stringify({
        content: body,
        type: conv.type,
        state: conv.state,
      }),
      "unreadids": conv.unreadIds,
      "mute_notification": conv.mute_notification,
      "broadcast_title": conv.broadcast_title,
      "mute_sound": conv.mute_sound,
    };

    return this.middlewareService.post("/api/conversationHistory", true, conversationObject)
      .pipe(map(() => this.mapMiddlewareConversationToConversation(conversationObject)));
  }

  public markConversationsRead(conversationTarget: string, timestamp?: number): Observable<any> {
    // console.trace("markConversationsRead");

    let data = {};
    if (timestamp) {
      data[conversationTarget] = Math.round(timestamp / 1000);
    } else {
      data[conversationTarget] = Math.round(new Date().getTime() / 1000);
    }
    const xmppConnected = this.xmppService.getIsConnectedXMPP();
    if (!xmppConnected) {
      data["send_rtc_signal"] = 1;
    }
    this.logger.info("[conversationService][markConversationsRead] ", data, xmppConnected);
    return this.middlewareService.post("/api/markConversationsRead", true, data).pipe(timeout(20000));
  }

  public markBulkConversationsRead(conversationTargets: any): Observable<any> {
    // console.trace("markConversationsRead");
    const xmppConnected = this.xmppService.getIsConnectedXMPP();
    if (!xmppConnected) {
      conversationTargets["send_rtc_signal"] = 1;
    }
    this.logger.info("[conversationService][markConversationsRead] ", conversationTargets, xmppConnected);

    return this.middlewareService.post("/api/markConversationsRead", true, conversationTargets).pipe(timeout(20000));
  }



  public markPadsRead(conversationTarget: string): Observable<any> {
    let data = {};
    data[conversationTarget] = new Date().getTime();
    const signal = {
        type: "pad-read",
        target: conversationTarget
      };
    this.xmppService.sendSignalToMyself(signal);

    return this.middlewareService.post("/api/markPadsRead", true, data).pipe(timeout(10000));
  }

  public getAudienceList(broadcastId: string): Observable<any> {
    return this.middlewareService.get("/api/broadcastAudience/" + encodeURIComponent(broadcastId), true);
  }

  private mapMiddlewareConversationToConversation(conversation: MiddlewareConversation): Conversation {
    const contentObject = JSON.parse(conversation.Content);
    let content = CommonUtil.unEscapeHTMLString(contentObject?.content);
    if (contentObject?.replyMessage) {
      content = CommonUtil.unEscapeHTMLString(contentObject?.replyMessage);
    }
    let timestamp = conversation.Timestamp * 1000;
    if (timestamp === 0 && conversation.updated_at) {
      timestamp = conversation.updated_at * 1000;
      contentObject.has_data = true;
    }
    let type = "chat";
    if (!!localStorage.getItem("conferenceDomain")) {
      type = conversation.Target.indexOf(localStorage.getItem("conferenceDomain")) !== -1 ? "groupchat" : "chat";
    }
    let conv: Conversation = {
      Target: conversation.Target,
      type: contentObject.type ? contentObject.type : type,
      // Adding false as default since all the conv might not have this payload
      archived: contentObject.archived || false,
      state: contentObject.state,
      is_persistent: contentObject.mucpersistent || false,
      incoming: contentObject.incoming || false,
      received_receipt: contentObject.received_receipt || false,
      // read: contentObject.read || false,
      Timestamp: timestamp, // PRASHANT_COMMENT fix 10/13 digit issue between middleware and xmpp
      updated_at: conversation.updated_at,
      content: content,
      displayName: contentObject.displayname,
      conference_start: conversation.x_conference_start,
      pad_read: conversation.pad_read,
      last_mention_stamp: conversation.last_mention_stamp,
      groupChatTitle: contentObject.groupChatTitle || contentObject.displayname,
      unreadIds: conversation.unreadids,
      mute_sound: conversation.mute_sound,
      has_pads: conversation.has_pads,
      has_iom: conversation.has_iom,
      audience_only: conversation.audience_only,
      mute_notification: conversation.mute_notification,
      retention_time: conversation.retention_time,
      deleted: conversation.deleted,
      historystate: "NONE",
      last_message_id: conversation.message_id || "",
      conferenceType: contentObject.x_vncConference ? contentObject.x_vncConference.conferenceType : "chat"
    };
    if (conversation.broadcast_title) {
      conv.broadcast_title = conversation.broadcast_title;
    }
    try {
      if (contentObject.x_conference_scheduler) {
        conv.conference_scheduler = contentObject.x_conference_scheduler.vncTalkConferenceScheduler;
      }
      if (content.startsWith("http://") || content.startsWith("https://") || content.startsWith("ftp://")) {
        conv.historystate = "LINK";
      }
      if (!!contentObject.x_attachment && !!contentObject.x_attachment.fileType) {
        if (CommonUtil.isImage(contentObject.x_attachment.fileType)) {
          conv.historystate = "PHOTO";
        } else if (CommonUtil.isSupportedVideo(contentObject.x_attachment.fileType)) {
          conv.historystate = "VIDEO";
        } else if (CommonUtil.isAudio(contentObject.x_attachment.fileType)) {
          conv.historystate = "VOICE_MESSAGE";
        } else if (CommonUtil.isVCFfile(contentObject.x_attachment.fileType)) {
          conv.historystate = "VCF";
        } else {
          conv.historystate = "DOCUMENT";
        }
      }

      if (!!contentObject.x_vncConference && !!contentObject.x_vncConference.eventType) {
        switch (contentObject.x_vncConference.eventType) {
          case "leave": conv.historystate = "ENDED_CALL"; break;
          case "join": conv.historystate = "JOINED_CALL"; break;
          case "invite": conv.historystate = "STARTED_CALL"; break;
          case "missed" : conv.historystate = "MISSED_CALL"; break;
        }
      }

    } catch (error) {

    }
    if(conversation?.pinorder !== undefined || conversation?.pinorder !== null) {
      conv.pinorder = conversation.pinorder;
    }
    if (conversation.bc_owner) {
      conv.broadcast_owner = conversation.bc_owner;
    }
    if (conversation.total_mentions) {
      conv.total_mentions = conversation.total_mentions;
    }
    if (conversation.unread_mentions) {
      conv.unread_mentions = conversation.unread_mentions;
    }
    conv.has_active_call = conversation.has_active_call;
    conv.isfav = conversation.isfav;
    conv.ispinned = conversation.ispinned;
    if (conversation.lastavatarupdate) {
      conv.lastavatarupdate = conversation.lastavatarupdate;
    }
    conv.encrypted = !!conversation.e2e;
    return conv;
  }

  private mapServerMessage(docMessage: any): SearchMessage {
    const timestamp = new Date(docMessage.date).getTime();
    const resultType = CommonUtil.getMediaType(docMessage.body);
    const type = docMessage.room ? "groupchat" : "chat";
    const sort_id = docMessage.sort_id ? +docMessage.sort_id : null;
    let id: string;
    if (docMessage.id) {
      const splitId = docMessage.id.split("_");
      id = splitId[splitId.length - 1];
    } else {
      id = String(timestamp);
    }

    const msg = {
      ...docMessage,
      id,
      timestamp,
      resultType,
      type
    };
    if (docMessage.expiry) {
      msg.expiry = docMessage.expiry;
    }
    if (sort_id) {
      msg.sort_id = sort_id;
    }

    // omemo
    if (msg.encrypted) {
      const xml2js = require("xml2js");
      const parser = new xml2js.Parser({mergeAttrs: true, explicitArray: false, charkey: "content",
        attrValueProcessors: [(value, name) => {
          if (name === "prekey") {
            if (value === "1") {
              value = true;
            }
          }
          return value;
        }]
      });

      // this.logger.info("msg.encrypted1", msg.encrypted);
      parser.parseString(msg.encrypted, (err, result) => {
        msg.encrypted = result.encrypted;
        delete msg.encrypted.xmlns;

        if (msg.encrypted.header && msg.encrypted.header.key) {
          msg.encrypted.header.keys = Array.isArray(msg.encrypted.header.key) ? msg.encrypted.header.key : [msg.encrypted.header.key];
          delete msg.encrypted.header.key;
        }
      });
      // this.logger.info("msg.encrypted2", msg.encrypted);

      // this.logger.info("msg.encryption1", msg.encryption);
      parser.parseString(msg.encryption, (err, result) => {
        msg.encryption = result.encryption;
        delete msg.encryption.xmlns;
      });
      // this.logger.info("msg.encryption2", msg.encryption);
    }

    return msg;
  }

  public sendEmail(body: EmailData) {
    return this.middlewareService.post("/api/sendmail", true, body)
    .pipe(catchError(error => {
        return throwError(error);
      })
    );
  }

  getRunningConferences(): Observable<any> {
    return this.middlewareService.get(`/api/conference-signal`, true).pipe(map((v: any) => {
      return v.state;
    }));
  }

  getConferencesByRoomIds(roomids) {
    const body = {roomids};
    return this.middlewareService.post(`/api/get-conferences-by-roomids`, true, body);
  }

  // TODO: do we really need it?
  loadFirstMentionedMessages(target: string, bare): Observable<any> {
    let data = {};
    data["rows"] = 1;
    data["offset"] = 0;
    data["tags"] = [];
    data["roomMatch"] = target;
    data["mention"] = bare;
    data["excludeGroupchat"] = false;
    const response = new Subject<any>();
    this.middlewareService.post<MiddlewareMessageSearchResponse>("/api/search-messages", true, data).pipe(map(data => {
      return {
        ...data,
        docs: data.docs.map(doc => this.mapServerMessage(doc))
      };
    })).subscribe(res => {
        let messages = res.docs.map(message => MessageUtil.convertToXmppMessage(message));
        response.next({ messages : messages, total : res.numFound });
      });
    return response.asObservable().pipe(take(1));
  }

  createOrUpdateTag(jid: string, tags: any[]) {
    const objects = {"0": {id: jid, tags_list: tags.join(",")}};
    const body = {
      taggings: {
        product: "vnctalk",
        object_type: "meeting",
        objects: objects
      }
    };
    return this.middlewareService.post(`/api/taggings`, true, body);
  }

  getTags(params?: any): Observable<any> {
    const url = "/api/tags";
    return this.middlewareService.get(url, true, params);
  }

  getTagsByJid(jid): Observable<any> {
    let url = "/api/taggings";
    const params = {
      product: "vnctalk",
      object_type: "meeting",
      object_ids: jid
    };
    const query = Object.keys(params).map(key => {
      return `${key}=${params[key]}`;
    });
    url += "?" + query.join("&");
    this.logger.info("[getTagsByJid]", params);
    return this.middlewareService.get(url, true);
  }

  getMCBList(organizationId) {
    return this.middlewareService.getWithError(`/api/org/${organizationId}/mcbs`, true).pipe(map((res: any) => {
      if (res?.error === "request failed") {
        return [];
      }
      if (!!res) {
        return (res.meta_conference_boards as MetaConferenceBoard[]);
      }
      return [];
    }));
  }

  getWhiteboards(target: string) {
    let newTarget = target;
    if (target.indexOf("@") === -1) {
      newTarget = encodeURIComponent(target.replace(/#/g, "AT"));
    }
    return this.middlewareService.get(`/api/whiteboards/${newTarget}`, true).pipe(map((res: any) => {
      if (res && res.whiteboards) {
        return (res.whiteboards as Whiteboard[]).map(wb => {
          wb.target = target;
          return wb;
        });
      }
      return [];
    }));
  }

  createWhiteboard(target: string, name: string) {
    if (target.indexOf("@") === -1) {
      target = encodeURIComponent(target.replace(/#/g, "AT"));
    }
    const body = {
      whiteboard: {
        name,
        uid: this.buildTargetHash(CommonUtil.randomId(10))
      }
    };
    return this.middlewareService.post(`/api/whiteboards/${target}`, true, body).pipe(map((res: any) => {
      if (!!res && res.whiteboard) {
        return res.whiteboard as Whiteboard;
      }
      return res;
    }));
  }

  getRecordings(target: string) {
    const body = {
      target
    };
    return this.middlewareService.post(`/api/listRecordings`, true, body);
  }

  fetchRecording(convkey: string, callid: string, fileid: string) {
    const body = {
      convkey,
      callid,
      fileid
    };
    const headers = new HttpHeaders();
    headers.set("responseType", "blob");
    return this.http.post(`/api/fetchRecording`, body, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  downloadArchiveAttachment(){
    let _baseUrl = this.middlewareService._baseUrl + "/api/download-zip";
    return this.http.get(_baseUrl, {
      responseType: "blob"
    });
  }

  getCallParticipants(roomJid: string): Observable<any> {
    return this.http.get(`/api/running-call-participants/${roomJid}`);
  }

  updateWhiteboard(target: string, id: number, name: string) {
    if (target.indexOf("@") === -1) {
      target = encodeURIComponent(target.replace(/#/g, "AT"));
    }
    const body = {
      whiteboard: {
        name
      }
    };
    return this.middlewareService.put(`/api/whiteboards/${target}/${id}`, true, body).pipe(map((res: any) => {
      if (!!res && res.whiteboard) {
        return res.whiteboard as Whiteboard;
      }
      return res;
    }));
  }

  deleteWhiteboard(target: string, id: number) {
    if (target.indexOf("@") === -1) {
      target = encodeURIComponent(target.replace(/#/g, "AT"));
    }
    return this.middlewareService.delete(`/api/whiteboards/${target}/${id}`, true);
  }

  buildTargetHash(target) {
    if (this.electronService.isElectron) {
      return this.electronService.md5(target);
    }
    return md5(target);
  }

  purify(value: string): string {
    return this.dompurifySanitizer.sanitize(SecurityContext.HTML, value);
  }
}


export interface MetaConferenceBoard {
  id: number;
  name: string;
  description?: string;
  status: string;
  conferences?: any[];
  created_on: number;
  updated_on: number;
  author?: any;
  totalLive?: number;
  totalScheduled?: number;
  totalEnded?: number;
  totalArchived?: number;
}

interface EmailData {
  to: string[];
  body: string[];
  subject: string;
  description?: string;
}

interface MiddlewareConversation {
  has_active_call?: boolean;
  pinorder?: number;
  x_conference_start?: number;
  isfav?: boolean;
  ispinned?: boolean;
  Target: string;
  Timestamp: number;
  last_mention_stamp?: number;
  updated_at?: number;
  Content: string;
  unreadids: string[];
  mute_sound: number;
  mute_notification: number;
  broadcast_title?: string;
  retention_time?: any;
  bc_owner?: string;
  message_id?: string;
  unread_mentions?: string[];
  total_mentions?: number;
  pad_read?: number;
  deleted?: boolean;
  has_pads?: boolean;
  lastavatarupdate?: number;
  has_iom?: boolean;
  audience_only?: boolean;
  e2e?: boolean;
}

interface MiddlewareMessageSearchResponse {
  numFound: number; // Total Count
  start: number; // Offset
  docs: SearchMessage[];
}

export interface MailFolder {
  id: string;
  name: string;
  title?: string;
  icon?: string;
  absFolderPath?: string;
  activesyncdisabled?: string;
  f?: string;
  i4ms?: string;
  i4next?: string;
  l?: string;
  luuid?: string;
  ms?: string;
  n?: string;
  rev?: string;
  rgb?: string;
  s?: string;
  uuid?: string;
  view?: string;
  webOfflineSyncDays?: string;
  acl?: ACL;
  link?: FolderLink[];
  u?: string;
  children?: MailFolder[];
  originalFolder: any;
  perm?: string;
  zid?: string;
  oname?: string;
  owner?: string;
  color?: number;
  retentionPolicy?: any[];
  shareFolderSearchPath?: string;
  rid?: string;
}


export interface ACL {
  grant: Grant;
}

export interface Grant {
  perm: string;
  d: string;
  gt: string;
  zid: string;
}

export interface FolderLink extends MailFolder {
  oname: string;
  owner: string;
  reminder: string;
  rest: string;
  rid: string;
  ruuid: string;
  zid: string;
}

export interface MailTag {
  id: string;
  name: string;
  color: string;
  rgb?: string;
  u?: string;
  n?: string;
  d?: string;
  rev?: string;
  md?: string;
  ms?: string;
  meta?: any;
  retentionPolicy?: any;
}
