import { ChangeDetectionStrategy, Component, Output, EventEmitter, ChangeDetectorRef, OnDestroy, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Channel } from "app/channels/models/channel.model";
import { TalkRootState } from "app/talk/reducers";
import { CommonUtil } from "app/talk/utils/common.util";
import {Subject, Subscription} from "rxjs";
import { ChannelRepository } from "../../../channels/repository/channel.repository";
import { ChannelService } from "app/channels/channel.service";
import {debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil} from "rxjs/operators";
import { Topic } from "app/channels/models/topic.model";
import { MatMenuTrigger } from "@angular/material/menu";
import {getBare, getUserConfig} from "../../../reducers";
import {SocialService} from "../../../talk/services/social.service";
import {ConfigService} from "../../../config.service";
import {UserConfig} from "../../models/user-config.model";
import { SocialRepository } from "app/talk/repositories/social.repository";
import { Follow } from "app/talk/models/follow.model";
import { Router } from "@angular/router";

export enum ChannelGroupType {
  "DATE_ASC" = "created_on:asc",
  "DATE_DESC" = "created_on:desc",
  "AUTHOR_ASC" = "author:asc",
  "AUTHOR_DESC" = "author:desc"
}

@Component({
    selector: "vp-subscription-center",
    templateUrl: "./subscription-center.component.html",
    styleUrls: ["./subscription-center.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
  })

  export class SubscriptionCenterComponent implements OnDestroy {
    @ViewChild("sortMenuTrigger") sortMenuTrigger: MatMenuTrigger;
    subscriptionTabs = [
      {
        label: "CHANNELS",
        value: "channels",
        active: true
      },
      {
        label: "TOPICS",
        value: "topics",
        active: false
      },
      {
        label: "FOLLOWINGS",
        value: "followings",
        active: false,
        checkIfSocialEnabled: true
      },
      {
        label: "FOLLOWERS",
        value: "followers",
        active: false,
        checkIfSocialEnabled: true
      }
    ];
    searchControl = new UntypedFormControl("");
    groupType = ChannelGroupType;
    groupControl = new UntypedFormControl(this.groupType.DATE_ASC);
    currentTab: string = "channels";
    @Output() onBackClick = new EventEmitter<Event>();
    private isAlive$ = new Subject<boolean>();
    subscribedChannels: Channel[] = [];
    subscribedTopics: Topic[] = [];
    private data = { offset: 0, limit: 25 };

    followersList: any [] = [];
    followingsList: any [] = [];
    searchUsers: any[] = [];
    totalSearchUsersCount: any[] = [];

    jid = "";
    subscription: Subscription;

    isSocialEnabled: boolean = false;
    isSocialModuleEnabled: boolean = false;
    userConfig: UserConfig;
    isInSearchView: boolean = false;

  constructor(
      private channelRepository: ChannelRepository,
      private store: Store<TalkRootState>,
      private channelService: ChannelService,
      private changeDetection: ChangeDetectorRef,
      private _socialRepository: SocialRepository,
      private _talkRootStore: Store<TalkRootState>,
      private _socialService: SocialService,
      private configService: ConfigService,
      private router: Router,
    ) {
      this.channelRepository.selectSubscribedChannels()
      .pipe(takeUntil(this.isAlive$)
      , filter( v => !!v)
      , distinctUntilChanged((p: Channel[], q: Channel[]) => CommonUtil.compareChannelsArrays(p, q)))
      .subscribe(channels => {
        const pinned = channels.filter(v => v.pinned && !v?.is_social);
        const unPinned = channels.filter(v => !v.pinned && !v?.is_social);
        this.subscribedChannels = [...this.sortPinnedChannels(pinned), ...unPinned];
        this.changeDetection.markForCheck();
      });
      this.channelRepository.getSubscribeTopics().pipe(takeUntil(this.isAlive$)).subscribe(res => {
        this.subscribedTopics = res;
        this.subscribedTopics.map(c => {
          c.description = "";
        });
        this.changeDetection.markForCheck();
      });
      this.getFollowersAndFollowings();
      this.searchControl.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(val => {
        if (this.subscriptionTabs[2]?.active || this.subscriptionTabs?.[3]?.active) {
          if (val !== "") {
            this.isInSearchView = true;
            this.searchForUsers(val);
          } else {
            this.isInSearchView = false;
            this.searchUsers = [];
            this.changeDetection.markForCheck();
          }
          return;
        }
        this.searchChannelTopic(val);
      });

      this.configService.getLoadedConfig().subscribe(() => {
        if (this.isSocialEnabled !== this.configService.get("isSocialEnabled")) {
          this.isSocialEnabled = this.configService.get("isSocialEnabled");
          if (this.isSocialEnabled !== undefined) {
            this.setSocialFeatureState();
          }
          this.changeDetection.markForCheck();
        }
      });

      this.store.select(getUserConfig).pipe(takeUntil(this.isAlive$)).subscribe(userConfig => {
        this.userConfig = userConfig;
        if (this.userConfig && this.userConfig.vnc_features) {
          this.setSocialFeatureState();
        }
        this.changeDetection.markForCheck();
      });
    }

  setSocialFeatureState() {
    this.isSocialModuleEnabled = this.isSocialFeatureEnabled();
    this.changeDetection.markForCheck();
  }

  isSocialFeatureEnabled() {
    if (this.isSocialEnabled && this.userConfig && this.userConfig.vnc_features) {
      if (this.userConfig?.vnc_features?.includes("live")) {
        return true;
      }
    }
    return false;
  }

    private searchForUsers(val) {
      if (!val) {
        return;
      }
      this._socialService.searchFollowUnfollow(val).pipe(take(1)).subscribe((resp: any) => {
        if (!!resp && resp.users) {
          this.searchUsers = resp.users;
          this.totalSearchUsersCount = resp.total_count;
          this.changeDetection.markForCheck();
        }
      });
    }

    private getFollowersAndFollowings() {
      if (!this.jid) {
        this.jid = this.getLoggedInUserJid();
      }

      this._socialRepository.getSelectedUserJid().pipe(takeUntil(this.isAlive$), switchMap(id => {
        return this._socialRepository.getSelectedUserFollowings(id);
      })).subscribe( res => {
        if (!!res?.[this.jid]) {
          const ids = res[this.jid].ids;
          setTimeout(() => {
            if (this.subscription) {
              this.subscription.unsubscribe();
            }
            this.subscription = this._socialRepository.getFollowersByIds(ids).pipe(
              distinctUntilChanged((p: Follow[], q: Follow[]) => CommonUtil.compareFollowersArray(p, q))
            ).subscribe(followers => {
              this.followingsList = followers;
              this.changeDetection.markForCheck();
            });
          });
        }
      });

      this._socialRepository.getSelectedUserJid().pipe(takeUntil(this.isAlive$), switchMap(id => {
        return this._socialRepository.getSelectedUserFollowers(id);
      })).subscribe( res => {
        if (!!res?.[this.jid]) {
          const ids = res[this.jid].ids;
          setTimeout(() => {
            if (this.subscription) {
              this.subscription.unsubscribe();
            }
            this.subscription = this._socialRepository.getFollowersByIds(ids).pipe(
              distinctUntilChanged((p: Follow[], q: Follow[]) => CommonUtil.compareFollowersArray(p, q))
            ).subscribe(followers => {
              this.followersList = followers;
              this.changeDetection.markForCheck();
            });
          });
        }
      });
    }

  followUser(user: any) {
    const response = this._socialRepository.followUser(user?.jid);
    if (this.isInSearchView) {
      response.asObservable().subscribe(() => {
        user.following = true;
        this.changeDetection.markForCheck();
      });
    }
  }

  unfollowUser(user: any) {
    const response = this._socialRepository.unFollowUser(user?.jid);
    if (this.isInSearchView) {
      response.asObservable().subscribe(() => {
        user.following = false;
        this.changeDetection.markForCheck();
      });
    }
  }

    private getLoggedInUserJid() {
      let jid = "";
      this._talkRootStore.select(getBare)
        .pipe(take(1))
        .subscribe(bare => jid = bare);
      return jid;
    }

    goBack() {
      this.onBackClick.emit();
    }

    activateTab(tab): void {
      const currentActive = this.subscriptionTabs.find(t => t?.active);
      if (currentActive?.value === tab) {
        return;
      }
      this.searchControl.setValue("");
      this.subscriptionTabs = this.subscriptionTabs.map(t => {
        return {
          ...t,
          active: t?.value === tab
        };
      });
    }

    ngOnDestroy() {
      this.isAlive$.next(false);
      this.isAlive$.complete();
    }

    private sortPinnedChannels(channels: Channel[], order: "asc" | "desc" = "asc") { // sort pinned channels, such that latest pinned comes first (default = 'asc')
    return channels.sort((a: Channel, b: Channel) => {
      const aDate = Date.parse(a?.pinned_at);
      const bDate = Date.parse(b?.pinned_at);
      if (aDate < bDate) {
        return order === "asc" ? 1 : -1;
      } else if (aDate > bDate) {
        return order === "asc" ? -1 : 1;
      }
      return 0;
    });
  }

  onUserScroll(type: string): void {
    let offset = 0;
    if (type === "channels") {
      offset = this.subscribedChannels.length;
      this.channelRepository.loadMoreSubscribedChannels(offset);
    } else if (type === "topic") {
      offset = this.subscribedTopics.length;
      this.channelRepository.loadMoreSubscribeTopicList(offset, 25, this.searchControl.value);
    }
  }

  unsubscribeChannel(item): void {
    this.channelRepository.unsubscribeChannel(item.id).pipe(take(1)).subscribe();
  }

  unsubscribeTopic(item): void {
    this.channelRepository.unsubscribeTopic(item.id).pipe(take(1)).subscribe();
  }

  searchChannelTopic(val): void {
    if (this.subscriptionTabs[0].active) {
      this.channelService.searchChannel(this.searchControl.value, false, [], this.data)
        .subscribe((value: any) => {
          if (value.channels) {
            this.subscribedChannels = value.channels.filter(c => !c?.is_social);
            this.changeDetection.markForCheck();
          }
        }, () => { });
    } else {
      this.channelRepository.getSubscribeTopicList(0, 25, val);
    }
  }

  subscribeTopic(item): void {
    this.channelRepository.subscribeTopic(item.id);
  }

  changeSelection(value: string): void {
    if (value === "date") {
      this.groupControl.patchValue(this.groupType.DATE_ASC);
    } else {
      this.groupControl.patchValue(this.groupType.AUTHOR_ASC);
    }
    this.sortMenuTrigger.closeMenu();
    this.channelRepository.underDevelopmemnt();
  }

  subscribeChannel(item): void {
    if (item.channel_type?.includes("iom")) {
      this.channelRepository.subscribeChannel(item.id, true).subscribe();
    } else {
      this.channelRepository.subscribeChannel(item.id).subscribe();
    }
    item.subscribed = true;
    this.changeDetection.markForCheck();
  }

  userAvatarFailedToLoad(userId, list: string) {
    if (list === "followings") {
      const index = this.followingsList.findIndex(u => u.id === userId);
      this.followingsList[index] = {...this.followingsList[index], avatarFailedToLoad: true};
    }

    if (list === "followers") {
      const index = this.followersList.findIndex(u => u.id === userId);
      this.followersList[index] = {...this.followersList[index], avatarFailedToLoad: true};
    }
    this.changeDetection.markForCheck();
  }

  redirectToTopic(item) {
    this.channelRepository.setSelectedChannelId(item?.channel_id);
    this.router.navigateByUrl(`/talk/channels/${item?.channel_id}/topics/${item?.id}`);
  }
}
