/* eslint no-console: 0 */
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { getIsAppOnline, getIsWindowFocused, getAppSettings,
  getContactStatusById, getBare, getGroupsByName, getIsLoggedIn, getUserProfile, getUserConfig, getXmppOmemoInitState, getFederatedApps } from "./../../reducers/index";
import { ConversationService } from "../services/conversation.service";
import { Store } from "@ngrx/store";
import {
  getArchivedConversations,
  getConversationById,
  getConversationConfig,
  getConversationIds,
  getConversationMembers,
  getConversations,
  getIsSelectedConversationMessagesLoading,
  getIsSelectedConversationMessagesOnLastPage,
  getLastMessageByConversationTarget,
  getMessagesByConversationTarget,
  getIsActivatedConversation,
  getSelectedConversation,
  getSelectedConversationMessages,
  getSelectedConversationText,
  getSelectedMessages,
  getUnArchivedConversations,
  TalkRootState,
  getMessageById,
  getMessageIdsByConversationTarget,
  getConversationOwner,
  getActiveConversation, getConversationRoomId, getSelectedConversationMembers,
  getSelectedConversationMessagesWithContent, hasNotification, getPadNotifications, getAllConversationsUnreadCount, getAllMembersOfSelectedConversation,
  getAllConversationMembers, getConversationAdmins, hasWBNotification, getConversationAudiences, getConversationMCBs,
  getSelectedMiniConversation, getSelectedMiniConversationMessagesWithContent, getAllMembersOfSelectedMiniConversation, getConversationNotificationConfig, getActiveConference, getProcessingE2EMessages, getDownloadInProgress, getPinConverstaions,
  getConversationTextById
} from "../reducers";
import {
  getNetworkInformation
} from "../../reducers";
import { combineLatest, interval, Observable } from "rxjs";
import { Conversation, ConversationConfig } from "../models/conversation.model";
import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { getIsConnectedXMPP, getUserJID,   getIsAppBootstrapped } from "../../reducers";
import { Message, MessageStatus, GroupAction } from "../models/message.model";
import {
  DeleteMessages,
  MessageAdd,
  MessageBulkAppend,
  MessageBulkAppendMultiConversation,
  MessageBulkLoading,
  MessageBulkLoadingFailed,
  MessageDeleteAction,
  MessageDeletedStatusUpdateAction,
  MessageFavoriteUpdateAction,
  MessageLastPageLoaded,
  MessageStatusUpdateAction,
  MultiConversationMessageAdd,
  MultiConversationMessageDeletedStatusUpdateAction,
  ResetMessages,
  SelectMessage,
  UnselectMessage,
  MessageBulkLoaded,
  MessageUpdateAction,
  MessagesBulkUpdate,
  MultiConversationUpdateLastMessage,
  RemoveProcessingMessage,
  AddProcessingMessages,
} from "../actions/message";
import { JID } from "../models/jid.model";
import { XmppService } from "../services/xmpp.service";
import { OmemoDatabaseStorage } from "../services/xmpp.service.omemo";
import { CommonUtil } from "../utils/common.util";
import { ConversationUtil } from "../utils/conversation.util";
import { Broadcaster } from "../shared/providers/broadcaster.service";
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpEventType } from "@angular/common/http";
import { MessageUtil } from "../utils/message.util";
import { AppService } from "../../shared/services/app.service";
import { ConstantsUtil } from "../utils/constants.util";
import {
  ArchiveConversation,
  ConversationBlockListAdd,
  ConversationBlockListRemove,
  ConversationCreate,
  ConversationDelete,
  ConversationNextLoadSuccess,
  ConversationRemoveMember,
  ConversationSelect,
  ConversationActivate,
  ConversationSetConfig,
  ConversationUpdateMembers,
  MultiConversationUpdateMembers,
  UnArchiveConversation,
  UpdateFileInProgress,
  RemoveUploadedFile,
  ResetLastPageLoaded,
  ConversationInActive,
  UpdateNotificationSetting,
  UpdateSoundSetting,
  SetActiveConversation,
  UpdateConversationRoomId,
  ConversationUpdateOwner,
  MultiConversationUpdateOwner,
  ConversationResetUnread,
  ConversationMultipleDelete,
  ConversationRemoveMembers,
  ConversationUpdate,
  ConversationAddPadNotification,
  ConversationRemovePadNotification,
  ConversationUpdateAdmins,
  MultiConversationUpdateAdmins,
  ConversationUpdateBroadcastData,
  ConversationAddMembers,
  ConversationDataUpdate,
  ConversationUpdateMCB,
  ConversationUpdateAudiences,
  MiniConversationSelect,
  UpdateConversationNotificationConfig,
  UpdateConversationCallFlag,
  UpdateConversationFavFlag,
  UpdateConversationPinFlag,
  ConversationDeactivate,
  UpdateRetentionTime,
  MultiConversationUpdateAudience,
  DownloadFileInProgress,
  ResetDownloadInProgress,
  UpdateConversationPinOrder,
  ConversationContentUpdate
} from "../actions/conversation";
import { BehaviorSubject } from "rxjs";
import { HideGlobalSearch, ResetDownloadFileIds } from "../actions/layout";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import { GroupSettings } from "../models/group-settings.model";
import { DatabaseService } from "../services/db/database.service";
import { DisableGlobalMute, EnableGlobalMute, SetActiveTab, SetChatBackgroundImages } from "../../actions/app";
import { of, forkJoin } from "rxjs";
import { ContactRepository } from "./contact.repository";
import { JitsiOption } from "../models/jitsi-participant.model";
import { ElectronService } from "app/shared/providers/electron.service";
import { Contact } from "app/talk/models/contact.model";
import { AuthService } from "app/shared/services/auth.service";
import { SearchGroup } from "../models/search-group.model";
import { TranslateService } from "@ngx-translate/core";
import { UserStatus } from "app/shared/models";
import { ContactRest } from "../models/contact-rest.model";
import { Notification } from "../notifications/notifications.model";
import { DatetimeService } from "../services/datetime.service";
import { P2PDataChannelService } from "../services/p2p-datachannel.service";
import { AvatarRepository } from "./avatar.repository";
import { GroupChatsService } from "../services/groupchat.service";
import { ConfigService } from "app/config.service";
import { ConferenceUtil } from "../utils/conference.util";
import { FilesStorageService } from "../services/files-storage.service";
import { WhiteboardBulkAppend, WhiteboardUpdateAction, WhiteboardDeleteAction } from "../actions/whiteboard";
import { saveAs } from "file-saver";
import {TOPIC_MENTION_MESSAGE_RECEIVER} from "../../channels/comments/comments.component";
import { bufferTime, distinctUntilChanged, filter, finalize, map, pairwise, retry, sampleTime, debounceTime, skip, switchMap, take, tap} from "rxjs/operators";
import { META_TASK_MENTION, TICKET_MENTION } from "../shared/components";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotificationService } from "../services/notification.service";
import { LoggerService } from "app/shared/services/logger.service";
import differenceInMonths from "date-fns/differenceInMonths";
import addHours from "date-fns/addHours";
import format from "date-fns/format";
import differenceInSeconds from "date-fns/differenceInSeconds";
import addMinutes from "date-fns/addMinutes";
import { catchError } from "rxjs";
import { NotificationService as NewNotificationService } from "../../talk/services/notification.service";
import { PadService } from "../chat-window/pad/services/pad.service";
import { CommonService } from "app/shared/providers";
import { ContactInformation } from "../models/vcard.model";
import { exec } from "child_process";
import { VncLibraryService } from "vnc-library";
import { MatDialog } from "@angular/material/dialog";
import { ToastService } from "app/shared/services/toast.service";
import { SnackbarType } from "app/channels/models/snackbar.model";
import { ChannelSnackbarService } from "app/channels/channel-snackbar.service";

const MESSAGES_PER_PAGE = environment.messagesCount;

const TOTAL_GROUP_TO_JOIN = 10;

const URL_REGEXP = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;()\s]*[-A-Z0-9+&@#\/%=~_|])/ig;

@Injectable()
export class ConversationRepository {
  messageToReply: any;
  userJID: JID;
  currentConv: Conversation;
  //
  currentConvUnreadIds: string[];
  currentMentionedMessageId: string;
  //
  openSearch$ = new Subject<any>();
  contentForClipBoard = {};
  sendDeleteMessage$ = new Subject<any>();
  private joinedConversations: string[] = [];
  private joinedConversationsLater: string[] = [];
  private colors = ConstantsUtil.CHAT_BUBBLE_COLORS;
  private pendingMessages: Message[] = [];
  private pendingReadRequest = {};
  private isConnectedXMPP = false;
  private isAppOnline = false;
  private shouldMark = true;
  private isLoggedIn: boolean;
  private storePendingRequest = new Subject();
  private localDeviceId: number = 0;
  private identityKeyPair: any;
  private tryToDecryptPreInitIds = [];
  sendReceipt: boolean;
  subscribedUsers = [];
  allowScreenSharing: boolean;
  item: Notification;
  private messageUpdates$ = new Subject<any>();
  public callInvitationType: string;
  public callInvitationName: string;
  pendingPadReadRequest: any = {};
  lastMarkedAsRead: number;
  lockMarkAsRead: boolean;
  storePendingPadRequest: any = new Subject();
  isGroupManageEnabled: boolean = false;
  isAllRoomsMembersSynced = false;
  isInitialAppStart: boolean = true;
  leftRoomList = [];
  joinedPublicList = [];
  initialOmemoSessions = {};
  triggerGallary = new Subject<any>();
  bootstrapped: boolean = false;
  isBgSyncInProgress: boolean = false;
  omemoMamQueue: string[] = [];
  private _onPresenceMuc$ = new Subject<any>();
  private _onPresenceMucAddMember$ = new Subject<any>();
  private _onNickChange$ = new Subject<any>();
  private _removeMessagesFromPendings$ = new Subject<any>();
  convDragEvent$ = new Subject<boolean>();
  private presencesErrorsToIgnore = {};

  // public isRedirectedToChatViaChromeNotification: boolean;
  organizationId: number;
  apps: any = {};
  loadedGroupInfo = [];
  recentChat$ = new BehaviorSubject<string[]>([]);
  omemoRefreshList = new BehaviorSubject<string[]>([]);
  runningConferences$ = new BehaviorSubject<string[]>([]);
  scheduledConferences = [];
  isOnNativeMobileDevice: boolean;
  scheduledConferencesShow: boolean;
  deletedMessages: string[] = [];
  lastTimeCheck: any = {};
  processingE2EMessages = [];
  decryptedMessages: any = [];
  isConvHistoryRunning: boolean;
  isSendingPendingFiles: boolean;
  previousSyncTimestamp: number;
  sendAttachmentSubscription: any = {};
  downloadFileSubscription: any = {};
  downloadChunkArray: any = {};
  omemoDBstore: any;
  changeChatBackgroundImageId: any;
  isGroupChat: boolean = false;
  conversationInfo$ = new Subject<any>();
  udpateFavouriteEvent$ = new Subject<any>();
  broadcastDescriptions: string = "";
  broadcastTags: any[] = [];

  editLinkQuillEditorClicked$: any = new Subject();
  quillToolBarOptionClickedInMobile$: any = new Subject();
  botConnectFails = 0;
  WS: WebSocket;
  snackBarProgress: any;
  numberOfFiles: number = 0;
  clipboardAttachment: any;
  websocketData$ = new Subject<any>();
  sentFile$ = new Subject<any>();
  setFocus$ = new Subject<any>();
  profileInfoForRightSidebar$ = new Subject<any>();

  profileInfoOpenedByCircularMenu$ = new Subject<any>();
  showOnlyProfileTab : boolean = false;

  resetRedminePreview$ = new Subject<any>();
  unreadMessagesCount: {} = {};
  allowSleepAgainTimeout: NodeJS.Timeout;
  refreshConversationsRunning: boolean;

  constructor(private conversationService: ConversationService,
    private xmppService: XmppService,
    private http: HttpClient,
    private store: Store<TalkRootState>,
    private broadcaster: Broadcaster,
    private appService: AppService,
    private contactRepo: ContactRepository,
    private router: Router,
    private logger: LoggerService,
    private authService: AuthService,
    private translate: TranslateService,
    private vncLibraryService: VncLibraryService,
    private snackbar: MatSnackBar,
    public notificationService: NotificationService,
    private databaseService: DatabaseService,
    private datetimeService: DatetimeService,
    private p2pDataChannelService: P2PDataChannelService,
    private configService: ConfigService,
    private filesStorageService: FilesStorageService,
    private avatarRepo: AvatarRepository,
    private groupChatsService: GroupChatsService,
    private electronService: ElectronService,
    public newNotificationService: NewNotificationService,
    private padService: PadService,
    private commonService: CommonService,
    private dialog:MatDialog,
    private toastService: ToastService,
    private channelSnackBarService: ChannelSnackbarService,


  ) {
      this.configService.getLoadedConfig().subscribe(() => {
        if (this.configService.get("botWS")) {
          this.connectAIBackend();
        }
      });
      this.store.select(getAllConversationsUnreadCount).subscribe(unread => {
        this.unreadMessagesCount = unread;
      });
      if (!!localStorage.getItem("lastTimeCheck")) {
        try {
          this.lastTimeCheck = JSON.parse(localStorage.getItem("lastTimeCheck"));
        } catch (error) {
          this.logger.info("cannot parse last time check", error);
        }
      }
      if (!this.configService.isAnonymous) {
        this.initData();
      }
      this.store.select(getFederatedApps).subscribe(apps => {
        if (!!apps) {
          apps.forEach(app => {
            this.apps[app.name] = app.options.url;
          });
        }
        this.logger.info("getFederatedApps", apps, this.apps);
      });

      this.store.select(getProcessingE2EMessages).subscribe(v => {
       this.processingE2EMessages = v || [];
      });

    this.isOnNativeMobileDevice = CommonUtil.isOnNativeMobileDevice();
    this.lastMarkedAsRead = 0;
    this.lockMarkAsRead = false;
    this.isSendingPendingFiles = false;
    this.omemoDBstore = new OmemoDatabaseStorage(this.databaseService, this.logger);
    /*
    this.getUnArchivedConversations().pipe(distinctUntilChanged(), take(1)).subscribe(conversations => {
      // this.logger.info("[conversationRepo] getUnArchivedConversations1: ", conversations);
      const now = new Date();
      const convs = conversations.filter(v => differenceInMonths(now, new Date(v.Timestamp)) < 1 && this.lastTimeCheck[v.Target] < now.getTime() - 60000);
      if (convs.length > 0) {
        // this.logger.info("[conversationRepo] getUnArchivedConversations1 convs: ", convs);
        this.processConversationsMessages(convs);
      }
    });
    */
  }

  connectAIBackend() {
    this.logger.info("connectAIBackend this.WS ", this.botConnectFails, this.WS?.readyState, this.WS);
    if (!environment.isElectron && this.botConnectFails < 10) {
      if (!this.WS || this.WS.readyState !== WebSocket.OPEN) {
        this.logger.info("connectAIBackend this.WS => new");
        const rayUrl = (environment.isElectron) ? `ws://localhost:8000/ws` : this.configService.get("botWS");
        this.WS = new WebSocket(
          // `ws://localhost:8000/ws`
          rayUrl
        );
        this.WS.onclose = (e) => {
          setTimeout(() => {
            this.logger.info("connectAIBackend reTry this.WS => new");
            this.botConnectFails++;
            this.connectAIBackend();
          }, 500);
        };
        this.WS.onmessage = (e) => {
          this.setWSData(e.data);
        };
      }
    }
  }

  updateMessageBody(message) {
    try {
      const data = JSON.parse(message);
        if (data.status === "finished") {
          if (data.response?.trim() === "") {
            data.response = "NO_RESULTS";
          }
          this.store.dispatch(new ConversationContentUpdate({target: data.target, content: data.response}));
          this.getMessageById(data.id).pipe(take(1)).subscribe(msg => {
            if (msg) {
              msg.body = data.response;
              this.updateMessage(msg, data.target);
            }
          });
        }
    } catch (e) {
      this.logger.error("updateMessageBody error", e);
    }

  }


  setWSData(data) {
    this.logger.info("setWSData", data);
    this.websocketData$.next(data);
  }

  openUrl(url) {
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(url);
    } else {
      window.open(url, "_blank");
    }
  }

  openUrlApp(url, app) {
    const openURL = new URL(url);
    this.logger.info("openUrlApp: ", url, app, openURL);

    if (environment.isCordova) {
      const nativeLink = app + "://" + openURL.pathname + openURL.search;
      this.appService.checkInstalledApp(app + "://", app).pipe(take(1)).subscribe(installed => {
        const url2open = (installed) ? nativeLink : url;
        this.logger.info("[conversationRepo] openUrlApp: ", installed, url2open);
        if (device.platform === "iOS") {
          window.open(url2open, "_system");
        } else if (device.platform === "Android") {
          navigator.app.loadUrl(url2open, {
            openExternal: true
          });
        }
      });
    } else if (environment.isElectron) {
      try {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol(app + "://");
        this.logger.info("[conversationRepo] nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          if (app === "vncmail") {
            let nativeLink = app + "://" + url.split("mail/")[1];
            this.electronService.openExternalUrl(nativeLink);
          } else {
            this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(url));
          }
        } else {
          this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(url));
        }
      } catch (e) {
        this.logger.error("[AppSwitcherComponent] error: ", e);
      }
    } else {
      window.open(CommonUtil.translateAppURLtoDeeplinkURL(url), "_blank");
    }
  }


  createTask(email) {
    this.logger.info("[conversationrepo][openexternalapp][createtask] ", email);
    let url;
    const appUrl = this.apps["vnctask"] || "";
    this.logger.info("task server", appUrl);
    const domain = this.userJID.domain;
    const target = ((email.indexOf("@") > -1) && !!domain && email.split("@")[1] === domain) ? email.split("@")[0] : email;
    if (appUrl.endsWith("/")) {
      url = appUrl  + "task/open?action=compose&assignee=" + target;
    } else {
      url = appUrl  + "/task/open?action=compose&assignee=" + target;
    }
    this.openUrlApp(url, "vnctask");
  }

  createTicket(email) {
    this.logger.info("[conversationrepo][openexternalapp][createticket] ", email);
    let url;
    const rawAppUrl = this.apps["vncproject"] || "";
    const appUrl = CommonUtil.getURLwithoutPath(rawAppUrl);
    this.logger.info("ticket server", appUrl);
    if (appUrl.endsWith("/")) {
      url = appUrl  + "issues/new?assignee_username=" + email.split("@")[0];
    } else {
      url = appUrl  + "/issues/new?assignee_username=" + email.split("@")[0];
    }
    this.openUrl(url);
  }

  composeMail(email) {
    this.logger.info("[conversationrepo][openexternalapp][composeMail] ", email);
    let url;
    const appUrl = this.apps["vncmail"] || "";
    this.logger.info("mail server", appUrl);
    if (appUrl.endsWith("/")) {
      url = appUrl  + "mail/compose?to=" + email;
    } else {
      url = appUrl  + "/mail/compose?to=" + email;
    }
    this.openUrlApp(url, "vncmail");
  }

  private translateMailFolderId(folderId: string): string {
    this.logger.info("translateMailFolderId: ", folderId);
    let fid = parseInt(folderId);
      if (fid === 2) {
        return "inbox";
      }
      if (fid === 3) {
        return "trash";
      }
      if (fid === 4) {
        return "junk";
      }
      if (fid === 5) {
        this.logger.info("translateMailFolderId: sent");
        return "sent";
      }
      return "folder/" + folderId;
    }

  openMail(folderId: any, mailId: any) {
    this.logger.info("[conversationrepo][openexternalapp][openMail] ", folderId, mailId);
    let url;
    let folderPart = this.translateMailFolderId(folderId);
    if (this.apps["vncmail"].endsWith("/")) {
      url = this.apps["vncmail"]  + "mail/" + folderPart + "/detail/m/" + mailId;
    } else {
      url = this.apps["vncmail"]  + "/mail/" + folderPart + "/detail/m/" + mailId;
    }
    this.openUrlApp(url, "vncmail");
  }


  initData() {
    this.store.select(getUserJID).pipe(filter(v => !!v)).subscribe(jid => {
      this.logger.info("[ConversationRepository] getUserJID", jid);
      this.userJID = jid;
      const botJid = this.configService.get("botJid");
      if (!!botJid && (botJid.indexOf("@") > -1) && (botJid.split("@")[1] === this.userJID?.domain)) {
        this.connectAIBackend();
      }
    });


    this.databaseService.getLocalDevice().pipe(take(1)).subscribe(localDevice => {
      if (!!localDevice && !!localDevice.id) {
        this.localDeviceId = localDevice.id;
      }
    });

    this.databaseService.getIdentityKeyPair().pipe(take(1)).subscribe(keyPair => {
      this.identityKeyPair = keyPair;
    });

    this.databaseService.getAllSessions().pipe(take(1)).subscribe(omemosessions => {
      omemosessions.forEach(session => {
        this.initialOmemoSessions[session.id] = session.session;
      });
      // this.logger.info("[ConversationRepoOmemoSessions]", this.initialOmemoSessions);
    });


    this.store.select(getIsAppBootstrapped).pipe(distinctUntilChanged()).subscribe(v => {
      if (!this.bootstrapped) {
        if (!!v) {
          this.bootstrapped = true;
          this.logger.info("[RootComponent][getIsAppBootstrapped]", v);
          this.store.select(getUserConfig).subscribe(userConfig => {
            this.logger.info("[getUserConfig]", userConfig);
            if (userConfig) {
              this.organizationId = userConfig.organizationId;
              if (this.organizationId) {
                setTimeout(() => {
                  this.getMCBList(this.organizationId).pipe(take(1)).subscribe(res => {
                    this.logger.info("[getMCBList]", res);
                  }, err => {
                  });
                }, 7000);
              }
            }
          });
        }
      }
    });
    // this.updateConversationMembers$.bufferTime(30000).subscribe(targets => { // Update each 30s
    //   if (!!targets && targets.length > 0) {
    //     targets = CommonUtil.uniq(targets);
    //     this.logger.info("[updateConversationMembers] getRoomMembersAndStore", targets);
    //     targets.forEach(target =>  setTimeout(() => { this.getRoomMembersAndStore(target).subscribe() }, 100)); // To make sure we do not have parallax call
    //   }
    // });
      this.messageUpdates$.pipe(bufferTime(2000), filter(v => !!v && v.length > 0)).subscribe(data => {
        if (!!data && data.length > 0) {

          data.forEach(item => {
            const uniqueMessages = CommonUtil.uniqBy(item.messages, "id");

            // Because we have 'bufferTime(2000)',
            // we need to preserver the 'status' field if it was changed during the buffer time
            for (const msg of uniqueMessages) {
              let existingMessageInStore: Message;
              this.store.select(state => getMessageById(state, msg.id)).pipe(take(1)).subscribe(message => existingMessageInStore = message);
              if (existingMessageInStore && existingMessageInStore.status > msg.status) {
                msg.status = existingMessageInStore.status;
              }
            }

            this.store.dispatch(new MessagesBulkUpdate(uniqueMessages));

            this.databaseService.createOrUpdateMessages(uniqueMessages, item.convTarget);
          });
        }
      });

    this.store.select(getIsLoggedIn).subscribe(v => {
      this.isLoggedIn = v;
    });

    this.store.select(getPadNotifications).subscribe(v => {
      this.logger.info("[getPadNotifications]", v);
    });


    this.configService.getLoadedConfig().subscribe(() => {
      if (this.isGroupManageEnabled !== this.configService.get("groupManagementViaDirectory")) {
        this.isGroupManageEnabled = this.configService.get("groupManagementViaDirectory");
      }
    });

    this.broadcaster.on<any>("sendUpdateContacts").subscribe(() => {
      this.sendContactNotification();
    });

    this.broadcaster.on<any>("BGSYNCOMPLETE").subscribe(() => {
      this.isBgSyncInProgress = false;
      this.omemoMamQueue.forEach(jid => {
        this.xmppService.getOMEMODevicesForTarget(jid).pipe(take(1)).subscribe(res => {
          this.logger.info("COMBINED getOMEMODevicesForTarget ", jid, res);
        });
      });
      this.omemoMamQueue = [];
    });

    this.broadcaster.on<any>("UPDATED_LOCAL_OMEMO_DEVICE").subscribe(localDevice => {
      if (!!localDevice && !!localDevice.id) {
        this.localDeviceId = localDevice.id;
      }
    });


    this.broadcaster.on<any>("encryptedMessages").subscribe(encryptedMessages => {
      for (let convTarget of Object.keys(encryptedMessages)) {
          const messages = encryptedMessages[convTarget] || [];
          this.filterOutAlreadyDecodedOmemoMessages(messages).subscribe(filteredMessages => {
            this.logger.info("[ConversationRepository] filterOutAlreadyDecodedOmemoMessages", messages, filteredMessages);
            /*
            // do not decrypt on background sync - it leads to out of order processing with broken chains
            if (filteredMessages && filteredMessages.length > 0) {
              this.decryptOMEMOMessages(convTarget, filteredMessages, messages[0]);
            }
            */
          });
      }

    });

    this.broadcaster.on<any>("CALLKITACCEPTED").subscribe(calldata => {
      this.handlePushNotification(calldata);
    });

    this.broadcaster.on<any>("maybeRenewJitsiAuth").subscribe(() => {
      this.getUnArchivedConversations().pipe(take(1)).subscribe(convs => {
        const convsToCheck = convs.filter(v => !!v && v.Target !== ConstantsUtil.ALL_TARGET && !CommonUtil.isBroadcast(v.Target) && !CommonUtil.isVideoMeeting(v.Target)).map(v => v.Target);
        const convs2recheck = localStorage.getItem("recheckAvatars");
        let additionalconvs = [];
        if (!!convs2recheck) {
          try {
            additionalconvs = JSON.parse(localStorage.getItem("recheckAvatars"));
          } catch (error) {
            this.logger.info("could not restore localStorage value");
          }
        }
        if (additionalconvs.length > 0) {
          additionalconvs.forEach(c => {
            if (convsToCheck.indexOf(c) === -1) {
              convsToCheck.push(c);
            }
          });
        }
        const avtIdMap = {};
        convsToCheck.forEach(c => {
          avtIdMap[c] = this.avatarRepo.buildTargetHash(c);
        });
        const avtIds = Object.values(avtIdMap);
        // this.logger.info("[ConversationRepository] startRecheckAvatarInfo convs: ", convsToCheck, avtIdMap, avtIds);
        const avatarsLastUpdatedInDB = this.avatarRepo.avatarsLastUpdated$.value;
        const data = { ids: avtIds};
        this.conversationService.fetchAvatarUpdateInfo(data).subscribe(res => {
          if (!!res) {
            convsToCheck.forEach(c => {
              const avtHashId = avtIdMap[c];
              const lastUpdatedInDB = (!!avatarsLastUpdatedInDB[c]) ? avatarsLastUpdatedInDB[c].updated : -1;
              if (!!res[avtHashId] && (res[avtHashId] > lastUpdatedInDB)) {
                // this.logger.info("[ConversationRepository] startRecheckAvatarInfo found AvatarUpdateRequired while processing " + c + ":", res[avtHashId], lastUpdatedInDB);
                this.avatarRepo.upgradeAvatar(c);
              }
            });
          }
        }, err => {
          this.logger.error("[ConversationRepository] fetchavatarInfo err: ", err);
        });

      });
    });

    this.broadcaster.on<any>("electronScreenUnlock").subscribe(() => {
      this.logger.info(new Date().toISOString() + " [ConversationRepository] electronScreenUnlock");
      this.reloadConversationHistory();
    });

    // Setting this because we don't know the last time when app was disconnect when app gets killed

    const pendingMessages = localStorage.getItem("pendingMessages");

    if (pendingMessages) {
      this.pendingMessages = JSON.parse(pendingMessages);
    }

    this.logger.info("[ConversationRepository][constructor] pendingMessages", this.pendingMessages);

    document.addEventListener("pause", () => {
      this.logger.info("[ConversationRepository] pause");
    });

    document.addEventListener("resume", () => {
      this.logger.info("[ConversationRepository] resume");
      if (environment.isCordova && CommonUtil.isOnAndroid()) {
        this.logger.info("[ConversationRepository] resume -> refreshConversations");
        this.refreshConversations();
      }
      this.store.select(getIsAppOnline).pipe(filter(v => !!v), take(1)).subscribe(() => {
        this.refreshConversations();
      });
    });

    this.store.select(getIsAppOnline).subscribe(isOnline => {
      this.logger.info("[ConversationRepository][getIsAppOnline$]1 isOnline ", isOnline);
      if (!!isOnline) {
        if (environment.isCordova && CommonUtil.isOnAndroid()) {
          if (!window.appInBackground) {
            this.refreshConversations();
          }
        } else if (environment.isElectron) {
          this.reloadConversationHistory();
        } else {
          this.refreshConversations();
          this.resumeFileDownload();
        }
      } else {
        this.pauseCancelFileDownload();
      }
      this.isAppOnline = isOnline;
    });

    this.store.select(getAppSettings)
      .pipe(distinctUntilChanged())
      .subscribe(options => {
        this.sendReceipt = options.receipts;
      });


    // load all updated convs on start and if have internet connection
    //
    this.appService.onLoggedIn().pipe(switchMap(() => this.getIsAppOnline()), filter(v => !!v), take(1)).subscribe(() => {
      this.logger.info(new Date().toISOString() + " [ConversationRepository]  logged in & online", this.isAppOnline, this.isLoggedIn);
      // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 1");
      this.loadAllUpdatedConversations();
    });

    this.appService.onXmppConnect().pipe(take(1)).subscribe(() => {
      this.logger.info("[ConversationRepository] onXmppConnect CONNECT");

      // Join all rooms at start app
      this.joinAllRooms();

      setTimeout(() => {
        // this.logger.info(new Date().toISOString() + " [ConversationRepository] onXmppConnect FIRST-CONNECT callLoadAllUpdatedConversations 2");
        this.loadAllUpdatedConversations();
      }, 5 * 1000);

    });

    // on xmpp reconnect
    //
    this.appService.onXmppConnect().pipe(skip(1)
      , switchMap(() => this.loadAllUpdatedConversations())).subscribe(() => {

        this.logger.info(new Date().toISOString() + " [ConversationRepository] onXmppConnect RE-CONNECT");
        // Join all rooms at xmpp re-connect
        this.joinAllRooms();

        // TODO: here we are trying to fix an issue with diff recent convs list on ddoff clients
        // setTimeout(() => {
        //   this.logger.info(new Date().toISOString() + " [ConversationRepository] onXmppConnect RE-CONNECT loadAllUpdatedConversations");
        //   this.loadAllUpdatedConversations();
        // }, 10 * 1000);
      });


    this.store.select(getSelectedConversation).pipe(pairwise()).subscribe(conversations => {
      this.logger.info("[ConversationReporsitory][getSelectedConversation]", conversations);
      this.currentConv = conversations[1];

      const previousConv = conversations[0];

      if (!previousConv) {
        return;
      }

      // this.logger.info(`[ConversationRepository][getSelectedConversation] ${previousConv.Target} -> ${this.currentConv.Target}`);

      if (!this.currentConv || this.currentConv.Target !== previousConv.Target) {
        if (!!this.currentConv && !!this.currentConv.Target && !!this.currentConv.type) {
          // this.logger.info("[conversationRepository] loadListPad? ", this.currentConv);
          if (this.currentConv.has_pads) {
            this.padService.loadListPad(this.currentConv.Target, this.currentConv.type);
          }
        }
        // TODO: use deleteMessagesFromRedux API

        this.store.select(state => getMessagesByConversationTarget(state, previousConv)).pipe(take(1), filter(messages => messages.length > MESSAGES_PER_PAGE)).subscribe(messages => {

          // this.logger.info(`[ConversationRepository][getSelectedConversation] messages`, messages);

          // on conv switch - here we reset messages in redux and leave only last X (50)
          //
          if (messages.length > MESSAGES_PER_PAGE) {
            const messageIdsToRemoveFromRedux = messages.slice(MESSAGES_PER_PAGE, messages.length).map(message => message.id);
            // this.logger.info(`[ConversationRepository][getSelectedConversation] messageIdsToRemoveFromRedux`, messageIdsToRemoveFromRedux);
            this.store.dispatch(new DeleteMessages({
              messageIds: messageIdsToRemoveFromRedux,
              persistentConversationIds: [previousConv.Target],
              nonPersistentConversationIds: []
            }));
          }

          this.store.dispatch(new ResetLastPageLoaded(previousConv.Target));
        });
      }
    });

    this.xmppService.getOnMucInvite().subscribe((data) => {
      this.logger.info("[ConversationRepository][getOnMucInvite]", data);

      const target = data.room.bare;
      const reason = data.reason;
      const isE2EE = reason === ConstantsUtil.START_E2EE_CHAT;
      let deletedConvs = [];
      try {
        deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
      } catch (error) {

      }

      if (deletedConvs.indexOf(target) > -1) {
        let newDeletedConvs = deletedConvs.filter(c => c !== target);
        localStorage.setItem("deletedConvs", JSON.stringify(newDeletedConvs));
      }

      this.store.select(state => getConversationById(state , target))
      .pipe(take(1))
      .subscribe(conv => {
        this.logger.info("[ConversationRepository][getOnMucInvite]", {data, isE2EE, conv});
        if (!conv) {
          const conv = ConversationUtil.createLocalConversation(target, "", "groupchat", "", isE2EE);
          this.store.dispatch(new ConversationCreate(conv));
        } else {
          if (isE2EE) {
            this.store.dispatch(new ConversationUpdate({target: target,  changes: {encrypted: isE2EE}}));
          }
        }
      });

      this.joinRoomFromTarget(target, false, 0);
    });

    this.xmppService.getOnMucError().subscribe((data) => {
      this.logger.info("[getOnMucError]", data);
      if (data.type === "error" && data?.error?.type !== "cancel") {
        if (this.currentConv && this.currentConv.Target === data.from.bare) {
          this.processLeaveConversationUI();
          this.logger.info("[getOnMucError] processLeaveConversationUI", data);
        }
      }
    });

    this.xmppService.getOnDeleteMessage().pipe(bufferTime(200), filter(res => res.length > 0))
    .subscribe((ids) => {
      this.logger.info("[ConversationRepository][XmppService][getOnDeleteMessage]", ids);
      this.broadcaster.broadcast("deleteMessages", ids);
      this.deletedMessages = [...this.deletedMessages, ...ids];
    });
    if (!this.configService.isAnonymous) {
      this.xmppService.getOnMessage().pipe(bufferTime(600), filter(res => res.length > 0)).subscribe((messages) => {

        this.logger.info("[ConversationRepository][XmppService][getOnMessageA]", messages.length, messages); // message.to.bare

        const lastXmppDisconnectAt = localStorage.getItem("lastXmppDisconnectAt") ? +localStorage.getItem("lastXmppDisconnectAt") * 1000 : new Date().getTime();
        messages = messages.filter(m => !m.delay || m.delay && addMinutes(new Date(m.timestamp), 10).getTime() > lastXmppDisconnectAt);

        const allMessages = [];
        const messageDeleteStatus = [];
        let counter = 0;
        const now = new Date().getTime();
        messages.filter(v => !v.expiry || v.expiry && v.expiry * 1000 > now).forEach(message => {
          if (message.muc && message.muc.affiliation === "none") {
            // Remove from members list
            const conversationTarget = message.from.bare;
            const member = message.muc.jid.bare;
            setTimeout(() => {
              this.logger.info("[ConversationRepository][XmppService][MUC] Remove from members list", conversationTarget, member);
              this.store.dispatch(new ConversationRemoveMember({ conversationTarget, member }));
              this.getConversationMembersStorage(conversationTarget).pipe(take(1)).subscribe(members => {
                // this.logger.info("[ConversationRepository][XmppService][MUC] Remove from members list - updated members: ", members);
                const domain = this.userJID.domain;
                const iomMembers = members.find(m => m.indexOf("@" + domain) === -1);
                const has_iom = !!iomMembers && (iomMembers.length > 0);
                this.store.dispatch(new ConversationUpdate({target: conversationTarget,  changes: {has_iom: has_iom}}));
                this.broadcaster.broadcast("updateIOM", {target: conversationTarget, val: has_iom});
              });
            }, 1000);
          }

          if (message.type === "groupchat" && this.leftRoomList.includes(message.to.bare)) {
            return;
          }
          try {
            // sync signal
            if (message.signal && (message.type === "groupchat")) {
              this.logger.info("[ConversationRepository][XmppService] ACTIVEVCALLSIGNAL ", message);
              if (message.signal.type === "pad-create") {
                this.store.dispatch(new ConversationUpdate({target: message.signal.target, changes: {has_pads: true}}));
              } else if (message.signal.type === "last-pad-deleted") {
                this.store.dispatch(new ConversationUpdate({target: message.signal.target, changes: {has_pads: false}}));
              } else if (message.signal.type === "callactive") {
                this.store.dispatch(new ConversationUpdate({ target: message.signal.target, changes: { has_active_call: true, ended_call_time: null } }));
              } else if (message.signal.type === "callinactive") {
                this.store.dispatch(new UpdateConversationCallFlag({target: message.signal.target, flag: false}));
                this.store.dispatch(new ConversationUpdate({ target: message.signal.target, changes: { has_active_call: false, ended_call_time: new Date().getTime() } }));

              } else if (message.signal.type === "reactionupdate") {
                this.updateStoredReactionsForMessage(message.signal);
              } else if (message.signal.type === "requestaccess") {
                this.handleGroupRequestNotification(message.signal.target, message.from.bare);
              }
              return;
            }
            if (message.delay && (message.type === "groupchat") && (!!message.vncTalkConference)) {
              try {
                if (message.vncTalkConference.eventType === "join") {
                  // a call was just joined - so show active call!
                  let nTimeStamp = new Date().getTime();
                  if ((nTimeStamp - message.timestamp) < 5000){
                    this.logger.info("[ConferenceRepository][getOnMessage] MESSAGEJOINDELAY ?", nTimeStamp - message.timestamp, message);
                    this.store.dispatch(new ConversationUpdate({ target: message.from.bare, changes: { has_active_call: true, ended_call_time: null } }));
                  }

                }
              } catch (error) {
                this.logger.info("error parsing signal MESSAGEJOINDELAY ");
              }
            }

            if (message.signal && (message.type === "normal") && message.noStore) {
              this.logger.info("[ConversationRepository][XmppService] messagesignal: ", message.signal);
              if (message.signal.type === "logout") {
                // this.logger.info("[ConversationRepository][XmppService] messagesignal LOGOUT processing: ", message.signal);
                try {
                  const storedProfile = JSON.parse(localStorage.getItem("profile"));
                  // this.logger.info("[ConversationRepository][XmppService] messagesignal LOGOUT storedProfile: ", storedProfile);
                  if (!!storedProfile && storedProfile.user && !!storedProfile.user.nameID) {
                    if (storedProfile.user.nameID === message.signal.target) {
                      // this.logger.info("[ConversationRepository][XmppService] messagesignal LOGOUT: ", message.signal);
                      this.appService.logout();
                    }
                  }
                } catch (error) {
                  this.logger.error("[ConversationRepository][XmppService] messagesignal LOGOUT error: ", error);
                }
              }
              if (message.signal.type === "changeBackgroundImage") {
                this.appService.changeChatBackground(message.signal.target, false);
                this.changeChatBackgroundImageId = message.signal.target;
                this.appService.getChatBackgroundCustomImages().subscribe((res: any) => {
                  if (res && res.length) {
                    res.map(img => {
                      this.appService.getChatBackgroundCustomImageById(img.id).subscribe((res: any) => {
                        this.store.dispatch(new SetChatBackgroundImages({ id: img.id.toString(), name: img.filename, type: img.type, src: res[0].base64data }));
                      });
                    });
                  }
                });
              }
              if (message.signal.type === "updateAvatarData") {
                this.updateCropAvatar(this.userJID?.bare, JSON.parse(message.signal.data));
              }

              if (message?.from?.full == this.userJID?.full) {
                this.logger.info("[ConversationRepository][XmppService]message signal from this client - ignore");
                return;
              }

              if (!message.signal.type || !message.signal.target) {
                this.logger.info("[ConversationRepository][XmppService] message signal w/o target - ignore");
                return;
              }

              const signalTarget = message.signal.target;
              const signalType = message.signal.type;
              if (message.signal.type === "pad-create" && message.signal.target === this.userJID?.bare) {
                this.store.dispatch(new ConversationUpdate({target: message.from.bare, changes: {has_pads: true}}));
              } else if (message.signal.type === "last-pad-deleted" && message.signal.target === this.userJID?.bare) {
                this.store.dispatch(new ConversationUpdate({target: message.from.bare, changes: {has_pads: false}}));
              }
              // IOM activecall / join button
              if (message.signal.type === "callactive") {
                this.store.dispatch(new ConversationUpdate({ target: message.signal.target, changes: { has_active_call: true, ended_call_time: null } }));
                return;
              }
              if (signalType === "closeNotification") {
                this.broadcaster.broadcast("closeNotification", message.signal);
                return;
              }
              if (message.signal.type === "callinactive") {
                this.store.dispatch(new UpdateConversationCallFlag({target: message.signal.target, flag: false}));
                return;
              }

              if (message.signal.type === "reactionupdate") {
                this.updateStoredReactionsForMessage(message.signal);
                return;
              }
              // request resend
              if (message.signal.type === "resend") {
                // this.logger.info("[ConversationRepository][XmppService] requestresend received: ", message);
                this.processResendSignal(message);
                return;
              }

              // receive resend
              if (message.signal.type === "requestresend") {
                // this.logger.info("[ConversationRepository][XmppService] requestresend signal: ", message);
                this.resendRequestedMessage(message.from.bare, message.signal.target, message.signal.data);
                return;
              }

              // Read
              //
              if (signalType === "read") {
                this.logger.info("readSignal for ", signalTarget);
                signalTarget.split(",").forEach(target => {
                  this.logger.info("[ConversationRepository][markAsRead] sync signal for ", target);
                  this.resetUnreadCount(target);
                });
                return;
              }

              // Pin
              //
              if (signalType === "pin") {
                this.logger.info("[ConversationRepository][XmppService]message signal PIN ", signalTarget);
                let val = false;
                try {
                  val = JSON.parse(message.signal.data);
                } catch (e) {
                  this.logger.info("parse error: ", e);
                  return;
                }
                this.store.select(state => getConversationById(state, signalTarget)).pipe(take(1)).subscribe(conv => {
                  this.togglePinConversation(conv, val, true);
                });
                return;
              }

              // Favs
              //
              if (signalType === "favourite") {
                this.logger.info("[ConversationRepository][XmppService]message signal isfav ", message.signal);
                let val = false;
                try {
                  val = JSON.parse(message.signal.data);
                } catch (e) {
                  this.logger.info("parse error: ", e);
                  return;
                }
                this.store.select(state => getConversationById(state, signalTarget)).pipe(take(1)).subscribe(conv => {
                  this.toggleConversationFavorite(conv, val, true);
                });
                return;
              }

              // Retention
              //
              if (signalType === "retention-time") {
                let val = { retention_time: "-1"};
                try {
                  val = JSON.parse(message.signal.data);
                } catch (e) {
                  this.logger.info("parse error: ", e);
                  return;
                }
                // updateRetentionTime(conv: Conversation, retention_time: any, fromSignal?:boolean) {
                this.store.select(state => getConversationById(state, signalTarget)).pipe(take(1)).subscribe(conv => {
                  // this.logger.info("[ConversationRepository][XmppService]message signal retentionSignal ", message.signal, val);
                  this.updateRetentionTime(conv, val.retention_time, true);
                });
                return;
              }

              // Pads
              //
              if (signalType === "pad-read") {
                this.store.dispatch(new ConversationRemovePadNotification(signalTarget));
                return;
              }
              //
              if (signalType === "pad-create") {
                this.store.dispatch(new ConversationUpdate({target: signalTarget, changes: {has_pads: true}}));
                return;
              }
              if (signalType === "last-pad-deleted") {
                this.store.dispatch(new ConversationUpdate({target: signalTarget, changes: {has_pads: false}}));
                return;
              }

              // E2EE
              //
              if (signalType === "e2ee-on" || signalType === "e2ee-off") {
                this.store.dispatch(new ConversationUpdate({target: signalTarget,  changes: {encrypted: signalType === "e2ee-on"}}));
                if (signalType === "e2ee-on") {
                  this.databaseService.setLastOmemoActiveTS(signalTarget);
                }
                return;
              }

              // E2EE omemo devices updated
              //
              if (signalType === "omemo-devices-updated") {
                this.broadcaster.broadcast("omemo-devices-updated");
                return;
              }

              // Archive
              //
              if (signalType === "conv-archive") {
                this.store.dispatch(new ArchiveConversation({
                  target: signalTarget,
                  timestamp: Date.now(),
                }));

                return;
              }
              //
              if (signalType === "conv-unarchive") {
                this.store.dispatch(new UnArchiveConversation({
                  target: signalTarget,
                  timestamp: Date.now(),
                }));
                return;
              }

              // Mute settings
              //
              if (signalType === "notification-settings") {
                let val: any;
                try {
                  val = JSON.parse(message.signal.data);
                } catch (e) {
                  this.logger.error("notification-setting parse error: ", e);
                  return;
                }

                const type = val.type;
                this.store.dispatch(new UpdateNotificationSetting({ conversationTarget: signalTarget, type }));

                return;
              }
              //
              if (signalType === "sound-settings") {
                let val: any;
                try {
                  val = JSON.parse(message.signal.data);
                } catch (e) {
                  this.logger.error("sound-setting parse error: ", e);
                  return;
                }

                const type = val.type;
                this.store.dispatch(new UpdateSoundSetting({ conversationTarget: signalTarget, type }));

                return;
              }
              //
              if (signalType === "notification-config") {
                let val: any;
                try {
                  val = JSON.parse(message.signal.data);
                } catch (e) {
                  this.logger.error("notification-config parse error: ", e);
                  return;
                }

                const config = val.config;
                this.store.dispatch(new UpdateConversationNotificationConfig({ conversationTarget: signalTarget, config }));

                return;
              }
            }

            // Notification
            if (message.notification && message.notification.jid && message.type === "normal" && (message.noStore || message.body.startsWith("You have unread"))) {
              const newConvTarget = message.notification.jid;
              this.logger.info("[ConversationRepository][XmppService][MUC] notification about new message in unjoined room: ", newConvTarget);

              this.loadMessages(newConvTarget, true);
              this.joinedConversations = this.joinedConversations.filter(v => v !== newConvTarget);
              this.joinRoomFromTarget(newConvTarget, false, 1);

              // top up the conversation
              this.store.dispatch(new ConversationUpdate({ target: newConvTarget, changes: { Timestamp: message.timestamp } }));

              return;
            }
            if (message.notification) {
              this.logger.info("[ConversationRepository][XmppService][notification]", message);
              message.fromJid = this.getBareFromMessage(message);
              const isSentMessage = this.isSentMessage(message);
              let target = message.fromJid;
              if (message.notification && message.notification.target && message.notification.target.indexOf("@conference") !== -1) {
                target = message.notification.target;
              }
              if (message.notification.action === "create") {
                if (!isSentMessage) {
                  this.addPadNotification(target);
                  this.broadcaster.broadcast("newPadCreated", message);
                  this.store.dispatch(new ConversationUpdate({target, changes: {pad_read: 0}}));
                }
                this.store.dispatch(new ConversationUpdate({target, changes: {has_pads: true}}));
              } else if (message.notification.action === "delete") {
                // if (!isSentMessage) {
                //   this.addPadNotification(target);
                // }
                this.broadcaster.broadcast("padDeleted", message);
              } else if (message.notification.action === "rename") {
                // if (!isSentMessage) {
                //   this.addPadNotification(target);
                // }
                this.broadcaster.broadcast("padRenamed", JSON.parse(message.notification.content));
              } else if (message.notification.action === "contactsupdate") {
                this.broadcaster.broadcast("vncdirectorycontactsbulkupdate");
              } else if (message.notification.action === "userconfigupdate") {
                this.broadcaster.broadcast("vncdirectoryuserconfigupdate");
              } else if (message.notification.action === "whiteboardCreated") {
                const key = this.getConferenceKey();
                this.getWhiteboards(key).subscribe(whiteboards => {
                  // this.logger.info("[whiteboards]", whiteboards);
                  this.store.dispatch(new WhiteboardBulkAppend({conversationTarget: key, whiteboards: whiteboards}));
                });
                if (message.notification.target.indexOf("@conference") === -1) {
                  message.notification.target = message.fromJid;
                }
                this.broadcaster.broadcast("whiteboardCreated", message.notification);
              } else if (message.notification.action === "whiteboardUpdated") {
                this.broadcaster.broadcast("whiteboardUpdated", message.notification);
                const data = JSON.parse(message.notification.content);
                this.store.dispatch(new WhiteboardUpdateAction({id: data.id, changes: data}));
              } else if (message.notification.action === "whiteboardDeleted") {
                const data = JSON.parse(message.notification.content);
                this.store.dispatch(new WhiteboardDeleteAction(data.id));
                this.broadcaster.broadcast("whiteboardDeleted", message.notification);
              } else if (message.notification.action === "rfcnotify") {
                this.broadcaster.broadcast("vncdirectoryrfcnotify", message );
              } else if (message.notification.action === "updateContact") {
                this.broadcaster.broadcast("vncdirectorycontactsbulkupdate");
              } else if (message.notification.type === "runningConferences") {
                if (message.notification.content) {
                  this.runningConferences$.next(message.notification.content.split(","));
                } else {
                  this.runningConferences$.next([]);
                }
              }
              return;
            } else if (message.recording_notify) {
              // Recording notification
              this.broadcaster.broadcast("recordingNotify", message.recording_notify);
              return;
            } else if (message.x) {
              let data: any = {};
              let affiliations: any = {};
              if (message.x.affiliations) {
                affiliations = JSON.parse(message.x.affiliations);
                const members = [];
                const admins = [];
                let owner = "";
                for (let jid of Object.keys(affiliations)) {
                  if (affiliations[jid] === "member") {
                    members.push(jid);
                  } else if (affiliations[jid] === "admin") {
                    admins.push(jid);
                  } else if (affiliations[jid] === "owner") {
                    owner = jid;
                  }
                }
                // this.logger.info("[ConversatinRepository] group chat data updateConversationMembersInStorage 1", members, owner, admins);
                this.updateConversationMembersInStorage(message.from.bare, members, owner, admins);
              }
              if (message.x.data) {
                data = JSON.parse(message.x.data);
                if (data.vdata) {
                  try {
                    const vdata = JSON.parse(data.vdata);
                    this.store.dispatch(new ConversationDataUpdate({target: message.from.bare, data: vdata}));
                    this.updateGroupInfo(vdata, message.from.bare);
                  } catch (ex) {

                  }
                }
              }
              // this.logger.info("[ConversatinRepository] group chat data", affiliations,  data);
            }

            if (!message.body && (message.chatState || message.receipt) || message.carbonReceived) {
              return;
            }
            message.timestamp += counter;
            counter++;

            this._removeMessagesFromPendings$.next(message.id);

            message.fromJid = this.getBareFromMessage(message);
            if (message.type !== "groupchat" && !this.subscribedUsers.includes(message.fromJid)) {
              this.contactRepo.getContactById(message.fromJid).pipe(take(1)).subscribe(contact => {
                if (!contact) {
                  this.xmppService.subscribe(message.fromJid);
                  this.subscribedUsers.push(message.fromJid);
                }
              });
            }
            if (!message.body) {
              this.logger.warn("[Message does not have body]", message);
              message.body = "";
            }

            const isSentMessage = this.isSentMessage(message);
            let target = isSentMessage ? message.to.bare : message.from.bare;
            this.logger.info("[ConversationRepository][XmppService][getOnMessage] isSentMessage", isSentMessage, target);
            if (isSentMessage) {

              let msg;
              this.store.select(state => getMessageById(state, message.id)).pipe(take(1)).subscribe(message => msg = message);

              if (message.carbon) {
                this.store.select(state => getConversationById(state, target))
                  .pipe(take(1))
                  .subscribe(carbonconv => {
                    if (!carbonconv) {
                      this.createLocalConversation(target, "chat", message.body);
                    }
                  });
              }

              if (msg && msg.status === MessageStatus.DELIVERED) {
                message.status = MessageStatus.DELIVERED;
              } else {
                message.status = MessageStatus.SENT;
              }
              this.logger.info("[ConversationRepository][XmppService][getOnMessage] update status", msg, message);

              // receiving a message that we sent => reset the unreadcount
              this.resetUnreadCount(target);

              // Update conversation last message data (Timestamp)
              if (message.type === "chat") {
                this.store.dispatch(new ConversationUpdate({ target: target, changes: { incoming: false, received_receipt: message.status === MessageStatus.DELIVERED, content: CommonUtil.processHTMLBody(message.body), Timestamp: message.timestamp + 1 } }));
              }
              if (message.type === "normal" && message.vncTalkBroadcast && !message.vncTalkBroadcast.avatarup) {
                if (message.vncTalkBroadcast.origtarget) {
                  target = message.vncTalkBroadcast.origtarget;
                } else {
                  target = message.to.bare;
                }
                this.store.dispatch(new ConversationUpdate({ target: target, changes: { incoming: false, received_receipt: false, Timestamp: message.timestamp + 1, content: CommonUtil.processHTMLBody(message.body) } }));
              }

              // update last activity if this is a received message and locally we have a sender offline.
            } else {
              if (message.body) {
                message.body = message.body.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;"); // parse message like in solr
              }
              if (message.type === "chat") {
                const senderBare = message.fromJid;
                this.store.select(state => getContactStatusById(state, senderBare)).pipe(take(1)).subscribe(status => {
                  if (status === UserStatus.OFFLINE) {
                    this.contactRepo.getLastActivity(senderBare);
                  }
                });
              }
            }


            if (message.type === "groupchat") {
              target = message.from.bare;
              if (message.html && message.html.body) {
                message.htmlBody = message.html.body.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
                // this.logger.info("[ConversationRepository][XmppService][getOnMessage] parse html body", message.html.body, message.htmlBody);
              }
            // } else if (!environment.isCordova && this.electronService.isElectron && message.vncTalkBroadcast) {
            } else if (message.vncTalkBroadcast) { // WTF above? only handle broadcast correctly on electron?
              if (!message.vncTalkBroadcast.avatarup) {
                if (message.vncTalkBroadcast.origtarget) {
                  target = message.vncTalkBroadcast.origtarget;
                } else {
                  target = message.to.bare;
                }
              }
            }
            // muc echo messages
            if (this.userJID && message.type === "groupchat" && target === this.userJID?.bare) {
              // this is a message came from '[XmppService][handleOnMessageDeliveredToServer]'
              target = message.to.bare;
            }


            if (!message.id) {
              message.id = CommonUtil.randomId(10);
            } else if (message.vncTalkBroadcast) {
              // here is an id from XMPP: "y5-pDolCTrntA_fZKLep0-QdCqnQmjF-"
              // but when request from backend - it returns only a 2nd part, e.g. "fZKLep0-QdCqnQmjF-"
              // and hence messages duplication can occure
              const splitBroadcastId = message.id.split("_");
              if (splitBroadcastId.length > 1) {
                message.id = splitBroadcastId[splitBroadcastId.length - 1];
              }
              // this.logger.info("[conversation.repo][checkbroadcast] ", MessageUtil.isValidMessage(message), target, message);
            }

            // group chat actions (e.g. <user> changed the group photo)
            if (message.group_action) {
              if (message.group_action.type === GroupAction.UPDATE_AVATAR) {
                this.logger.info("[conversation.repo][group_action] avatarupdate ", target);
                // update current chat avatar
                this.avatarRepo.upgradeAvatar(target);
              } else if (message.group_action.type === GroupAction.UPDATE_ENCRYPTION) {

                const isE2EEEnabled = message.group_action.data === "0" ? false : true;

                // update conv
                this.store.dispatch(new ConversationUpdate({target: target,  changes: {encrypted: isE2EEEnabled}}));

                if (isE2EEEnabled) {
                  if (message.type === "groupchat") {
                    this.getRoomMembersAndStore(target).subscribe(roomMembers => {
                      this.logger.info("joinRoomFromTarget [getRoomMembersAndStore]", roomMembers);
                      let newList = [];
                      if (!!roomMembers && (roomMembers.length > 0)) {
                        for (let i = 0; i < roomMembers.length; i++) {
                          if (newList.indexOf(roomMembers[i]) === -1) {
                            newList.push(roomMembers[i]);
                          }
                        }
                      }
                      this.omemoRefreshList.next(newList);
                    });
                  } else {
                    this.xmppService.getOMEMODevicesForTarget(target, true).pipe(take(1)).subscribe(res => {
                      this.logger.info("conversationrepo getOnMessage getOMEMODevicesForTarget res ", target, res);
                    });
                  }
                }

                // update local conv config
                if (message.type === "groupchat") {
                  this.store.select(state => getConversationConfig(state, target)).pipe(take(1)).subscribe(config => {
                    if (config) {
                      const newConfig = {...config, isE2E: isE2EEEnabled ? 1 : 0};
                      this.store.dispatch(new ConversationSetConfig({ conversationTarget: target, config: newConfig }));
                    }
                  });
                }

                // this is to update chat window header
                this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
                  if (conv && conv.Target === target) {
                    this.store.dispatch(new ConversationSelect(target));
                  }
                });
              } else if ((message.group_action.type === GroupAction.ADD_PARTICIPANTS) || (message.group_action.type === GroupAction.LEAVE_GROUP)) {
                if (!!message.group_action.data) {
                  const participantsJids: string[] = message.group_action.data.split(",");
                  this.store.dispatch(new ConversationAddMembers({
                    conversationTarget: target,
                    members: participantsJids
                  }));
                }
                // ToDO: add hook for re-checking own role
                // only call when message is current, not when sync
                if (!message.delay) {
                  this.getAndUpdateGroupInfo(target).pipe(take(1)).subscribe(res => {
                    if (!!res.audiences) {
                      let audience_only = res.audiences.indexOf(this.userJID?.bare) > -1;
                      this.store.dispatch(new ConversationUpdate({target: target,  changes: {audience_only: audience_only}}));
                      this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
                        if (conv && target === conv.Target) {
                          // this.logger.info("convrepo messageUPDATE_ROLE_IN_CURRENTCHAT bc target:", target, audience_only);
                          this.broadcaster.broadcast("UPDATE_ROLE_IN_CURRENTCHAT", target);
                        }
                      });
                    }
                  });
                }
              } else if (message.group_action.type === "INITIATOR_LEFT" || message.group_action.type === "ENDED_CALL") {
                this.logger.info("[conversation.repository][joincallbutton] skip removing the flag");
                // this.store.dispatch(new UpdateConversationCallFlag({target: target, flag: false}));
              } else if (message.group_action.type === GroupAction.GROUP_UPDATE) {
                if (!message.delay) {
                  this.getAndUpdateGroupInfo(target).pipe(take(1)).subscribe(res => {
                    if (!!res.audiences) {
                      let audience_only = res.audiences.indexOf(this.userJID?.bare) > -1;
                      this.store.dispatch(new ConversationUpdate({target: target,  changes: {audience_only: audience_only}}));
                      this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
                        if (conv && target === conv.Target) {
                          this.broadcaster.broadcast("UPDATE_ROLE_IN_CURRENTCHAT", target);
                        }
                      });
                    }
                  });
                }
              } else if (message.group_action.type === "archived") {
                this.resetUnreadCount(target);
                this.getConversationById(target).subscribe(aconv => {
                  console.log("GROUPACTIONARCHIVE ", aconv);
                  this.archiveConversation(aconv);
                });
              }
            }
            // contact avatar updates broadcasts
            if (message.vncTalkBroadcast && message.vncTalkBroadcast.avatarup) {
              this.logger.info("[conversationrep] avatarupdate: ", target);
              // update current chat avatar
              this.avatarRepo.upgradeAvatar(target);
            }

            // call messages
            if (message.vncTalkConference) {
              this.logger.info("[conversationrepository][vnctalkConference] ", message.vncTalkConference);
              const callConfId = message.vncTalkConference.conferenceId;
              if ((message.from.bare === this.userJID?.bare) && (message.from.bare === message.to.bare)) {
                this.logger.info("skip own signal message");
              } else {
                // check if this is a call inside a group (1-1 calls will contain a '#' isntead of '@')
                if (callConfId && callConfId.indexOf("@") !== -1) {
                  allMessages.push({ conversationTarget: callConfId, message: message, incoming: this.userJID && message.vncTalkConference.from !== this.userJID?.bare });
                } else {
                  allMessages.push({ conversationTarget: target, message: message, incoming: this.userJID && message.vncTalkConference.from !== this.userJID?.bare });
                }
              }

            // regular message
            } else if ((message.type === "groupchat" && !!message.body) || MessageUtil.isValidMessage(message) || message.replace) {
              if (!message.replace || message.replace && message.body?.trim() !== "") {
                allMessages.push({ conversationTarget: target, message: message, incoming: !isSentMessage });
              } else {
                messageDeleteStatus.push({ id: message.replace.id, isDeleted: true, target: target });
                this.store.dispatch(new ConversationUpdate({ target: target, changes: { content: " " } }));

              }
            } else {
              this.logger.warn("[ConversationRepository][getOnMessage] ignore", message, MessageUtil.isValidMessage(message));
              if (message.vncTalkConferenceScheduler && message.vncTalkConferenceScheduler.startTime
                && (new Date(message.vncTalkConferenceScheduler.startTime).getTime() > new Date().getTime())) {
                  const conferenceStart = new Date(message.vncTalkConferenceScheduler.startTime).getTime() / 1000;
                this.store.dispatch(new ConversationUpdate({ target: target, changes: { conference_start: conferenceStart } }));
                this.databaseService.updateConversationConferenceStart(target, conferenceStart);
                this.scheduledConferences = this.scheduledConferences.filter(v => v.jid !== message.from.bare);
                this.scheduledConferences.push({...message.vncTalkConferenceScheduler, jid: message.from.bare});
                this.broadcaster.broadcast("scheduledConferences", this.scheduledConferences);
                this.logger.info("[ConversationRepository] scheduledConferences", message, this.scheduledConferences);
                setTimeout(() => {
                  if (!document.querySelector("vp-scheduled-conferences")) {
                    this.logger.info("[ConversationRepository] showScheduledConferences", this.scheduledConferences);
                    this.broadcaster.broadcast("showScheduledConferences", this.scheduledConferences);
                  }
                }, 1000);
              }
            }

            // MUC signals
            if (message.vncTalkMuc) {
              this.store.select(state => getConversationById(state, message.vncTalkMuc.conferenceId))
                .pipe(take(1)
                , filter(conv => !!conv))
                .subscribe(conv => {
                  const eventType = message.vncTalkMuc.eventType;
                  if (eventType && eventType === "delete" || eventType === "leave") {
                    // e.g. a case if a user uses 2 devices at the same time
                    // and deleted a conv at one of them,
                    // so we get a signal here and also need to clear it from other device
                    if (!!conv && message.vncTalkMuc.to === this.userJID?.bare) {
                      this.addToDeletedConvs(conv);
                      this.deleteConversationLocally(conv);
                    }
                  } else {
                    if (!!conv.state && (conv.state === "active")) {
                      this.logger.info("[leaveinactive? ] conv, eventType: ", conv, eventType);
                      this.leaveConversation(conv, eventType);
                    }
                  }
                });
            } else if (message.startFile) {
              this.broadcaster.broadcast("startFile", message);
            } else {
              if (!isSentMessage && MessageUtil.isValidMessage(message)) {
                if ((this.currentConv && this.currentConv.Target && (this.currentConv.Target === message.from.bare)) || !this.currentConv) {
                  this.shouldMark = true;
                }
                this.store.select(getIsWindowFocused).pipe(take(1)).subscribe(focused => {

                  if ((this.userJID && message.fromJid === this.userJID?.bare) || (focused && this.currentConv && this.currentConv.Target === message.from.bare)) {
                    return;
                  }

                  // this.logger.info("[ConversationRepository] ConversationSetUnread 0 - message.vncTalkBroadcast.origtarget?", message);
                  if (message.vncTalkBroadcast) {
                    if (message.vncTalkBroadcast.origtarget) {
                      target = message.vncTalkBroadcast.origtarget;
                    } else {
                      target = message.to.bare;
                    }
                  }

                  this.store.select(state => getConversationById(state, target))
                    .pipe(take(1))
                    .subscribe(async conv => {
                      let conversation: Conversation = conv;
                      if (!conversation) {
                        if (message.type === "groupchat") {
                          conversation = this.createLocalConversation(target, "groupchat", message.body);
                        } else if (message.type === "chat") {
                          conversation = this.createLocalConversation(target, "chat", message.body);
                        }
                        // if this is a broadcast msg & we do not have this broadcast conv e.g. a user deleted it then to poll and get this broadcast conv again
                        if (message.vncTalkBroadcast) {
                          // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 3");
                          this.loadAllUpdatedConversations();
                        }
                      }

                      // show notifications only for Desktop here.
                      // for mobile we use FCM to show local notifications.
                      if (!environment.isCordova || (environment.isCordova && CommonUtil.isOnIOS)) {
                        if (message.type === "groupchat" || message.vncTalkBroadcast) {
                          // remove delayed property for replay messages received on join from signal - so we get a notification
                          const nMessage = {...message};
                          if (this.joinedConversationsLater.includes(target)) {
                            nMessage.delay = null;
                          }
                          // this.logger.info("ConversationSetUnread notify ", message, conversation.Timestamp, message.timestamp, message.timestamp - conversation.Timestamp);
                          if (message.timestamp - conversation.Timestamp > 500) {
                            this.broadcaster.broadcast("new_group_text", nMessage);
                          }
                        } else if (message.type === "chat") {
                          const generateSecretKey = "_._" + message.to.bare;
                          const ticketMentionSecretKey = TICKET_MENTION + "#" + message.to.bare;
                          const metaTaskMentionSecretKey = META_TASK_MENTION + "#" + message.to.bare;
                          if (message.fromJid === this.userJID?.bare) {
                            const receiverName = this.getFullName(message.to.bare);
                            const translatedMessage = await this.translate.get("TOPIC_MENTION_MESSAGE_SENDER", {USERNAME: receiverName}).pipe(take(1)).toPromise();
                            message.body = message.body.replace(TOPIC_MENTION_MESSAGE_RECEIVER, translatedMessage);
                            if (message?.body?.startsWith(ticketMentionSecretKey)) {
                              const receiverName = this.getFullName(message.to.bare);
                              const translatedMessage = await this.translate.get("TICKET_MENTION_SENDER_MESSAGE", { receiver_name: receiverName }).pipe(take(1)).toPromise();
                              message.body = message.body.replace(ticketMentionSecretKey, translatedMessage);
                            }
                            if (message?.body?.startsWith(metaTaskMentionSecretKey)) {
                              const receiverName = this.getFullName(message.to.bare);
                              const translatedMessage = await this.translate.get("META_TASK_MENTION_SENDER_MESSAGE", { receiver_name: receiverName }).pipe(take(1)).toPromise();
                              message.body = message.body.replace(metaTaskMentionSecretKey, translatedMessage);
                            }
                          } else {
                            const translatedMessage = await this.translate.get("TOPIC_MENTION_MESSAGE_RECEIVER").pipe(take(1)).toPromise();
                            message.body = message.body.replace(TOPIC_MENTION_MESSAGE_RECEIVER, translatedMessage);
                            if (message?.body?.startsWith(ticketMentionSecretKey)) {
                              const senderName = this.getFullName(message.from.bare);
                              const translatedMessage = await this.translate.get("TICKET_MENTION_RECEIVER_MESSAGE", { sender_name: senderName }).pipe(take(1)).toPromise();
                              message.body = message.body.replace(ticketMentionSecretKey, translatedMessage);
                            }
                            if (message?.body?.startsWith(metaTaskMentionSecretKey)) {
                              const senderName = this.getFullName(message.from.bare);
                              const translatedMessage = await this.translate.get("META_TASK_MENTION_RECEIVER_MESSAGE", { sender_name: senderName }).pipe(take(1)).toPromise();
                              message.body = message.body.replace(metaTaskMentionSecretKey, translatedMessage);
                            }
                          }
                          message.body = message.body.replace(generateSecretKey, "");
                          this.broadcaster.broadcast("new_single_text", message);
                        }
                      }

                      // unread badge unread counter
                      // this.logger.info("[ConversationRepository] ConversationSetUnread 1", conversation);
                      if (conversation) {
                        this.store.select(state => getMessageIdsByConversationTarget(state, conversation)).pipe(take(1)).subscribe(msgIds => {
                        //  this.logger.info("[ConversationRepository] ConversationSetUnread 2", {msgIds, message});
                          if (
                            (!message.delay || (this.joinedConversationsLater.includes(target) && ((message.timestamp - conversation.Timestamp) > 500))  || message.carbon)
                              &&
                              (message.type !== "normal" || (message.type === "normal" && message.vncTalkBroadcast && !message.vncTalkBroadcast.avatarup))
                              && (!msgIds.includes(message.id) || (!message.delay && (message.fromJid !== this.userJID?.bare) && message.type === "chat"))
                              && !conversation.archived) {

                            const unreadIds = conversation.unreadIds || [];
                            const unread_mentions = conversation.unread_mentions || [];
                            let last_mention_stamp = conversation.last_mention_stamp;
                            let total_mentions;
                            if (!unreadIds.includes(message.id)) {
                              unreadIds.push(message.id);
                              if (!!conversation.total_mentions) {
                                total_mentions = conversation.total_mentions;
                              } else {
                                total_mentions = 0;
                              };
                              if (conversation.type === "groupchat") {
                                let references = [];
                                if (!!message.references) {
                                  references = message.references.map(v => v.uri.replace(/xmpp:+/ig, ""));
                                }
                                let hasMentionFromBody = false;
                                let msgBody = message.body;
                                if (message.originalMessage) {
                                  msgBody = message.originalMessage.replyMessage;
                                }
                                if (msgBody.match(/@all\b/ig)) {
                                  hasMentionFromBody = true;
                                }
                                if (!unread_mentions.includes(message.id) && ((!!message.references && this.userJID && references.includes(this.userJID?.bare)) ||  hasMentionFromBody)) {
                                  unread_mentions.push(message.id);
                                  last_mention_stamp = message.timestamp;
                                  total_mentions = conversation.total_mentions + 1;
                                }
                              }
                            }

                            this.setUnreadCount(target, unreadIds, unread_mentions, last_mention_stamp, total_mentions);

                            this.broadcaster.broadcast("update-counts", target);
                          }
                        });
                      }
                    });

                });
              }
            }
          } catch (ex) {
            this.logger.sentryErrorLog("Error during processing message:" + ex);
            this.logger.error("Error during processing message:" + ex);
          }
          // end loop
        });

        // Store normal messages
        const allNewMessages = allMessages.filter(m => !m.message.replace);
        const allUpdatedMessages = allMessages.filter(m => m.message.replace);
        if (allNewMessages && allNewMessages.length > 0) {
          this.logger.info("[ConversationRepository][[XmppService]][getOnMessage] allNewMessages", allNewMessages.length, allNewMessages);

          // save to DB
          // TODO: get rid of this for loop, insert all in once
          allNewMessages.forEach(item => {
            this.databaseService.createOrUpdateMessages([{ ...item.message, incoming: item.incoming }], item.conversationTarget);
          });

          this.store.dispatch(new MultiConversationMessageAdd(allNewMessages));
        }
        if (allUpdatedMessages && allUpdatedMessages.length > 0) {
          this.logger.info("[ConversationRepository][[XmppService]][getOnMessage] allUpdatedMessages", allUpdatedMessages);

          allUpdatedMessages.forEach(item => {
            const message = item.message;
            let msg;
            message.id = message.replace.id;
            this.store.select(state => getMessageById(state, message.id)).pipe(take(1)).subscribe(message => msg = message);
            if (msg) {
              msg.body = message.body;
              if (msg.$body && msg.$body.en) {
                msg.$body.en = message.body;
              }
              if (msg.html?.body) {
                msg.html.body = message.body;
              }
              msg.htmlBody = message.body;
              msg.cachedContent = message.body;
              this.store.dispatch(new MessageUpdateAction({id: message.id, changes: msg}));
              this.databaseService.createOrUpdateMessages([{ ...msg, incoming: item.incoming }], item.conversationTarget);
              this.logger.info("[ConversationRepository][[XmppService]][getOnMessage] allUpdatedMessages", msg);
            }
          });

        }

        // Deleted message
        if (messageDeleteStatus && messageDeleteStatus.length > 0) {
          this.broadcaster.broadcast("updateDeletedStatus", messageDeleteStatus.map(msg => msg.id));

          // update in redux
          this.store.dispatch(new MultiConversationMessageDeletedStatusUpdateAction(messageDeleteStatus));

          // update in DB
          messageDeleteStatus.forEach(m => {
            this.store
              .select(state => getMessageById(state, m.id))
              .pipe(take(1), filter(msg => !!msg))
              .subscribe(message => {
                if (!!message.references && (message.references.length > 0)) {
                  const referencesMeCond =  "xmpp:" + this.userJID.bare;
                  const referencesMe = message.references.filter(r => r.uri === referencesMeCond);
                  if (!!referencesMe && referencesMe.length > 0) {
                    this.getConversationById(message.from.bare).pipe(take(1)).subscribe(mentionedConv => {
                      const updatedUnreadMentions = mentionedConv.unread_mentions.filter(m => m !== message.id);
                      // this.logger.info("[conversationrepository][mucMessageDeleteSSA] processing deleted in Conv: ", mentionedConv, updatedUnreadMentions);
                      this.setUnreadCount(message.from.bare, null, updatedUnreadMentions, null, null);
                    });
                  }
                }
                this.databaseService.createOrUpdateMessages([{ ...message, isDeleted: true }], m.target);
              });
          });
        }

        let bare = messages[0]?.to?.bare;
        if (bare.indexOf("broadcast-") > -1) {
          this.getAudienceList(bare).pipe(take(1)).subscribe(data => {
            if (!!data) {
              data.broadcast_title = data.title;
              this.store.dispatch(new ConversationUpdateBroadcastData({
                conversationTarget: bare,
                changes: data
              }));
            }
          });
        }

      });

      this.xmppService.getOnMamMessage().pipe(bufferTime(600), filter(res => res.length > 0)).subscribe((messages) => {
        this.logger.info("[ConversationRepository][[XmppService]][getOnMamMessages] ", messages);
        messages.forEach(msg => {
          this.logger.info("[ConversationRepository][[XmppService]][getOnMamMessage] ", msg);
          this.store.dispatch(new MessagesBulkUpdate([msg]));
          let msgTarget;
          if (msg.type === "groupchat") {
            msgTarget = msg.from.bare;
          } else {
            msgTarget = (msg.from.bare === this.userJID.bare) ? msg.to.bare : msg.from.bare;
          }
          this.getConversationById(msgTarget).subscribe(targetConv => {
            this.logger.info("[ConversationRepository][getOnMamMessageTargetConv-" + msgTarget + "] ", targetConv, msg.id);
            if ((targetConv.last_message_id === msg.id)) {
              if (msg.body === "Encrypted message") {
                this.databaseService.getMessageById(msg.id).subscribe(dbMessage => {
                  const data = [{ conversationTarget: msgTarget, message: dbMessage, incoming: targetConv.incoming, historystate: targetConv.historystate }];
                  this.logger.info("[ConversationRepository][getOnMamMessageTargetConv-" + msgTarget + "] lastmessageupdateDB ", targetConv, msg.id, targetConv.content, dbMessage.body);
                  this.store.dispatch(new MultiConversationUpdateLastMessage(data));
                });
              } else {
                const data = [{ conversationTarget: msgTarget, message: msg, incoming: targetConv.incoming, historystate: targetConv.historystate }];
                this.databaseService.updateConversationContent(msgTarget, msg.body, msg.id, targetConv.historystate);
                this.logger.info("[ConversationRepository][getOnMamMessageTargetConv-" + msgTarget + "] lastmessageupdate ", targetConv, msg.id, targetConv.content, msg.body);
                this.store.dispatch(new MultiConversationUpdateLastMessage(data));
              }
            }
          });
          // this.saveMessagesToStoreAndDB(conversationTarget, [msg]);
          // this.saveMessagesToStoreAndDB(conversationTarget, [msg], updateLastConvMessage);
        });
      });
    }

    this.xmppService.getOnResendSignal().pipe(bufferTime(600), filter(res => res.length > 0)).subscribe((signals) => {
      this.logger.info("[ConversationRepository][[XmppService]][getOnResendSignals] ", signals);
      if (!!signals && signals.length > 0) {
        signals.forEach(signal => {
          try {
            const parsedSignal = JSON.parse(signal.data);
            this.logger.info("[ConversationRepository]processResendSignal ", parsedSignal);
            if (!!parsedSignal.id) {
              this.databaseService.getMessageById(parsedSignal.id).pipe(take(1)).subscribe(existingMsg => {
                if (!!existingMsg) {
                  const updatedMsg = { ...existingMsg };
                  if (existingMsg.body !== parsedSignal.body) {
                    updatedMsg.body = parsedSignal.body;
                  }
                  if (!!parsedSignal.htmlBody && (existingMsg.htmlBody !== parsedSignal.htmlBody)) {
                    updatedMsg.htmlBody = parsedSignal.htmlBody;
                  }
                  const target = !!existingMsg.convTarget ? existingMsg.convTarget : existingMsg.conversationTarget;
                  this.databaseService.createOrUpdateDecryptedMessage(updatedMsg, target).pipe(take(1)).subscribe(() => {
                    this.store.dispatch(new MessagesBulkUpdate([updatedMsg]));
                    this.logger.info("[ConversationRepository]processResendSignal done: ", updatedMsg);
                  });
                }
              });
            }
          } catch (error) {
            this.logger.info("[ConversationRepository][[XmppService]][getOnResendSignalProcessing] error: ", error);
          }
        });
      }
    });

    this.xmppService.getOnMessageReceipt().subscribe(messageId => {
      this.updateMessageReceipt(messageId);
    });

    // If either XMPP or Internet re-connected
    combineLatest(this.store.select(getIsConnectedXMPP), this.store.select(getNetworkInformation).pipe(distinctUntilChanged()))
      .subscribe(([isConnected , information ]) => {
        this.logger.info("[ConversationRepository][XMPPService] getIsConnectedXMPP/getNetworkInformation", this.joinedConversations, isConnected, information && information.onlineState );

        this.isConnectedXMPP = isConnected;
        const networkOnline = information && information.onlineState;

        if (isConnected && networkOnline) {
          setTimeout(() => {
            this.logger.info("[ConversationRepository][XMPPService] need to send pending messages");
            this.sendPendingMessages();
          }, 2000);
          setTimeout(() => {
            if (Object.keys(this.pendingReadRequest).length > 0) {
              this.xmppService.sendMarkReadSignalToSelf(Object.keys(this.pendingReadRequest).join(","));
            }
            this.sendMarkAsReadRequests();

            // sync currently opened conv
            // this.syncNewMessagesForActiveConv();
          }, 200);
        } else {
          this.joinedConversations = [];
          // ToDoSSA: check reconnect case
          // this.joinedConversationsLater = [];
          this.logger.info("[ConversationRepository][XMPPService] re-queue pendingMessages");
          this.pendingMessages.forEach((pMsg: Message) => {
            pMsg.sendingAt = null;
            if (!pMsg.id) {
              pMsg.id = CommonUtil.randomId(10);
            }
          });
        }

        if (!window.appInBackground) {
          // we just lost network, xmpp connection is stale
          if (isConnected && !networkOnline) {
            this.logger.info("[ConversationRepository][XMPPService] stale connection -> trigger reconnect");
            this.isConnectedXMPP = false;
            this.xmppService.triggerReconnect();
          }

          // we appear to have network, but xmpp is offline
          if (!isConnected && networkOnline) {
            this.logger.info("[ConversationRepository][XMPPService] stale connection -> trigger reconnect");
            this.isConnectedXMPP = false;
            this.xmppService.triggerReconnect();
          }
        }

    });

    combineLatest(this.store.select(getIsConnectedXMPP), this.store.select(getNetworkInformation).pipe(distinctUntilChanged()), this.omemoRefreshList).pipe(debounceTime(300))
      .subscribe(([isConnected, information, omemoRefreshList]) => {
        if (!!isConnected && information.onlineState && !!omemoRefreshList && (omemoRefreshList.length > 0)) {
          this.logger.info("COMBINED ", isConnected, information, omemoRefreshList);
          omemoRefreshList.forEach(jid => {
            if (this.isBgSyncInProgress) {
              this.omemoMamQueue.push(jid);
            } else {
              this.xmppService.getOMEMODevicesForTarget(jid).pipe(take(1)).subscribe(res => {
                this.logger.info("COMBINED getOMEMODevicesForTarget ", jid, res);
              });
            }
          });
        }
    });

    // // // handle muc presences type=available
    // this.xmppService.getOnMucAvailable()
    //   .bufferTime(300)
    //   .filter(res => res.length > 0)
    //   .subscribe(this.handleMucAvailable.bind(this));

    // handle only MUC presences
    this.xmppService.getOnPresence().subscribe(presence => {
      this.logger.info("[ConversationRepository][getOnPresence]", presence);

      // fix issue with previosly subscribed to MUC chats
      if (presence.error) {
        const fromJid = presence.from.bare;
        if (this.presencesErrorsToIgnore[fromJid]) {
          return;
        }
        if (/*presence.error.condition === "remote-server-not-found" || */ fromJid.includes("@conference")) {
          this.xmppService.unsubscribe(fromJid);
          this.presencesErrorsToIgnore[fromJid] = true;
          this.logger.warn("[ConversationRepository][getOnPresence] fix MUC/remote-server-not-found subscription issue", fromJid);
          return;
        }
      }

      // MUC presences
      if (presence.muc) {
        this._onPresenceMuc$.next(presence);

      // self avatar update notification
      } else {
        if (presence.avatarId && presence.from.bare === this.userJID?.bare) {
          // this.logger.info("[ConversationRepository][getOnPresence] self.avatar", 4);
          this.avatarRepo.upgradeAvatar(presence.from.bare);
          this.broadcaster.broadcast("userAvatarUpdated");

        // a nick is changed
        } else if (presence.nick) {
          // this.logger.info("[ConversationRepository][getOnPresence]", 5);
          this._onNickChange$.next({ bare: presence.from.bare, nick: presence.nick });
        }
        if (presence.type === "available" && presence.show === "online") {
          // this.logger.warn("SHOWONLINE ", presence);
          this.contactRepo.updateContactStatus(presence.from.bare, Date.now());
        }
      }
    });

    this._onNickChange$.asObservable()
      .pipe(bufferTime(2000)
      , filter((data: any) => data.length > 0))
      .subscribe((data: any) => {

        // group changes by bare
        const nickChanges = {};
        data.forEach(item => {
          nickChanges[item.bare] = item.nick;
        });

        // call nick change
        Object.keys(nickChanges).forEach(bare => {
          if (this.xmppService && this.xmppService.updateNick) {
            this.xmppService.updateNick(bare, nickChanges[bare]);
          }
        });
      });

      this._removeMessagesFromPendings$.asObservable()
        .pipe(bufferTime(2000)
        , filter(data => data.length > 0))
        .subscribe(mids => {
          this.removeMessagesFromPending(mids);
        });

      this._onPresenceMuc$.asObservable()
        .pipe(bufferTime(2000)
        , filter(data => data.length > 0))
        .subscribe(mucPresences => {
          this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc]", mucPresences);

          mucPresences.forEach(presence => {
            const conversationTarget = presence.from.bare;
            const jidBare = presence.muc.jid.bare;
            const affiliation = presence.muc.affiliation;
            const role = presence.muc.role;
            const reason = presence.muc.reason;

            //  create conv if not exist
            if (presence.type === "available") {
              this.store.select(state => getConversationById(state, conversationTarget)).pipe(take(1)).subscribe(conv => {
                if (!conv) {
                  this.databaseService.getConversationFromDB(conversationTarget).pipe(take(1)).subscribe(dbconv => {
                    this.logger.info("[ConversationRepository][getOnPresence] createLocalConversation check dbconv:", dbconv);
                    if (dbconv.length === 0) {
                      this.logger.info("[ConversationRepository][getOnPresence] createLocalConversation", conversationTarget);
                      this.createLocalConversation(conversationTarget);
                    }
                  });
                } else {
                  // this.logger.info("[ConversationRepository][getOnPresence] existing conversation", conv);
                }
              });
            }

            if (!jidBare) {
              return;
            }

            // REMOVE participant
            if (((affiliation === "none" || presence?.type === "unavailable") && (role === "participant" || role === "none" || reason === "kick" || reason === "unregistered"))) {
              this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc]", presence);


                // сurrent user is REMOVE from group chat
                if (this.userJID && this.userJID?.bare === jidBare && (this.joinedPublicList.indexOf(conversationTarget) === -1)) {
                  // this.leftRoomList
                  this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] сurrent user is REMOVE? from group chat ", presence);

                  if (presence?.status?.indexOf("Replaced by new connection") === -1) {

                    this.resetUnreadCount(conversationTarget);
                    //
                    this.store.select(state => getConversationById(state, conversationTarget))
                      .pipe(take(1))
                      .subscribe(conv => {
                        if (conv) {
                          this.logger.info("[leaveOnMucPresence] ", conv.Target, affiliation, presence);
                          this.leaveConversation(conv);
                        }
                      });

                      this.deleteConversationByTarget(conversationTarget).pipe(take(1)).subscribe(() => {
                        this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] DELETE MEETING ROOM");
                      });
                  } else {
                    this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] сurrent user is REMOVE from group chat SKIPPED!");
                  }

                  this.getAndUpdateGroupInfo(conversationTarget).pipe(take(1)).subscribe(res => {
                    this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] сurrent user is REMOVE, getAndUpdateGroupInfo target, res: ", conversationTarget, res);
                    if (!!res.audiences) {
                      let audience_only = res.audiences.indexOf(this.userJID?.bare) > -1;
                      this.store.dispatch(new ConversationUpdate({ target: conversationTarget, changes: { audience_only: audience_only } }));
                      this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
                        if (conv && conversationTarget === conv.Target) {
                          this.broadcaster.broadcast("UPDATE_ROLE_IN_CURRENTCHAT", conversationTarget);
                        }
                      });
                    }
                  }, err => {
                    this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] сurrent user is REMOVE, getAndUpdateGroupInfo err: ", err);
                    if (!!err.status && (err.status === 403)) {
                      // not authorized - so no member; hence leave
                      this.logger.info("[getGroupInfo] err 403 => leave ", conversationTarget);
                      this.resetUnreadCount(conversationTarget);
                      //
                      this.store.select(state => getConversationById(state, conversationTarget))
                        .pipe(take(1))
                        .subscribe(conv => {
                          if (conv) {
                            this.logger.info("[leaveOnMucPresence] ", conv.Target, affiliation, presence);
                            this.leaveConversation(conv);
                          }
                        });

                      this.deleteConversationByTarget(conversationTarget).pipe(take(1)).subscribe(() => {
                        this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] DELETE MEETING ROOM");
                      });
                    }

                  });
                }


              if (presence?.status !== "Kicked: recipient unavailable") {
                // if other user is removed from conv
                this.store.dispatch(new ConversationRemoveMember({
                  conversationTarget: conversationTarget,
                  member: jidBare
                }));
              } else {
                this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] skipping removal of " + presence?.from.resource + " from " + presence?.from.bare);
              }

              // if other user (admin) is removed from conv
              this.store.select(state => getConversationAdmins(state, conversationTarget))
                .pipe(take(1))
                .subscribe(adminsJid => {
                  if (adminsJid.includes(jidBare)) {
                    adminsJid = adminsJid.filter(jid => jid !== jidBare);
                    this.store.dispatch(new ConversationUpdateAdmins({
                      conversationTarget: conversationTarget,
                      admins: adminsJid
                    }));
                  }
                });

            // ADD ADMIN
            } else if (affiliation === "admin" && (role === "moderator" || role === "participant" || role === "none")) {
              this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc]", 2);

              this.store.select(state => getConversationAdmins(state, conversationTarget))
                .pipe(take(1))
                .subscribe(adminsJid => {
                if (!adminsJid.includes(jidBare)) {
                  adminsJid.push(jidBare);
                  this.store.dispatch(new ConversationUpdateAdmins({
                    conversationTarget: conversationTarget,
                    admins: adminsJid
                  }));
                }
              });

            // ADD participant
            } else if (affiliation === "member" && (role === "participant" || role === "none")) {
              // this.logger.info("[ConversationRepository][getOnPresence][_onPresenceAvailable]", conversationTarget, jidBare);
              // this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc]", 3);

              // remove from admins
              this.store.select(state => getConversationAdmins(state, conversationTarget))
                .pipe(take(1))
                .subscribe(adminsJid => {
                  if (adminsJid.includes(jidBare)) {
                    adminsJid = adminsJid.filter(jid => jid !== jidBare);
                    this.store.dispatch(new ConversationUpdateAdmins({
                      conversationTarget: conversationTarget,
                      admins: adminsJid
                    }));
                  }
                });

              // add as members if required
              if (jidBare !== this.userJID?.bare) {
                this._onPresenceMucAddMember$.next({ conversationTarget, jidBare });
              }
            } else if (affiliation === "owner" && reason === "change_owner") {
              this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMuc] change owner", jidBare, conversationTarget);

              this.setConversationOwnerInRedux(conversationTarget, jidBare);
            }

          });
        });

        this._onPresenceMucAddMember$.asObservable()
          .pipe(bufferTime(2000)
          , filter(data => data.length > 0))
          .subscribe(data => {

            const convsMembers = {};
            data.forEach(item => {
              let members = convsMembers[item.conversationTarget];
              if (!members) {
                members = new Set();
              }
              members.add(item.jidBare);
              convsMembers[item.conversationTarget] = members;
            });

            this.logger.info("[ConversationRepository][getOnPresence][_onPresenceMucAddMember]", data, convsMembers);

            Object.keys(convsMembers).forEach(conversationTarget => {
              this.leftRoomList = this.leftRoomList.filter(v => v !== conversationTarget);
              this.addMemberToMembersList(conversationTarget, Array.from(convsMembers[conversationTarget]));
            });
          });
  }

  refreshConversations() {
    // re-fetch convs from DB (they may have new data (Timestamp & Content) after FCM -> IndexedDB flow)
    if (this.refreshConversationsRunning) {
      return;
    }
    this.refreshConversationsRunning = true;
    setTimeout(() => {
      this.refreshConversationsRunning = false;
    }, 60 * 1000);
    const t0 = performance.now();
    this.databaseService.fetchConversationsTop(11).subscribe(conversations => {
      this.logger.info("[ConversationRepository] resume, fetchConversations, reload", conversations);
      // this.logger.info("[ConversationRepository] resume, fetchConversations, reload, dhaval.dodiya@dev.vnc.de",
      //   conversations.filter(c =>  c.Target === "dhaval.dodiya@dev.vnc.de")[0].content);
      const t1 = performance.now();
      this.logger.info(`[PERFORMANCE] [ConversationRepository] resume, databaseService.fetchConversations: took ${t1 - t0} milliseconds.`);

      // it could be we have stale data here,
      // e.g. when call 2 requests simultaniusly - update conv and fetch top10,
      // so need to fix

      const conversationsChecked = [];
      conversations.forEach(convInDB => {
        this.store.select(state => getConversationById(state, convInDB.Target)).pipe(take(1)).subscribe(convInStore => {
          let convToAdd: Conversation;
          if (convInStore && convInStore.Timestamp > convInDB.Timestamp) {
            this.logger.info("[ConversationRepository] resume, fix stale cache", {convInStore, convInDB});
            convToAdd = convInStore;
          } else {
            convToAdd = convInDB;
          }
          conversationsChecked.push(convToAdd);
        });
      });


      this.store.dispatch(new ConversationNextLoadSuccess({ conversations: conversationsChecked }));

      const t2 = performance.now();
      this.logger.info(`[PERFORMANCE] [ConversationRepository] resume, ConversationReLoadSuccess: took ${t2 - t0} milliseconds.`);

    });

    // pool conversations updates on resume
    setTimeout(() => {
      // this.logger.info("[ConversationRepository][refreshConvs] calling loadAllUpdatedConversations");
      if (navigator.connection && (navigator.connection.type !== "none")) {
        // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 4");
        this.loadAllUpdatedConversations();
      }
    }, 100);
    this.syncNewMessagesForActiveConv();
  }


  isSentMessage(message: Message) {
    // this.logger.info("[ChatWindowComponent][DataSource][isSentMessage]", this.userJID, message.fromJid, message.from.resource, message);
    return this.userJID && (message.fromJid === this.userJID?.bare || message.from.resource === this.userJID?.bare || message.from.bare === this.userJID?.bare);
  }

  private updateMessageReceipt(messageId: string, retryCount = 0) {
    this.logger.info("[ConversationRepository][updateMessageReceipt]", {messageId, retryCount});

    this.store.select(state => getMessageById(state, messageId)).pipe(take(1)).subscribe(message => {
      this.logger.info("[ConversationRepository][updateMessageReceipt] message exists", message);

      if (!message) {
        this.logger.warn("[ConversationRepository][updateMessageReceipt] message not found", {messageId, retryCount});
        if (retryCount < 3) {
          // a delivered status received before the message carbon?
          // let's retry in 500 ms
          setTimeout(() => {
            this.updateMessageReceipt(messageId, ++retryCount);
          }, 500);
        }
        return;
      }

      const convTarget = message.to.bare;
      if (message.attachment && message.attachment.url.startsWith("blob")) {
        message.attachment.url = message.body;
      }
      // update message status in redux & DB
      this.store.dispatch(new MessageStatusUpdateAction({ id: messageId, status: MessageStatus.DELIVERED }));
      this.databaseService.createOrUpdateMessages([{ ...message, status: MessageStatus.DELIVERED }], convTarget);

      // update conv's receipt
      this.store.select(state => getConversationById(state, convTarget)).pipe(take(1)).subscribe(conv => {
        if (conv && !conv.incoming) {
          this.store.dispatch(new ConversationUpdate({ target: convTarget, changes: { received_receipt: true } }));
        }
      });


      // original message was sent from vncproject or parallel client - hence need to update with delay
      if (message?.to?.unescapedFull !== message?.to?.unescapedBare) {
        setTimeout(() => {
          this.logger.info("[ConversationRepository] getOnMessageReceipt DELIVERED UpdateMessageStatus delay", messageId, message.body, message);
          this.store.dispatch(new ConversationUpdate({ target: convTarget, changes: { received_receipt: true } }));
        }, 200);
      }
    });
  }

  private syncNewMessagesForActiveConv() {
    this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        this.logger.info("[ConversationRepository][syncNewMessagesForActiveConv]", conv);
        this.loadMessages(conv.Target);
      }
    });
  }

  public setConversationAsUnreadWhenMissedCall(conversationTarget){
    this.logger.info("[ConversationRepository][setConversationAsUnreadWhenMissedCall]", conversationTarget);

    this.getSelectedConversation().pipe(take(1)).subscribe(selectedConv => {
      if (selectedConv && selectedConv.Target === conversationTarget) {
        // ignore, we are in the conv
      } else {
        this.store.select(state => getConversationById(state, conversationTarget)).pipe(take(1)).subscribe(conv => {
          if (conv) {
            const unreadIds = conv.unreadIds || [];
            unreadIds.push("missed_call_message_id"); // some dummy id
            this.logger.info("[ConversationRepository][setConversationAsUnreadWhenMissedCall] unreadIds", unreadIds);

            this.setUnreadCount(conversationTarget, unreadIds);
          }
        });
      }
    });
  }

  public getConversations(): Observable<Conversation[]> {
    return this.store.select(getConversations);
  }

  public getArchivedConversations(): Observable<Conversation[]> {
    return new Observable<Conversation[]>((observer) => {
      this.loadConversationsDB("", 2000).subscribe({
        next: (convs) => {
          const archivedConvs = convs.filter(
            (conv: Conversation) => conv.archived && !conv.deleted
          );
          observer.next(archivedConvs);
          observer.complete(); // Mark the Observable as completed
        },
        error: (err) => {
          observer.error(err);
        }
      });
    });
  }


  public getUnArchivedConversations(): Observable<Conversation[]> {
    return this.store.select(getUnArchivedConversations);
  }

  public updateMessageFavorite(id: string, isStarred: boolean): void {
    this.store.dispatch(new MessageFavoriteUpdateAction({ id, isStarred }));
  }

  public addPadNotification(target: string): void {
    this.store.dispatch(new ConversationAddPadNotification(target));
    this.store.dispatch(new ConversationUpdate({ target: target, changes: { pad_read: 0 } }));
  }

  public removePadNotification(target: string): void {
    this.store.dispatch(new ConversationRemovePadNotification(target));
  }

  public getSelectedMessages() {
    return this.store.select(getSelectedMessages);
  }

  public getLastMessageByConversationTarget(conv: Conversation): Observable<Message> {
    return this.store.select(state => getLastMessageByConversationTarget(state, conv));
  }

  public hasNotification(target: string): Observable<boolean> {
    return this.store.select(state => hasNotification(state, target));
  }

  public hasWBNotification(target: string): Observable<boolean> {
    return this.store.select(state => hasWBNotification(state, target));
  }

  public getColors(): string[] {
    return [...this.colors];
  }

  navigateToConversation(target?: string, shouldRedirectHome: boolean = true) {
    this.logger.info("[ConversationRepository][navigateToConversation] openurl", target);

    if (!target) {
      this.selectConversation(null);
      if (!this.router.url.includes("channels")) {
        this.router.navigateByUrl("/talk");
        this.logger.info("[ConversationRepository][navigateToConversation] /talk");
      }
      this.broadcaster.broadcast("hideChatWindow");
    } else {
      this.navigateToTarget(target).subscribe();
    }
  }

  navigateToTempConversation(target: string) {
    this.navigateToTarget(target, true).subscribe();
  }

  navigateToConversationAndMessages(target: string, messagesIds) {
    this.logger.info("[ConversationRepository][navigateToConversationAndMessage]", target, messagesIds);

    this.navigateToConversation(target);
  }

  navigateToConversationAndJumpToMessage(target: string, message: any, isHighLightCompleteRow: boolean = false) {
    const messageId = message.id;
    const messageTimestamp = message.timestamp;
    this.commonService.highlightMessageCompleteRow = false;
    this.commonService.isShowLoading = true;
    this.logger.info("[ConversationRepository][navigateToConversationAndJumpToMessage]", target, messageId, messageTimestamp + "");

    this.setMentionedMessage(messageTimestamp, messageId);

    // reset activated state
    this.store.dispatch(new ConversationDeactivate(target));
    //
    // and clear redux
    // this.store.select(state => getConversationById(state, target)).pipe(take(1)).subscribe(conv => {
    //   this.deleteMessagesFromRedux(conv);
    // });

    this.navigateToConversation(target);

    if (isHighLightCompleteRow) {
      this.highLightCompleteRowOfUnreadMessages();
    }
  }

  highLightCompleteRowOfUnreadMessages() {
    this.commonService.highlightMessageCompleteRow = true;
  }

  setMentionedMessage(messageTimestamp: number, messageId: string) {
    localStorage.setItem("mentionedTimestamp", messageTimestamp + "##" + messageId);
  }

  mentionedMessage() {
    const mData = localStorage.getItem("mentionedTimestamp");
    if (!mData) {
      return {timestamp: null, id: null};
    }
    const mDataSplit = mData.split("##");
    return {timestamp: parseInt(mDataSplit[0]), id: mDataSplit[1] || undefined};
  }

  resetMentionedMessage() {
    localStorage.removeItem("mentionedTimestamp");
  }

  navigateToTarget(target: string, isTempConversation?: boolean): Observable<any> {
    // console.trace("[ConversationRepository][navigateToTarget]", target, `is temp: ${!!isTempConversation}`);
    this.logger.info("[ConversationRepository][navigateToTarget]", target, `is temp: ${!!isTempConversation}`);

    const response = new Subject();

    this.store.select(state => getConversationById(state, target))
      .pipe(take(1))
      .subscribe(conv => {
        let conversation = conv;

        // If selected conversation is not in history of our conversations then create
        if (!conversation) {
          if (target.indexOf("@conference") !== -1) {
            if (isTempConversation) {
              conversation = this.createLocalTempGroupConversation(target);
            } else {
              conversation = this.createLocalConversation(target, "groupchat");
            }
          } else {
            conversation = this.createLocalConversation(target, "chat");
          }
        }

        // navigate
        try {
          const encodedUrl = btoa(target);
          this.router.navigateByUrl("/talk/conv/" + encodedUrl)
          .then(res => {
            this.logger.info("[ConversationRepository][navigateToTarget] navigateByUrl success?", res, target);
            response.next(null);
          }).catch(err => {
            this.logger.error("[ConversationRepository][navigateToTarget] navigateByUrl", target, err);
            response.next(null);
          });
        } catch (ex) {
          this.logger.error("[ConversationRepository][navigateToConversation]", target, ex);
        }
      });

    return response.asObservable();
  }


  public createLocalE2EEConversation(target: string, type: string = "groupchat"): Conversation {
    return this._createLocalConversation(target, type, "", null, true);
  }

  public createLocalConversation(target: string, type: string = "groupchat", content: string = "", audience?: string[], subject = "", broadcastDesc?:string , broadcastTags?:any[]): Conversation {
    if(broadcastDesc) this.broadcastDescriptions = broadcastDesc;
    if(broadcastTags) this.broadcastTags = broadcastTags;
    return this._createLocalConversation(target, type, content, audience, false, subject, broadcastDesc, broadcastTags);
  }

  private _createLocalConversation(target: string, type: string = "groupchat", content: string = "", audience: string[], isE2EE: boolean = false, subject = "",  broadcastDesc?:string , broadcastTags?:any[]): Conversation {
    this.logger.info("[ConversationRepository][_createLocalConversation]", target);

    const conv = ConversationUtil.createLocalConversation(target, subject, type, content, isE2EE);

    if (type === "broadcast") {
      const appDomain = localStorage.getItem("appDomain") || this.userJID.domain;
      const broadcastId = CommonUtil.randomId(12);
      conv.Target = `broadcast-${broadcastId}@${appDomain}`;
      conv.type = "broadcast";
      conv.broadcast_title = target;
      conv.broadcast_owner = this.userJID?.bare;
      conv.audience = audience;
      conv["description"] = broadcastDesc;
      conv["tags"] = broadcastTags;
    }

    this.store.dispatch(new ConversationCreate(conv));
    // deactivate for debug
    // this.conversationService.createActiveConversation(conv).subscribe();
    this.databaseService.createOrUpdateConversation([conv]).subscribe();
    if (type === "broadcast") {
      this.conversationService.updateConversationStatus(conv, {
        archived: false,
        state: "active",
      });
    }
    return conv;
  }

  createOrUpdateConversationSetting(settings: any[]) {
    return this.databaseService.createOrUpdateConversationSetting(settings);
  }

  public createLocalTempGroupConversation(target: string): Conversation {
    this.logger.info("[ConversationRepository][createLocalTempGroupConversation]", target);

    const conv = ConversationUtil.createLocalConversation(target, ConversationUtil.getGroupChatTitle(target), "groupchat", "", false, true);
    this.store.dispatch(new ConversationCreate(conv));
    return conv;
  }

  public removeLocalTempGroupConversation(target: string) {
    this.logger.info("[ConversationRepository][removeLocalTempGroupConversation]", target);

    this.store.dispatch(new ConversationDelete(target));
  }

  public selectConversation(target: string) {
    this.logger.info("[ConversationRepository][selectConversation]", target);

    this.store.dispatch(new HideGlobalSearch());

    if (this.currentConv && this.currentConv.Target === target) {
      // conversation already selected
      this.markConversationsRead(this.currentConv);
      this.logger.warn("[ConversationRepository][selectConversation] skip, conversation already selected");
      return;
    }

    this.hideHeaderOnMobile();

    // if target is null, unselect any conversation if selected and return
    if (!target) {
      if (this.currentConv) {
        this.store.dispatch(new ConversationSelect(null));
      }
      return;
    }

    this.store.select(state => getConversationById(state, target))
      .pipe(take(1))
      .subscribe(conv => {
        this.logger.info("[ConversationRepository][selectConversation]", target, conv, this.currentConv);
        const previousConv = this.currentConv;
        this.currentConv = conv;
        if (!conv) {
          return;
        }
        this.currentConvUnreadIds = conv.unreadIds ? [...conv.unreadIds] : [];
        if (this.currentConvUnreadIds.length === 0) {
          const {id: messageId} = this.mentionedMessage();
          if (messageId) {
            this.currentConvUnreadIds = [messageId];
            this.currentMentionedMessageId = messageId;
          }
        }

        // this.logger.info("[ConversationRepository][selectConversation]",
        //   {currentConvUnreadIds: this.currentConvUnreadIds, currentMentionedMessageId: this.currentMentionedMessageId});

        if (!!conv && ConversationUtil.isGroupChat(conv) && !conv.deleted) {
          this.logger.info("[maybejoindeleted] ", conv);
          this.joinRoomIfNotJoined(conv);
        }

        // clear redux if conv is not activated and we have unreads
        // otherwise we will have redirects collisions
        this.store.select(state => getIsActivatedConversation(state, target)).pipe(take(1)).subscribe(isAlreadyActived => {
          if (!isAlreadyActived && this.currentConvUnreadIds.length > 0) {
            this.deleteMessagesFromRedux(conv);
          }
        });

        this.store.dispatch(new ConversationSelect(target));

        if (!!conv && !conv.isTemporary && (target !== "all")) {
          this.loadMessages(target);

          // mark as read
          this.closeNotifications([conv.Target]);
          this.markConversationsRead(conv);
        }
        if ((target === "all") && previousConv && !previousConv.isTemporary) {
          this.closeNotifications([previousConv.Target]);
          this.markConversationsRead(previousConv);
        }
      });
  }

  selectMiniConversation(target) {
    this.logger.info("[selectMiniConversation]", target);
    this.store.select(state => getConversationById(state, target))
      .pipe(take(1))
      .subscribe(conv => {
        this.logger.info("[selectMiniConversation]", target, conv);
        if (!conv && target) {
          conv = this.createLocalConversation(target, target.indexOf("@conference") !== -1 ? "groupchat" : "chat");
        }
        this.currentConv = conv;
        if (!!conv && ConversationUtil.isGroupChat(conv) && !conv.deleted && !conv.isTemporary) {
          this.joinRoomIfNotJoined(conv);
        }
        this.store.dispatch(new MiniConversationSelect(target));
        if (!!conv && !conv.isTemporary) {
          this.loadMessages(target);
        }
      });
  }

  resetMiniConversation() {
    this.store.dispatch(new MiniConversationSelect(null));
  }

  // TODO: it should not be in this class
  private hideHeaderOnMobile() {
    if (document.getElementById("mainLayout") !== null) {
      document.getElementById("mainLayout").classList.add("hide-header-mobile");
    }
  }

  public deselectCurrentConversation(shouldRedirect: boolean = true) {
    const pathName = window.location.pathname;
    if (CommonUtil.isOnMobileDevice()) {
      this.navigateToConversation(ConstantsUtil.ALL_TARGET);
    } else {
      if (!pathName.includes("talk/archive")) {
        this.navigateToConversation(null, shouldRedirect);
      }
    }

  }

  public selectMessage(messageId: string) {
    this.store.dispatch(new SelectMessage(messageId));
  }

  public unselectMessage(messageId: string) {
    this.store.dispatch(new UnselectMessage(messageId));
  }

  public resetMessages() {
    this.store.dispatch(new ResetMessages());
  }

  public removeFailedMessage(msg) {
    this.logger.info("[ConversationRepository][removeFailedMessage]", msg);

    this.store.dispatch(new MessageDeleteAction(msg.id));
    this.databaseService.deleteMessage(msg).subscribe();
  }

  public getSelectedConversation(isMiniChat?: boolean): Observable<Conversation> {
    return isMiniChat ? this.store.select(getSelectedMiniConversation) : this.store.select(getSelectedConversation);
  }

  public getPadNotifications(): Observable<string[]> {
    return this.store.select(getPadNotifications);
  }

  public getAllConversationsUnreadCount(): Observable<any> {
    return this.store.select(getAllConversationsUnreadCount);
  }

  public getActiveConversation(): Observable<Conversation> {
    return this.store.select(getActiveConversation);
  }

  public setActiveConversation(conversation: Conversation): void {
    this.store.dispatch(new SetActiveConversation(conversation));
  }

  public updateConvSubjectInRedux(target: string, subject: string) {
    const data = {
      displayName: subject,
      groupChatTitle: subject
    };
    this.store.dispatch(new ConversationUpdateBroadcastData({ conversationTarget: target, changes: data }));
  }

  public setConversationSubject(target: string, newTitle: string) {
    if (this.isGroupManageEnabled) {
      this.groupChatsService.setSubject(target, newTitle).subscribe();
    } else {
      this.xmppService.setSubject(target, newTitle);
    }
  }

  public blockConversation(conversationTarget: string): Observable<boolean> {
    return this.xmppService.block(conversationTarget).pipe(map((isBlocked) => {
      if (isBlocked) {
        this.store.dispatch(new ConversationBlockListAdd(conversationTarget));
      }

      return isBlocked;
    }));
  }

  public unblockConversation(target): Observable<boolean> {
    return this.xmppService.unblock(target).pipe(map((isBlocked) => {
      if (isBlocked) {
        this.store.dispatch(new ConversationBlockListRemove(target));
      }

      return isBlocked;
    }));
  }

  public updateNotificationSetting(conversationTarget: string, type: number): Observable<any> {
    this.logger.info("confrepo updateNotificationSetting: ", conversationTarget, type);

    // sync with other active clients
    const signal = {
      type: "notification-settings",
      target: conversationTarget,
      data: JSON.stringify({type}),
    };
    this.xmppService.sendSignalToMyself(signal);

    return this.conversationService
      .updateNotificationSettings(conversationTarget, type)
      .pipe(tap(() => {
        this.store.dispatch(new UpdateNotificationSetting({ conversationTarget, type }));
      }));
  }

  public updateSoundSetting(conversationTarget: string, type: number): Observable<any> {
    this.logger.info("[ConversationRepository][updateSoundSetting]", type, conversationTarget);

    // sync with other active clients
    const signal = {
      type: "sound-settings",
      target: conversationTarget,
      data: JSON.stringify({type}),
    };
    this.xmppService.sendSignalToMyself(signal);

    return this.conversationService
      .updateSoundSetting(conversationTarget, type)
      .pipe(tap(() => {
        this.logger.info("[ConversationRepository][updateSoundSetting] update redux", type, conversationTarget);
        this.store.dispatch(new UpdateSoundSetting({ conversationTarget, type }));
      }));
  }

  public updateNotificationConfig(conversationTarget: string, config: any): void {
    this.logger.info("[updateNotificationConfig]", conversationTarget, config);

    // sync with other active clients
    const signal = {
      type: "notification-config",
      target: conversationTarget,
      data: JSON.stringify(config),
    };
    this.xmppService.sendSignalToMyself(signal);

    this.store.dispatch(new UpdateConversationNotificationConfig({ conversationTarget, config }));
    this.getNotificationConfig(conversationTarget).pipe(take(1)).subscribe(v => {
      this.createOrUpdateConversationSetting([{Target: conversationTarget, Settings: v}]);
    });
  }

  public getNotificationConfig(conversationTarget: string) {
    return this.store.select(state => getConversationNotificationConfig(state, conversationTarget));
  }

  public getConversationRoomId(conversationTarget: string): Observable<JitsiOption> {
    return this.store.select(state => getConversationRoomId(state, conversationTarget));
  }

  public updateConversationRoomId(conversationTarget: string, option: JitsiOption): void {
    this.store.dispatch(new UpdateConversationRoomId({ conversationTarget, option }));
  }

  public enableGlobalMute(): Observable<any> {
    return this.conversationService
      .updateSoundSetting("global", 2)
      .pipe(tap(() => {
        this.store.dispatch(new EnableGlobalMute());
      }));
  }

  public disableGlobalMute(): Observable<any> {
    return this.conversationService
      .updateSoundSetting("global", 0)
      .pipe(tap(() => {
        this.store.dispatch(new DisableGlobalMute());
      }));
  }

  private processLeaveConversationUI() {
    this.broadcaster.broadcast("turnOffVideo", {});
    this.logger.info("[ConversationRepository][processLeaveConversationUI] null");

    if (CommonUtil.isOnMobileDevice()) {
      this.navigateToConversation(ConstantsUtil.ALL_TARGET);
    } else {
      this.navigateToConversation(null);
    }

    this.broadcaster.broadcast("showLeftPanel", {});
  }

  /**
   * Rooms
   */
  public createRoom(name?: string, isTemporary?: boolean): Observable<string> {
    this.logger.info("[ConversationRepository][createRoom]", name, isTemporary);

    const response = new Subject<string>();

    // we update store in join room, instead of this.
    this.xmppService.createRoom(name, isTemporary).subscribe(bare => {
      this.getConversationById(bare).pipe(filter(v => !!v), take(1)).subscribe(() => {
        // set owner in redux
        if (this.userJID) {
          this.setConversationOwnerInRedux(bare, this.userJID?.bare);
        }
      });
      response.next(bare);
    }, error => {
      response.error(error);
    });

    return response.asObservable();
  }

  public createMeetingRoom(name?: string): Observable<string> {
    this.logger.info("[ConversationRepository][createMeetingRoom]", name);

    const response = new Subject<string>();

    // we update store in join room, instead of this.
    this.xmppService.createMeetingRoom(name).subscribe(bare => {

      // set owner in redux
      this.getConversationById(bare).pipe(filter(v => !!v), take(1)).subscribe(() => {
        // set owner in redux
        if (this.userJID) {
          this.setConversationOwnerInRedux(bare, this.userJID?.bare);
        }
      });
      response.next(bare);
    }, error => {
      response.error(error);
    });

    return response.asObservable();
  }

  ///

  // TODO we may need to move this to SW
  //
  public setConversationsMembersOwnerAdminsInRedux(conversations: Conversation[]) {
    // save members to redux
    const membersJoint = {};
    const ownersJoint = {};
    const adminsJoint = {};

    const t0 = performance.now();

    //
    conversations.map(c => {
      ownersJoint[c.Target] = c.owner;
      //
      adminsJoint[c.Target] = c.admins;
      //
      // membersJoint[c.Target] = Array.from(new Set([...c.members, c.owner, ...c.admins])); // TODO do we really need this? isn't it already done in DB?
      membersJoint[c.Target] = c.members;
    });

    const t1 = performance.now();
    this.logger.info(`[PERFORMANCE] [ConversationRepository][setConversationsMembersOwnerAdminsInRedux] for loop: took ${t1 - t0} milliseconds.`);

    //
    const t2 = performance.now();
    this.store.dispatch(new MultiConversationUpdateMembers(membersJoint));
    this.store.dispatch(new MultiConversationUpdateOwner(ownersJoint));
    this.store.dispatch(new MultiConversationUpdateAdmins(adminsJoint));

    const t3 = performance.now();
    this.logger.info(`[PERFORMANCE] [ConversationRepository][setConversationsMembersOwnerAdminsInRedux] save to redux: took ${t3 - t2} milliseconds.`);
  }

  public setConversationMembersInRedux(conversationTarget: string,  members: string[]) {
    const domain = this.userJID.domain;
    this.logger.info("[ConversationRepository][setConversationMembersInRedux]", conversationTarget, members, domain, this.userJID);
    const iomMembers = members.find(m => m.indexOf("@" + domain) === -1);
    const has_iom = !!iomMembers && (iomMembers.length > 0);
    this.store.dispatch(new ConversationUpdate({target: conversationTarget,  changes: {has_iom: has_iom}}));
    this.broadcaster.broadcast("updateIOM", {target: conversationTarget, val: has_iom});
    this.store.dispatch(new ConversationUpdateMembers({
      conversationTarget: conversationTarget,
      members: members
    }));
  }

  public setConversationAudiencesInRedux(conversationTarget: string,  audiences: string[]) {
    this.logger.info("[ConversationRepository][setConversationAudiencesInRedux]", conversationTarget, audiences);

    this.store.dispatch(new ConversationUpdateAudiences({
      target: conversationTarget,
      audiences: audiences
    }));
  }

  public setConversationOwnerInRedux(conversationTarget: string, ownerBare: string) {
    this.logger.info("[ConversationRepository][setConversationOwnerInRedux]", conversationTarget, ownerBare);

    this.store.dispatch(new ConversationUpdateOwner({
      conversationTarget: conversationTarget,
      owner: ownerBare
    }));
  }

  public getConversationOwnerFromRedux(conversationTarget: string) {
    return this.store.select(state => getConversationOwner(state, conversationTarget));
  }

  public setConversationAdminsInRedux(conversationTarget: string, adminsBare: string[]) {
    this.logger.info("[ConversationReposotory][setConversationAdminsInRedux]", conversationTarget, adminsBare);
    this.store.dispatch(new ConversationUpdateAdmins({
        conversationTarget: conversationTarget,
        admins: adminsBare
    }));
  }

  public getConversationAdminsFromRedux(conversationTarget: string) {
    return this.store.select(state => getConversationAdmins(state, conversationTarget));
  }

  public getConversationAudiencesFromRedux(conversationTarget: string) {
    return this.store.select(state => getConversationAudiences(state, conversationTarget));
  }

  public getConversationMCBsFromRedux(conversationTarget: string) {
    return this.store.select(state => getConversationMCBs(state, conversationTarget));
  }

  ///

  public setSubject(target: string, newTitle: string): void {
    this.logger.info("[ConversationReposotory][setSubject]", target, newTitle);
    this.xmppService.setSubject(target, newTitle);
  }

  public joinPublicRoomIfNotJoined(room: any): Observable<string> {
    const response = new Subject<string>();
    this.logger.info("[ConversationReposotory][joinPublicRoomIfNotJoined] start room ", room);
    this.store.select(state => getConversationById(state, room))
      .pipe(take(1))
      .subscribe(conv => {
        this.logger.info("[ConversationReposotory][joinPublicRoomIfNotJoined] existingConv? ", conv);
        if (!!conv && (conv.Target === room)) {
          this.logger.info("[ConversationReposotory][joinPublicRoomIfNotJoined] open existingConv ", room);
          setTimeout(() => {
            response.next(room);
          }, 3);
        } else {

          this.logger.info("[ConversationReposotory][joinPublicRoomIfNotJoined] room ", room);
          if (this.joinedPublicList.indexOf(room) > -1) {
            response.next(room);
            return;
          }
          if (this.joinedConversations.includes(room)) {
            response.next(room);
            return;
          }

          this.joinedPublicList.push(room);
          this.groupChatsService.joinPublicRoom(room).subscribe(res => {
            this.logger.info("[ConversationReposotory][joinPublicRoomIfNotJoined] res ", room, res);
            response.next(room);
          }, err => {
            this.logger.info("[ConversationReposotory][joinPublicRoomIfNotJoined] error ", room, err);
            response.error(err);
          });
        }
      });

    return response.asObservable();
  }

  public joinRoomIfNotJoined(room: any) {
    this.logger.info("[ConversationRepository][joinRoomIfNotJoined]", room);

    // skip if not connected
    if (!this.isConnectedXMPP) {
      return;
    }

    // skip if not grgoup
    if (!ConversationUtil.isGroupChat(room)) {
      return;
    }

    this.joinRoomFromTarget(room.Target, true);
  }

  joinRoomFromTrigger(conversationTarget: string) {
    this.store.select(state => getConversationById(state , conversationTarget))
      .pipe(take(1))
      .subscribe(conv => {
        this.logger.info("[ConversationRepository][joinRoomFromTrigger]", conv);
        if (!conv) {
          const conv = ConversationUtil.createLocalConversation(conversationTarget, "", "groupchat", "", false);
          this.store.dispatch(new ConversationCreate(conv));
        }
      });

    this.joinRoomFromTarget(conversationTarget, false, 5);
  }

  joinRoom(conversationTarget: string, maxstanzas = 0) {
    this.xmppService.joinRoom(conversationTarget, maxstanzas);
  }

  invite(conversationTarget: string, to: string, reason = "join") {
    this.xmppService.invite(conversationTarget, [to], reason);
  }

  joinRoomFromTarget(conversationTarget: string, skipMembersRetrieval?: boolean, maxstanzas = 0) {
    // return if already joined
    this.leftRoomList = this.leftRoomList.filter(v => v !== conversationTarget);
    if (this.joinedConversations.includes(conversationTarget)) {
      this.getConversationById(conversationTarget).pipe(take(1)).subscribe(conv => {
        // this.logger.info("[ConversationRepository][joinRoomFromTarget] already joined", conv, this.leftRoomList, {conversationTarget, skipMembersRetrieval});
        if (!conv) {
          this.createLocalConversation(conversationTarget, "groupchat", "");
        }
        if (!skipMembersRetrieval || conv.encrypted) {
          this.getRoomMembersAndStore(conversationTarget).subscribe(roomMembers => {
            this.logger.info("joinRoomFromTarget [getRoomMembersAndStore]", roomMembers);
            let newList = [];
            if (!!roomMembers && (roomMembers.length > 0)) {
              for (let i = 0; i < roomMembers.length; i++) {
                if (newList.indexOf(roomMembers[i]) === -1) {
                  newList.push(roomMembers[i]);
                }
              }
            }
            this.omemoRefreshList.next(newList);
          });
        }
      });
      return;
    }

    this.logger.info("[ConversationRepository][joinRoomFromTarget]", conversationTarget, {conversationTarget, skipMembersRetrieval, maxstanzas});

    // join room
    if (maxstanzas !== 0) {
      this.joinedConversationsLater.push(conversationTarget);
    }
    this.joinedConversations.push(conversationTarget);
    this.xmppService.joinRoom(conversationTarget, maxstanzas);
    this.getConversationById(conversationTarget).pipe(take(1)).subscribe(conv => {
      this.logger.info("[ConversationRepository][joinRoomFromTarget] conv: ", conv);
      if (!conv) {
        this.createLocalConversation(conversationTarget, "groupchat", "");
      }

      // get members
      if (!skipMembersRetrieval || conv?.encrypted) {
        this.getRoomMembersAndStore(conversationTarget).subscribe(roomMembers => {
          this.logger.info("joinRoomFromTarget [getRoomMembersAndStore]", roomMembers);
        });
      }
    });

    // call message sync
    // TODO: when someone invited us to chat - we eed to call syc messages to get all the recent messages?
    // this.getNewMessagesFromServer({Target: conversationTarget, type: "groupchat"});
  }

  // Join all rooms on initial start app & on xmpp re-connect
  private joinAllRooms() {
    this.logger.info("[ConversationRepository][joinAllRooms]");

    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {

    }


    this.getConversations().pipe(take(1), filter(conversations => conversations.length > 0)).subscribe(conversations => {
      let convs = conversations.filter(conv =>
          ConversationUtil.isGroupChat(conv)
          && !this.joinedConversations.includes(conv.Target)
          && conv.state !== "inactive" && !conv.deleted && (deletedConvs.indexOf(conv.Target) === -1));

      // join only 10 convs at start app, to improve performance
      if (convs.length > TOTAL_GROUP_TO_JOIN) {
        convs = convs.slice(0, TOTAL_GROUP_TO_JOIN);
      }

      this.logger.info("[ConversationRepository][joinAllRooms]", convs);

      for (let conv of convs) {
        if (this.joinedConversations.length < TOTAL_GROUP_TO_JOIN) {
          this.joinedConversations.push(conv.Target);
          this.xmppService.joinRoom(conv.Target);
        } else {
          break;
        }
      }

      this.logger.info("[ConversationRepository][joinAllRooms] JOINED", this.joinedConversations.length);
    });

    // reset bot connet fails and reconnect
    this.botConnectFails = 0;
    this.connectAIBackend();

  }

  public getRoomConfig(target: string): Observable<ConversationConfig> {
    this.logger.info("[ConversationRepository][getRoomConfig] target", target);

    const response = new BehaviorSubject(null);

    this.store.select(state => getConversationConfig(state, target)).pipe(take(1)).subscribe(config => {
      this.logger.info("[ConversationRepository][getRoomConfig] res from redux", config);

      this.xmppService.getRoomConfig(target).subscribe(newConfig => {
        this.logger.info("[ConversationRepository][getRoomConfig] res from server", newConfig);
        this.store.dispatch(new ConversationSetConfig({ conversationTarget: target, config: newConfig }));
        response.next(newConfig);
      }, err => {
        response.error(err);
      });
    });

    return response.asObservable().pipe(filter(config => !!config), take(1));
  }

  public configureRoom(target: string, config?: ConversationConfig): Observable<any> {
    this.logger.info("[ConversationRepository][configureRoom]", target, config);

    const response = new Subject();

    this.xmppService.configureRoom(target, config).subscribe(res => {
      this.logger.info("[ConversationRepository][configureRoom] res", res);

      this.store.dispatch(new ConversationSetConfig({ conversationTarget: target, config: config }));

      response.next(res);
    }, err => {
      response.error(err);
    });

    return response.asObservable();
  }

  public saveGroupSettings(data: { [target: string]: GroupSettings }) {
    this.logger.info("[ConversationRepository][saveGroupSettings]", data);
    this.xmppService.updatePrivateDocuments({ groupSettings: { ...data } });
  }

  public getGroupSettings(): Observable<any> {
    this.logger.info("[ConversationRepository][getGroupSettings]");

    return this.authService.getPrivateDocuments().pipe(map(data => data.groupSettings));
  }

  public getSelectedConversationMembers(): Observable<string[]> {
    return this.store.select(getSelectedConversationMembers).pipe(map(members => {
      if (this.currentConv && !ConversationUtil.isGroupChat(this.currentConv)) {
        const privateChatMembers = [];
        if (this.userJID) {
          privateChatMembers.push(this.userJID?.bare);
        }
        privateChatMembers.push(this.currentConv.Target);
        return privateChatMembers;
      }
      return CommonUtil.uniq(members || []);
    }));
  }

  private allMembersCache = {};
  public getAllMembersOfSelectedConversation(isMiniChat?: boolean): Observable<string[]> {
    // this.logger.info("[getAllMembersOfSelectedConversation] start");
    let members$: any;
    if (isMiniChat) {
      members$ = this.store.select(getAllMembersOfSelectedMiniConversation);
    } else {
      members$ = this.store.select(getAllMembersOfSelectedConversation);
    }

    return members$.pipe(distinctUntilChanged(CommonUtil.isEqual), switchMap((mms: any) => {
      let resMembers: string[];
      if (this.currentConv) {
        if (!ConversationUtil.isGroupChat(this.currentConv)) {
          const privateChatMembers = [];
          if (this.userJID) {
            privateChatMembers.push(this.userJID?.bare);
          }
          privateChatMembers.push(this.currentConv.Target);
          resMembers = privateChatMembers;
        } else {
          resMembers = mms;
        }

        // check the cache
        let entryKey = CommonUtil.hashCode(JSON.stringify({Target: this.currentConv.Target, resMembers}));
        const cachedMembers = this.allMembersCache[entryKey];
        this.allMembersCache[entryKey] = resMembers;
        if (cachedMembers) {
          // this.logger.info("[getAllMembersOfSelectedConversation] end from cache", cachedMembers);
          return of(cachedMembers);
        } else {
          // this.logger.info("[getAllMembersOfSelectedConversation] end from redux", resMembers);
          return of(resMembers);
        }
      } else {
        resMembers = mms;
      }
      this.logger.info("[getAllMembersOfSelectedConversation] end", resMembers);
      return of(resMembers);
    }));
  }

  public getAllMembersOfByConversationId(target: string): Observable<string[]> {
    // this.logger.info("[getAllMembersOfSelectedConversation] start");
    let members$ = this.store.select(state => getAllConversationMembers(state, target));
    return members$.pipe(distinctUntilChanged(CommonUtil.isEqual), switchMap(mms => {
      let resMembers: string[];
      if (this.currentConv) {
        if (!CommonUtil.isGroupTarged(target)) {
          const privateChatMembers = [];
          if (this.userJID) {
            privateChatMembers.push(this.userJID?.bare);
          }
          privateChatMembers.push(target);
          resMembers = privateChatMembers;
        } else {
          resMembers = mms;
        }

        // check the cache
        let entryKey = CommonUtil.hashCode(JSON.stringify({Target: target, resMembers}));
        const cachedMembers = this.allMembersCache[entryKey];
        this.allMembersCache[entryKey] = resMembers;
        if (cachedMembers) {
          // this.logger.info("[getAllMembersOfSelectedConversation] end from cache", cachedMembers);
          return of(cachedMembers);
        } else {
          // this.logger.info("[getAllMembersOfSelectedConversation] end from redux", resMembers);
          return of(resMembers);
        }
      } else {
        resMembers = mms;
      }
      // this.logger.info("[getAllMembersOfSelectedConversation] end", resMembers);
      return of(resMembers);
    }));
  }

  public getSelectedConversationText(): Observable<any> {
    return this.store.select(getSelectedConversationText);
  }

  public getConversationTextById(conversationId: string): Observable<any> {
    return this.store.select(getConversationTextById(conversationId));
  }

  getFirstUnreadId(unreadIds: string[]){
    return unreadIds[0];
  }

  getFirstUnreadIdMessageIndex(unreadIds: string[], messages: Message[]){
    let res: number;

    if (!unreadIds || unreadIds.length === 0) {
      return null;
    }

    const firstUnreadId = this.getFirstUnreadId(unreadIds);

    //
    let isUnreadMessageArrived = false;
    this.getMessageById(firstUnreadId).pipe(take(1)).subscribe(msg =>  {
      isUnreadMessageArrived = !!msg;
    });

    if (isUnreadMessageArrived) {
      // here we can have 2 cases: focus on 1st unread OR focus on mentioned
      // If this is focus on mentioned, we have to find a real message index
      if (unreadIds.length === 1) {
        const realMsgIndex = messages.findIndex(m => m.id === unreadIds[0]);
        if (realMsgIndex) {
          res = realMsgIndex;
        } else {
          res = unreadIds.length - 1;
        }
      } else {
        res = unreadIds.length - 1;
      }
    } else {
      res = -1;
    }

    this.logger.info("[ConversationRepository][getFirstUnreadIdMessageIndex]", {unreadIds, isUnreadMessageArrived, index: unreadIds.length - 1, res});

    return res;
  }



  // main method, all messages load starts here
  private loadMessages(target: string, updateConvLastMessage = false) {
    this.logger.info("[ConversationRepository][loadMessages]", target);

    this.store.select(state => getIsActivatedConversation(state, target)).pipe(take(1)).subscribe(isAlreadyActived => {
      // this.logger.info("[ConversationRepository][loadMessages] isAlreadyActived", conv.Target, isAlreadyActived);

      if (isAlreadyActived) {
        this.logger.info("[ConversationRepository][loadMessages] skip?, already activated", target);
        // return;
      }

      if (this.isAppOnline && !isAlreadyActived) {
        // then we deactivate all convs by the following events:
        // - lost xmpp connection
        // - lost Internet
        this.logger.info("[ConversationRepository][loadMessages] ACTIVATE", target);
        this.store.dispatch(new ConversationActivate(target));
      }


      // detect unreads and focus index
      let selectedConv: Conversation;
      this.store.select(state => getConversationById(state, target)).pipe(take(1)).subscribe(conv => {
        selectedConv = conv;
        this.logger.info("[ConversationRepository][loadMessages] ACTIVATED conv", conv);
      });
      //
      const unreadIds = selectedConv?.unreadIds || [];
      //
      const shouldFocusOnFirstUnread = unreadIds && unreadIds.length > 0;
      //
      // is mentioned redirect?
      const {timestamp: mentionedMessageTimestamp, id: mentionedMessageId} = this.mentionedMessage();
      if (mentionedMessageTimestamp && !isAlreadyActived) {
        this.resetMentionedMessage();
      }

      this.logger.info("[ConversationRepository][loadMessages]", {target, shouldFocusOnFirstUnread, mentionedMessageTimestamp});

      //
      if (shouldFocusOnFirstUnread) {

        this.store.dispatch(new MessageBulkLoading(target));

        // show msgs imd from DB
        this.loadMessagesFromDB(target, true, unreadIds.length).subscribe(() => {
          this.logger.warn("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread - finished loadingFromDB");
          let storedTimeStamp = 0;
          this.store.select(state => getMessagesByConversationTarget(state, selectedConv)).pipe(take(1)).subscribe(storedmessages => {
            this.logger.warn("[ConversationRepository][loadMessages] messages in store: ", storedmessages);
            // SSAtodo: improve check - maybe use include default chunk size (20)
            if (storedmessages && (storedmessages.length > 0) && (unreadIds.length !== storedmessages.length)) {
              storedTimeStamp = storedmessages[0].timestamp;
            }
          });

          if (storedTimeStamp > 0) {

            if (!isAlreadyActived) {
              setTimeout(() => {
                this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread lastMessage from DB", storedTimeStamp);
                this.loadAllMessagesUntilTimestamp2(target, storedTimeStamp).subscribe(() => {
                  this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread loadAllMessagesUntilTimestamp2 OK");

                  // this.store.dispatch(new MessageBulkLoaded(target));
                }, () => {
                  this.store.dispatch(new MessageBulkLoaded(target));
                });
              }, 100);
            } else {
              this.store.dispatch(new MessageBulkLoaded(target));
            }

          } else {
            setTimeout(() => {
              // load 1st unread and onlt then focus
              this.conversationService.getMessagesAroundFirstUnread(target, MESSAGES_PER_PAGE).subscribe((data: any) => {
                this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread", data.messages);
                // loading up to  100 messages, only use 1st result?
                const firstMessage = data.messages[0]; // here we take the above 'firstUnreadId' message's timestamp
                if (firstMessage) {
                  this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread lastMessage", firstMessage);
                  this.loadAllMessagesUntilTimestamp2(target, firstMessage.timestamp).subscribe(() => {
                    this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread loadAllMessagesUntilTimestamp2 OK");

                    // this.store.dispatch(new MessageBulkLoaded(target));
                  }, () => {
                    this.store.dispatch(new MessageBulkLoaded(target));
                  });
                } else {
                  this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundFirstUnread empty response");
                  this.store.dispatch(new MessageBulkLoaded(target));

                  this.initialLoadMessagesStandard(target, updateConvLastMessage);
                }
              });
            }, 300);
          }


        });

      // focus on particular message
      } else if (mentionedMessageTimestamp) {
        if (this.isAppOnline) {
          this.conversationService.getMessagesFrom(target, mentionedMessageId, MESSAGES_PER_PAGE).subscribe((data: any) => {
            // data.messages.sort((a, b) => {return a.timestamp - b.timestamp}); //asc
            this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundId", data.messages);
            const firstMessage = data.messages[0]; // here we take the earliest message
            if (firstMessage) {
              this.store.dispatch(new MessageBulkLoaded(target));
              this.saveMessagesToStoreAndDB(target, data.messages);
            } else {
              this.logger.info("[ConversationRepository][loadMessages] getMessagesAroundId empty response");
              this.store.dispatch(new MessageBulkLoaded(target));

              this.initialLoadMessagesStandard(target, updateConvLastMessage);
            }
          });
        } else {
          this.store.dispatch(new MessageBulkLoaded(target));
          this.initialLoadMessagesStandard(target, updateConvLastMessage);
        }

      } else {
        this.logger.info("[ConversationRepository][loadMessages] going for initialLoadMessagesStandard1 isAlreadyActived: ", isAlreadyActived);
        if (!isAlreadyActived) {
          this.initialLoadMessagesStandard(target, updateConvLastMessage);
        } else {
          // rationale: without this, messages are loaded with a delay on mobile. this really speeds it up!
          if (this.isOnNativeMobileDevice) {
            this.loadMessagesFromDB(target).subscribe(() => {
              this.logger.info("[ConversationRepository][loadMessages] loadMessagesFromDB isAlreadyActived success");
            });
          }
        }
      }
    });
  }

  /*
  Call this method when open a particular chat for the first time per app/internet session
  */
  private initialLoadMessagesStandard(target: string, updateConvLastMessage = false) {
    this.logger.info("[ConversationRepository][initialLoadMessagesStandard] ", target);
    // normal load
    this.loadMessagesFromDB(target).subscribe(() => {
      setTimeout(() => {
        // load new messages from server (1 page only)
        // and then detect do we have a gap or not
        if (this.isAppOnline) {
          this.getNewMessagesFromServer(target, 0, false, updateConvLastMessage);
        } else {
          this.logger.warn("[ConversationRepository][loadMessagesStandard][getNewMessagesFromServer] skip getNewMessagesFromServer, no connection" );
        }
      }, 100);
     });
  }

  private loadMessagesFromDB(target: string, skipSetLoadingToFalse = false, chunkSize?: number) {
    const response = new Subject<any>();
    const chunk = (!!chunkSize) ? chunkSize + 20 : 20;
    // normal load
    this.databaseService.fetchMessages(target, new Date().getTime(), chunk).subscribe(dbMessages => {
      this.logger.info("[ConversationRepository][loadMessagesFromDB]", target, dbMessages?.length);
     if (dbMessages && dbMessages?.length > 0) {
       this.saveMessagesToStore(target, dbMessages, skipSetLoadingToFalse);
     }
     response.next(true);
   });

   return response.asObservable().pipe(take(1));
 }


  public getNewMessagesFromServer(target: string, offset = 0, skipLoading = false, updateConvLastMessage = false) {
    this.logger.info("[ConversationRepository][getNewMessagesFromServer]", {offset, target, updateConvLastMessage});

    // get new messages from server
    this.loadNewMessagesFromServer(target, offset, skipLoading).subscribe(nMessages => {
      if (nMessages && nMessages.length > 0) {
        this.logger.info("[ConversationRepository][getNewMessagesFromServer] targetConv nMessages: ", nMessages);

        if (updateConvLastMessage) {
          const lastMsg = nMessages[0];
          const data = [{ conversationTarget: target, message: lastMsg, incoming: !this.isSentMessage(lastMsg) }];

          // update last messages and timestamp
          this.store.dispatch(new MultiConversationUpdateLastMessage(data));

          this.store.select(state => getConversationById(state, target)).pipe(take(1)).subscribe(conv => {
            if (conv) {
              const unreadIds = conv.unreadIds || [];
              if (!unreadIds.includes(lastMsg.id)) {
                unreadIds.push(lastMsg.id);
                // update unread ids
                this.setUnreadCount(target, unreadIds);
              }
            } else {
              // TODO: get conv from DB/server
            }
          });
        }

        this.isMessagesGap(nMessages).subscribe(isGap => {
          this.logger.info("[ConversationRepository][getNewMessagesFromServer] isGap ", isGap);
          // save
          this.saveMessagesToStoreAndDB(target, nMessages);

          if (isGap && this.isAppOnline) {
            // load one more page
            if (offset === 0) {
              this.getNewMessagesFromServer(target, MESSAGES_PER_PAGE, true);
            } else {
              // we have a gap!
              this.logger.warn("[ConversationRepository][getNewMessagesFromServer] WE HAVE A GAP!");
              this.broadcaster.broadcast("gapAvailable", {
                target,
                nextOffset: offset + MESSAGES_PER_PAGE,
                lastMessageIdBeforeGap: nMessages[0].id});
            }
          }
        });
      }
    }, (err) => {
      // deactivate conf and shown an alert
      this.logger.warn("[ConversationRepository][loadMessages] DEACTIVATE", target, err);
      this.store.dispatch(new ConversationDeactivate(target));
      let unAuthState = (!!err.status && (err.status === 401 || err.status === 403)) ? true : false;
      if (!unAuthState || environment.isElectron || environment.isCordova) {
        alert("An error occured while fetching new chat messages for the conversation. Please check your Internet connection and then re-open the chat");
      } else {
        this.configService.clearStorage();
        this.configService.redirectToLoginScreen();
      }
    });
  };

  private isMessagesGap(nMessages: Message[]): Observable<boolean> {
    const response = new BehaviorSubject<boolean>(null);

    const lastMsgId = nMessages[nMessages.length - 1]?.id;
    if (lastMsgId) {
      let isFirstMessageOnDevice = false;
      this.getMessageById(lastMsgId).pipe(take(1)).subscribe(reduxMessage => {
        isFirstMessageOnDevice = !!reduxMessage;

        this.logger.info("[ConversationRepository][isMessagesGap] reduxMessage", reduxMessage);

        if (!isFirstMessageOnDevice) {
          this.databaseService.getMessageById(lastMsgId).subscribe(dbMessage => {
            isFirstMessageOnDevice = !!dbMessage;
            this.logger.info("[ConversationRepository][isMessagesGap] dbMessage", dbMessage);

            if (!isFirstMessageOnDevice) {
              response.next(true);
            } else {
              response.next(false);
            }
          });
        } else {
          response.next(false);
        }
      });
    } else {
      response.next(false);
    }
    return response.asObservable();
  }

  public getSelectedConversationMessages(): Observable<Message[]> {
    return this.store.select(getSelectedConversationMessages);
  }

  public getSelectedConversationMessagesWithContent(isMiniChat?: boolean): Observable<Message[]> {
    if (isMiniChat) {
      return this.store.select(getSelectedMiniConversationMessagesWithContent);
    }
    return this.store.select(getSelectedConversationMessagesWithContent);
  }


  /////////////// LOAD MESSAGES ////////////////////

  private loadNewMessagesFromServer(target: string, offset = 0, skipLoading = false): Observable<Message[]> {
    this.logger.info(`[ConversationRepository][loadNewMessagesFromServer]`, {target, offset, skipLoading});

    const response = new Subject<Message[]>();

    if (!skipLoading) {
      this.store.dispatch(new MessageBulkLoading(target));
    }

    this.conversationService.loadMessages(target, MESSAGES_PER_PAGE, undefined, false, offset).subscribe(res => {
      const now = new Date().getTime();
      const messages = (res.messages || []).filter(v => !v.expiry || v.expiry * 1000 > now);
      this.logger.info(`[ConversationRepository][loadNewMessagesFromServer] Retrieved messages:`, messages.length, messages);

      if (this.isMessagesEncrypted(messages) && false) {
        // do not attempt to decrypt from loadMessages / sync-messages - this leads to decryption attempts on invalid chains when devices have changed
        // and the update / new OMEMO session can only be established once xmpp session is active
        this.logger.info(`[ConversationRepository][loadNewMessagesFromServer] Retrieved messages: case 1`);

        this.filterOutAlreadyDecodedOmemoMessages(messages).subscribe(filteredMessages => {
          this.logger.info(`[ConversationRepository][loadNewMessagesFromServer] Retrieved messages: filterOutAlreadyDecodedOmemoMessages`, filteredMessages);

          this.filterOutAlreadyDecodedOmemoAndStoredMessages(filteredMessages).subscribe(filteredStoredMessages => {
            this.logger.info(`[ConversationRepository][loadNewMessagesFromServer] Retrieved messages: filterOutAlreadyDecodedOmemoAndStoredMessages`, filteredStoredMessages);
            if (!skipLoading) {
              this.store.dispatch(new MessageBulkLoaded(target));
            }
            response.next(filteredStoredMessages || []);
            this.logger.info(`[ConversationRepository][decryptOMEMOMessages][loadNewMessagesFromServer]`);

            this.decryptOMEMOMessages(target, filteredStoredMessages || [], messages[0]);
          });

        });
      } else {
        this.logger.info(`[ConversationRepository][loadNewMessagesFromServer] Retrieved messages: case2`, skipLoading);

        if (!skipLoading) {
          this.store.dispatch(new MessageBulkLoaded(target));
        }
        response.next(messages);
      }
    }, err => {
      this.logger.error("[ConversationRepository][loadNewMessagesFromServer] error", err);

      if (!skipLoading) {
        this.store.dispatch(new MessageBulkLoadingFailed(target));
      }

      response.error(err);
    });

    return response.asObservable();
  }

  // call it on scroll to get more (from ChatWindowComponent)
  public loadPreviousMessages(conversationTarget: string, needToCheckDBFirstForPreviousMessages = true, skipLoading = false) {
    this.logger.info("[ConversationRepository][loadPreviousMessages]", conversationTarget, needToCheckDBFirstForPreviousMessages);

    let isOnLastPage: boolean;
    this.store.select(getIsSelectedConversationMessagesOnLastPage).pipe(take(1)).subscribe(onLastPage => isOnLastPage = onLastPage);
    if (isOnLastPage) {
      // already on last page.
      this.logger.info("[ConversationRepository][loadPreviousMessages]: skip, already on last page");
      return;
    }

    let lastMessageMessageTimestamp: number = new Date().getTime();
    let messageIdsWithSameTimestamp = [];
    this.getSelectedConversationMessages().pipe(filter(messages => messages && messages.length > 0), take(1)).subscribe(messagesInRedux => {
      const lastMessage = messagesInRedux[messagesInRedux.length - 1];
      lastMessageMessageTimestamp = lastMessage.timestamp;

      this.logger.info("[ConversationRepository][loadPreviousMessages] firstMessageTimestamp", lastMessageMessageTimestamp, lastMessage);

      // if there are messages with same timestamp
      const messagesWithTimestamp = messagesInRedux.filter(m => m.timestamp === lastMessageMessageTimestamp);
      if (messagesWithTimestamp.length > 1) {
        messageIdsWithSameTimestamp = messagesWithTimestamp.map(m => m.id);
      }
    });

    // mobile or electron
    if (needToCheckDBFirstForPreviousMessages) {
      this.databaseService
        .fetchMessages(conversationTarget, lastMessageMessageTimestamp, MESSAGES_PER_PAGE)
        .subscribe(dbMessages => {
          this.logger.info("[ConversationRepository][loadPreviousMessages] fetched from DB: ", dbMessages);

          if (dbMessages) {
            // save to redux
            if (dbMessages.length > 0) {
              this.saveMessagesToStore(conversationTarget, dbMessages);
            }

            // if there are no enough messages in the local DB
            // then call server to load back end messages
            if (dbMessages.length >= 0 && dbMessages.length < MESSAGES_PER_PAGE && this.isAppOnline) {
              this.loadPreviousMessagesFromServer(conversationTarget, lastMessageMessageTimestamp, messageIdsWithSameTimestamp, skipLoading);
            }
          }
        });
    } else if (this.isAppOnline) {
      this.loadPreviousMessagesFromServer(conversationTarget, lastMessageMessageTimestamp, messageIdsWithSameTimestamp, skipLoading);
    }
  }

  // load mentioned message and all messages before
  loadAllMessagesUntilTimestamp2(conversationTarget: string, messageTimestamp: number): Observable<boolean> {
    this.logger.info("[ConversationRepository][loadAllMessagesUntilTimestamp2]", {conversationTarget, messageTimestamp});
    const response = new Subject<boolean>();

    this.conversationService.loadMessages(conversationTarget, 0, this.currentConv?.type, true, 0, messageTimestamp)
      .subscribe(data => {
        const now = new Date().getTime();
        const messages = (data.messages || []).filter(v => !v.expiry || v.expiry * 1000 > now);

        if (this.isMessagesEncrypted(messages) && false) {
          // do not attempt to decrypt from loadMessages / sync-messages - this leads to decryption attempts on invalid chains when devices have changed
          // and the update / new OMEMO session can only be established once xmpp session is active

          this.filterOutAlreadyDecodedOmemoMessages(messages).subscribe(filteredMessages => {
            this.store.dispatch(new MessageBulkLoaded(conversationTarget));
            this.logger.info("[ConversationRepository][loadAllMessagesUntilTimestamp2] filterOutAlreadyDecodedOmemoMessages", filteredMessages);
            if (!!filteredMessages && filteredMessages.length > 0) {
              this.saveMessagesToStoreAndDB(conversationTarget, filteredMessages);
              this.logger.info("[ConversationRepository][decryptOMEMOMessages][loadAllMessagesUntilTimestamp2]");
              this.decryptOMEMOMessages(conversationTarget, filteredMessages, messages[0]);
            }
          });
        } else {
          this.store.dispatch(new MessageBulkLoaded(conversationTarget));
          this.saveMessagesToStoreAndDB(conversationTarget, messages);
        }

        response.next(true);
      }, err => {
        this.logger.error("[ConversationRepository][loadAllMessagesUntilTimestamp2] err", err);
        response.error(err);
      });

    return response.asObservable();
  }

  private loadPreviousMessagesFromServer(conversationTarget: string, firstMessageTimestamp: number, loadedMessageIds?: string[], skipLoading = false) {
    let messagesLoading: boolean;
    this.store.select(getIsSelectedConversationMessagesLoading).subscribe(loading => messagesLoading = loading);
    if (messagesLoading) {
      // messages are already loading
      this.logger.warn("[ConversationRepository][loadPreviousMessagesFromServer] previous messages already loading");
      return;
    }

    let isOnLastPage: boolean;
    this.store.select(getIsSelectedConversationMessagesOnLastPage).pipe(take(1)).subscribe(onLastPage => isOnLastPage = onLastPage);
    if (isOnLastPage) {
      // already on last page.
      this.logger.warn("[ConversationRepository][loadPreviousMessagesFromServer] already on last page");
      return;
    }

    if (!skipLoading) {
      this.store.dispatch(new MessageBulkLoading(conversationTarget));
    }

    let limit = MESSAGES_PER_PAGE;
    if (loadedMessageIds && loadedMessageIds.length > 0) {
      limit += Math.ceil(loadedMessageIds.length / MESSAGES_PER_PAGE) * MESSAGES_PER_PAGE;
    }

    this.logger.info("[ConversationRepository][loadPreviousMessagesFromServer]", {conversationTarget, limit, firstMessageTimestamp, loadedMessageIds, skipLoading});

    this.conversationService.loadMessages(conversationTarget, limit, this.currentConv.type, true, 0, null, firstMessageTimestamp)
      .subscribe(data => {
        const now = new Date().getTime();
        let messages = (data.messages || []).filter(v => !v.expiry || v.expiry * 1000 > now);

        const isLastPage = data.total < MESSAGES_PER_PAGE;
        if (isLastPage) {
          this.logger.warn("[ConversationRepository][loadPreviousMessagesFromServer] finish, last page loaded", conversationTarget);
          this.store.dispatch(new MessageLastPageLoaded(conversationTarget));
        }

        if (this.isMessagesEncrypted(messages) && false) {
          // do not attempt to decrypt from loadMessages / sync-messages - this leads to decryption attempts on invalid chains when devices have changed
          // and the update / new OMEMO session can only be established once xmpp session is active
          this.filterOutAlreadyDecodedOmemoMessages(messages).subscribe(filteredMessages => {
            if (!skipLoading) {
              this.store.dispatch(new MessageBulkLoaded(conversationTarget));
            }
            if (!!filteredMessages && (filteredMessages.length > 0)) {
              this.saveMessagesToStoreAndDB(conversationTarget, filteredMessages);
              this.logger.warn("[ConversationRepository][decryptOMEMOMessages][loadPreviousMessagesFromServer]");
              this.decryptOMEMOMessages(conversationTarget, filteredMessages,messages[0]);
            }
          });
        } else {
          if (!skipLoading) {
            this.store.dispatch(new MessageBulkLoaded(conversationTarget));
          }

          this.saveMessagesToStoreAndDB(conversationTarget, messages);
        }

      }, err => {
        this.logger.error("[ConversationRepository][loadPreviousMessagesFromServer] err", err);

        if (!skipLoading) {
          this.store.dispatch(new MessageBulkLoadingFailed(conversationTarget));
        }
      });
  }

  ////////////


  //////////// OMEMO ///////////////

  private isMessagesEncrypted(messages: Message[]) {
    // this.logger.info("[ConversationRepository][isMessagesEncrypted] messages", messages);
    return messages.some(m => m.encrypted);
  }

  private filterOutAlreadyDecodedOmemoAndStoredMessages(messages: Message[]): Observable<Message[]>{
    const response = new BehaviorSubject<Message[]>(null);
    messages = [...(messages || [])];
    let sortedMessages = messages.sort((a, b) => b.timestamp - a.timestamp);
    // this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoAndStoredMessages]", sortedMessages);
    for (const msg of sortedMessages) {
      let existingMessageInStore: Message;
      this.store.select(state => getMessageById(state, msg.id)).pipe(take(1)).subscribe(message => existingMessageInStore = message);
      if (existingMessageInStore && (existingMessageInStore.body !== "Encrypted message") && (existingMessageInStore.body !== "Can't decrypt the message - not intended for current device")) {
        sortedMessages = sortedMessages.filter(m => m.id !== existingMessageInStore.id);
      }
    }
    // this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoAndStoredMessages] post ", sortedMessages);

    response.next(sortedMessages);


    return response.asObservable();
  }

  private filterOutAlreadyDecodedOmemoMessages(messages: Message[]): Observable<Message[]>{
    const response = new BehaviorSubject<Message[]>(null);
    messages = [...(messages || [])];
    // collect encrypted messages
    const encryptedMessages = [];
    messages.forEach((m) => {
      if (m.encrypted) {
        encryptedMessages.push(m);
      }
    });

    // this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoMessages] encryptedMessages", encryptedMessages);

    forkJoin(encryptedMessages.map(msg => {
      // https://www.learnrxjs.io/learn-rxjs/operators/combination/forkjoin
      return this.xmppService.isOMEMOMessageStored(msg);
    })).subscribe({
      next: (alreadyStoredResult) => {
        // this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoMessages] alreadyStoredResult", alreadyStoredResult)
        // replace encrypted with decrypted
        const toRemoveIds = [];
        alreadyStoredResult.forEach((isStored, index) => {
          if (isStored) {
            const message = encryptedMessages[index];
            toRemoveIds.push(message.id);
            this.getConversationById(message.fromJid).pipe(take(1)).subscribe(conv => {
              if (!!conv && conv.last_message_id === message.id && conv.content !== message.body && message.body !== "Encrypted message" && message.body !== "Can't decrypt the message - not intended for current device") {
                this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoMessages] alreadyStoredResultUp", message.id, message, conv);
                const data = [{ conversationTarget: message.fromJid, message: message, incoming: !this.isSentMessage(message) }];
                this.store.dispatch(new MultiConversationUpdateLastMessage(data));
                this.updateConversationContent(message.fromJid, message.body, message.id);
              }
            });
          }
        });

        let filteredMesasges: Message[] = [];
        if (toRemoveIds.length > 0) {
          filteredMesasges = messages.filter(m => !toRemoveIds.includes(m.id));
        } else {
          filteredMesasges = messages;
        }

        this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoMessages]", messages.length, "->", filteredMesasges.length, messages);
        response.next(filteredMesasges);
      },
      complete: () => this.logger.info("[ConversationRepository][filterOutAlreadyDecodedOmemoMessages] done"),
      error: (err) => {
        this.logger.error("[ConversationRepository][filterOutAlreadyDecodedOmemoMessages]", err);
        response.error(err);
      }
    });

    return response.asObservable();
  }


  private decryptOMEMOMessages(conversationTarget: string, messages: Message[], firstMessage: Message){
    this.logger.info("[ConversationRepository][decryptOMEMOMessages]", messages, !!window.libsignal);
    // change conv last message from "Encrypted mesasge" to real content
    if (!messages || messages.length === 0) {
      this.store.select(state => getMessageById(state, firstMessage.id)).pipe(take(1)).subscribe(message => {
        this.logger.info("[ConversationRepository][decryptOMEMOMessages] messages.length === 0", message, JSON.stringify(message));
        if (message && message.body !== "Encrypted message") {
          const data = [{ conversationTarget: conversationTarget, message, incoming: !this.isSentMessage(message) }];
          this.store.dispatch(new MultiConversationUpdateLastMessage(data));
        }
      });
      return;
    }
    this.store.select(getXmppOmemoInitState).pipe(take(1)).subscribe((v) => {
      this.logger.info("[ConversationRepository][decryptOMEMOMessages] getXmppOmemoInitState step 1", v);
      if (v > 1) {
        this.logger.info("[ConversationRepository][decryptOMEMOMessages] getXmppOmemoInitState step 2", messages.filter(v => !!v.encrypted), v);
        if (!!messages && messages.filter(v => !!v.encrypted).length > 0) {
          this.decryptMultipleOMEMOMessages(conversationTarget, messages.filter(v => !!v.encrypted));
        }
      } else {
        if (!!messages && messages.filter(v => !!v.encrypted).length > 0 && !!window.libsignal) {
          messages.forEach(msg => {
            if (!!msg.encrypted) {
              this.databaseService.getMessageById(msg.id).pipe(take(1)).subscribe(m => {
                if (!!m && !!m.body && ((m.body !== "Encrypted message") || (!!m && !!m.html && !!m.html.body) || !!m.htmlBody)) {
                  // this.logger.info("decryptOMEMOMessagesPreXmpp msg already decrypted: ", m);
                  let dmsg = { ...m };
                  if (!!m.html && !!m.html.body) {
                    dmsg.body = m.html.body;
                  }
                  this.saveMessagesToStoreAndDB(conversationTarget, [dmsg]);
                } else {
                  const omemoSessionHeader = msg.fromJid + "." + msg.encrypted.header?.sid;
                  if (Object.keys(this.initialOmemoSessions).includes(omemoSessionHeader) && (this.tryToDecryptPreInitIds.indexOf(msg.id) === -1)) {
                    this.tryToDecryptPreInitIds.push(msg.id);
                    const isEncryptedForLocalDevice = (this.localDeviceId !== 0 && !!msg.encrypted?.header?.keys.find(k => (parseInt(k.rid, 10) === this.localDeviceId)));
                    // this.logger.info("decryptOMEMOMessagesPreXmpp-got-session and isEncryptedForLocalDevice ", isEncryptedForLocalDevice, this.localDeviceId, msg.id, msg);
                    this.tryTodecryptOMEMOMessagePreInit(conversationTarget, msg);
                  } else {
                    this.logger.info("decryptOMEMOMessagesXX decryptOMEMOMessagesPreXmpp - no omemo session or already decrypting ", this.tryToDecryptPreInitIds.indexOf(msg.id));
                  }
                }
              });
            }
          });
        }
      }
    });
  }

  private decryptOMEMOMessage(conversationTarget: string, message: Message, messages: Message[], updateLastConvMessage?: boolean){
    this.logger.info("[ConversationRepository][decryptOMEMOMessage]", message.id, message, updateLastConvMessage);
    if (message.encrypted) {
      this.xmppService.decriptOrUseExisting(message).subscribe(decryptedMessage => {
        if (Object.keys(decryptedMessage).length === 1) {
          this.logger.warn("[ConversationRepository][decryptOMEMOMessage]] ignore - already processed", message);
        } else {
          this.logger.info("[ConversationRepository][decryptOMEMOMessage] DECRYPTED", decryptedMessage, updateLastConvMessage);
          this.saveMessagesToStoreAndDB(conversationTarget, [decryptedMessage], updateLastConvMessage);
          this.decryptNextOMEMOMessage(conversationTarget, messages);
        }
      }, err => {
        this.logger.error("[ConversationRepository][decryptOMEMOMessage]", err, message);
        this.decryptNextOMEMOMessage(conversationTarget, messages);
      });
    } else {
      this.decryptNextOMEMOMessage(conversationTarget, messages, updateLastConvMessage);
    }
  }

  private decryptMultipleOMEMOMessages(conversationTarget: string, messages: Message[]) {
    messages = messages.filter(v => !!v.encrypted && !this.processingE2EMessages.includes(v.id) && !this.decryptedMessages.includes(v.id));
    const messagesToDecrypt = messages.splice(0, 5);
    this.store.dispatch(new AddProcessingMessages(messagesToDecrypt.map(v => v.id)));
    const decryptMessages$ = messagesToDecrypt.map(message => {
      return this.xmppService.decriptOrUseExisting(message).pipe(map(decryptedMessage => {
        if (Object.keys(decryptedMessage).length === 1) {
          this.logger.warn("[ConversationRepository][decryptMultipleOMEMOMessages]] ignore - already processed", message);
        } else {
          this.logger.info("[ConversationRepository][decryptMultipleOMEMOMessages] DECRYPTED ONE", decryptedMessage);
          this.decryptedMessages.push(decryptedMessage.id);
          this.saveMessagesToStoreAndDB(conversationTarget, [decryptedMessage]);
        }
        this.store.dispatch(new RemoveProcessingMessage(decryptedMessage.id));
        return decryptedMessage;
      }));
    });

    combineLatest(decryptMessages$).subscribe(res => {
      this.logger.info("[ConversationRepository][decryptMultipleOMEMOMessages] DECRYPTED BULK", res);
      if (messages.length > 0) {
        this.decryptMultipleOMEMOMessages(conversationTarget, messages);
      }
    });
  }

  private decryptNextOMEMOMessage(conversationTarget: string, messages: Message[], updateLastConvMessage?: boolean){
    if (messages.length > 0) {
      const firstMessage = messages.shift();
      setTimeout(() => {
        this.decryptOMEMOMessage(conversationTarget, firstMessage, messages, updateLastConvMessage);
      }, 10);
    } else {
      this.logger.info("[ConversationRepository][decryptNextOMEMOMessage]", "DONE");
    }
  }

  private async tryTodecryptOMEMOMessagePreInit(conversationTarget: string, msg: Message) {
    this.tryToDecryptPreInitIds.push(msg.id);
    const header = msg.encrypted.header;

    const localDeviceId = this.localDeviceId;

    const keys = header.keys.filter(key => (parseInt(key.rid, 10) === localDeviceId));

    const senderJid = msg.type === "groupchat" ? msg.from.resource : msg.from.bare;
    const currentSenderDevice = parseInt(header.sid, 10);

    const iv = this.base64StringToArrayBuffer(header.iv);
    const payload = this.base64StringToArrayBuffer(msg.encrypted.payload);
    const address = new window.libsignal.SignalProtocolAddress(senderJid, msg.encrypted.header.sid);
    const session = new window.libsignal.SessionCipher(this.omemoDBstore, address);
    const subtleCrypto = window.crypto.subtle;

    // this.logger.info("[tryTodecryptOMEMOMessagePreInit][processing] ", msg);
    keys.forEach(async k => {
      let whisper = await this.getWhisper(senderJid, msg.id);
      const keyData = this.base64StringToArrayBuffer(k.content);
      if (!whisper) {
        try {
          if (k.preKey) {
            whisper = await session.decryptPreKeyWhisperMessage(keyData, "binary");
          } else {
            whisper = await session.decryptWhisperMessage(keyData, "binary");
          }
          if (!!whisper) {
            // this.logger.info("[tryTodecryptOMEMOMessagePreInit][whisper] ", typeof whisper, whisper);
            this.databaseService.storeWhisper(senderJid, msg.id, whisper).subscribe();
          }
        } catch (error) {
          this.logger.error("[tryTodecryptOMEMOMessagePreInit][processing whisper]", error);
        }
      }
      // this.logger.info("[tryTodecryptOMEMOMessagePreInit][processing-whisper-" + msg.id, k, whisper);
      if (!!whisper) {
        let b64whisper = this.arrayBufferToBase64String(whisper);
        let bufWhisper = this.base64StringToArrayBuffer(b64whisper);
        const gcmKey = bufWhisper.slice(0, 16);
        let authTag = new Uint8Array(bufWhisper.byteLength - 16);
        authTag.set(new Uint8Array(bufWhisper.slice(16)));

        const subtleKey = await subtleCrypto.importKey("raw", gcmKey, { name: "AES-GCM" }, false, ["decrypt", "encrypt"]);
        const decryptData = new Uint8Array(payload.byteLength + authTag.byteLength);

        decryptData.set(new Uint8Array(payload));
        decryptData.set(authTag, payload.byteLength);
        const tagLength = authTag.byteLength === 0 ? 128 : authTag.byteLength * 8;

        try {
          let decryptedData = await subtleCrypto.decrypt(
            {
              name: "AES-GCM",
              iv: iv,
              tagLength: tagLength,
            },
            subtleKey,
            decryptData
          );

          let content: any;
          const contentBase64: string = this.arrayBufferToBase64String(decryptedData);
          content = this.fromBinary(atob(contentBase64));
          content = this.b64_to_utf8(content);
          content = JSON.parse(content);
          // this.logger.info("[tryTodecryptOMEMOMessagePreInit][decryptData] decrypted content: ", content);
          let msgToStore = { ...msg };
          if (!!content.body && content.body !== "Encrypted message") {
            msgToStore.body = content.body;
            msgToStore.cachedContent = content.body;
          }
          if (!!content.htmlBody) {
            msgToStore.htmlBody = content.htmlBody;
          }
          msgToStore.encryption = null;
          msgToStore.encrypted = null;
          msgToStore.cannotDecrypt = false;

          this.saveMessagesToStoreAndDB(conversationTarget, [msgToStore]);
        } catch (e) {
          this.logger.error("[[tryTodecryptOMEMOMessagePreInit][decryptData] Failed decrypting data", e);
        }
      }
    });
  }

  private fromBinary(binary: string): string {
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    const codeUnitsBuffer = new Uint16Array(bytes.buffer);
    return String.fromCharCode(...Array.from(codeUnitsBuffer));
  }

  private utf8_to_b64(str: string) {
    const res = window.btoa(unescape(encodeURIComponent(str)));
    return res;
  }

  private b64_to_utf8(str: string) {
    const res = decodeURIComponent(escape(window.atob(str)));
    return res;
  }

  private async getWhisper(address: string, id: string) {
    return new Promise((resolve, reject) => {
      this.databaseService.getWhisper(address, id).subscribe((whisper) => {
        resolve(whisper);
      }, err => {
        reject(err);
      });
    });
  }


  private base64StringToArrayBuffer(str: string) {
    const byteStr = atob(str);
    const arrayBuffer = new ArrayBuffer(byteStr.length);
    const byteArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteStr.length; i++) {
      byteArray[i] = byteStr.charCodeAt(i);
    }

    return arrayBuffer;
  }

  private arrayBufferToBase64String(arrayBuffer) {
    const charArray = new Uint8Array(arrayBuffer);
    return btoa(charArray.reduce((carry, x) => carry + String.fromCharCode(x), ""));
  }

  ///////////////

  public sendMessageText(messageText: string, convTarget?: string) {
    this.logger.info("[ConversationRepository][sendMessageText]", messageText, convTarget);

    let currentConv;
    this.store.select(state => getConversationById(state, convTarget)).pipe(take(1)).subscribe(conv => {
      currentConv = conv;
    });

    const message = {
      body: messageText,
      type: currentConv.type
    };

    this.sendMessage(message, convTarget, currentConv.type);
  }

  public sendChatState(type: string, chatState: string) {
    this.sendMessage({type, chatState});
  }

  public deleteMessage(message: any) {
    this.logger.info("[ConversationRepository] deleteMessage");

    let convTarget = this.currentConv && this.currentConv.Target;
    if (!convTarget) {
      return;
    }

    this.prepareMessageIdBodyTimestampParams(message);

    this.removeMessage(message, convTarget);
  }

  public deleteMessagesFromRedux(conv: Conversation) {
    if (!!conv) {
      this.store.select(state => getMessagesByConversationTarget(state, conv)).pipe(take(1)).subscribe(messages => {
        this.logger.info("[ConversationRepository][deleteMessagesFromRedux]", conv.Target, messages.length);

        const messageIdsToRemoveFromRedux = messages.map(message => message.id);

        this.store.dispatch(new DeleteMessages({
          messageIds: messageIdsToRemoveFromRedux,
          persistentConversationIds: [conv.Target],
          nonPersistentConversationIds: []
        }));

        this.store.dispatch(new ResetLastPageLoaded(conv.Target));
      });
    }

  }

  public sendUpdateGroupChatAvatarSignal() {
    let screenName: string;
    this.store.select(getUserProfile).pipe(take(1)).subscribe(profile => {
      this.logger.info("[ConversationRepository][updateGroupChatAvatar] getUserProfile", profile);
      screenName =  profile.user ?  profile.user.fullName : "";
    });

    let selectedConversation: Conversation;
    this.getSelectedConversation().subscribe(conv => selectedConversation = conv);

    const type = selectedConversation.type;
    const group_action = {type: GroupAction.UPDATE_AVATAR};
    const body = `${screenName} changed the group photo`;

    this.logger.info("[ConversationRepository][updateGroupChatAvatar]", type, group_action);

    this.sendMessage({type, group_action, body}, selectedConversation.Target, type);
  }

  public sendUpdateE2ESignal(isEnabled: boolean, conversation: Conversation): Message {
    let screenName: string;
    this.store.select(getUserProfile).pipe(take(1)).subscribe(profile => {
      this.logger.info("[ConversationRepository][sendUpdateE2ESignal] getUserProfile", profile);
      screenName =  profile.user ?  profile.user.fullName : "";
    });

    const type = conversation.type;
    const group_action = {type: GroupAction.UPDATE_ENCRYPTION, data: isEnabled ? "1" : "0"};
    const body = `${screenName} ${isEnabled ? "enabled" : "disabled"} E2E encryption for the chat`;

    this.logger.info("[ConversationRepository][sendUpdateE2ESignal]", type, group_action);

    // sync to other active clients
    const signal = {
      type: isEnabled ? "e2ee-on" : "e2ee-off",
      target: conversation.Target
    };
    this.xmppService.sendSignalToMyself(signal);

    return this.sendMessage({type, group_action, body}, conversation.Target, type);
  }

  public sendGroupArchivedSignal(groupName, target): Message {
    const type = "groupchat";
    const group_action = { type: "archived", data: groupName };
    const body = `Group "${groupName}" Archived`;
    this.logger.info("[ConversationRepository][sendGroupArchivedSignal]", type, group_action, target);
    return this.sendMessage({ type, group_action, body }, target, "type");
  }

  public sendGroupUnarchivedSignal(groupName, target): Message {
    const type = "groupchat";
    const group_action = { type: "unarchived", data: groupName };
    const body = `Group "${groupName}" unarchived`;
    const conversationTarget = target;
    this.logger.info("[ConversationRepository][sendGroupUnarchivedSignal]", type, group_action, target);
    return this.sendMessage({ type, group_action, body, conversationTarget }, target, "type");
  }



  public sendNewGroupSignal(groupName, target): Message {
    const type = "groupchat";
    const group_action = {type: "created", data: groupName};
    const body = `Group "${groupName}" Created`;
    this.logger.info("[ConversationRepository][sendNewGroupSignal]", type, group_action, target);
    return this.sendMessage({type, group_action, body}, target, "type");
  }

  public sendUpdateParticipantSignal(participants: string[], target: string): Message {
    const group_action = {type: GroupAction.ADD_PARTICIPANTS, data: participants.join(",")};
    let body = `Added ${participants.length} participants: <br>`;
    if (participants.length === 1) {
      body = `Added 1 participant: <br>`;
    }
    const name =  participants.map(p => this.contactRepo.getFullName(p));
    body += name.join(", ");
    return this.sendMessage({type: "groupchat", group_action, body}, target, "groupchat");
  }

  public sendGroupUpdatedSignal(target: string): Message {
    const group_action = { type: GroupAction.GROUP_UPDATE };
    return this.sendMessage({ type: "groupchat", group_action }, target, "groupchat");
  }


  // TODO: partial Message as parameter type. Also this should return Observable.
  public sendMessage(message: any, target?: string, type?: string): Message {
    // bail out for fake target "all"
    if (!!target && (target === "all")) {
      return;
    }
    this.logger.info("[ConversationRepository] sendMessage", message, target, type);
    let convTarget = target || this.currentConv && this.currentConv.Target;
    if (!convTarget) {
      return;
    }

    this.prepareMessageIdBodyTimestampParams(message);

    // system message
    if (MessageUtil.isSystemMessage(message)) {
      this.xmppService.sendMessage(convTarget, message);
      return;
    }

    this.prepareMessageRestParams(message, convTarget);

    const [messageToSend, msgToStore] = this.prepareMessageObjectToSendAndStore(message, type, convTarget);
    const jwtPattern = /token [A-Za-z0-9\-._~+\/]+=*/;
    const htmlBody = messageToSend.htmlBody;
    const jwtTokenMatch = htmlBody.match(jwtPattern);
    if (jwtTokenMatch) {
      const jwtToken = jwtTokenMatch[0];

      // Remove the JWT token from the htmlBody attribute
      const cleanedHtmlBody = htmlBody.replace(jwtPattern, "");

      // Update the object with the cleaned htmlBody attribute
      messageToSend.htmlBody = cleanedHtmlBody;
    }

    this.addMessageToPendings(messageToSend);

    this.resetUnreadCount(target);

    this.storeMessage(msgToStore, convTarget);

    return msgToStore;
  }

  public sendMessageAndReturnObjectToStore(message: any, target?: string, type?: string): Message {
    let convTarget = target || this.currentConv && this.currentConv.Target;
    this.logger.info("[ConversatinRepository][sendMessageAndReturnObjectToStore]", message, convTarget);

    if (!convTarget) {
      this.logger.warn("[ConversatinRepository][sendMessageAndReturnObjectToStore] skip, convTarget is empty");
      return;
    }

    const [messageToSend, msgToStore] = this.prepareMessageObjectToSendAndStore(message, type, target);
    this.addMessageToPendings(messageToSend);

    this.resetUnreadCount(target);
    if (this.currentConv.archived && this.currentConv.type === "groupchat" && this.isOwnerOrAdmin(this.currentConv)) {
      this.unArchiveGroupchat(this.currentConv);
    };
    return msgToStore;
  }

  prepareMessageObjectToSendAndStore(message: any, type: string, convTarget: string): Message[] {
    const escapedHTMLBody = CommonUtil.escapeHTMLString(message.body);
    const chatType = CommonUtil.isGroupTarged(convTarget) ? "groupchat" : "chat";
    const messageToSend: Message = {
      from: this.userJID,
      fromJid: this.userJID?.bare,
      type: type || this.currentConv?.type || chatType,
      isForwarded: false,
      body: escapedHTMLBody,
      htmlBody: escapedHTMLBody,
      html: {
        body: message.body
      },
      pendingIn: convTarget,
      status: MessageStatus.PENDING,
      ...message
    };

    return [messageToSend, messageToSend];
  }

  public storeMessage(msgToStore: any, target?: string) {
    let convTarget = target || this.currentConv && this.currentConv.Target;
    if (!convTarget) {
      this.logger.warn("[ConferenceRepository][storeMessage] IGNORED!", msgToStore, target, this.currentConv);
      return;
    }

    this.logger.info("[ConferenceRepository][storeMessage]", msgToStore, convTarget);

    // save to Redux
    this.store.dispatch(new MessageAdd({
      conversationTarget: convTarget,
      incoming: false,
      message: msgToStore
    }));

    // save to DB
    this.databaseService.createOrUpdateMessages([{ ...msgToStore, incoming: false }], convTarget);
  }

  processAndStoreCallMessage(callSignalMessage){
    if (!callSignalMessage) {
      this.logger.info("[ConversationRepository][processAndStoreCallMessage] ignore, 'callSignalMessage' is null");
      return;
    }

    // by default we have a type=normal for call signals
    if (ConferenceUtil.isPrivateCallSignal(callSignalMessage)) {
      callSignalMessage.type = "chat";
    } else {
      callSignalMessage.type = "groupchat";
    }

    callSignalMessage.fromJid = this.userJID?.bare;

    callSignalMessage.to = MessageUtil.convertMessageToFromStringToObject(callSignalMessage.to);

    this.logger.info("[ConversationRepository][processAndStoreCallMessage]", callSignalMessage);
    if (callSignalMessage.vncTalkConference.conferenceId.indexOf("@") !== -1) {
      this.storeMessage(callSignalMessage, callSignalMessage.vncTalkConference.conferenceId);
    } else {
      this.storeMessage(callSignalMessage, callSignalMessage.vncTalkConference.to);
    }

    return callSignalMessage;
  }

  public allowToSendXMPP(target: string): boolean {
    const domain = this.getXmppDomain();
    const allowedDomain = [...this.configService.get("knownIOMDomains"), domain];
    let conferenceDomain = `conference.${domain}`;
    if (!!localStorage.getItem("conferenceDomain")) {
      conferenceDomain = localStorage.getItem("conferenceDomain");
    }
    allowedDomain.push(conferenceDomain);
    const targetDomain = target.split("@")[1];
    this.logger.info("[shouldSendCallSignal]", allowedDomain, targetDomain);
    return allowedDomain.indexOf(targetDomain) !== -1;
  }

  prepareMessageIdBodyTimestampParams(message: any) {
    if (!message.id) {
      message.id = CommonUtil.randomId(10);
    }

    const body = message.body;
    if (!!body) {
      message.html = {
        body: body
      };
      message.htmlBody = CommonUtil.escapeHTMLString(body);
      message.body = CommonUtil.HTMLToPlainText(message.body);
    }

    message.timestamp = this.datetimeService.getCorrectedLocalTime();
  }

  prepareMessageRestParams(message: Message, convTarget: string) {
    let mentions = CommonUtil.parseMentions(message.body);
    if (message.type === "groupchat" && mentions.length > 0) {
      let references = [];
      this.getAllConversationMembers(convTarget).pipe(take(1)).subscribe(members => {
        mentions = mentions.filter(bare => members.includes(bare));
      });
      mentions.forEach(bare => {
        bare = bare.replace(/xmpp:+/ig, "");
        references.push({
          type: "mention",
          uri: `xmpp:${bare}`
        });
      });
      message.references = references;
    }
    if (message.type === "chat") {
      message.requestReceipt = this.sendReceipt;
    } else if (message.type === "broadcast") {
      this.store.select(state => getConversationById(state, convTarget)).pipe(take(1)).subscribe(conv => {
        if (!!conv && conv.audience) {
          const audience = conv.audience;
          const groups = audience.filter(v => v.indexOf("@conference.") !== -1);
          let jidsFromGroups = [];
          if (groups.length > 0) {
            groups.forEach(group => {
              this.store.select(state => getConversationById(state, group)).pipe(take(1)).subscribe(groupConv => {
                if (jidsFromGroups.indexOf(groupConv.owner) === -1) {
                  jidsFromGroups.push(groupConv.owner);
                }
                                groupConv.members.forEach(member => {
                  if (jidsFromGroups.indexOf(member) === -1) {
                    jidsFromGroups.push(member);
                  }
                });
              });
            });
          }

          const jid = audience.filter(v => (v.indexOf("@") !== -1 && v.indexOf("@conference.") === -1));
          const accumulatedJids = [...jid, ...jidsFromGroups];
          const roster = audience.filter(v => v.indexOf("@") === -1);
	  // ToDo #30003052-18709 add description and tags array to message.vncTalkBroadcast here
          message.vncTalkBroadcast = {
            title: conv.broadcast_title,
            to: [...accumulatedJids.filter(v => v !== this.userJID?.bare).filter((value, index, self) => { return self.indexOf(value) === index; })],
            roster: roster,
            description: this.broadcastDescriptions,
            tags: this.broadcastTags
          };
          console.log("message.vncTalkBroadcast", message.vncTalkBroadcast);
        } else {
          this.logger.info("[Cannot find audience to send the broadcast]", convTarget);
          message.vncTalkBroadcast = {
            title: conv.broadcast_title,
            to: [],
            roster: []
          };
        }
      });
      message.type = "normal";
    }
    if (message.type === "broadcast" && !message.vncTalkBroadcast) {
      return;
    }
    message.to = MessageUtil.convertMessageToFromStringToObject(convTarget);
  }

  private removeMessage(message: Message, convTarget: string) {
    const localMessage: Message = {
      ...message,
      pendingIn: convTarget,
    };

    this.store
      .select(state => getMessageById(state, message.replace.id))
      .pipe(take(1), filter(msg => !!msg))
      .subscribe(msg => {
        this.databaseService.createOrUpdateMessages([{ ...msg, isDeleted: true }], convTarget);
      });

    this.logger.info("[ConversationRepository][removeMessage]", localMessage);

    this.store.dispatch(new MessageDeletedStatusUpdateAction({
      id: message.replace.id,
      isDeleted: true,
      convTarget: convTarget
    }));

    this.addMessageToPendings(localMessage);
  }

  storeUrlUnfurledData(message: any, convTarget: string) {
    this.store.dispatch(new MessageUpdateAction({ id: message.id, changes: { urlPreviewData: message.urlPreviewData } }));

    this.databaseService.createOrUpdateMessages([message], convTarget);
  }

  storeRedmineData(message: any, convTarget: string) {
    // this.logger.info("[ConversationRepository][storeRedmineData]", message.redminePreview );

    this.store.dispatch(new MessageUpdateAction({ id: message.id, changes: { redminePreview: message.redminePreview } }));

    this.databaseService.createOrUpdateMessages([message], convTarget);
  }

  storeRedmineVersionData(message: any, convTarget: string) {
    this.store.dispatch(new MessageUpdateAction({ id: message.id, changes: { versionPreview: message.versionPreview } }));

    this.databaseService.createOrUpdateMessages([message], convTarget);
  }

  public inviteToRoomViaGroupManage(target: string, newMembers: string[], reason?: string, role?: string) {
    this.logger.info("[ConversationRepository][inviteToRoomViaGroupManage]", target, newMembers);
    newMembers = CommonUtil.uniq(newMembers);
    this.xmppService.invite(target, newMembers, reason);
    //
    if (this.isGroupManageEnabled) {
      this.groupChatsService.setRoomAffiliation(target, newMembers, role).subscribe( () => {
        this.sendUpdateParticipantSignal(newMembers, target);
        this.addMemberToMembersList(target, newMembers);
        this.getAllMembersOfByConversationId(target).pipe(take(1)).subscribe( members => {
          const domain = this.getXmppDomain();
          this.logger.info("[ConversationRepository][postInviteToRoomViaGroupManage]", target, domain, members);
          const iomMembers = members.find(m => m.indexOf("@" + domain) === -1);
          if (!!iomMembers && (iomMembers.length > 0)) {
            this.getConversationById(target).pipe(take(1)).subscribe( econv => {
              this.store.dispatch(new ConversationUpdate({target: target,  changes: {has_iom: true}}));
              econv.has_iom = true;
              this.databaseService.createOrUpdateConversation([econv]).subscribe();
              this.broadcaster.broadcast("updateIOM", {target: target, val: true});
            });
          } else {
            this.getConversationById(target).pipe(take(1)).subscribe( econv => {
              this.store.dispatch(new ConversationUpdate({target: target,  changes: {has_iom: false}}));
              econv.has_iom = true;
              this.databaseService.createOrUpdateConversation([econv]).subscribe();
              this.broadcaster.broadcast("updateIOM", {target: target, val: false});
            });
            // this.broadcaster.broadcast("updateIOM", {target: target, val: false});
          }
        });
      }, err => {
        this.logger.error("[ConversationRepository][inviteToRoomViaGroupManage] setRoomAffiliation", err);
        this.notificationService.openSnackBarWithTranslation("LOAD_FAILED");
      });
    } else {
      this.addMemberToMembersList(target, newMembers);
    }
  }

  public removeFromRoomViaGroupManage(target: string, membersToRemove: string[]) {
    this.logger.info("[ConversationRepository][removeFromRoomViaGroupManage]", target, membersToRemove);

    if (this.isGroupManageEnabled) {
      this.groupChatsService.setRoomAffiliation(target, membersToRemove, "none").subscribe( () => {
        this.removeMemberFromMembersList(target, membersToRemove);
      }, err => {
        this.logger.error("[ConversationRepository][removeFromRoomViaGroupManage] setRoomAffiliation", err);
        // already added?
        this.removeMemberFromMembersList(target, membersToRemove);
      });
    } else {
      this.removeMemberFromMembersList(target, membersToRemove);
    }
  }

  public inviteCallByMail(jidsOrEmails: string[], callUrls: string[], password?: string, title?:string, description?: string): Observable<any> {
    const response = new Subject<any>();

    let subject = !!title ? title : "";
    if (!title) {

      this.translate.get("VNCTALK_VIDEO_CONFERENCE").pipe(take(1)).subscribe(text => {
        subject = text;
      });
    }

    const bodys = callUrls.map(cUrl => {
      let formatedLink: string;
      this.translate.get("INVITATION_MESSAGE", {link: cUrl}).pipe(take(1)).subscribe(text => {
        formatedLink = text;
      });

      return formatedLink;
    });

    const emails = jidsOrEmails.map(je => {
      // get real email by jid
      let contact: Contact;
      this.contactRepo.getContactById(je).pipe(take(1)).subscribe(c => {
        contact = c;
      });
      return CommonUtil.getContactEmailByContactJid(je, contact);
    });

    this.logger.info("[ConversationRepository][inviteCallByMail]", jidsOrEmails, emails, bodys, callUrls, title);

    let body = {
      to: emails,
      body: bodys,
      subject: subject,
      password: password
    };
    if (!!description) {
      body["description"] = description;
    }
    this.conversationService.sendEmail(body).subscribe((res: any) => {
      this.logger.info("[ConversationRepository][inviteCallByMail] sent", body);
      response.next(res);
    }, err =>  {
      this.logger.error("[ConversationRepository][inviteCallByMail]", err);
      response.error(err);
    });

    return response.asObservable();
  }

  public sendAttachmentsTo(to: string, subject: string, message: string, attachments: any[]): Observable<any> {
    const response = new Subject<any>();
    const body = {
      to: [to],
      body: [message],
      subject: subject,
      attachments: attachments
    };
    this.conversationService.sendEmail(body).subscribe((res: any) => {
      this.logger.info("[ConversationRepository][sendAttachmentsTo] sent", body);
      response.next(res);
    }, err =>  {
      this.logger.error("[ConversationRepository][sendAttachmentsTo] err", err);
      response.error(err);
    });
    return response.asObservable();
  }

  sendEmail(jidsOrEmails: string[], message: string[], subject: string, attachments = []) {
    let sendingToast;
    this.translate.get("SENDING_EMAIL").pipe(take(1)).subscribe(text => {
      sendingToast = this.vncLibraryService.openSnackBarWithFullcontrol(text, "","", "", 40000, "bottom", "left");
    });
    const emails = jidsOrEmails.map(je => {
      // get real email by jid
      let contact: Contact;
      this.contactRepo.getContactById(je).pipe(take(1)).subscribe(c => {
        contact = c;
      });
      return CommonUtil.getContactEmailByContactJid(je, contact);
    });

    this.logger.info("[ConversationRepository][sendEmail]", jidsOrEmails, emails);

    const params = {
      to: emails,
      subject: subject,
      body: message
    };
    if (attachments && attachments.length) params["attachments"] = attachments;

    this.conversationService.sendEmail(params).subscribe(
      res => {
        sendingToast.dismiss();
        this.translate.get("SENT").pipe(take(1)).subscribe(text => {
          this.vncLibraryService.openSnackBar(text, "checkmark", "", "", 2000, "left", "left").subscribe(() => {});
        });
        this.logger.info("[ConversationRepository][sendEmail]", params, res);
      },
      error => {
        sendingToast.dismiss();
        if (error.error.message.responseCode === 552) {
          this.translate.get("FILE_LIMIT_SENT_MAIL").pipe(take(1)).subscribe(text => {
            this.vncLibraryService.openSnackBar(text, "","", "", 4000, "bottom", "left").subscribe(() => {});
          });
        };
        this.logger.info("[ConversationRepository][error]",error);
      }
    );
  }

  public leaveSelectedConversation() {
    this.logger.info("[ConversationRepository][leaveSelectedConversation]", this.currentConv);
    if (this.currentConv) {
      this.leaveConversation(this.currentConv, "leave");
    }
    if (CommonUtil.isOnMobileDevice()) {
      this.navigateToConversation(ConstantsUtil.ALL_TARGET);
    } else {
      this.navigateToConversation(null);
    }
  }

  public kick(target: string, bare: string) {
    this.logger.info("[ConversationRepository][kick]", bare, target);

    this.setRoomAffiliation(target, bare, "none").subscribe(() => {
      this.xmppService.setRoomAffiliation(target, bare, "none").subscribe(() => {
        this.xmppService.kick(target, bare).subscribe(v => {
          this.logger.info("[ConversationRepository][kick] xmpp res: ", v);
        });
      });
    }, () => {
      this.logger.error("could not set affiliation - needs handling");
      // this.xmppService.kick(target, bare).subscribe();
    });
    this.store.dispatch(new ConversationRemoveMember({ conversationTarget: target, member: bare }));
  }

  public kickMultiples(target: string, ids: string[]) {
    this.logger.info("[ConversationRepository][kickMultiples]", ids, target);

    ids.forEach(bare => {
      this.setRoomAffiliation(target, bare, "none").subscribe(res => {
        this.logger.info("[ConversationRepository][kickMultiples][setRoomAffiliation] res: ", res);
        this.removeMemberFromMembersList(target, [bare]);
        this.xmppService.setRoomAffiliation(target, bare, "none").subscribe(xres => {
          this.logger.info("[ConversationRepository][kickMultiples][setRoomAffiliation] xmpp res: ", xres);
          this.xmppService.kick(target, bare).pipe(take(1)).subscribe(v => {
            this.logger.info("[ConversationRepository][kickMultiples][setRoomAffiliation] xmpp kick res: ", v);
          });
        }, err => {
          this.logger.error("[ConversationRepository][kickMultiples][setRoomAffiliation] xmpp kick err: ", err);
        });
      });
    });

    this.store.dispatch(new ConversationRemoveMembers({ conversationTarget: target, members: ids }));
    // updateIOM
    this.getAllMembersOfByConversationId(target).pipe(take(1)).subscribe( members => {
      const domain = this.getXmppDomain();
      this.logger.info("[ConversationRepository][postInviteToRoomViaGroupManage]", target, domain, members);
      const iomMembers = members.find(m => m.indexOf("@" + domain) === -1);
      if (!!iomMembers && (iomMembers.length > 0)) {
        this.getConversationById(target).pipe(take(1)).subscribe( econv => {
          this.store.dispatch(new ConversationUpdate({target: target,  changes: {has_iom: true}}));
          econv.has_iom = true;
          this.databaseService.createOrUpdateConversation([econv]).subscribe();
          this.broadcaster.broadcast("updateIOM", {target: target, val: true});
        });
      } else {
        this.getConversationById(target).pipe(take(1)).subscribe( econv => {
          this.store.dispatch(new ConversationUpdate({target: target,  changes: {has_iom: true}}));
          econv.has_iom = false;
          this.databaseService.createOrUpdateConversation([econv]).subscribe();
          this.broadcaster.broadcast("updateIOM", {target: target, val: false});
        });
      }
    });
  }

  public isGroupchat() {
    return this.currentConv && this.currentConv.type === "groupchat";
  }

  public unregisterMemberList(conversationTarget: string): Observable<any> {
    this.logger.info("[ConversationRepository][unregisterMemberList] conversationTarget", conversationTarget);

    return this.xmppService.unregisterMemberList(conversationTarget);
  }

  public sendFileAndReturnObjectToStore(file: any, fileName: string, fileSize: number, convTarget?: string, fileType?: string, skipObjectURL?: boolean) {
    this.logger.info("[ConversationRepository][sendFileAndReturnObjectToStore]", file.size);

    // cache currentConv so that message is sent to right conv even if conv change before file upload.
    let currentConv = this.currentConv;
    if (convTarget) {
      this.store.select(state => getConversationById(state, convTarget)).pipe(take(1)).subscribe(conv => {
        if (!!conv) {
          currentConv = conv;
        }
      });
    }
    this.logger.info("[ConversationRepository][sendFileAndReturnObjectToStore] currentConv", currentConv);
    if (!currentConv) {
      currentConv = this.createLocalConversation(convTarget, convTarget.indexOf("@conference") !== -1 ? "groupchat" : "chat", "");
      this.logger.info("[ConversationRepository][sendFileAndReturnObjectToStore] created currentConv", currentConv);
    }

    let sentURL;
    try {
      sentURL = URL.createObjectURL(file); // File
    } catch (e) {
      this.logger.error("[ConversationRepository][sendFileAndReturnObjectToStore] URL.createObjectURL(file) did not work on this platform: " + e);
      if (!skipObjectURL) {
        return;
      }
    }
    this.logger.info("[ConversationRepository][sendFileAndReturnObjectToStore] createObjectURL sentURL: ", sentURL);

    const fType = fileType || fileName.split(".").pop().toLowerCase();
    if (CommonUtil.isImage(fType)) {
      if (!fileName.split(".")[0].trim()) {
        fileName = "photo_" + CommonUtil.randomId(5) + "." + fType;
      }
    }

    const timestamp = this.datetimeService.getCorrectedLocalTime();
    const msg: Message = {
      id: CommonUtil.randomId(10),
      from: this.userJID,
      to: MessageUtil.convertMessageToFromStringToObject(currentConv.Target),
      pendingIn: currentConv?.Target || convTarget,
      body: sentURL,
      timestamp,
      type: currentConv?.type || "chat",
      attachment: {
        url: sentURL,
        fileSize: fileSize,
        fileName: fileName,
        fileType: fType
      },
      file: file,
      isForwarded: null,
      status: MessageStatus.PENDING
    };
    this.logger.info("[ConversationRepository][sendFileAndReturnObjectToStore] msg: ", msg);

    if (CommonUtil.isBroadcast(currentConv?.Target)) {
      this.store.select(state => getConversationById(state, currentConv?.Target)).pipe(take(1)).subscribe(conv => {
        if (!!conv && conv.audience) {
          const audience = conv.audience;
          const jid = audience.filter(v => v.indexOf("@") !== -1);
          const roster = audience.filter(v => v.indexOf("@") === -1);
          msg.vncTalkBroadcast = {
            title: currentConv?.broadcast_title,
            to: jid,
            roster: roster
          };
        }
      });
      msg.type = "normal";
    } else if (msg.type === "chat") {
      msg.requestReceipt = this.sendReceipt;
    }

    this.addMessageToPendings(msg);

    return msg;
  }

  public sendFile(file: any, fileName: string, fileSize: number, convTarget?: string, fileType?: string, skipObjectURL?: boolean) {
    this.logger.info("[ConversationRepository][sendFile]");

    const msgToStore = this.sendFileAndReturnObjectToStore(file, fileName, fileSize, convTarget, fileType, skipObjectURL);

    this.storeMessage(msgToStore, convTarget);
  }

  public createAndJoinGroupChat(members: string[], callback?: any) {
    this.logger.info("[ConversationRepository][createAndJoinGroupChat] members: ", members);

    this.createRoom().subscribe(roomBare => {
      this.joinRoomIfNotJoined(this.createLocalConversation(roomBare));

      this.configureRoom(roomBare, {
        persistent: 1,
        isPublic: 0,
        memberOnly: 0
      }).subscribe();
      if (typeof callback === "function") {
        callback(roomBare);
      }

      this.inviteToRoomViaGroupManage(roomBare, members.filter(p => p !== this.userJID?.bare));
      this.updateConversationMembersInStorage(roomBare, members);
      this.navigateToConversation(roomBare);
    });
  }

  public createGroupChatFromSingleChat(name: string): Observable<string> {
    return this.xmppService.createRoom(name);
  }

  public loadConvsMore(conv: Conversation, pageSize: number):Observable<any> {
    // this.logger.info("[ConversationRepository][loadConvsMore] conv: ", conv);
    // this.logger.info("[ConversationRepository][loadConvsMore] pageSize: ", pageSize);
    const response = new Subject<Conversation[]>();

    // 1. load from DB first
    this.loadConversationsDB(conv?.Timestamp, pageSize).subscribe(convs => {
      this.logger.info("[ConversationRepository][loadConvsMore] loadConversationsDB: ",convs, convs.length);
      if (convs.length < pageSize) {
        // 2. then load from server if not enought
        // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 5");
        this.loadAllUpdatedConversations(conv.updated_at).subscribe(loadconvs => {
          this.logger.info("[ConversationRepository][loadConvsMore] loadAllUpdatedConversations: ", loadconvs.length);
          response.next(loadconvs);
        }, () => {
          this.store.dispatch(new ConversationNextLoadSuccess({ conversations: convs }));
          response.next(convs);
        });
      } else {
        this.store.dispatch(new ConversationNextLoadSuccess({ conversations: convs }));
        response.next(convs);
      }
    });

    return response.asObservable();
  }

  private loadConversationsDB(before: any, pageSize: number): Observable<Conversation[]> {
    return this.databaseService.fetchConversationsBefore(before, pageSize);
  }

  // we call 'loadAllUpdatedConversations' in the following cases:
  // 1. start app
  // 2. xmpp re-connect
  // 3. app resume (mobile only)
  // 4. when receive broadcast message
  private loadAllUpdatedConversations(before?: any, after?:any): Observable<Conversation[]> {

    const response = new Subject<Conversation[]>();

    this.getLastConvsSyncTimestamp().subscribe(lastConvsSyncTimestamp => {
      const checkingAt = Date.now();
      this.logger.info(" [ConversationRepository][loadAllUpdatedConversations] debug: ", this.isConvHistoryRunning, this.previousSyncTimestamp, lastConvsSyncTimestamp, checkingAt);
      if (this.isConvHistoryRunning) {
        this.logger.info(" [ConversationRepository][loadAllUpdatedConversations] MAC bailing out");
        response.next([]);
        return response.asObservable();
      }
      this.previousSyncTimestamp = lastConvsSyncTimestamp;
      this.logger.info(new Date().toISOString() + " [ConversationRepository][loadAllUpdatedConversationsbefore: ", before, after);
      const isResyncRequired = this.isResyncAllConvsRequred(lastConvsSyncTimestamp);
      let lastFullSyncStampFromLS = 0;
      let lastXmppDisconnLS = 0;
      let newSyncParam = 0;
      try {
        lastFullSyncStampFromLS = parseInt(localStorage.getItem("resyncedConvsAt")) * 1000;
        lastXmppDisconnLS = parseInt(localStorage.getItem("lastXmppDisconnectAt")) * 1000;
      } catch (e) {
        this.logger.info("error reading stamps from localstorage: ", e);
      }
      if (lastFullSyncStampFromLS < lastXmppDisconnLS) {
        // this.logger.info("[ConversationRepository][loadAllUpdatedConversations] newSync => lastFullSyncStampFromLS ", lastFullSyncStampFromLS);
        newSyncParam = lastFullSyncStampFromLS;
      }
      if ((lastXmppDisconnLS > 0) && (lastXmppDisconnLS < lastFullSyncStampFromLS)) {
        // this.logger.info("[ConversationRepository][loadAllUpdatedConversations] newSync lastXmppDisconnLS ", lastXmppDisconnLS);
        newSyncParam = lastXmppDisconnLS;
      }

      let page;
      if (before) {
        if (before === 1) {
          newSyncParam = 30001;
          page = 0;
        } else {
          newSyncParam = (before * 1000) + 31000;
          page = -1;
        }
      }

      if (!!after) {
        newSyncParam = after - 10000;
      }
      this.isConvHistoryRunning = true;
      setTimeout(() => {
        this.isConvHistoryRunning = false;
      }, 2 * 60 * 1000);
      this.logger.info("[ConversationRepository][loadAllUpdatedConversations.isResyncRequired", isResyncRequired, before, newSyncParam, newSyncParam - 240000);
      this.loadConvsAndProcess(newSyncParam - 240000, page, before).subscribe(res => { // this will always return 1st page only
        this.isConvHistoryRunning = false;
        if (this.isInitialAppStart) {
          setTimeout(() => {
            this.broadcaster.broadcast("LOADINGFINISHED");
          }, 1500);
        }
        const conversations = res.conversations;

        // this.logger.info("[ConversationRepository][loadAllUpdatedConversations] processedConvs: ", conversations);
        if (lastConvsSyncTimestamp > 0 && !isResyncRequired) { // do only if update and not a fresh start
          // hide FCM for read convs
          const t0 = performance.now();
          this.getConversations().pipe(take(1), filter(localConversations => localConversations.length > 0)).subscribe(localConversations => {
            const convsToClearFCM = localConversations.filter(c => c.unreadIds && c.unreadIds.length > 0);
            const targetsToClearFCM = convsToClearFCM.map(c => c.Target);

            const t1 = performance.now();
            this.logger.info(`[PERFORMANCE] clear FCM: took ${t1 - t0} milliseconds.`);

            this.removeAllNotificationsExceptForTargets(targetsToClearFCM);
          });

          // join new popped up convs
          if (!before || (before === 1)) { // if it's load more then no need to join
            conversations.filter(c => c.type === "groupchat" && !c.deleted).forEach(conv => {
              if (this.isConnectedXMPP) {
                this.logger.info("[ConversationRepository][loadAllUpdatedConversations] joinRoomFromTarget ", conv.Target);
                this.joinRoomFromTarget(conv.Target, true);
              }
            });
          }
        }
        response.next(conversations);
        // this.processConversationsMessages(conversations);
        this.syncAllConversationMessagesBackground();
      }, () => {
        this.isConvHistoryRunning = false;
      });
    });

    return response.asObservable();
  }

  private processConversationsMessages(conversations) {
    // const debugConvs = conversations.filter(v => !!v.last_message_id);
    // this.logger.info("[processConversationsMessages] getUnArchivedConversations1 debug ", debugConvs);
    conversations.filter(v => !!v.last_message_id).forEach(conv => {
      if (!conv.deleted) {
        this.databaseService.getMessageById(conv.last_message_id).pipe(take(1)).subscribe(m => {
          if (!m) {
            this.store.select(getIsAppOnline).pipe(filter(v => !!v), take(1)).subscribe(() => {
              if (environment.isCordova && CommonUtil.isOnAndroid() && !!window.appInBackground) {
                this.logger.info("[processConversationsMessages] skip loading with worker in background", conv.Target, this.lastTimeCheck[conv.Target]);
              } else {
                this.logger.info("[processConversationsMessages] fetchMessagesAPI", conv.Target, this.lastTimeCheck[conv.Target]);
                this.databaseService.worker.postMessage({type: "fetchMessagesAPI", token: localStorage.getItem("token"),
                serverURL: localStorage.getItem("serverURL") || "",
                lastTimeCheck: this.lastTimeCheck[conv.Target],
                lastTimestamp: conv.Timestamp,
                args: {convTarget: conv.Target}});
                this.lastTimeCheck[conv.Target] = new Date().getTime();
                localStorage.setItem("lastTimeCheck", JSON.stringify(this.lastTimeCheck));
              }
            });
          }
        });
      }
    });
  }

  sendBotResponse(body) {
    return this.conversationService.sendBotResponse(body);
  }

  private syncAllConversationMessagesBackground() {
    this.store.select(getIsAppOnline).pipe(filter(v => !!v), take(1)).subscribe(() => {
      // this.logger.info("[syncAllConversationMessagesBackground] maybeStart: ", navigator.connection, window.appInBackground);
      if (environment.isCordova && CommonUtil.isOnAndroid() && !!window.appInBackground) {
        this.logger.info("[syncAllConversationMessagesBackground] skip loading with worker in background");
      } else {
        const lastXmppDisconnectAt = !!localStorage.getItem("lastXmppDisconnectAt") ? parseInt(localStorage.getItem("lastXmppDisconnectAt")) - 180 : Math.floor(Date.now() / 1000) - 3600;
        const lastSyncCompleted = !!localStorage.getItem("lastSyncCompleted") ? parseInt(localStorage.getItem("lastSyncCompleted")) : Math.floor(Date.now() / 1000) - 600;
        const after = Math.min(lastXmppDisconnectAt, lastSyncCompleted);
        this.logger.info("[syncAllConversationMessagesBackground] syncAllConversationMessagesBackground start: ", lastXmppDisconnectAt, lastSyncCompleted);
        // this.conversationService.syncMessagesAfter(after).subscribe(res => {
        //  this.databaseService.
        // })
        this.isBgSyncInProgress = true;
        this.databaseService.worker.postMessage({type: "syncAllConversationMessagesBackground", token: localStorage.getItem("token"),
        serverURL: localStorage.getItem("serverURL") || "", after: after });
      }
    });
  }

  mobileSyncAllConversationMessagesBackground(after: number) {
    // this.logger.info("[syncAllConversationMessagesBackground] mobileSyncAllConversationMessagesBackground start: ", after);
    this.conversationService.backgroundSyncMessagesAfter(after).pipe(take(1)).subscribe( bgMessages => {
      // this.logger.info("[syncAllConversationMessagesBackground] mobileSyncAllConversationMessagesBackground bgMessages: ", bgMessages);
      if (!!bgMessages.messages && (bgMessages.messages.length > 0)) {
        const wmessages = [...bgMessages.messages];
        // this.logger.info("[syncAllConversationMessagesBackground] mobileSyncAllConversationMessagesBackground bgMessages for worker: ", wmessages);
        this.databaseService.worker.postMessage({type: "createOrUpdateSyncMessages", args: {messages: wmessages}});
      }
    });
  }

  mobileSyncAllRecentBackground(after?: number) {
    this.conversationService.getConversations(0, 500, 1000 * (after - 1)).subscribe(bgConvs => {
      this.logger.info("[conversationRepo][mobileSyncAllRecentBackground] getConversations bgConvs: ", bgConvs);
      if (!!bgConvs && !!bgConvs.conversations && (bgConvs.conversations.length > 0)) {
        bgConvs.conversations.forEach(bgConv => {
          this.getConversationById(bgConv.Target).pipe(take(1)).subscribe(existingConv => {
            const updatedConv = { ...existingConv, ...bgConv};
            this.logger.info("[conversationRepo][mobileSyncAllRecentBackground] updatedConv: ", updatedConv);
            this.databaseService.createOrUpdateConversation([updatedConv]).subscribe();
          });

        });
        // const bgConversations = {conversations: [...bgConvs.conversations], eod: true };
        // this.logger.info("[conversationRepo][mobileSyncAllRecentBackground] bgConversations: ", bgConversations);
        // this.databaseService.createOrUpdateConversation(bgConvs).subscribe();
        // this.databaseService.worker.postMessage({type: "createOrUpdateSyncConversation", bgConversations});
      }
      // this.databaseService.worker.postMessage({type: "createOrUpdateSyncMessages", bgMessages});
    });
  }

  private loadConvsAndProcess(recentConvTimestamp: number, page?: number, before?: number): Observable<any>  {
    this.logger.info("[ConversationRepository][loadConvsAndProcessCall]", {recentConvTimestamp, page, before});

    const response = new Subject<any>();

    const offset = (page || 0) * 100;

    this.conversationService.getConversations(offset, 100, recentConvTimestamp).pipe(retry(2)).subscribe(res => {
      this.logger.info("[ConversationRepository][loadConvsAndProcess] res", recentConvTimestamp, res.conversations.length, res.eod, res);

      const t0 = performance.now();
      this.processUpdatedConversationsAndStore(res.conversations, recentConvTimestamp);
      this.refreshOmemoDeviceInfo(res.conversations);
      const t1 = performance.now();
      this.logger.info(`[PERFORMANCE] processUpdatedConversationsAndStore: took ${t1 - t0} milliseconds.`);
      if (!!res.eod && !!res.ownLastAvatarUpdate) {
        if ((res.ownLastAvatarUpdate > this.avatarRepo.ownAvatarUpdated) || (!this.avatarRepo.ownAvatarUpdated)) {
          this.broadcaster.broadcast("userAvatarUpdated");
        }
      }
      // load next page after small delay so it will not block
      // if (!res.eod && (res.conversations.length > 0) && (recentConvTimestamp > 0) && (!before || before === 1)) {
      if (!res.eod && (res.conversations.length > 0) && (Math.abs(recentConvTimestamp) > 0) && (!before || before === 1)) {
        this.logger.info("[ConversationRepository][loadConvsAndProcess] loadMoreConvsAndProcess ", recentConvTimestamp, res.conversations.length, res.eod);
        setTimeout(() => {
          this.loadConvsAndProcess(recentConvTimestamp, 1 + (page || 0));
        }, 200);
      } else if (res.conversations && res.conversations.length > 0) {
        this.logger.info("[ConversationRepository][loadAllUpdatedConversations] setting resyncedConvsAt ", "" + Math.floor(Date.now() / 1000));
        if (!!window.appInBackground) {
          this.logger.info("[ConversationRepository][loadAllUpdatedConversations] background resync: ", res.conversations);
          for (let i = 0; i < res.conversations.length; i++) {
            // this.logger.info("[ConversationRepository][loadAllUpdatedConversations] background resync conv: ", res.conversations[i]);
            this.backgroundResyncConv(res.conversations[i], recentConvTimestamp - 60000);
          }
          if (this.allowSleepAgainTimeout) {
            clearTimeout(this.allowSleepAgainTimeout);
          }
          this.allowSleepAgainTimeout = setTimeout(() => {
            if (typeof cordova !== "undefined") {
              try {
                cordova.plugins.backgroundMode.disable();
                window.plugins.insomnia.allowSleepAgain(() => {
                  // cordova.plugins.backgroundMode.moveToBackground();
                  this.logger.info("[app.component] backgroundSync meta sync done, insomnia sleep again done");
                }, error => {
                  this.logger.info("[app.component] backgroundSync meta sync done, error insomnia", error);
                });
              } catch (error) {
                this.logger.info("[app.component] backgroundSync meta sync done, ending background mode");
              }
            }

          }, 13000);
        }
        localStorage.setItem("resyncedConvsAt", "" + Math.floor((Date.now() - 300000) / 1000));
      }

      response.next(res);
    });

    return response.asObservable();
  }

  private processUpdatedConversationsAndStore(allConversations: Conversation[], lastConvsSyncTimestamp: number) {

    if (this.isAllRoomsMembersSynced && allConversations.length === 0) {
      return;
    }

    this.logger.info("[ConversationRepository][processAllUpdatedConversationsAndStore] allConversations", allConversations);

    // remove deleted convs from redux & DB
    const deletedConversationIds = allConversations.filter(c => c && (c.deleted || c.state === "inactive")).map(c => c.Target);
    if (deletedConversationIds.length > 0) {
      this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
        if (!!conv && deletedConversationIds.indexOf(conv.Target) !== -1) {
          if (CommonUtil.isOnMobileDevice()) {
            this.navigateToConversation(ConstantsUtil.ALL_TARGET);
          } else {
            this.navigateToConversation(null);
          }
        }
      });
      this.databaseService.deleteConversations(deletedConversationIds).subscribe();
      this.multiaddToDeletedConvs(deletedConversationIds);
      this.store.dispatch(new ConversationMultipleDelete(deletedConversationIds));
    }

    const seclastConvsSyncTimestamp = Math.floor(lastConvsSyncTimestamp / 1000);
    const updatedAvatarConvs = allConversations.filter(c => (c.lastavatarupdate >= seclastConvsSyncTimestamp)).map(c => c.Target);
    this.logger.info("[ConversationRepository][processAllUpdatedConversationsAndStore] updatedAvatarConvs", updatedAvatarConvs, lastConvsSyncTimestamp);

    if (updatedAvatarConvs.length > 0) {
      updatedAvatarConvs.forEach(c => this.avatarRepo.upgradeAvatar(c));
    }

    let conversations = allConversations.filter(c => !(c.deleted || c.state === "inactive"));
    this.logger.info("[ConversationRepository][processAllUpdatedConversationsAndStore] filtered ", conversations);
    conversations = this.postProcessConversations(conversations || []);

    // save to redux
    // this.logger.info("processUpdatedConversationsAndStore createOrUpdateConversation ", conversations);
    this.store.dispatch(new ConversationNextLoadSuccess({ conversations: conversations }));

    // this.logger.info("[ConversationRepository][processAllUpdatedConversationsAndStore]", conversations.length, this.isAllRoomsMembersSynced);

    // now load rooms members if required and store convs and members to DB
    if (this.isAllRoomsMembersSynced) {
      if (conversations.length > 0) {
        this.databaseService.createOrUpdateConversation(conversations).subscribe();
      }
    } else {
      this.isAllRoomsMembersSynced = true;

      // load all rooms members if required and save along with convs
      // force resync once after update after https://gitlab.vnc.biz/uxf/vnc-portal/-/merge_requests/10885 is merged
      let resyncedAfterUpdate = localStorage.getItem("resyncedAfterMembersOnlyFix");
      let actual_updated_after = !!resyncedAfterUpdate ? lastConvsSyncTimestamp : 0;
      this.getAllRoomsMembers().pipe(take(1)).subscribe(membersOwnerAdmins => {
        this.logger.info("[ConversationRepository][getConversations] getAllRoomsMembers", membersOwnerAdmins);
        this.getUnArchivedConversations().pipe(take(1)).subscribe(c => {
          const unarchviedConvTargets = c.map(a => a.Target);
          const filteredMembersByConvs = membersOwnerAdmins.filter(m => unarchviedConvTargets.indexOf(m.room) > -1);
          // console.log("getAllRoomsMembers filteredMembersByConvs ", filteredMembersByConvs);
          filteredMembersByConvs.forEach(conv => {
            this.store.dispatch(new ConversationUpdate({ target: conv.room, changes: { members: conv.members, owner: conv.owner } }));
          });

        });
        const membersJoint = {};
        const ownersJoint = {};
        const adminsJoint = {};
        const audienceJoint = {};

        //
        const t0 = performance.now();
        membersOwnerAdmins.filter(v => !!v).map(c => {
          ownersJoint[c.room] = c.owner;
          //
          adminsJoint[c.room] = (typeof c.admins === "string") ?  JSON.parse(c.admins) : c.admins;
          audienceJoint[c.room] = (typeof c.audience === "string") ? JSON.parse(c.audience) : c.audience;
          //
          try {
            membersJoint[c.room] = Array.from(new Set(
              [...((typeof c.members === "string") ?  JSON.parse(c.members) : (c.members || [])),
              ...(c.owner ? [c.owner] : []),
              ...(c.admins || [])]));
          } catch (e) {
            this.logger.error("[ConversationRepository][processUpdatedConversationsAndStore] spread error", c);
          }
        });
        // this.logger.info("REDUUUX", {membersJoint, ownersJoint, adminsJoint});
        const membersOnlyConvs = membersOwnerAdmins.filter(v => (!!v && !!v.members_only));

        // const t1 = performance.now();
        // this.logger.info(`[PERFORMANCE] [ConversationRepository][processUpdatedConversationsAndStore] for loop members: took ${t1 - t0} milliseconds.`);

        // save to redux
        this.store.dispatch(new MultiConversationUpdateMembers(membersJoint));
        this.store.dispatch(new MultiConversationUpdateOwner(ownersJoint));
        this.store.dispatch(new MultiConversationUpdateAdmins(adminsJoint));
        this.store.dispatch(new MultiConversationUpdateAudience(audienceJoint));
        membersOnlyConvs.forEach(mr => {
          this.store.dispatch(new ConversationUpdate({ target: mr.room, changes: { members_only: true } }));
        });
        if (actual_updated_after === 0) {
          localStorage.setItem("resyncedAfterMembersOnlyFix", "done");
        }
        const t2 = performance.now();
        // this.logger.info(`[PERFORMANCE] [ConversationRepository][processUpdatedConversationsAndStore] save to redux: took ${t2 - t0} milliseconds.`);

        //
        // save to db
        this.databaseService.createOrUpdateConversation(conversations, membersOwnerAdmins).subscribe();
      }, () => {
        // 404 means empty response
        if (conversations.length > 0) {
          this.databaseService.createOrUpdateConversation(conversations).subscribe();
        }
      });
    }
  }

  private postProcessConversations(conversations: Conversation[]): Conversation[] {
    if (conversations.length === 0) {
      return [];
    }

    this.logger.info("[ConversationRepository][postProcessConversations]", conversations);
    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {
      this.logger.info("[ConversationRepository][postProcessConversations] error restoring deletedConvs", error);
    }

    conversations.forEach(conv => {
      const convTarget = conv.Target;

      // global conf
      if (convTarget === "global") {
        if (conv.mute_sound === 2) {
          this.store.dispatch(new EnableGlobalMute());
        } else {
          this.store.dispatch(new DisableGlobalMute());
        }
        return;
      }

      // remove from deleted on re-join
      if ((deletedConvs.length > 0) && (deletedConvs.indexOf(convTarget) > -1)) {
        let newDeletedConvs = deletedConvs.filter(c => c !== convTarget);
        localStorage.setItem("deletedConvs", JSON.stringify(newDeletedConvs));
      }

      // update grorup chat title
      if (ConversationUtil.isGroupChat(conv)) {
        conv.groupChatTitle = conv.displayName || ConversationUtil.getGroupChatTitle(convTarget);
      }

      // check if avatars updated
      if (conv.lastavatarupdate) {
        this.store.select(state => getConversationById(state, convTarget)).pipe(take(1)).subscribe(prevConvWithAvatar => {
          // an here we compare and update avatar if required
          if (prevConvWithAvatar && conv.lastavatarupdate !== prevConvWithAvatar.lastavatarupdate) {
            // this.logger.info("[ConversationRepository][AvatarRepository][processConversations] upgradeAvatar", convTarget);
            this.avatarRepo.upgradeAvatar(convTarget);
          }
        });
      }

      // replace encrypted message hint to real decrypted text if it's not new
      this.store.select(state => getIsActivatedConversation(state, conv.Target)).pipe(take(1)).subscribe(isAlreadyActived => {
        if (isAlreadyActived) {
          if (conv.content === "Encrypted message"){
            this.store.select(state => getConversationById(state, conv.Target)).pipe(take(1)).subscribe(convInStore => {
              if (conv.Timestamp > convInStore.Timestamp) {
                this.getLastMessageByConversationTarget(conv).pipe(take(1)).subscribe(lastMsg => {
                  if (lastMsg) {
                    this.store.select(state => getMessageById(state, lastMsg.id))
                        .pipe(take(1))
                          .subscribe(message => {
                          this.logger.info("[ConversationRepository][postProcessConversations] override conv last msg1", conv, message);
                          if (message) {
                            conv.content = message.body;
                          }
                    });
                  }
                });
              } else {
                this.logger.info("[ConversationRepository][postProcessConversations] override conv last conv", conv, convInStore);
                conv.content = convInStore.content;
              }
            });
          }
        } else {
          if (conv.content === "Encrypted message"){
            // this.logger.info("[ConversationRepository][postProcessConversations] override unactivated? ", conv);
            this.store.select(state => getConversationById(state, conv.Target)).pipe(take(1)).subscribe(convInStore => {
              // this.logger.info("[ConversationRepository][postProcessConversations] override unactivated? store ", conv, convInStore);
              if (convInStore && !(conv.Timestamp > convInStore.Timestamp)) {
                conv.content = convInStore.content;
              }
            });
          }
        }
      });

      // this.logger.info("[ConversationRepository][postProcessConversations] conv ", conv);
      if (conv.type === "groupchat") {
        if (!conv.last_mention_stamp) {
          conv.last_mention_stamp = 1;
        }
        if (!conv.total_mentions) {
          conv.total_mentions = 0;
        }
      }

        // this.logger.info("[ConversationRepository][postProcessConversations] override2", conv.content);

      // load audience for own broadcasts
      if (conv.broadcast_owner) {
        if (!conv.broadcast_title) {
          conv.broadcast_title = conv.Target.split("@")[0];
        }

        if (!this.userJID) {
          this.logger.warn("[ConversationRepository][postProcessConversations] this.userJID is null", conv);

          this.store.select(getUserJID).pipe(filter(v => !!v), take(1)).subscribe(jid => {
            this.userJID = jid;

            this.setConvAudienceList(conv);
          });
        } else {
          this.setConvAudienceList(conv);
        }
      }

    });

    return conversations;
  }

  // resync

  private isResyncAllConvsRequred(lastConvsSyncTimestamp: number): boolean   {
    const isResyncDone = localStorage.getItem("resyncedConvsAt");
    this.logger.info("[ConversationRepository][isResyncAllConvsRequred] isResyncDone", isResyncDone);

    if (isResyncDone) {
      return false;
    } else {
      this.logger.info("[ConversationRepository][isResyncAllConvsRequred] lastConvsSyncTimestamp", lastConvsSyncTimestamp);
      if (!lastConvsSyncTimestamp) {
        return false; // no need resync on fresh start app
      } else {
        return true;
      }
    }
  }

  //

  private setConvAudienceList(conv: Conversation) {
    const convTarget = conv.Target;

    if (conv.broadcast_owner === this.userJID?.bare) {
      this.logger.info("[ConversationRepository][setConvAudienceList]", conv);

      this.store.select(state => getConversationById(state, convTarget)).pipe(take(1)).subscribe(prevConv => {
        if (!prevConv || !prevConv.audience) {
          this.getAudienceList(convTarget).pipe(take(1)).subscribe(data => {
            if (!!data) {
              data.broadcast_title = data.title;
              this.store.dispatch(new ConversationUpdateBroadcastData({ conversationTarget: conv.Target, changes: data }));
            }
          });
        }
      });
    }
  }

  public uploadFile(url, file, messageId?: string): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders().set("Content-Type", "application/octet-stream");
    if (environment.theme === "hin") {
      headers = new HttpHeaders().set("Content-Type", "application/octet-stream").set("Authorization", localStorage.getItem("token"));
    }
    if (file.type === "image/svg+xml") {
      file.text().then( fileContent => {
        const sanitizedContent = this.conversationService.purify(fileContent);
        const sanitizedFile = new File([sanitizedContent], file.name, {type: file.type});
        return this.http.put(CommonUtil.translateHINFileURL(url), sanitizedFile, {
          headers: headers,
          observe: "events",
          reportProgress: true
        });
      });
    }
    return this.http.put(CommonUtil.translateHINFileURL(url), file, {
      headers: headers,
      observe: "events",
      reportProgress: true
    });
  }

  cancelSendFileRequest(messageId) {
    if (this.sendAttachmentSubscription[messageId] && this.sendAttachmentSubscription[messageId].unsubscribe) {
      this.logger.info("[cancelSendFileRequest]", messageId);
      this.sendAttachmentSubscription[messageId].unsubscribe();
      this.broadcaster.broadcast("fileSent");
      this.sendAttachmentSubscription[messageId] = null;
      this.store.dispatch(new RemoveUploadedFile({ messageId: messageId }));
      this.updateFileInProgress(messageId, true, null, "");
      this.removeMessageFromPending(messageId);
      this.store.dispatch(new MessageDeleteAction(messageId));
      this.databaseService.deleteMessage({id: messageId});
    }
  }

  private getLastConvsSyncTimestamp(): Observable<number> {
    const response = new BehaviorSubject<number>(-1);

    let ts;
    this.getConversations().pipe(take(1)).subscribe(convs => {
      if (convs.length === 0) {
        this.databaseService.getMaxConversationTimestamp().pipe(take(1)).subscribe(maxTs => {
          ts = maxTs || 0;
          this.logger.info("[ConversationRepository][getLastConvsSyncTimestamp] ts1", ts);
          response.next(ts);
        });
      } else {
        ts = Math.max(...convs.map(c => c.updated_at || 0));
        // const maxConv = convs.reduce(function(prev, current) {
        //   return (prev.updated_at > current.updated_at) ? prev : current
        // });
        // ts = maxConv.updated_at;
        // this.logger.info("[ConversationRepository][getLastConvsSyncTimestamp] maxConv", ts, maxConv);

        if (!ts) {
          this.logger.warn("[ConversationRepository][getLastConvsSyncTimestamp] updated_at null");
          ts = Math.max(...convs.map(c => c.Timestamp));
        } else {
          ts = ts * 1000;
        }
        this.logger.info("[ConversationRepository][getLastConvsSyncTimestamp] updated_at", ts);
        response.next(ts);
      }
    });

    return response.asObservable().pipe(filter(ts => ts > -1), take(1));
  }

  /// OMEMO

  saveE2EESetting(conv: Conversation, enableE2EE: boolean, roomConfig?: any, skipSelectConversation?: boolean) {
    // group
    if (conv.type === "groupchat") {
      this.setE2EEForGroupConv(conv, enableE2EE, roomConfig);
    // 1-1
    } else if (conv.type === "chat") {
      this.setE2EEForPrivateConv(conv, enableE2EE).subscribe(res => {
        this.logger.info("[SettingsCompoenent][saveE2EESetting] res", res);
      });
    }

    this.updateConvE2EDataInRedux(conv.Target, enableE2EE, skipSelectConversation);
  }

  updateRetentionTime(conv: Conversation, retention_time: any, fromSignal?:boolean) {
    this.store.dispatch(new UpdateRetentionTime({
      conversationTarget: conv.Target,
      retention_time
    }));
    let updatedConv = conv;
    updatedConv.retention_time = retention_time;
    this.logger.info("[conversationrepoupdateretention] updated ", updatedConv, retention_time);
    this.databaseService.createOrUpdateConversation([updatedConv]).subscribe();
    this.conversationService.setRetention(conv, +retention_time).subscribe();
    const signal = {
      type: "retention-time",
      target: conv.Target,
      data: JSON.stringify({retention_time: retention_time}),
    };
    if (!fromSignal) {
      this.xmppService.sendSignalToMyself(signal);
    }
  }

  updateMembersOnly(conv: Conversation, value: any) {
    this.groupChatsService.updateGroupInfo(conv.Target, {
      members_only: value
    }).subscribe(v => {
      this.logger.info("updateMembersOnly", v);
    });
  }

  updateHidden(conv: Conversation, value: any) {
    this.groupChatsService.updateGroupInfo(conv.Target, {
      hidden: value
    }).subscribe(v => {
      this.logger.info("updateHidden", v);
    });
  }

  private setE2EEForPrivateConv(conv: Conversation, enableE2EE: boolean): Observable<any> {
    this.sendUpdateE2ESignal(enableE2EE, conv);
    return this.conversationService.setE2EEForPrivateConv(conv.Target, enableE2EE);
  }

  private setE2EEForGroupConv(conv: Conversation, enableE2EE: boolean, roomConfig?: any) {
    this.logger.info("[SettingsCompoenent][setE2EEForGroupConv]", conv.Target, enableE2EE, roomConfig);

    if (roomConfig) {
      roomConfig.isE2E = enableE2EE ? 1 : 0;

      for (let key of Object.keys(roomConfig)) {
        // We have room name here so not always true/false
        if (roomConfig[key] === true) {
          roomConfig[key] = 1;
        } else if (roomConfig[key] === false) {
          roomConfig[key] = 0;
        }
      }

      this.configureRoom(conv.Target, roomConfig);
      this.sendUpdateE2ESignal(!!roomConfig.isE2E, conv);
    } else {
      this.getRoomConfig(conv.Target).subscribe(config => {
        this.setE2EEForGroupConv(conv, enableE2EE, config);
      });
    }
  }

  private updateConvE2EDataInRedux(target: string, enableE2EE: boolean, skipSelectConversation?: boolean) {
    this.store.dispatch(new ConversationUpdate({target,  changes: {encrypted: enableE2EE}}));

    // this is to update chat window header
    if (!skipSelectConversation) {
      this.store.dispatch(new ConversationSelect(target));
    }

    // TODO: what about DB?
    //
  }

  omemoSendDataExchangeIntention(chatsNames: string[]) {
    const to = this.userJID?.bare;

    const message: any = { type: "normal", body: "" };

    message["omemoHistoryExchangeSignal"] = {data: {sendChatsHistoryIntention: 1, chatsNames}};

    this.logger.info("[ConversationRepository][omemoSendDataExchangeIntention] message", message);

    this.xmppService.sendMessage(to, message);
  }

  omemoSendCancelDataExchange(to: string) {
    const message: any = { type: "normal", body: "" };

    message["omemoHistoryExchangeSignal"] = {data: {cancelChatsHistoryIntention: 1}};

    this.logger.info("[ConversationRepository][omemoSendCancelDataExchange] message", message);

    this.xmppService.sendMessage(to, message);
  }

  omemoSendAcceptDataExchange(to: string) {
    const message: any = { type: "normal", body: "" };

    message["omemoHistoryExchangeSignal"] = {data: {acceptChatsHistoryIntention: 1}};

    this.logger.info("[ConversationRepository][omemoSendAcceptDataExchange] message", message);

    this.xmppService.sendMessage(to, message);
  }

  omemoSendHistoryOfChats(to: string, chatsJids: string[]): Observable<any> {
    this.logger.info("[ConversationRepository][omemoSendHistoryOfChats]", chatsJids);

    const response = new Subject<any>();

    this.p2pDataChannelService.createConnection((signalingMesageToSend: any) => {
      const message: any = { type: "normal", body: "" };
      message["omemoHistoryExchangeSignal"] = {data: {p2pDataChannelSignaling: signalingMesageToSend}};
      this.logger.info("[ConversationRepository][omemoSendHistoryOfChats] message", message);
      this.xmppService.sendMessage(to, message);

    // on data channel open
    }, () => {
      this.logger.info("[ConversationRepository][omemoSendHistoryOfChats] on DC open");
      this.databaseService.fetchMessagesByTargets(chatsJids).subscribe(messagesByTargets => {
        this.logger.info("[ConversationRepository][omemoSendHistoryOfChats] fetchMessagesByTargets", messagesByTargets);

        this.p2pDataChannelService.sendMessage(messagesByTargets);

        response.next({});
      });
    // on data channel message
    }, () => {});

    return response.asObservable();
  }

  omemoSendHistoryProcessP2PSignaling(to: string, data: any) {
    if (data.offer) {
      this.p2pDataChannelService.acceptConnection(data.offer.sdp, (signalingMesageToSend: any) => {
        const message: any = { type: "normal", body: "" };
        message["omemoHistoryExchangeSignal"] = {data: {p2pDataChannelSignaling: signalingMesageToSend}};
        this.logger.info("[ConversationRepository][omemoSendHistoryProcessP2PSignaling] message", message);
        this.xmppService.sendMessage(to, message);

      // on data channel open
      }, () => {
        this.logger.info("[ConversationRepository][omemoSendHistoryProcessP2PSignaling] on DC open");
      // on data channel message
      }, (dataChannelMessage: any) => {
        this.logger.info("[ConversationRepository][omemoSendHistoryProcessP2PSignaling] onmessage", dataChannelMessage);

        // save to DB and redux
        for (const conversationTarget of Object.keys(dataChannelMessage)) {
          this.store.dispatch(new MessageBulkAppendMultiConversation([{
            conversationTarget: conversationTarget,
            messages: dataChannelMessage[conversationTarget]
          }]));
          this.databaseService.createOrUpdateMessages(dataChannelMessage[conversationTarget], conversationTarget);
        }

        // report success
        this.broadcaster.broadcast("onOMEMOChatsTransferSuccess");
      });
    } else if (data.answer) {
      this.p2pDataChannelService.setRemoteDescription(data.answer.sdp);
    } else if (data.ice) {
      this.p2pDataChannelService.gotIceCandidate(data.ice);
    }
  }

  ///

  public insertPreviousMessage(conv: Conversation, message: Message): Observable<Conversation> {
    return this.conversationService.insertPreviousMessage(conv, message);
  }

  public getAudienceList(broadcastId: string): Observable<any> {
    return this.conversationService.getAudienceList(broadcastId);
  }

  public createFileCopy(data: any): Observable<any> {
    return this.conversationService.createFileCopy(data);
  }


  //// Mark Conv as Read
  ////

  public markConversationsRead(conv: Conversation, timestamp = new Date().getTime()) {
    this.logger.info("[ConversationRepository][markConversationsRead]", conv.Target, conv.unreadIds && conv.unreadIds.length, this.pendingReadRequest[conv.Target]);

    this.resetUnreadCount(conv.Target);
    this.xmppService.sendMarkReadSignalToSelf(conv.Target);

    if ((this.pendingReadRequest[conv.Target]) || ((conv.unreadIds && conv.unreadIds.length === 0) && (this.shouldMark !== true))) {
      return;
    }
    this.pendingReadRequest[conv.Target] = timestamp;
    this.shouldMark = false;

    this.sendMarkAsReadRequests();
  }

  public resetUnreadCount(convTarget: string) {
    if (!convTarget) {
      return;
    }

    // this.logger.info("[ConversationRepository][resetUnreadCount]", convTarget);
    if (convTarget === this.userJID?.bare) {
      // this.logger.info("[ConversationRepository][resetUnreadCount] skip", convTarget);
      return;
    }

    this.getConversationById(convTarget).pipe(take(1)).subscribe(conversation => {
      const unreadCounts = conversation?.unreadIds?.length || 0;
      if (unreadCounts > 0) {
        // this.logger.info("[ConversationRepository][resetUnreadCount] DO", convTarget, unreadCounts);
        this.store.dispatch(new ConversationResetUnread(convTarget));
        this.databaseService.createOrUpdateConversation([{ ...conversation, unreadIds: [], unread_mentions: [] }]).subscribe();
      }
    });
  }

  private setUnreadCount(convTarget: string, unreadIds: any, unread_mentions?: any, last_mention_stamp?: any, total_mentions?: any) {
    this.logger.info("[ConversationRepository][setUnreadCount]", {convTarget, unreadIds});

    const changes: any = {
      unreadIds
    };
    if (unread_mentions) {
      changes.unread_mentions = unread_mentions;
    }
    if (last_mention_stamp) {
      changes.last_mention_stamp = last_mention_stamp;
    }
    if (total_mentions) {
      changes.total_mentions = total_mentions;
    }

    this.store.dispatch(new ConversationUpdate({ target: convTarget, changes}));

    this.getConversationById(convTarget).pipe(take(1)).subscribe(conversation => {
      this.databaseService.createOrUpdateConversation([{ ...conversation, ...changes }]).subscribe();
    });
  }

  private sendMarkAsReadRequests() {
    this.logger.info("[ConversationRepository][sendMarkAsReadRequests]", this.pendingReadRequest, this.isAppOnline);
    let now = new Date().getTime();
    if (this.isAppOnline && (Object.keys(this.pendingReadRequest).length > 0) && ((now - this.lastMarkedAsRead) > 3000)) {

      this.conversationService
        .markBulkConversationsRead(this.pendingReadRequest)
        .subscribe(() => {
          this.lastMarkedAsRead = now;
          this.lockMarkAsRead = false;
          this.pendingReadRequest = {};
          this.storePendingRequest.next(true);
        }, (err) => {
          let unAuthState = (!!err.status && (err.status === 401 || err.status === 403)) ? true : false;
          if (!unAuthState || environment.isElectron || environment.isCordova) {
            this.logger.info("[ConversationRepository][sendMarkAsReadRequests] Error", err);
          } else {
            this.configService.clearStorage();
            this.configService.redirectToLoginScreen();
          }
         });

    } else {
      if (this.isAppOnline && (Object.keys(this.pendingReadRequest).length > 0) && !this.lockMarkAsRead) {
        this.lockMarkAsRead = true;
        setTimeout(() => {
          if (Object.keys(this.pendingReadRequest).length > 0) {
            this.conversationService
              .markBulkConversationsRead(this.pendingReadRequest)
              .subscribe(() => {
                this.lastMarkedAsRead = new Date().getTime();
                this.lockMarkAsRead = false;
                this.pendingReadRequest = {};
                this.storePendingRequest.next(true);
              }, () => { });
          }
          this.storePendingRequest.next(true);
        }, 4000);
      } else {
        this.storePendingRequest.next(true);
      }
    }
  }

  markConversationAsRead(target: string) {
    this.logger.info("[ConversationRepository][markConversationAsRead]", target);

    this.store.select(getIsWindowFocused).pipe(take(1)).subscribe(focused => {
      if (!focused) {
        return;
      }
      this.store.select(getIsAppOnline).pipe(filter(v => !!v), take(1)).subscribe(() => {
        this.resetUnreadCount(target);
        this.xmppService.sendMarkReadSignalToSelf(target);
        this.conversationService.markConversationsRead(target).subscribe(() => {
        }, () => {

        });
      });
    });
  }

  ////


  markPadsRead(convTarget) {
    this.pendingPadReadRequest[convTarget] = new Date().getTime();
    this.logger.info("[ConversationRepository][markPadsRead]", this.pendingPadReadRequest, this.isAppOnline);
    if (this.isAppOnline) {
      for (let target in this.pendingPadReadRequest) {
        this.conversationService
          .markPadsRead(target)
          .subscribe(() => {
            delete this.pendingPadReadRequest[target];
            const padReadTime = new Date().getTime();
            let tenDigitTime  = String(padReadTime / 1000);
            this.store.dispatch(new ConversationUpdate({target, changes: {pad_unread: padReadTime}}));
            this.store.dispatch(new ConversationUpdate({target, changes: {pad_read: parseInt(tenDigitTime)}}));
            this.removePadNotification(target);
            this.storePendingRequest.next(true);
          }, () => {
          });
      }
    } else {
      this.storePendingRequest.next(true);
    }
  }

  public closeNotifications(convTargets: string[]) {
    // clean notifications from notification bar
    if (environment.isCordova && CommonUtil.isOnAndroid() && !window.appInBackground) {
      setTimeout(() => {
        this.appService.removeLocalNotificationsForTarget(convTargets).subscribe();
      }, 300);
    }
  }

  private removeAllNotificationsExceptForTargets(convTargets: string[]) {
    // this.logger.info("[ConversationRepository][removeAllNotificationsExceptForTargets]", convTargets.length);

    if (environment.isCordova && CommonUtil.isOnAndroid() && !window.appInBackground) {
      setTimeout(() => {
        this.appService.removeAllNotificationsExceptForTargets(convTargets).subscribe();
      }, 300);
    }
  }

  public deleteConversation(conv: Conversation): Observable<any> {
    this.logger.info("[ConversationRepository][deleteConversation]", conv.Target);
    let target: string = conv.Target;
    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {

    }
    this.logger.info("[ConversationRepository][deleteConversation] deletedConvs", deletedConvs);
    if ((deletedConvs.length > 0) && (deletedConvs.indexOf(target) > -1)) {
      this.logger.info("[ConversationRepository][deleteConversation] already left, so skip leaving ", target);
    } else {
      this.logger.info("[ConversationRepository][deleteConversation] add to deletedConvs: ", target);
      this.markConversationAsRead(target);

      if (conv.type === "groupchat") {
        this.leaveConversation(conv, "leave");
      }
      deletedConvs.push(target);
      localStorage.setItem("deletedConvs", JSON.stringify(deletedConvs));
    }
    this.newNotificationService.openSnackBarWithTranslation("CHAT_REMOVED_FROM_RECENT");
    return this.conversationService.deleteConversation(conv.Target).pipe(map(res => {
      this.deleteConversationLocally(conv);
      return res;
    }));

    // setTimeout(() => {
    //   // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 6");
    //   this.loadAllUpdatedConversations();
    // }, 300);
  }

  public deleteConversationByTarget(target: string): Observable<any> {
    this.logger.info("[ConversationRepository][deleteConversationByTarget]", target);

    this.markConversationAsRead(target);
    //
    return this.conversationService.deleteConversation(target).pipe(map(res => {
      this.deleteLocalConversationByTarget(target);
      return res;
    }));
  }

  private multiaddToDeletedConvs(convs: string[]) {
    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {

    }
    if (convs.length > 0) {
      convs.forEach(c => {
        if (deletedConvs.indexOf(c) === -1) {
          deletedConvs.push(c);
          localStorage.setItem("deletedConvs", JSON.stringify(deletedConvs));
        }
      });
    }
  }

  private addToDeletedConvs(conv: Conversation) {
    let target: string = conv.Target;
    let deletedConvs = [];
    try {
      deletedConvs = !!JSON.parse(localStorage.getItem("deletedConvs")) ? JSON.parse(localStorage.getItem("deletedConvs")) : [];
    } catch (error) {

    }
    this.logger.info("[ConversationRepository][addToDeletedConvs] deletedConvs", deletedConvs);
    if ((deletedConvs.length > 0) && (deletedConvs.indexOf(target) > -1)) {
      this.logger.info("[ConversationRepository][addToDeletedConvs] already left, so skip leaving ", target);
    } else {
      this.logger.info("[ConversationRepository][addToDeletedConvs] add to deletedConvs: ", target);

      if (conv.type === "groupchat") {
        deletedConvs.push(target);
        localStorage.setItem("deletedConvs", JSON.stringify(deletedConvs));
      }
    }

  }

  private deleteConversationLocally(conv: Conversation) {
    this.logger.info("[ConversationRepository][deleteConversationLocally]", conv.Target);

    this.deleteLocalConversationByTarget(conv.Target);
  }

  private deleteLocalConversationByTarget(target: string) {
    this.logger.info("[ConversationRepository][deleteLocalConversationByTarget]", this.currentConv, target);
    if (this.currentConv && this.currentConv.Target === target) {
      this.logger.info("[ConversationRepository][deleteConversationLocally][navigateToConversation] null", target);
      this.processLeaveConversationUI();
    }
    this.broadcaster.broadcast("deleteConversation", target);
    if (target.indexOf("@conference.") !== -1) {
      this.unregisterMemberList(target);
      this.xmppService.leave(target, "delete");
      // this.removeRoomFromJoinedList(conv.Target);
    }

    this.store.dispatch(new ConversationInActive({ target: target }));
    this.store.dispatch(new ConversationDelete(target));
    // delete in DB & redux
    setTimeout(() => {
      this.databaseService.deleteConversation(target).subscribe();
    }, 500);
    this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!!conv && target == conv.Target) {
        if (CommonUtil.isOnMobileDevice()) {
          this.navigateToConversation(ConstantsUtil.ALL_TARGET);
        } else {
          this.navigateToConversation(null);
        }
      }
    });

  }

  private sendLeaveConv(conv: Conversation) {
    const group_action = { type: GroupAction.LEAVE_GROUP };
    let body = `left the group`;
    return this.sendMessage({ type: "groupchat", group_action, body }, conv.Target, "groupchat");
  }

  private leaveConversation(conv: Conversation, eventType?: string) {
    this.logger.info("[ConversationRepository][leaveConversation] ssaleave", conv.Target, this.currentConv?.Target, eventType, this.userJID);

    if (conv.type === "groupchat") {
      this.sendLeaveConv(conv);
      this.groupChatsService.leaveConversation(this.userJID?.bare, conv.Target).pipe(take(1)).subscribe(() => {
        this.logger.info("[ConversationRepository][leaveConversation] removed affiliation in vncd");
      });
    }

    this.getSelectedConversation().pipe(take(1)).subscribe(selectedConv => {
      if (selectedConv && conv.Target === selectedConv.Target) {
        if (CommonUtil.isOnMobileDevice()) {
          this.navigateToConversation(ConstantsUtil.ALL_TARGET);
        } else {
          this.navigateToConversation(null);
        }
      }
    });

    this.store.select(getActiveConference).pipe(take(1)).subscribe(activeTarget => {
      if (conv.Target === activeTarget) {
        this.broadcaster.broadcast("turnOffVideo", {});
      }
    });

    this.joinedConversations = this.joinedConversations.filter(v => v !== conv.Target);
    this.xmppService.leave(conv.Target, "leave");
    this.leftRoomList.push(conv.Target);
    this.unregisterMemberList(conv.Target);
    this.store.dispatch(new ConversationDelete(conv.Target));
    this.databaseService.deleteConversation(conv.Target).subscribe();

    this.conversationService.createInactiveConversation(conv).subscribe(() => {
      this.removeRoomFromJoinedList(conv.Target);
    });

  }

  private removeRoomFromJoinedList(conversationTarget: string) {
    this.logger.info("[ConversationRepository][removeRoomFromJoinedList]", conversationTarget);

    this.joinedConversations = this.joinedConversations.filter(t => t !== conversationTarget);
    if (this.currentConv && this.currentConv.Target === conversationTarget) {
      this.processLeaveConversationUI();
    }
  }

  handlePushNotification(notification?: any): void {
    console.log("[ConversatinRepository][handlePushNotification] start ", notification, !notification);
    let vncPeerJid: string;
    let vncEventType: string;
    let initialNotificationEmpty = false;
    let vncCallkitNotification = {};

    if (notification && notification.vncPeerJid && notification.vncPeerJid) {
      vncPeerJid = notification.vncPeerJid;
      vncEventType = notification.vncEventType;
    } else {
      vncPeerJid = localStorage.getItem("vncPeerJid");
      vncEventType = localStorage.getItem("vncEventType");
      try {
        vncCallkitNotification = JSON.parse(localStorage.getItem("vncCallkitNotification"));
        localStorage.removeItem("vncCallkitNotification");
      } catch (error) {
        this.logger.info("[ConversatinRepository][handlePushNotification4] unable to parse data", error);
      }
    }

    if (!notification) {
      initialNotificationEmpty = true;
    }

    if (vncPeerJid !== null && vncPeerJid !== undefined && vncEventType !== null && vncEventType !== undefined) {
      this.logger.info("[ConversatinRepository][handlePushNotification]", vncPeerJid, vncEventType, notification);

      this.broadcaster.broadcast("hideAboutDialog");
      this.broadcaster.broadcast("hideHelpDialog");
      this.broadcaster.broadcast("hideServiceDesk");
      this.logger.info("[ConversatinRepository][handlePushNotification] broadcast ConstantsUtil.TOGGLE_SIDEBAR ", notification);
      this.broadcaster.broadcast(ConstantsUtil.CLOSE_SIDEBAR);
      this.broadcaster.broadcast("hideAllSettings");
      this.broadcaster.broadcast("closeDialog");
      this.store.dispatch(new SetActiveTab("chat"));
      this.navigateToTarget(vncPeerJid).subscribe(err => {
        if (!!err) {
          this.logger.info("[ConversatinRepository][handlePushNotification] error ", err);
        }
        if (!err) {
          // join call
          this.logger.info("[ConversatinRepository][handlePushNotification2]", vncPeerJid, vncEventType, initialNotificationEmpty, notification);
          if (initialNotificationEmpty) {
            const data2: any = {

                conversationTarget: vncPeerJid,
                conferenceType: vncEventType
              };
            const jitsiRoom2 = vncCallkitNotification["extra_jitsi_room"];
            const jitsiURL2 = vncCallkitNotification["extra_jitsi_url"];
            if (vncEventType === "screen") {
              vncEventType = "screen-receive";
            }
            if (jitsiRoom2) {
              data2.jitsiRoom = jitsiRoom2;
            }
            if (jitsiURL2) {
              data2.jitsiURL = jitsiURL2;
            }
            data2.extraCallAction = "TalkCallAccept";

            this.logger.info("[ConversatinRepository][handlePushNotification]TalkCallAccept -> initianNotificationEmpty ", initialNotificationEmpty);
            this.broadcaster.broadcast("HIDEINCOMINGCALLNOTIFICATION", { target: vncPeerJid, cancelCallkitCall: false});
            // this.notificationsService.hideIncomingCallNotification(vncPeerJid, false);
            this.broadcaster.broadcast("joinConferenceViaNotification", data2);
          }

          if (vncEventType === "audio" || vncEventType === "video"  || vncEventType === "screen") {
            const vncInitiatorJid = notification.vncInitiatorJid;
            let extraCallAction = notification.extra_call_action;
            if (!!localStorage.getItem("extraCallAction")) {
              extraCallAction = localStorage.getItem("extraCallAction");
              localStorage.removeItem("extraCallAction");
            }
            const jitsiRoom = notification.extra_jitsi_room;
            const jitsiURL = notification.extra_jitsi_url;
            if (vncEventType === "screen") {
              vncEventType = "screen-receive";
            }
            const data: any = {conversationTarget: vncPeerJid,
                            conferenceType: vncEventType};
            if (jitsiRoom) {
              data.jitsiRoom = jitsiRoom;
            }
            if (jitsiURL) {
              data.jitsiURL = jitsiURL;
            }
            if (vncInitiatorJid) {
              data.initiatorJid = vncInitiatorJid;
            }
            if (extraCallAction) {
              data.extraCallAction = extraCallAction; // "TalkCallAccept"
              if (extraCallAction === "TalkCallAccept") {
                this.logger.info("[ConversatinRepository][handlePushNotification]TalkCallAccept -> getting activeConf ", vncPeerJid, extraCallAction);
                this.store.select(getActiveConference).pipe(take(1)).subscribe(activeTarget => {
                  this.logger.info("[ConversatinRepository][handlePushNotification]TalkCallAccept -> hideIncomingCallNotification ", activeTarget, vncPeerJid);
                  if (!activeTarget ||  (activeTarget === vncPeerJid)) {
                    this.broadcaster.broadcast("HIDEINCOMINGCALLNOTIFICATION", { target: vncPeerJid, cancelCallkitCall: false });
                  } else {
                    this.broadcaster.broadcast("HIDEINCOMINGCALLNOTIFICATION", { target: vncPeerJid, cancelCallkitCall: true });
                  }
                });
              }
            }
//            if (!CommonUtil.isOnAndroid()) {
            this.logger.info("[ConversatinRepository][handlePushNotification] about to broadcast joinConferenceViaNotification: ", data);
              this.broadcaster.broadcast("joinConferenceViaNotification", data);
//            } else {
              this.logger.info("[ConversatinRepository][handlePushNotification] maybeScheduleLocalCallNotification: ", data);
//            }
          }
        }

        if (document.getElementById("mainLayout") !== null) { // To hide header on mobile
          document.getElementById("mainLayout").classList.add("hide-header-mobile");
        }
        localStorage.removeItem("vncPeerJid");
        localStorage.removeItem("vncEventType");

      });
    }
  }

  getBareFromMessage(message: Message): string {
    // this.logger.info("[ConversationRepository][getBareFromMessage]", message);
    if (message.room && !message.from) {
      return message?.to;
    }
    if (message.type !== "groupchat") {
      return message?.from?.bare;
    } else if (message.type === "groupchat" && !CommonUtil.isGroupTarged(message?.from?.resource) && (message?.from?.resource?.indexOf("@") > -1 )) {
      return message?.from?.resource;
    } else {
      if (message.muc && message.muc.jid) {
        if (typeof message.muc.jid === "object") {
          return message.muc.jid.bare;
        } else {
          return message.muc.jid;
        }
      }

      let memberJid;
      this.store.select(state => getConversationMembers(state, message.from.bare)).pipe(take(1)).subscribe(membersJids => {
        memberJid = !!membersJids ? membersJids.find(v => v === message.from.resource) : "";
      });

      if (memberJid) {
        return memberJid;
      } else if (message.from && this.userJID && message?.from?.bare === this.userJID?.bare) {
        return message?.from?.bare;
      } else {
        return message?.from?.resource;
      }
    }
  }

  isMyMessage(message: Message): boolean {
    if (!message.from || message.pendingIn) {
      return true;
    }

    let jid: string = "";
    this.store.select(getBare).pipe(take(1)).subscribe(bare => {
      jid = bare;
    });

    return jid === message.fromJid;
  }

  public addMessageFavorite(messageId: string): Observable<null> {
    return this.conversationService.addMessageFavorite(messageId);
  }

  public removeMessageFavorite(messageId: string): Observable<null> {
    return this.conversationService.removeMessageFavorite(messageId);
  }

  public getMessageTags(messageId: string): Observable<string[] | null> {
    return this.conversationService.getMessageTags(messageId);
  }

  public getMessageById(messageId: string): Observable<Message> {
    return this.store.select(state => getMessageById(state, messageId));
  }

  archiveGroupchat(conv: Conversation): Observable<boolean> {
    const response = new Subject<boolean>();
    this.groupChatsService.updateGroupInfo(conv.Target, {
      status: "archived"
    }).subscribe(v => {
      this.sendGroupArchivedSignal(conv.groupChatTitle, conv.Target);
      this.archiveConversation(conv);
    });

    return response.asObservable();
  }

  unArchiveGroupchat(conv: Conversation): Observable<boolean> {
    const response = new Subject<boolean>();
    this.groupChatsService.updateGroupInfo(conv.Target, {
      status: "active"
    }).subscribe(v => {
      this.sendGroupUnarchivedSignal(conv.groupChatTitle, conv.Target);
      this.unArchiveConversation(conv);
    });

    return response.asObservable();
  }

  unarchiveConversationByTarget(target: string) {
    this.getConversationById(target).pipe(take(1)).subscribe(conv => {
      this.unArchiveConversation(conv);
    });
  }

  archiveConversation(conversation: Conversation): Observable<boolean> {
    const response = new Subject<boolean>();
    if (!this.isAppOnline) {
      return of(null);
    }
    this.conversationService.archiveConversation(conversation).subscribe((conv) => {
      response.next(true);
      this.store.dispatch(new ArchiveConversation({
        target: conversation.Target,
        timestamp: conv.Timestamp,
      }));

      // sync with other client
      const signal = {
        type: "conv-archive",
        target: conversation.Target
      };
      this.xmppService.sendSignalToMyself(signal);

      this.databaseService.createOrUpdateConversation([{ ...conversation, archived: true }]).subscribe();
      if (this.currentConv && conversation.Target === this.currentConv.Target) {
        this.deselectCurrentConversation();
      }
    });

    return response.asObservable();
  }

  unDoFrvourite$ = new Subject<boolean>();
  lastConversation: any;
  toggleConversationFavorite(conversation: any, flag?: boolean, fromSignal = false): Observable<boolean> {
    const response = new Subject<boolean>();
    if (!this.isAppOnline) {
      return of(null);
    }
    this.store.dispatch(new UpdateConversationFavFlag({
      target: conversation.Target,
      flag: flag,
    }));
    if (fromSignal) {
      this.databaseService.createOrUpdateConversation([{ ...conversation, isfav: flag }]).subscribe();
      this.broadcaster.broadcast("toggleConversationFavorite", conversation.Target);
      this.broadcaster.broadcast("signalToggleConversationFavorite", { target: conversation.Target, flag: flag});
      response.next(true);
    } else {
      this.conversationService.toggleConversationFavorite(conversation, flag).subscribe((conv) => {
        this.logger.info("[toggleConversationFavorite]", conv);
        this.databaseService.createOrUpdateConversation([{ ...conversation, isfav: flag }]).subscribe();
        if (!flag) {
          this.broadcaster.broadcast("toggleConversationFavorite", conversation.Target);
        }
        if (flag) {
            this.notificationService.openSnackBarWithTranslation("ADDED_TO_FAVORITES");
        } else {
            this.hitUndoFav = true;
            this.toastService.showSnackbar("REMOVED_FROM_FAVORITES", 2000, this.undoFavourite.bind(this), "UNDO");

        }
        response.next(true);
      });
    }

    return response.asObservable();
  }

  hitUndoFav:boolean = false;
  undoFavourite() {
    if (this.hitUndoFav) {
      this.unDoFrvourite$.next(this.hitUndoFav);
      this.hitUndoFav = false;
    }
  }
  deleteContactFromGroup(conversation: any, flag?: boolean, fromSignal = false): Observable<boolean> {
    const response = new Subject<boolean>();
    if (!this.isAppOnline) {
      return of(null);
    }
    this.store.dispatch(new UpdateConversationFavFlag({
      target: conversation.Target,
      flag: flag,
    }));
    if (fromSignal) {
      this.databaseService.createOrUpdateConversation([{ ...conversation, isfav: flag }]).subscribe();
      this.broadcaster.broadcast("toggleConversationFavorite", conversation.Target);
      this.broadcaster.broadcast("signalToggleConversationFavorite", { target: conversation.Target, flag: flag});
      response.next(true);
    } else {
      this.conversationService.toggleConversationFavorite(conversation, flag).subscribe((conv) => {
        this.logger.info("[toggleConversationFavorite]", conv);
        this.databaseService.createOrUpdateConversation([{ ...conversation, isfav: flag }]).subscribe();
        if (!flag) {
          this.broadcaster.broadcast("toggleConversationFavorite", conversation.Target);
        }
        response.next(true);
      });
    }

    return response.asObservable();
  }

  togglePinConversation(conversation: Conversation, flag?: boolean, fromSignal = false): Observable<boolean> {
    const response = new Subject<boolean>();
    if (!this.isAppOnline) {
      return of(null);
    }
    this.store.dispatch(new UpdateConversationPinFlag({
      target: conversation.Target,
      flag: flag,
    }));

    if (fromSignal) {
      this.databaseService.createOrUpdateConversation([{ ...conversation, ispinned: flag }]).subscribe();
      this.broadcaster.broadcast("togglePinConversation", conversation.Target);
      response.next(flag);
    } else {
      this.conversationService.togglePinConversation(conversation, flag).subscribe((conv) => {
        this.logger.info("[togglePinConversation]", conv);

        this.databaseService.createOrUpdateConversation([{ ...conversation, ispinned: flag }]).subscribe();
        if (!flag) {
          this.broadcaster.broadcast("togglePinConversation", conversation.Target);
        }
        if (flag) {
          this.notificationService.openSnackBarWithTranslation("PINNED_CHAT");
        } else {
          this.notificationService.openSnackBarWithTranslation("UNPINNED_CHAT");
        }

        response.next(flag);
      });
    }

    return response.asObservable();
  }

  unArchiveConversation(conversation: Conversation, lastConvoTimestamp?: number): Observable<boolean> {
    let isArchived :any = false;
    isArchived = conversation.archived;
    console.log("[ConversationRepository][unArchiveConversation] XMPP data", conversation, conversation.Target, lastConvoTimestamp);
    const response = new Subject<boolean>();
    if (!this.isAppOnline) {
      return of(null);
    }
    conversation.archived = false;
    if (lastConvoTimestamp) {
      lastConvoTimestamp += 1000;
    }
    this.conversationService.unArchiveConversation(conversation, lastConvoTimestamp ).subscribe((conv) => {
      response.next(true);

      // sync with other client
      const signal = {
        type: "conv-unarchive",
        target: conversation.Target
      };
      this.xmppService.sendSignalToMyself(signal);

      this.store.dispatch(new UnArchiveConversation({
        target: conversation.Target,
        timestamp: conv.Timestamp + 1000,
      }));
      this.databaseService.createOrUpdateConversation([{ ...conversation, archived: false }]).subscribe();
      if (isArchived || isArchived === true) {
        this.notificationService.openSnackBarWithTranslation("CHAT_UNARCHIVED");
      }
    });

    return response.asObservable();
  }

  private sendFileNotification(target: string, fileType: string): void {
    let type = "FILE";
    if (CommonUtil.isImage(fileType)) {
      type = "IMAGE";
    }
    if (CommonUtil.isAudio(fileType)) {
      type = "VOICE";
    }

    const timestamp = this.datetimeService.getCorrectedLocalTime();
    const msg: Message = {
      id: CommonUtil.randomId(10),
      type: "chat",
      body: "",
      from: this.userJID,
      to: target,
      timestamp,
      startFile: {
        type: type
      }
    };

    this.logger.info("[ConversationRepository][sendFileNotification]", msg);
    this.xmppService.sendMessage(target, msg);
  }

  addMessageToPendings(message: Message) {
    this.logger.info("[ConversationRepository][addMessageToPendings] ", message);
    if (!this.pendingMessages.find(v => v.id === message.id )) {
      this.pendingMessages.push(message);

      if (message.attachment) {
      const fType = message.attachment?.fileType || message.attachment?.fileName.split(".").pop().toLowerCase();
      if (!!message.file && CommonUtil.isImage(fType)) {
        this.logger.info("[ConversationRepository][addMessageToPendings] with attachmentto db ", !!message.file, message);
        const reader = new FileReader();
        reader.readAsDataURL(message.file);
        reader.onload = async () => {
          const b64url = reader.result;
          const msgWithAttToStore = {
            id: message.id,
            message: message,
            b64attachment: b64url
          };
          this.databaseService.storeAttachment(msgWithAttToStore).pipe(take(1)).subscribe(() => {
            this.logger.info("[ConversationRepository][addMessageToPendings] result with attachment ", msgWithAttToStore);
          }, err => {
            this.logger.error("[ConversationRepository][addMessageToPendings] result with attachment ", err);
          });

        };
        }
      }
      this.sendPendingMessages();
      localStorage.setItem("pendingMessages", JSON.stringify(this.pendingMessages));
    }
  }

  sendPendingMessages() {
    this.logger.info("[ConversationRepository][XMPPService][sendPendingMessages]", this.isAppOnline, this.isConnectedXMPP, this.pendingMessages);
    // this.logger.info("[ConversationRepository][XMPPService][sendPendingMessages1]", this.isAppOnline, this.isConnectedXMPP, this.pendingMessages.sort((a, b) => b.sendingAt - a.sendingAt));

    if (!this.isAppOnline || !this.isConnectedXMPP || this.pendingMessages.length === 0) {
      return;
    }

    let convTargets: string[];
    this.store.select(getConversationIds).pipe(take(1)).subscribe(ids => convTargets = ids as string[]);
    const messages = this.pendingMessages;
    let processingAt = Date.now();
    messages.forEach((msg: Message) => {
      let timeOutVal = 0;
      this.logger.info("[ConversationRepository][XMPPService][sendPendingMessages] msg.pendingIn: ", msg.pendingIn, msg);
      // Attachment
      if (msg.file) {
        this.logger.info("[ConversationRepository][sendPendingMessages]check uploadFileInProgress", msg.uploadFileInProgress, msg.file, msg.id);
        if (msg.uploadFileInProgress) {
          this.logger.info("[ConversationRepository][sendPendingMessages] skip uploading file again, already in progress");

          // Already uploading, so do nothing.
          // this could happen if we quickly send another text messagss while file uploadinf is in prog.
          // This is because we remove a message from pedning queue only after sent an xmpp message with file metadata.
          return;
        }

        // send 'User sending attachment' notification
        if (msg.type === "chat") {
          this.sendFileNotification(msg.pendingIn, msg.attachment.fileType);
        }

        this.aboutToSendAttachment(msg, convTargets);

      // Text
      } else {
        if (!!msg.sendingAt && ((processingAt - msg.sendingAt) > 30000)) {
          timeOutVal += 1000;
          delete msg.sendingAt;
        }
        let isJoiningGroupToSend = false;
        this.logger.info("[ConversationRepository][XMPPService][sendPendingMessages] sending now - msg.pendingIn: ", msg.pendingIn, msg);
        if (CommonUtil.isGroupTarged(msg.pendingIn)) {
          const joinedXmppRooms = this.xmppService.getJoinedRooms();
          if (joinedXmppRooms.indexOf(msg.pendingIn) > -1) {
            this.sendMessageToXmpp(msg);
          } else {
            this.joinRoomIfNotJoined(msg.pendingIn);
            setTimeout(() => {
              timeOutVal += 2000;
              this.sendMessageToXmpp(msg);
            }, 2000);
          }
        } else {
          this.sendMessageToXmpp(msg);
        }
        /*        if (msg.replace) {
          this.sendMessageToXmpp(msg);
        } else if (convTargets.includes(msg.pendingIn)) {
          this.sendMessageToXmpp(msg);
        } else {
          this.store.dispatch(new MessageDeleteAction(msg.id));
          this.databaseService.deleteMessage(msg).subscribe();
        } */
      }
      if (timeOutVal > 0) {
        setTimeout(() => {
          // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 7");
          this.loadAllUpdatedConversations(Math.floor(processingAt / 1000 ) - 4000);
        }, timeOutVal);
      }
    });

    localStorage.setItem("pendingMessages", JSON.stringify(this.pendingMessages)); // set again to have new sendingAt value in localStorage
  }

  deleteMessageFromStoreAndDB(msg) {
    this.logger.info("[ConversationRepository][deleteMessageFromStoreAndDB] ", msg.id, msg);
    this.store.dispatch(new MessageDeleteAction(msg.id));
    this.databaseService.deleteMessage(msg).subscribe();
  }

  private aboutToSendAttachment(msg: Message, convTargets: string[]) {
    this.logger.info("[ConversationRepository][aboutToSendAttachment]", msg);

    this.updateFileInProgress(msg.id, true);

    // mobile
    // if (environment.isCordova) {
      // when restore file from local storage, e.g. after app kill then it's empty {}
      // so we need to read file from disk in this case
      if (!msg.file.size) {
        // mark as failed
        this.databaseService.fetchPendingAttachmentById(msg.id).pipe(take(1)).subscribe(attachmentMessage => {
          this.logger.info("aboutToSendAttachment attachmentMessages: ", attachmentMessage, msg);
          if (Object.keys(attachmentMessage).length > 0) {
            let attachMentString = attachmentMessage.b64attachment;
            const byteString = atob(attachMentString.split(",")[1]);
            let n = byteString.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = byteString.charCodeAt(n);
            }

            msg.file = new Blob([u8arr]);
            this.logger.info("aboutToSendAttachment attachmentMessage newBlob: ", msg);
            this.uploadAttachmentAndSend(msg, convTargets);
          } else {
            this.logger.error("[ConversationRepository][aboutToSendAttachment] attachments upload failed2", msg);

            msg.status = MessageStatus.FAILED;

            // load to redux & update in DB
            this.store.dispatch(new MessageAdd({ conversationTarget: msg.pendingIn, message: msg }));
            this.databaseService.createOrUpdateMessages([{ ...msg, status: MessageStatus.FAILED }], msg.pendingIn);

            this.removeMessageFromPending(msg.id);
          }
        });
      } else {
        this.uploadAttachmentAndSend(msg, convTargets);
      }

    // Web - normal upload
    /*
    } else {
      this.uploadAttachmentAndSend(msg, convTargets);
    }
    */
  }

  updateFileInProgress(msgId: string, value: boolean, newAttachment?: any, newBody?: string): void {
    this.getMessageById(msgId).pipe(take(1)).subscribe(msg => {
      // this.logger.info("[ConversationRepository][updateFileInProgress] current", { msgId, msg });
      this.logger.info("[ConversationRepository][updateFileInProgress]", {msgId, value}, newAttachment);

      this.pendingMessages.filter(v => v.id === msgId).forEach(m => {
        m.uploadFileInProgress = value;
      });

      const changes: any = { uploadFileInProgress: value };
      if (newAttachment) {
        // this.logger.info("[ConversationRepository][updateFileInProgress] newAttachment", newAttachment.recodingProgress, msg.attachment.recodingProgress );
        if (newAttachment.recodingProgress >= msg.attachment.recodingProgress) {
          changes.attachment = newAttachment;
        }
      }
      if (newBody) {
        changes.body = newBody;
      }
      this.store.dispatch(new MessageUpdateAction({ id: msgId, changes }));
    });

  }

  private notifyRecodingProgress(msg: Message, progress: number, convTargets: string[]) {
    let newAttachment;
    let newBody;
    if (progress < 100) {
      newAttachment = {
        url: msg.attachment.url,
        fileSize: msg.attachment.fileSize,
        fileName: msg.attachment.fileName,
        fileType: msg.attachment.fileType,
        recodingRequired: true,
        recodingProgress: progress
      };
      newBody = msg.body;
      this.updateFileInProgress(msg.id, false, newAttachment, newBody);
    } else {

      msg.file = null;
      let newUrl;
      if (msg.attachment.url.indexOf("?") > -1) {
        newUrl = msg.attachment.url.split("?")[0];
      } else {
        newUrl = msg.attachment.url;
      }
      newBody = newUrl;
      newAttachment = {
        url: newUrl,
        fileSize: msg.attachment.fileSize,
        fileName: msg.attachment.fileName,
        fileType: msg.attachment.fileType,
        recodingRequired: false,
        recodingProgress: progress
      };

      // update in redux
      this.updateFileInProgress(msg.id, false, newAttachment, newBody);
      this.store.dispatch(new RemoveUploadedFile({ messageId: msg.id }));


      if (convTargets.includes(msg.pendingIn)) {
        setTimeout(() => {
          this.broadcaster.broadcast("fileRecoded", msg);
        }, 50);

        const msgToSend = {...msg, body: newBody, attachment: newAttachment};
        this.logger.info("[ConversationRepository][uploadAttachmentAndSend][recoding] sending xmpp message", msgToSend);

        this.sendMessageToXmpp(msgToSend);
      }

    }
  }

  private async processRecoding(msg: Message, convTargets: string[]) {
    this.logger.info("[ConversationRepository][processRecoding] ", msg.attachment);
    const recodeUrl = msg.attachment.url.replace("share.php", "recode.php");
    const response = await fetch(recodeUrl);
    const reader = response.body.getReader();
    let finished = false;

    while (!finished) {
      // this.logger.info("[ConversationRepository][processRecoding] response", response);
      const {value, done} = await reader.read();
      if (done) {
        this.logger.info("[ConversationRepository][processRecoding] progress done ");
        this.notifyRecodingProgress(msg, 100, convTargets);
        finished = true;
        this.sentFile$.next(true);
      }

      const textValue = CommonUtil.uInt8ArrayToString(value);
      this.logger.info("[ConversationRepository][processRecoding] progress response ", textValue);
      if (textValue.indexOf("%") > -1) {
        const progress = parseInt(textValue.split("%")[0].trim());
        this.notifyRecodingProgress(msg, progress, convTargets);
      }
    }
  }

  private uploadAttachmentAndSend(msg: Message, convTargets: string[]) {

    // requesting XMPP slot
    this.logger.info("[ConversationRepository][uploadAttachmentAndSend] xmppService.requestSlot starting.. file: ", msg, msg.file);
    this.sendAttachmentSubscription[msg.id] = this.xmppService.requestSlot(msg.attachment.fileName, msg.attachment.fileSize)
    .pipe(switchMap(res => {
      this.logger.info("[ConversationRepository][uploadAttachmentAndSend] xmppService.requestSlot done, res: ", res);

      this.updateFileInProgress(msg.id, true);
      this.removePendingAttachment(msg.id);
      return this.uploadFile(res.httpupload.put, msg.file, msg.id);

    })).subscribe({
      next: (res: any) => {
        this.logger.info("[ConversationRepository][uploadAttachmentAndSend] res: ", res, msg.uploadFileInProgress);
        switch (res.type) {
          case HttpEventType.Sent: // 0
            this.logger.info("[ConversationRepository][uploadAttachmentAndSend] Uploading file, res: ", res);
            this.updateFileInProgress(msg.id, true);
            break;

          case HttpEventType.UploadProgress: // 1
            this.logger.info(`[ConversationRepository][uploadAttachmentAndSend] Uploading progress: ${res.loaded} from ${res.total}`, msg.uploadFileInProgress);

            // show upload progess
            const total = res.total;
            const loaded = res.loaded;
            this.store.dispatch(new UpdateFileInProgress({ messageId: msg.id, progress: { total, loaded } }));
            break;

          case HttpEventType.ResponseHeader: // 2
            this.updateFileInProgress(msg.id, true);
            this.logger.info(`[ConversationRepository][uploadAttachmentAndSend] response header`, res);
            // ok: false
            // status: 0
            // statusText: "OK"

            break;

          case HttpEventType.DownloadProgress: // 2

            break;

          case HttpEventType.Response: // 4
            this.logger.info(`[ConversationRepository][uploadAttachmentAndSend]HttpEventTypeResponse File was completely uploaded`, res, msg.uploadFileInProgress, typeof msg.file);
            // ok: true
            // status: 200
            // statusText: "OK"
            try {
              window.URL.revokeObjectURL(msg.attachment.url);
              this.logger.info("[ConversationRepository][uploadAttachmentAndSend] revoked objectURL: ", msg.attachment.url);
            } catch (error) {
              this.logger.info("UPLOADDONE2 - revoke error: ", error);
            }

            if (res.status === 201) {
              this.logger.info(`[ConversationRepository][uploadAttachmentAndSend]HttpEventTypeResponse recoding required `, msg);
              let newUrl = res.url;
              const newAttachment = {
                url: newUrl,
                fileSize: msg.attachment.fileSize,
                fileName: msg.attachment.fileName,
                fileType: msg.attachment.fileType,
                recodingRequired: true,
                recodingProgress: 0
              };

              this.updateFileInProgress(msg.id, false, newAttachment, msg.body);
              msg.attachment = newAttachment;
              this.store.dispatch(new RemoveUploadedFile({ messageId: msg.id }));
              if (convTargets.includes(msg.pendingIn)) {
                this.broadcaster.broadcast("fileSent", msg);
              }
              this.processRecoding(msg, convTargets);
              break;
            }
            msg.file = null;
            let newUrl;
            if (res.url.indexOf("?") > -1) {
              newUrl = res.url.split("?")[0];
            } else {
              newUrl = res.url;
            }
            const newBody = newUrl;
            const newAttachment = {
              url: newUrl,
              fileSize: msg.attachment.fileSize,
              fileName: msg.attachment.fileName,
              fileType: msg.attachment.fileType
            };

            // update in redux
            this.updateFileInProgress(msg.id, false, newAttachment, newBody);

            this.store.dispatch(new RemoveUploadedFile({ messageId: msg.id }));

            if (convTargets.includes(msg.pendingIn)) {
              this.broadcaster.broadcast("fileSent", msg.id);

              const msgToSend = {...msg, body: newBody, attachment: newAttachment};
              this.logger.info("[ConversationRepository][uploadAttachmentAndSend] sending xmpp message", msgToSend);

              this.sendMessageToXmpp(msgToSend);
            }

            break;

          default:
            break;
        }
      },
      error: (err) => {
      this.logger.error("[ConversationRepository][uploadAttachmentAndSend] xmppService.requestSlot error", err);

      // file is empty?
      if (err.status === 403) {
        // name: "HttpErrorResponse"
        // ok: false
        // status: 403
        // statusText: "Forbidden"

        this.removeMessageFromPending(msg.id);

        // lost connection while uploading
      } else if (err.status === 0) {
        this.logger.info("[ConversationRepository][uploadAttachmentAndSend] failed - keep in pending", msg);
        /*
        // name: "HttpErrorResponse"
        // ok: false
        // status: 0
        // statusText: "Unknown Error"
        msg.status = MessageStatus.FAILED;

        // load to redux & update in DB
        this.store.dispatch(new MessageStatusUpdateAction({ id: msg.id, status: MessageStatus.FAILED }));
        this.databaseService.createOrUpdateMessages([{ ...msg, status: MessageStatus.FAILED }], msg.pendingIn);

        this.removeMessageFromPending(msg.id);
        */
      }
      this.updateFileInProgress(msg.id, false);
      }
    });
  }

  resumeFileDownload () {
    this.store.select(getDownloadInProgress).pipe(take(1)).subscribe((downloads) => {
      Object.keys(downloads).forEach((download : any) => {
        downloads[download];
        let messageDetails = {
          messageId: download,
          ...downloads[download]
        };
        setTimeout(() => {
          this.downloadFile(messageDetails);
        }, 2000);
      });
    });
  }

  public async downloadFile(message: any, isGlobalSearch = false) {
      if (!isGlobalSearch) {
        this.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(conv => {
          let convoTarget = conv.Target;
          let convoType = conv.type;
          if(!!message.convoTarget){
            convoTarget = message.convoTarget;
            convoType = message.convoType;
          }
          this.store.dispatch(new DownloadFileInProgress({ messageId: message.messageId, progress: { total: parseInt(message.fileSize), loaded: 0, status: "pending", convoTarget: convoTarget, convoType:  convoType, url: message.url, fileName: message.fileName } }));
            this.downloadFileSubscription[message.messageId] = this.filesStorageService.download(message.url).subscribe((blob: any) => {
                if(blob.type === HttpEventType.DownloadProgress){
                  this.store.dispatch(new DownloadFileInProgress({ messageId: message.messageId, progress: { total: blob.total, loaded: blob.loaded, status: "inprogress" } }));
                }
                if(blob.type === HttpEventType.Response){
                  this.store.dispatch(new DownloadFileInProgress({ messageId: message.messageId, progress: { total: blob.total, loaded: blob.total, status: "done" } }));
                  setTimeout(() => {
                    this.notificationService.openSnackBarWithTranslation("FILE_DOWNLOADED");
                  }, 500);
                  saveAs(blob.body, message.fileName);
                }
              }
            );
        });
      }
      else {
        let convoTarget = message.Target;
        let convoType = message.type;
        if(!!message.convoTarget){
          convoTarget = message.convoTarget;
          convoType = message.convoType;
        }
        this.store.dispatch(new DownloadFileInProgress({ messageId: message.messageId, progress: { total: parseInt(message.fileSize), loaded: 0, status: "pending", convoTarget: convoTarget, convoType:  convoType, url: message.url, fileName: message.fileName } }));
          this.downloadFileSubscription[message.messageId] = this.filesStorageService.download(message.url).subscribe((blob: any) => {
              if(blob.type === HttpEventType.DownloadProgress){
                this.store.dispatch(new DownloadFileInProgress({ messageId: message.messageId, progress: { total: blob.total, loaded: blob.loaded, status: "inprogress" } }));
              }
              if(blob.type === HttpEventType.Response){
                this.store.dispatch(new DownloadFileInProgress({ messageId: message.messageId, progress: { total: blob.total, loaded: blob.total, status: "done" } }));
                setTimeout(() => {
                  this.notificationService.openSnackBarWithTranslation("FILE_DOWNLOADED");
                }, 500);
                saveAs(blob.body, message.fileName);
              }
            }
        );
      }
  }

  pauseCancelFileDownload(event = null) {
    if (this.downloadFileSubscription && Object.keys(this.downloadFileSubscription).length) {
      Object.values(this.downloadFileSubscription).forEach((element : any) => {
        element.unsubscribe();
        element = null;
      });
      if (event === "cancel") {
        this.store.dispatch(new ResetDownloadInProgress());
        this.store.dispatch(new ResetDownloadFileIds());
      }
    }
  }

  uploadAttachmentAndSendMessage(data) {
    // requesting XMPP slot
    this.logger.info("[ConversationRepository][uploadAttachmentAndSendMessage] xmppService.requestSlot starting.. file: ", data);
    this.xmppService.requestSlot(data.fileName, data.blob.size).pipe(switchMap(res => {
      this.logger.info("[ConversationRepository][uploadAttachmentAndSendMessage] xmppService.requestSlot done, res: ", res);
      return this.uploadFile(res.httpupload.put, data.blob, data.id);
    })).subscribe((res: any) => {
      if (this.numberOfFiles === 0) {
        return;
      }
      this.logger.info("[ConversationRepository][uploadAttachmentAndSendMessage] res: ", res);
      switch (res.type) {
        case HttpEventType.Sent: // 0
          this.logger.info("[ConversationRepository][uploadAttachmentAndSendMessage] Uploading file, res: ", res);
          break;

        case HttpEventType.UploadProgress: // 1
          this.translate.get("SENDING_FILES").pipe(take(1)).subscribe(text => {
            if (this.snackBarProgress) {
              const done = (res.loaded / res.total) * 100;
              if (done > 10) this.snackBarProgress.instance.progressValue = done;
            }
            else this.snackBarProgress = this.vncLibraryService.openSnackBarWithFullcontrol(text, "progress-loader", "", "", 2000000, "left", "left", "");
          });
          this.logger.info(`[ConversationRepository][uploadAttachmentAndSendMessage] Uploading progress: ${res.loaded} from ${res.total}`);
          break;

        case HttpEventType.ResponseHeader: // 2
          break;

        case HttpEventType.DownloadProgress: // 2

          break;

        case HttpEventType.Response: // 4
          this.logger.info(`[ConversationRepository][uploadAttachmentAndSend] File was completely uploaded`, res);
          // ok: true
          // status: 200
          // statusText: "OK"
          const url = res.url.split("?")[0];
          const newFileExt = data.fileName.slice(data.fileName.lastIndexOf(".") + 1, data.fileName.length);
          this.logger.info(`[ConversationRepository][uploadAttachmentAndSend] newFileExt `, newFileExt);
          const newAttachment = {
            url: url,
            fileSize: data.blob.size,
            fileName: data.fileName,
            fileType: (!!newFileExt && (newFileExt !== "")) ? newFileExt : data.blob.type.split("/")[1]
          };
          const timestamp = this.datetimeService.getCorrectedLocalTime();
          const msg: Message = {
            id: CommonUtil.randomId(10),
            from: this.userJID,
            body: url,
            timestamp,
            status: MessageStatus.PENDING
          };
          for (let target of data.recipients) {
            const msgToSend = {...msg,
              to: target,
              type: CommonUtil.isGroupTarged(target) ? "groupchat" : "chat",
              pendingIn: target,
              body: url,
              attachment: newAttachment
            };
            this.logger.info("[ConversationRepository][uploadAttachmentAndSend] sending xmpp message", msgToSend);
            this.sendMessageToXmpp(msgToSend);
            let translationText = "FILE_SENT";
            if (this.numberOfFiles > 1) {
              translationText = "FILES_SENT";
            }
            this.translate.get(translationText , { numberOfFile: this.numberOfFiles}).pipe(take(1)).subscribe(text => {
              if (this.snackBarProgress) {
                this.snackBarProgress.instance.message = text;
                const loader = document.getElementsByClassName("progress-loader-snackbar");
                const ele = document.getElementsByClassName("checkmark-with-loading");
                if (loader?.length > 0 && ele?.length > 0) {
                  loader[0]["style"].display = "none";
                  ele[0]["style"].display = "block";
                  setTimeout(() => {
                    this.snackBarProgress.dismiss();
                    this.snackBarProgress = null;
                    this.numberOfFiles = 0;
                  }, 2000);
                }
              }
              else {
                this.vncLibraryService.openSnackBar(text, "checkmark", "", "", 2000, "left", "left").subscribe(r => {
                  this.snackBarProgress = null;
                });
              }
            });
          }
          break;

        default:
          break;
      }
    }, err => {
      this.logger.error("[ConversationRepository][uploadAttachmentAndSendMessage] xmppService.requestSlot error", err);

    });
  }

  downloadFileToDownloadsOrGallery(url: string): Observable<any> {
    this.logger.info("[ConversationRepository] downloadFileToDownloadsOrGallery url: ", url);

    const response = new Subject();

    const fileName = url.substring(url.lastIndexOf("/") + 1);
    let headers = [];
    headers.push(
      {
          Key: "Authorization",
          Value: localStorage.getItem("token")
      }
    );
    this.filesStorageService.downloadFileAsBlob(url, headers).subscribe(fileBlob => {
      if (CommonUtil.isOnIOS()) {
        // save in hidden cache
        this.filesStorageService.saveBlobToDisc(fileBlob, fileName).subscribe((localFileUrl) => {
          // for iOS we need to additionaly save to Camera Roll
          cordova.plugins.imagesaver.saveImageToGallery(localFileUrl, () => {
            response.next(localFileUrl);
          }, (err) => {
            response.error(err);
          });
        }, err => {
          response.error(err);
        });

        // Android
      } else {
        this.filesStorageService.saveBlobToAndroidDownloadFolder(fileBlob, fileName).subscribe((localFileUrl) => {
          response.next(localFileUrl);
        }, err => {
          response.error(err);
        });
      }
    }, err => {
      response.error(err);
    });

    return response.asObservable();
  }

  isFileDownloadedToDownloadsOrGallery(url: string): Observable<any> {
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    return this.filesStorageService.isFileDownloadedToDownloadsOrGallery(fileName, CommonUtil.isOnIOS());
  }

  downloadFileToExternalOrGallery(url: string): Observable<any> {
    this.logger.info("[ConversationRepository][downloadFileToExternalOrGallery] url: ", url);

    const response = new Subject();

    const fileName = url.substring(url.lastIndexOf("/") + 1);
    let headers = [];
    headers.push(
      {
          Key: "Authorization",
          Value: localStorage.getItem("token")
      }
    );

    this.filesStorageService.downloadFileAsBlob(url, headers).subscribe(fileBlob => {
      this.logger.info("[ConversationRepository][downloadFileToExternalOrGallery] downloadFileAsBlob: ", fileBlob);
      if (CommonUtil.isOnIOS()) {
        // save in hidden cache
        this.filesStorageService.saveBlobToDisc(fileBlob, fileName).subscribe((localFileUrl)  => {
          this.logger.info("[ConversationRepository][downloadFileToExternalOrGallery] downloadFileAsBlob: ", fileBlob, localFileUrl);
          // for iOS we need to additionaly save to Camera Roll
          cordova.plugins.imagesaver.saveImageToGallery(localFileUrl, () => {
            response.next(localFileUrl);
          }, (err) => {
            response.error(err);
          });
        }, err => {
          response.error(err);
        });

      // Android
      } else {
        this.filesStorageService.saveBlobToDisc(fileBlob, fileName, cordova.file.externalDataDirectory).subscribe((localFileUrl)  => {
          this.logger.info("[ConversationRepository][downloadFileToExternalOrGallery] success, localFileUrl: ", localFileUrl);
          response.next(localFileUrl);
        }, err => {
          response.error(err);
        });
      }
    }, err => {
      response.error(err);
    });

    return response.asObservable();
  }

  isFileDownloadedToExternalOrGallery(url: string): Observable<any> {
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    return this.filesStorageService.isFileDownloadedToExternalOrGallery(fileName, CommonUtil.isOnIOS());
  }

  removeMessageFromPending(mId) {
    // this.logger.info("[ConversationRepository][XMPPService][removeMessageFromPending]", mId, this.pendingMessages);
    this.pendingMessages = CommonUtil.removeMessageFromPending(mId);

    // this.logger.info("[ConversationRepository][XMPPService][removeMessageFromPending]", this.pendingMessages);
  }

  private removeMessagesFromPending(mIds) {
    this.pendingMessages = CommonUtil.removeMessagesFromPending(mIds);
  }

  public sendMessageToXmpp(msg: Message) {
    this.logger.info("[XmppService][sendMessageToXmpp] checking", msg);

    if (msg.sendingAt) {
      this.logger.info("[XmppService][sendMessageToXmpp] ignore because of already msg.sendingAt", msg.sendingAt);
      return;
    }

    // join if not joined
    if (msg.type === "groupchat" && this.isConnectedXMPP) {
      this.joinRoomFromTarget(msg.pendingIn);
    }

    this.getMessageById(msg.id).pipe(take(1)).subscribe(msgInStore => {
      // already sent
      if (!!msgInStore && msgInStore.status !== 0) {
        this.removeMessageFromPending(msg.id);
        return;
      }
      if (!msgInStore || msgInStore.status === 0) {
        let processingAt = Date.now();
        if (!!msg.sendingAt && ((processingAt - msg.sendingAt) < 20000)) {
          this.logger.info("[XmppService][sendMessageToXmpp] skip re-send pending", msg, msg.sendingAt, processingAt);
          // do not re-try send immediately with new timestamp
          return;
        }
        msg.sendingAt = new Date().getTime();
        this.logger.info("[XmppService][sendMessageToXmpp] sending", msg, msg.sendingAt);
        if (msg.attachment && msg.body.startsWith("blob:")) {
          this.logger.error("[XmppService][sendMessageToXmpp] about to send blob: ", msg);
        } else {
          let conv;
          this.getConversationById(msg.pendingIn).pipe(take(1)).subscribe(v => conv = v);
          const timestamp = !!conv && !!conv.retention_time ? +conv.retention_time : 0;
          this.xmppService.sendMessage(msg.pendingIn, msg, timestamp * 1000);
          // this.xmppService.sendMessage(msg.pendingIn, msg, 60 * 1000); // for testing
        }
        if (msg.type === "normal" || msg.replace || msg.attachment) {
          // TODO: wtf is msg.type === "normal" ? => broadcast or call signal !
          this.removeMessageFromPending(msg.id);
        }
      }
    });
  }

  setRoomAffiliation(room: string, jid: string, affiliation: string): Observable<any> {
    if (this.isGroupManageEnabled) {
      return this.groupChatsService.setRoomAffiliation(room, [jid], affiliation);
    } else {
      return this.xmppService.setRoomAffiliation(room, jid, affiliation);
    }
  }

  setRoomAffiliations(room: string, jids: string[], affiliation: string): Observable<any> {
    this.logger.info("[ConversationRepository][setRoomAffiliations]", room, jids, affiliation);

    jids.forEach(jid => {
      this.xmppService.setRoomAffiliation(room, jid, affiliation).pipe(take(1)).subscribe(res => {
        this.logger.info("[ConversationRepository][setRoomAffiliations] res", room, jid, affiliation, res);
      });
    });

    return this.groupChatsService.setRoomAffiliation(room, jids, affiliation);
  }

  setUpMarkAsReadQueue() {
    this.pendingReadRequest = this.appService.getPendingMarkReadRequests();
    this.pendingPadReadRequest = this.appService.getPendingMarkPadReadRequests();
    this.storePendingRequest.asObservable().pipe(sampleTime(1000)).subscribe(() => {
      this.appService.storePendingMarkReadRequests(this.pendingReadRequest);
    });
    this.storePendingPadRequest.asObservable().pipe(sampleTime(500)).subscribe(() => {
      this.appService.storePendingMarkPadReadRequests(this.pendingPadReadRequest);
    });
  }

  //
  // URL preview
  //

  extractUrlFromMessageBody(body: string) {
    let fullUrl: string;
    try {
      const matches = body.match(URL_REGEXP); // complete body is url, followed by spaces maybe
      if (matches) {
        fullUrl = matches[0];
        if (!fullUrl.startsWith("http")) {
          fullUrl = `https://${fullUrl}`;
        }

        // skip call invites
        if (fullUrl?.includes("vnctalk-jitsi-meet") || fullUrl?.includes(this.configService.get("jitsiURL"))) {
          fullUrl = null;
        }
      }
    } catch (ex) {

    }

    // this.logger.info("[ConversatinRepository][extractUrlFromMessageBody]", body, fullUrl);

    return fullUrl;
  }

  getRedmineIssueId(fullUrl: string): string {
    return fullUrl.split(this.configService.REDMINE_URL + "/issues/")[1].split("#")[0];
  }

  getRedmineVersionId(fullUrl: string): string {
    return fullUrl.split(this.configService.REDMINE_URL + "/versions/")[1].split("#")[0];
  }

  shouldUnfurlURL(fullUrl: string) {
    return MessageUtil.shouldUnfurlURL(fullUrl);
  }

  extractAllUrlsFromMessageBody(body: string) {
    let urls = [];
    const REGEX = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
    try {
      const matches = body.match(REGEX); // complete body is url, followed by spaces maybe
      if (matches) {
        matches.forEach( url => {
          if (!(url?.includes("vnctalk-jitsi-meet") || url?.includes(this.configService.get("jitsiURL")))) {
            if (!url.startsWith("http")) {
              urls.push(`https://${url}`);
            } else {
              urls.push(url);
            }
          }
        });
      }
    } catch (ex) {

    }
    return urls;
  }

  private unfurledUrlCache = {};
  private pendingUnfurlUrlRequests = {};
  //
  unfurlURL(url: string, shouldInvalidateUrlCache?: boolean): Observable<any> {
    const response = new BehaviorSubject<any>(null);

    if (this.unfurledUrlCache[url] && !shouldInvalidateUrlCache) {
      response.next(this.unfurledUrlCache[url]);
      this.logger.info("[ConversatinRepository][unfurlURL] RETURN FROM CACHE");
    } else if (this.pendingUnfurlUrlRequests[url]) {
      this.logger.info("[ConversatinRepository][unfurlURL] PENDING");
      return this.pendingUnfurlUrlRequests[url];
    } else {
      this.logger.info("[ConversatinRepository][unfurlURL] REQUEST", url, {shouldInvalidateUrlCache});
      const res$ = this._unfurlURL(url);
      this.pendingUnfurlUrlRequests[url] = res$;
      res$.subscribe(res => {
        this.pendingUnfurlUrlRequests[url] = null;
        this.unfurledUrlCache[url] = res;
        response.next(res);
      });
    }

    return response.asObservable();
  }

  unfurlURLForMessage(mid: string, url: string, shouldInvalidateUrlCache?: boolean): Observable<any> {
    const response = new BehaviorSubject<any>(null);

    this.unfurlURL(url, shouldInvalidateUrlCache).subscribe(res => {
      response.next({res, messageId: mid});
    });

    return response.asObservable().pipe(filter(v => !!v));
  }

  private _unfurlURL(url: string): Observable<any> {
    const response = new Subject<any>();

    this.getPreviewUrl(url).pipe(take(1)).subscribe(data => {
      this.logger.info("[ConversatinRepository][_unfurlURL] url & data", url, data);

      let imageUrl = data?.image?.url;
      if (imageUrl) {
        if (typeof imageUrl === "object" && Array.isArray(imageUrl)) {
          imageUrl = imageUrl[0];
        }
        if (typeof imageUrl === "string" && !imageUrl.startsWith("http")) {
          const getUrl = new URL(url);
          // TODO: probably there are more diff cases
          if (imageUrl.startsWith("//")) {
            imageUrl = getUrl.protocol + imageUrl;
          } else {
            imageUrl = getUrl.protocol + "//" + getUrl.host + imageUrl;
          }
        }
        if (!data.url) {
          data.url = url;
        }
        data.timestamp = new Date().getTime();

        // check if possible to load img
        const image: HTMLImageElement = new Image();
        image.src = imageUrl;
        image.onload = () => {
          data.background = "url('" + imageUrl + "')";
          const res = {unfurledData: data};
          response.next(res);
        };
        image.onerror = () => {
          const res = {unfurledData: data, noImage: true};
          response.next(res);
        };
      } else if (data?.issue) {
        const ticketId = this.getRedmineIssueId(url);
        const unfurledAt = Date.now();
        const res = {redmineData: {...data.issue, id: ticketId, unfurledAt}};
        response.next(res);
      } else if (data?.version) {
        const versiontId = this.getRedmineVersionId(url);
        const unfurledAt = Date.now();
        const res = {versionData: {...data.version, id: versiontId, unfurledAt}};
        response.next(res);
      } else {
        const res = {unfurledData: data || {}, noImage: true};
        response.next(res);
      }
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(filter(v => !!v), take(1));
  }

  private activeUrlPreviewRequestsCount = 0;
  private getPreviewUrl(url: string): Observable<any> {
    const response = new Subject<any>();

    const tm = 200 * this.activeUrlPreviewRequestsCount;

    ++this.activeUrlPreviewRequestsCount;

    setTimeout(() => {
      // this.logger.info("[ConversatinRepository][getPreviewUrl] +", this.activeUrlPreviewRequestsCount);
      this.conversationService.getPreviewUrl(url).subscribe(res => {
        --this.activeUrlPreviewRequestsCount;
        // this.logger.info("[ConversatinRepository][getPreviewUrl] -", this.activeUrlPreviewRequestsCount);
        if (this.activeUrlPreviewRequestsCount < 0) {
          this.activeUrlPreviewRequestsCount = 0;
        }

        response.next(res);
      }, err => {
        --this.activeUrlPreviewRequestsCount;
        // this.logger.info("[ConversatinRepository][getPreviewUrl] -", this.activeUrlPreviewRequestsCount);
        if (this.activeUrlPreviewRequestsCount < 0) {
          this.activeUrlPreviewRequestsCount = 0;
        }

        response.error(err);
      });
    }, tm);

    this.logger.info("[ConversatinRepository][getPreviewUrl]", url, tm, this.activeUrlPreviewRequestsCount);

    return response.asObservable();
  }

  checkPreviewEditable(issueId: string): Observable<any> {
    return this.conversationService.checkPreviewEditable(issueId);
  }

  updateIssues(issueId: string, changes: any): Observable<any> {
    return this.conversationService.updateIssues(issueId, changes);
  }

  getConversationById(target: string): Observable<Conversation> {
    return this.store.select(state => getConversationById(state, target))
      .pipe(take(1));
  }

  getRoomMembersAndStore(target: string): Observable<any> {
    this.logger.info("[ConversationRepository][getRoomMembersAndStore]", target);
    return this.conversationService.getRoomMembers(target).pipe(map(res => {
      this.logger.info("[ConversationRepository][getRoomMembersAndStore] getRoomMembers", res);
      if (!!res && res[0]) {
        if (this.isGroupManageEnabled) {
          let members = res[0].members || [];
          const owner = res[0].owner;
          const admins = res[0].admins || [];
          members = CommonUtil.uniq([...members, ...admins, owner]);
          this.updateConversationMembersInStorage(target, members, owner, admins);
          if (!!res[0].members_only) {
            this.store.dispatch(new ConversationUpdate({ target: target, changes: { members_only: true } }));
          }
          return members;
        } else {
          let members = res[0].members && res[0].members.length > 0 ? JSON.parse(res[0].members) : [];
          const owner = res[0].owner;
          members = CommonUtil.uniq([...members, owner]);
          this.updateConversationMembersInStorage(target, members, owner);
          return members;
        }
      }
      return [];
    }));
  }

  private addMemberToMembersList(conversationTarget: string, jidsBare: string[]) {
    this.logger.info("[ConversationRepository][addMemberToMembersList] called", conversationTarget, jidsBare);
    let members = [];
    this.getConversationMembersStorage(conversationTarget).pipe(take(1)).subscribe(v => {
      // this.logger.info("[ConversationRepository][addMemberToMembersList] getConversationMembersStorage", conversationTarget, v);
      if (!!v) {
        members = v;
      }
    });

    let isChanged = false;
    jidsBare.forEach(jid => {
      if (!members.includes(jid)) {
        members.push(jid);
        isChanged = true;
      }
    });
    if (isChanged) {
      this.logger.info("[ConversationRepository][addMemberToMembersList] updateConversationMembersInStorage", conversationTarget, members);
      this.updateConversationMembersInStorage(conversationTarget, members);
    }
  }

  private removeMemberFromMembersList(conversationTarget: string, jidsBare: string[]) {
    this.getConversationMembersStorage(conversationTarget).pipe(take(1)).subscribe(members => {
      this.logger.info("[ConversationRepository][removeMemberFromMembersList] members", members, jidsBare);

      if (!members) {
        members = [];
      }

      members = members.filter(m => !jidsBare.includes(m));

      this.updateConversationMembersInStorage(conversationTarget, members);
    });
  }

  public updateConversationMembersInStorage(conversationTarget: string, members: string[], owner?: string, admins?: string[], audiences?: string[]): void {
    // this.logger.info("[ConversationRepository][updateConversationMembersInStorage]", conversationTarget, members, admins, owner, audiences);
    if (!members.includes(this.userJID?.bare)) {
      members.push(this.userJID?.bare);
    }
    let allMembers = [...members];

    if (this.isGroupManageEnabled) {
      if (owner && !members.includes(owner)) {
        members.push(owner);
        allMembers.push(owner);
      }
    }

    this.setConversationMembersInRedux(conversationTarget, members);
    //
    if (owner) {
      this.setConversationOwnerInRedux(conversationTarget, owner);
    }
    //
    if (admins) {
      this.setConversationAdminsInRedux(conversationTarget, admins);
      allMembers = [...allMembers, ...admins];
    }
    if (audiences) {
      this.setConversationAudiencesInRedux(conversationTarget, audiences);
      allMembers = [...allMembers, ...audiences];
    }
    //
    //
    const domain = this.userJID.domain;
    const iomMembers = allMembers.find(m => m.indexOf("@" + domain) === -1);
    const has_iom = !!iomMembers && (iomMembers.length > 0);

    this.databaseService.updateConversationMembersOwnerAdmins(conversationTarget, members, owner, admins, has_iom).subscribe();
  }

  private getAllRoomsMembers(updated_after?: number): Observable<any> {
    this.logger.info("[ConversationRepository][getAllRoomsMembers]", updated_after);
    return this.conversationService.getAllRoomsMembers(updated_after);
  }

  getConversationMembersStorage(target: string): Observable<string[]> {
    return this.getConversationMembersRedux(target).pipe(switchMap((members: any) => {
      if (members && members.length > 0) {
        return of(members);
      } else {
        return this.databaseService.getConversationMembersOwnerAdmins(target).pipe(map(data => {
          if (data) {
            return data[0];
          } else {
            return [];
          }
        }));
      }
    }));
  }

  getConversationMembersRedux(target: string): Observable<string[]> {
    return this.store.select(state => getConversationMembers(state, target));
  }

  getAllConversationMembers(target: string): Observable<string[]> {
    return this.store.select(state => getAllConversationMembers(state, target));
  }

  loadFirstMentionedMessages(target: string): Observable<any> {
    return this.conversationService.loadFirstMentionedMessages(target, this.userJID?.bare);
  }

  getMCBList(orgId): Observable<any> {
    return this.conversationService.getMCBList(orgId);
  }

  getTagsByJid(jid) {
      return this.conversationService.getTagsByJid(jid).pipe(map(res => {
          return res.objects || [];
      }));
  }

  getTags(params?: any) {
    return this.conversationService.getTags(params).pipe(map(res => {
        return res.tags || [];
    }));
  }

  createOrUpdateTag(roomBare: string, tags: any[]) {
    return this.conversationService.createOrUpdateTag(roomBare, tags).pipe(map((res: any) => {
      return res.objects || [];
    }));
  }

  getWhiteboards(target: string) {
    return this.conversationService.getWhiteboards(target);
  }

  createWhiteboard(target: string, name: string) {
    return this.conversationService.createWhiteboard(target, name).pipe(tap((data: any) => {
      if (data && data.id) {
        this.sendWhiteboardNotification("whiteboardCreated", JSON.stringify(data));
      }
    }));
  }

  updateWhiteboard(target: string, id: number, name: string) {
    return this.conversationService.updateWhiteboard(target, id, name).pipe(tap((data: any) => {
      if (data && data.id) {
        this.sendWhiteboardNotification("whiteboardUpdated", JSON.stringify(data));
      }
    }));
  }

  deleteWhiteboard(target: string, id: number) {
    return this.conversationService.deleteWhiteboard(target, id).pipe(tap(() => {
        this.sendWhiteboardNotification("whiteboardDeleted", JSON.stringify({target, id}));
    }));
  }

  searchGroups(searchString: string, memberName?: string, members?: string[]): Observable<SearchGroup[]> {
    const response = new Subject<SearchGroup[]>();
    this.conversationService.searchGroups(searchString, memberName, members).subscribe(res => {
      if (!!res && !res.severity) {
        response.next(res as SearchGroup[]);
      } else {
        response.next([]);
      }
    }, () => {
      response.next([]);
    });
    return response.asObservable();
  }

  blockContact(target: string): void {
    let contact: Contact;
    this.contactRepo.getContactById(target).pipe(take(1)).subscribe(c => {
      contact = c;
    });
    if (!!contact) {
      this.blockConversation(target).subscribe(() => {
        this.notificationService.openSnackBarWithTranslation("BLOCKED");
      });
    } else {
      this.contactRepo.getOrCreateContactFromBareJidOnServer(target).subscribe(() => {
        this.blockConversation(target).subscribe();
      });
    }
  }

  unblockContact(target: string): void {
    this.unblockConversation(target).pipe(take(1)).subscribe((blocked) => {
      if (blocked) {
        this.notificationService.openSnackBarWithTranslation("UNBLOCKED");
      }
    });
  }


  // utils

  private saveMessagesToStore(target: string, messages: Message[], skipSetLoadingToFalse = false) {
    this.logger.info("[ConversationRepository][saveMessagesToStore]", target, messages);

    this.store.dispatch(new MessageBulkAppend({
      messages: messages,
      conversationTarget: target,
      skipSetLoadingToFalse
    }));
  }

  private saveMessagesToStoreAndDB(target: string, messages: Message[], updateLastConvMessage?: boolean) {
    // this.logger.info("[ConversationRepository][saveMessagesToStoreAndDB]", target, messages, updateLastConvMessage);

    if (!messages) {
      console.error("[ConversationRepository][saveMessagesToStoreAndDB] empty messages?", target);
    }
    if (messages.length === 0) {
      console.error("[ConversationRepository][saveMessagesToStoreAndDB] messages length 0, bailing out", target);
      return;
    }

    this.saveMessagesToStore(target, messages);
    this.databaseService.createOrUpdateMessages(messages, target);
    let shouldUpdate = false;
    this.getConversationById(target).pipe(take(1)).subscribe(conv => {
      shouldUpdate = !!conv && conv?.last_message_id === messages[0].id;
    });

    if (updateLastConvMessage || shouldUpdate) {
      const lastMsg = messages[0];
      const data = [{ conversationTarget: target, message: lastMsg, incoming: !this.isSentMessage(lastMsg) }];
      // this.logger.info("[ConversationRepository][saveMessagesToStoreAndDB]MultiConversationUpdateLastMessage updateConversationContent", target, shouldUpdate, data, lastMsg.id, lastMsg);
      let historystate = "NONE";
      if (!!lastMsg.vncTalkConference && !!lastMsg.vncTalkConference.eventType) {
        switch (lastMsg.vncTalkConference.eventType) {
          case "leave": historystate = "ENDED_CALL"; break;
          case "join": historystate = "JOINED_CALL"; break;
          case "invite": historystate = "STARTED_CALL"; break;
        }
      }

      if (!!lastMsg.body && (lastMsg.body.startsWith("http://") || lastMsg.body.startsWith("https://") || lastMsg.body.startsWith("ftp://"))) {
        historystate = "LINK";
      }
      if (!!lastMsg.attachment && !!lastMsg.attachment.fileType) {
        if (CommonUtil.isImage(lastMsg.attachment.fileType)) {
          historystate = "PHOTO";
        } else if (CommonUtil.isSupportedVideo(lastMsg.attachment.fileType)) {
          historystate = "VIDEO";
        } else if (CommonUtil.isAudio(lastMsg.attachment.fileType)) {
          historystate = "VOICE_MESSAGE";
        } else if (CommonUtil.isVCFfile(lastMsg.attachment.fileType)) {
          historystate = "VCF";
        } else {
          historystate = "DOCUMENT";
        }
      }

      this.getSelectedConversation().pipe(take(1)).subscribe(v => {
        if (!v.archived) {
          this.databaseService.updateConversationContent(target, lastMsg.body, lastMsg.id, historystate);
          this.store.dispatch(new MultiConversationUpdateLastMessage(data));
        }
      });

    }
  }

  public saveMessagesToDB(target: string, messages: Message[]) {
    this.databaseService.createOrUpdateMessages(messages, target);
  }

  public saveMessagesToRedux(target: string, messages: Message[]) {
    this.store.dispatch(new MessageBulkAppend({conversationTarget: target, messages}));
  }

  addToFavourite(bareId: string) {
    let contact: Contact;
    this.contactRepo.getContactById(bareId).pipe(take(1)).subscribe(c => {
      contact = c;
    });
    // add to favorite
    this.store.select(state => getGroupsByName(state, ConstantsUtil.FAVOURITE_LIST_NAME)).pipe(take(1)).subscribe(groups => {
      this.logger.info("[addToFavourite]", contact, groups);
      this.notificationService.openSnackBarWithTranslation("ADDED_TO_FAVORITES");
      if (!!contact && contact.id) {
        this.addGroupToContact(groups, contact);
      } else {
        let newContact: ContactRest = {
          first_name: bareId.split("@")[0],
          bare: bareId,
          full: bareId,
          domain: bareId.split("@")[1],
          emails: [{ email: bareId }]
        };
        this.contactRepo.getOrCreateContactOnServer(newContact).subscribe(c => {
          this.logger.info("[createContact]", c);
          if (!!c) {
            this.addGroupToContact(groups, c);
          }
        });
      }
    });
  }

  addToHidden(bareId: string) {
    let contact: Contact;
    this.contactRepo.getContactById(bareId).pipe(take(1)).subscribe(c => {
      contact = c;
    });
    // add to favorite
    this.store.select(state => getGroupsByName(state, ConstantsUtil.HIDDEN_LIST_NAME)).pipe(take(1)).subscribe(groups => {
      this.logger.info("[addToHidden]", contact, groups);
      // this.notificationService.openSnackBarWithTranslation("ADDED_TO_FAVORITES");
      if (!!contact && contact.id) {
        this.addGroupToContact(groups, contact);
      } else {
        let newContact: ContactRest = {
          first_name: bareId.split("@")[0],
          bare: bareId,
          full: bareId,
          domain: bareId.split("@")[1],
          emails: [{ email: bareId }]
        };
        this.contactRepo.getOrCreateContactOnServer(newContact).subscribe(c => {
          this.logger.info("[createContact]", c);
          if (!!c) {
            this.addGroupToContact(groups, c);
          }
        });
      }
    });
  }

  addGroupToContact(groups, contact) {
    if (groups.length > 0) {
      this.contactRepo.addGroupToContact(groups[0], contact);
    } else {
      this.contactRepo.createGroup(ConstantsUtil.FAVOURITE_LIST_NAME, [contact]).subscribe(group => {
        this.logger.info("[addToFavourite] create, group", group);
        this.broadcaster.broadcast("sendUpdateContacts");
        // send update contact
      });
    }
  }

  removeFromFavorite(bare: string) {
    this.contactRepo.getContactById(bare).pipe(take(1)).subscribe(contact => {
      this.notificationService.openSnackBarWithTranslation("REMOVED_FROM_FAVORITES");
      this.store.select(state => getGroupsByName(state, ConstantsUtil.FAVOURITE_LIST_NAME)).pipe(take(1)).subscribe(groups => {
        if (!!contact && groups.length > 0) {
          this.contactRepo.removeContactFromGroup(contact, groups[0].id);
        }
      });
    });
  }

  public isMutedSound(target: string): boolean {
    let value = false;
    this.getConversationById(target).pipe(take(1)).subscribe(conv => {
      this.logger.info("getConversationById", conv);
      value = !!conv && conv.mute_sound === 2;
    });
    return value;
  }

  public isMutedNotification(target: string): boolean {
    let value = false;
    this.getConversationById(target).pipe(take(1)).subscribe(conv => {
      this.logger.info("getConversationById", conv);
      value = !!conv && conv.mute_notification === 2;
    });
    return value;
  }

  public updateMessage(message: Message, convTarget: string) {
    this.messageUpdates$.next({message, convTarget});
  }

  public sendPadNotification(to: string = null, action: string, padID: string): void {
    this.logger.info("[sendPadNotification]", to);
    this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      this.logger.info("[sendPadNotification] getSelectedConversation", conv);
      if (!!conv) {
        const message = {
          type: "normal",
          notification: {
            type: "pad",
            target: conv.Target,
            action: action,
            content: padID
          }
        };
        this.logger.info("[sendPadNotification]", message, CommonUtil.md5(conv.Target), to);
        if (conv.type === "chat") {
          this.xmppService.sendMessage(conv.Target, message);
        } else {
          this.getAllMembersOfSelectedConversation().pipe(take(1)).subscribe(members => {
            for (const member of members) {
              this.xmppService.sendMessage(member, message);
            }
          });
        }
      }
    });

  }

  public sendWhiteboardNotification(action: string, data: any): void {
    this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        const message = {
          type: "normal",
          notification: {
            type: "whiteboard",
            target: conv.Target,
            action: action,
            content: data
          }
        };
        this.logger.info("[sendWhiteboardNotification] getSelectedConversation", conv, message);
        if (conv.type === "chat") {
          this.xmppService.sendMessage(conv.Target, message);
        } else {
          this.getAllMembersOfSelectedConversation().pipe(take(1)).subscribe(members => {
            this.logger.info("[sendWhiteboardNotification] getSelectedConversationMembers", members);
            for (const member of members) {
              this.xmppService.sendMessage(member, message);
            }
          });
        }
      }
    });

  }

  public sendContactNotification(): void {
    const message = {
      type: "normal",
      notification: {
        type: "contacts",
        target: this.userJID?.bare,
        action: "updateContact",
        content: ""
      }
    };
    this.xmppService.sendMessage(this.userJID?.bare, message);
  }

  public getConferenceKey(): string {
    let key = "";
    this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
      if (!!conv) {
        if (conv.type === "groupchat") {
          key = conv.Target;
        } else {
          const participants: string[] = [conv.Target, this.userJID?.bare];
          participants.sort((a, b) => {
            if (a > b) return 1;
            return -1;
          });
          key = participants.join(",").replace(/@/g, "#").toLowerCase();
        }
      }
    });
    return key;
  }

  getGroupInfo(target) {
    return this.groupChatsService.getGroupInfo(target);
  }

  getAndUpdateGroupInfo(target) {
    return this.groupChatsService.getGroupInfo(target).pipe(map((res:any) => {

        let members = [];
        let owners = [];
        let owner = "";
        let admins = [];
        let audiences = [];
        if (res && res.group_chat) {
          if (res.group_chat.affiliations_member) {
            members = res.group_chat.affiliations_member.map(v => v.jid);
          }
          if (!!res.group_chat.affiliations_owner) {
            owners = res.group_chat.affiliations_owner.map(v => v.jid);
            owner = owners[0];
            admins = owners.filter(v => v !== owner);
          }
          if (!!res.group_chat.affiliations_admin) {
            admins = [...admins, ...res.group_chat.affiliations_admin.map(v => v.jid)];
          }
          if (!!res.group_chat.affiliations_audience) {
            audiences = res.group_chat.affiliations_audience.map(v => v.jid);
          }

          this.updateConversationMembersInStorage(target, members,owner, admins, owners);
        }
        return {owners, admins, members, audiences};
    }));
  }

  public getXmppDomain() {
    if (this.xmppService.xmpp) {
      return this.xmppService.xmpp.jid.domain;
    }
    return "";
  }

  getRunningConferences() {
    return this.conversationService.getRunningConferences();
  }

  getRecordings(target: string) {
    return this.conversationService.getRecordings(target);
  }

  getFullName(jid) {
    return this.contactRepo.getFullName(jid);
  }

  getConferencesByRoomIds(roomIds) {
    if (roomIds && roomIds.length > 0) {
        return this.conversationService.getConferencesByRoomIds(roomIds);
    }
    return of([]);
  }

  fetchRecording(convkey: string, callid: string, fileid: string) {
    return this.conversationService.fetchRecording(convkey, callid, fileid).subscribe(
      (data: any) => {
        saveAs(data, `${fileid}.mp4`);
      },
      err => {
        this.logger.error(err);
      }
    );
  }

  updateGroupInfo(vdata, target) {
    // this.logger.info("[group chat data] updateGroupInfo", vdata, target);
    let members = [];
    let admins = [];
    let audiences = [];
    let owner = "";
    if (vdata.affiliations_owner || vdata.affiliations_member) {
      if (vdata.affiliations_owner && (vdata.affiliations_owner.length > 0)) {
        owner = vdata.affiliations_owner[0].jid;
        admins = vdata.affiliations_owner.filter(v => v.jid !== owner).map(v => v.jid);
      }

      if (vdata.affiliations_admin) {
        admins = [...admins, ...vdata.affiliations_admin.map(v => v.jid)];
      }
      if (vdata.affiliations_member) {
        members = [...members, ...vdata.affiliations_member.map(v => v.jid)];
      }
      if (vdata.affiliations_audience) {
        members = [...members, ...vdata.affiliations_audience.map(v => v.jid)];
        audiences = vdata.affiliations_audience.map(v => v.jid);
      }
      this.updateConversationMembersInStorage(target, members, owner, admins, audiences);
    }
    if (!!vdata.members_only) {
      this.store.dispatch(new ConversationUpdate({ target: target, changes: { members_only: true } }));
    }
    if (vdata.meta_conference_boards) {
      this.store.dispatch(new ConversationUpdateMCB({target: target,
        meta_conference_boards: vdata.meta_conference_boards}));
    }
  }

  loadAllConvs() {
    // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 8");
    this.loadAllUpdatedConversations(1);
  }

  loadUpdatedConvsSince(since: number) {
    // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 9");
    this.loadAllUpdatedConversations(null, since);
  }

  getIsAppOnline() {
    return this.store.select(getIsAppOnline);
  }

  parseMentionConversationContent(content: string) {
    let translations: any = {};
    this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE",
      "TICKET_MENTION_SENDER_MESSAGE",
      "META_TASK_MENTION", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE","TICKET_MENTION_FORWARD_MESSAGE"]).pipe(take(1)).subscribe(v => {
        translations = v;
      });
    try {
      if (CommonUtil.parseTicketMentions(TICKET_MENTION, content).length > 0) {
        const textToReplace = CommonUtil.parseTicketMentions(TICKET_MENTION, content)[0];
        let userjid = textToReplace.split("#")[1];
        let mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
        if (this.userJID?.bare === userjid) {
          mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
        }
        content = content.replace(textToReplace + ": ", "");
      } else if (CommonUtil.parseTicketMentions(META_TASK_MENTION, content).length > 0) {
        const textToReplace = CommonUtil.parseTicketMentions(META_TASK_MENTION, content)[0];
        let userjid = textToReplace.split("#")[1];
        let mentionText = translations.META_TASK_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.contactRepo.getFullName(userjid));
        if (this.userJID?.bare === userjid) {
          mentionText = translations.META_TASK_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.contactRepo.getFullName(userjid));
        }
        content = content.replace(textToReplace, mentionText);
      }
    } catch (error) {

    }
    return content;
  }

  updateConversationContent(target: string, body: string, lastMsgId?: string) {
    this.databaseService.updateConversationContent(target, body, lastMsgId);
  }

  checkShowSchedulerMessage(timestamp, callTimer) {
    const subject = new Subject<any>();
    let startDate = null;
    let startTime = null;
    let callStartIn = null;
    let showJoinButton = false;
    let showSchedulerMessage = timestamp && timestamp  > new Date().getTime();
    if (callTimer) {
      callTimer.unsubscribe();
    }
    if (showSchedulerMessage) {
      const then = new Date(timestamp);
      if (addHours(new Date(), 1) >= then) {
        callTimer = interval(1000).subscribe(() => {
          const now = new Date();
          const diffTimeInSeconds = differenceInSeconds(then, now);
          const minutes = Math.floor(diffTimeInSeconds / 60);
          const seconds = Math.floor(diffTimeInSeconds - minutes * 60);
          callStartIn = `${this.twoDigits(minutes)}:${this.twoDigits(seconds)}`;
          showSchedulerMessage = timestamp > now.getTime();
          if (addMinutes(now, ConstantsUtil.TIME_TO_SHOW_JOIN) >= then) {
            showJoinButton = true;
          } else {
            showJoinButton = false;
          }
          if (minutes < 1 && seconds < 1) {
            callStartIn = "00:00";
            showJoinButton = true;
            showSchedulerMessage = false;
            callTimer.unsubscribe();
          }

          subject.next({callTimer, showJoinButton, showSchedulerMessage,  callStartIn, startDate, startTime});
        });
      } else {
        const diffTimeInSeconds = differenceInSeconds(then, new Date());
        const minutes = Math.floor(diffTimeInSeconds / 60);
        const seconds = diffTimeInSeconds - minutes * 60;
        callStartIn = `${this.twoDigits(minutes)}:${this.twoDigits(seconds)}`;
        startDate = format(timestamp, "dd.MM.yyyy");
        startTime = format(timestamp, "HH:mm");
        subject.next({callTimer, showJoinButton, showSchedulerMessage,  callStartIn, startDate, startTime});
      }
    }
    return subject.asObservable();
  }

  twoDigits(num) {
    if (isNaN(num)) {
      return "00";
    }
    if (num < 10) {
      return "0" + num;
    }
    return num;
  }

  private backgroundResyncConv(conversation: any, nb: number) {
    this.databaseService.createOrUpdateConversation([conversation]).subscribe(() => {
      this.conversationService.backgroundLoadMessages(conversation.Target, conversation.type, nb).subscribe(res => {
        // this.logger.info("[conversationRepo][backgroundResyncConv][backgroundLoadMessages] res: ", res);
        if (res.messages.length > 0) {
          this.databaseService.createOrUpdateMessages(res.messages, conversation.Target);
        }
      });
    });
  }

  private updateStoredReactionsForMessage(signal: any) {
    this.logger.info("[ConversationRepo][updateStoredReactionsForMessage] need to update existing message reactions:", signal);
    this.databaseService.getMessageById(signal.target).subscribe(oMessage => {
      if (!oMessage) {
        return;
      }
      this.logger.info("[ConversationRepo][updateStoredReactionsForMessage] need to update existing message: ", oMessage);
      let newReactions = {};
      let result;
      try {
        let regex = /\\/g;
        // let result = signal.data.replace(regex, "\\");
        result = signal.data.replace(regex, "");
        // .replace(/&#34;/g, "\"");
        if (result.startsWith("\"")) {
          result = result.slice(1, result.length - 1);
        }
        if (result.endsWith("\"")) {
          result = result.slice(0, result.length - 1);
        }

        newReactions = JSON.parse(result);

      } catch (error) {
        this.logger.info("[ConversationRepo][updateStoredReactionsForMessage] error parsing update: ", result, error);
      }

      this.logger.info("[ConversationRepo][updateStoredReactionsForMessage] new reactions update: ", newReactions);
      if (oMessage) {
        oMessage.reactions = newReactions;
        this.store.dispatch(new MessageUpdateAction({ id: oMessage.id, changes: { reactions: newReactions } }));
        this.databaseService.createOrUpdateMessages([oMessage], oMessage.convTarget);
      }
    });
  }

  joinGroupRequest(target: string) {
    return this.conversationService.joinGroupRequest(target);
  }

  grantGroupRequest(target: string, grantee: string) {
    return this.conversationService.grantGroupRequest(target, grantee);
  }

  getPendingJoinRequests(target: string) {
    return this.conversationService.getPendingJoinRequests(target);
  }

  handleGroupRequestNotification(requestor: string, target:string) {
    let ownerAndAdmins = [];
    this.getConversationOwnerFromRedux(target).pipe(take(1)).subscribe(owner => {
      this.getConversationAdminsFromRedux(target).pipe(take(1)).subscribe(admins => {
        ownerAndAdmins = admins;
        ownerAndAdmins.push(owner);
        if (ownerAndAdmins.indexOf(this.userJID.bare) > -1) {
          // add notificatiuon
          this.contactRepo.getContactById(requestor).pipe(take(1)).subscribe(async c => {
            const fullName = (!!c && !!c.name) ? c.name : CommonUtil.beautifyName(requestor.split("@")[0]);
            const translatedMessage = await this.translate.get("USER_REQUESTED_ACCES", { fullName: fullName }).pipe(take(1)).toPromise();
            this.broadcaster.broadcast("USER_REQUESTED_ACCES", {target: target, message: translatedMessage});
            // this.logger.info("handleGroupRequestNotification from contact: ", c, fullName);
          });
          this.getSelectedConversation().pipe(take(1)).subscribe(conv => {
            this.logger.info("handleGroupRequestNotification for conv: ", conv);
            if (conv.Target === target) {
              this.broadcaster.broadcast("REFRESH_GROUP_REQUESTS_INFO");
            }
          });
        }
      });
    });
  }

  reloadConversationHistory() {
    // re-fetch convs from DB (they may have new data (Timestamp & Content) after FCM -> IndexedDB flow)
    const t0 = performance.now();
    this.databaseService.fetchConversationsTop(20).subscribe(conversations => {
      this.logger.info("[ConversationRepository] electronScreenUnlock, fetchConversations, reload", conversations);
      // this.logger.info("[ConversationRepository] resume, fetchConversations, reload, dhaval.dodiya@dev.vnc.de",
      //   conversations.filter(c =>  c.Target === "dhaval.dodiya@dev.vnc.de")[0].content);
      const t1 = performance.now();
      this.logger.info(`[PERFORMANCE] [ConversationRepository] electronScreenUnlock, databaseService.fetchConversations: took ${t1 - t0} milliseconds.`);

      // it could be we have stale data here,
      // e.g. when call 2 requests simultaniusly - update conv and fetch top10,
      // so need to fix

      const conversationsChecked = [];
      conversations.forEach(convInDB => {
        this.store.select(state => getConversationById(state, convInDB.Target)).pipe(take(1)).subscribe(convInStore => {
          let convToAdd: Conversation;
          if (convInStore && convInStore.Timestamp > convInDB.Timestamp) {
            this.logger.info("[ConversationRepository] electronScreenUnlock, fix stale cache", {convInStore, convInDB});
            convToAdd = convInStore;
          } else if (convInDB) {
            convToAdd = convInDB;
          }
          if (convToAdd) {
            conversationsChecked.push(convToAdd);
          }
        });
      });


      this.store.dispatch(new ConversationNextLoadSuccess({ conversations: conversationsChecked }));

      const t2 = performance.now();
      this.logger.info(`[PERFORMANCE] [ConversationRepository] electronScreenUnlock, ConversationReLoadSuccess: took ${t2 - t0} milliseconds.`);

    });
    // find oldest conv with unread ids in store
    let oldestConv = Math.floor(Date.now() / 1000);
    this.getConversations().pipe(take(1), filter(conversations => conversations.length > 0)).subscribe(allconversations => {
      const conversations = allconversations.filter(c => c && c.unreadIds && c.unreadIds.length > 0);
      // this.logger.info("[electronScreenUnlock] conversations with unreads: ", conversations);
      conversations.forEach(conv => {
        const convStamp = Math.floor(conv.Timestamp / 1000);
        if (convStamp < oldestConv) {
          oldestConv = convStamp;
        }
        if (conv.updated_at < oldestConv) {
          oldestConv = conv.updated_at;
        }
      });
    });
    this.logger.info(new Date().toISOString() + " [electronScreenUnlock] oldest conversations with unreads stamp: ", oldestConv);
    // reload conversations updates on electronScreenUnlock, 2s delay to allow wifi connect
    setTimeout(() => {
      this.store.select(getNetworkInformation).pipe(filter(v => !!v && !!v.onlineState), take(1)).subscribe((info) => {
        // this.logger.info("[ConversationRepository] callLoadAllUpdatedConversations 10 ", info);
        this.loadAllUpdatedConversations(null, oldestConv - 300);
      });
    }, 2000);
  }

  private removePendingAttachment(id) {
    this.databaseService.deletePendingAttachment(id).subscribe(() => {
      this.logger.info("[ConversationRepository][removePendingAttachment] removed: ", id);
    }, err => {
      this.logger.info("[ConversationRepository][removePendingAttachment] failed to delete attachment for: ", id, err);

    });
  }

  getCallParticipants(jid): Observable<any> {
    return this.conversationService.getCallParticipants(jid);
  }

  public uploadImageVideo(file: any): Observable<any> {
    let response = new Subject();
    // requesting XMPP slot
    this.logger.info("[ConversationRepository][uploadImageVideo] xmppService.requestSlot starting.. file: ", file);
    this.xmppService.requestSlot(file.name, file.size)
    .pipe(switchMap(res => {
      this.logger.info("[ConversationRepository][uploadImageVideo] xmppService.requestSlot done, res: ", res);
      return this.uploadFile(res.httpupload.put, file);
    })).subscribe({
      next: (res: any) => {
        this.logger.info("[ConversationRepository][uploadImageVideo] res: ", res, file);
        switch (res.type) {
          case HttpEventType.Sent: // 0
            this.logger.info("[ConversationRepository][uploadImageVideo] Uploading file, res: ", res);
            break;

          case HttpEventType.UploadProgress: // 1
            this.logger.info(`[ConversationRepository][uploadImageVideo] Uploading progress: ${res.loaded} from ${res.total}`, file);
            break;

          case HttpEventType.ResponseHeader: // 2
            this.logger.info(`[ConversationRepository][uploadImageVideo] response header`, res);
            // ok: false
            // status: 0
            // statusText: "OK"

            break;

          case HttpEventType.DownloadProgress: // 2

            break;

          case HttpEventType.Response: // 4
            this.logger.info(`[ConversationRepository][uploadImageVideo]HttpEventTypeResponse File was completely uploaded`, res, file);
            // ok: true
            // status: 200
            // statusText: "OK"
            if (res.status === 201) {
              this.logger.info(`[ConversationRepository][uploadImageVideo]HttpEventTypeResponse recoding required `, file);
              file.url = res.url;
              response.next(file);
              break;
            }

            let newUrl;
            if (res.url.indexOf("?") > -1) {
              newUrl = res.url.split("?")[0];
            } else {
              newUrl = res.url;
            }
            file.url = newUrl;
            response.next(file);
            break;

          default:
            break;
        }
      },
      error: (err) => {
      this.logger.error("[ConversationRepository][uploadImageVideo] xmppService.requestSlot error", err);

      }
    });

    return response.asObservable();
  }

  upadatePinOrder(pinnedOrderList) {
    this.getUnArchivedConversations();
    return this.conversationService.changeOrderPinConversation(pinnedOrderList);
  }
  setConvPinDB(data) {
    this.databaseService.createOrUpdateConversation(data).subscribe();
  }
  refreshOmemoDeviceInfo(conversations: any[]) {
    if (!!conversations && conversations.length > 0) {
      const convs2refresh = conversations.filter(c => !!c && c.encrypted && c.type === "chat" && !c.archived && !c.deleted);
      this.logger.info("[ConversationRepository]refreshOmemoDeviceInfo ", conversations, convs2refresh);
      const refreshList = convs2refresh.map(c => c.Target).concat(this.omemoRefreshList.value);
      let newList = [];
      if (!!refreshList && (refreshList.length > 0)) {
        for (let i = 0; i < refreshList.length; i++) {
          if (newList.indexOf(refreshList[i]) === -1) {
            newList.push(refreshList[i]);
          }
        }
      }
      this.omemoRefreshList.next(newList);

    }
  }

  updateCropAvatar(jid, photo) {
    let originalData: ContactInformation = {};
    this.contactRepo.getContactVCard(jid).pipe(take(1)).subscribe(data => {
      originalData = data;
    });
    const newData = {
      ...originalData,
      ...{photo: photo}
    };

    this.contactRepo.publishVCards(newData).pipe(take(1)).subscribe((res) => {
      setTimeout(() => {
        this.avatarRepo.upgradeAvatar(jid);
        this.contactRepo.notifyOnAvatarUpdate().pipe(take(1)).subscribe(res => {
          this.logger.info("[ProfileComponent][updateAvatar] broadcast, res: ", res);
        });
      }, 1000);
    });
  }
  requestResendLastMessage(target: string) {
    this.getSelectedConversation().pipe(take(1)).subscribe(selectedConv => {
      this.logger.info("[ConversationRepository]requestResendLastMessage ", selectedConv);
      if (!!selectedConv && !!selectedConv.last_message_id) {
        const signal = {
          type: "requestresend",
          target: selectedConv.Target,
          data: selectedConv.last_message_id,
        };
        this.xmppService.sendSignalToTarget(signal);
      }
    });
  }

  resendRequestedMessage(target: string, msgTarget:string, msgId: string) {
    if (!!target && !!msgId && !!msgTarget) {
      this.databaseService.getMessageById(msgId).pipe(take(1)).subscribe(msg => {
        if (!!msg) {
          this.logger.info("[ConversationRepository]resendRequestedMessage ", target, msgTarget, msg);
          if ((msg.from.bare === target) || (msg.to.bare === target)) {
            const data = {
              id: msg.id,
              body: !!msg.body ? msg.body : "",
              htmlBody: !!msg.htmlBody ? msg.htmlBody : ""
            };

            const signal = {
              type: "resend",
              target: target,
              data: JSON.stringify({
                id: msg.id,
                body: !!msg.body ? msg.body : "",
                htmlBody: !!msg.htmlBody ? msg.htmlBody : ""
              })
            };

            this.xmppService.sendSignalToOmemoTarget(signal);
          }
        }
      });
    }
  }

  processResendSignal(message) {
    this.logger.info("[ConversationRepository]processResendSignal ", message);
    try {
      const data = JSON.parse(message.signal.data);
      if (!!data.id && !!data.body) {
        this.databaseService.getMessageById(data.id).pipe(take(1)).subscribe(existingMsg => {
          if (!!existingMsg) {
            const updatedMsg = { ...existingMsg };
            if (existingMsg.body !== data.body) {
              updatedMsg.body = data.body;
            }
            if (!!data.htmlBody && (existingMsg.htmlBody !== data.htmlBody)) {
              updatedMsg.htmlBody = data.htmlBody;
            }
            const target = !!existingMsg.convTarget ? existingMsg.convTarget : existingMsg.conversationTarget;
            this.databaseService.createOrUpdateDecryptedMessage(updatedMsg, target).pipe(take(1)).subscribe(() => {
              this.store.dispatch(new MessagesBulkUpdate([updatedMsg]));
              this.logger.info("[ConversationRepository]processResendSignal done: ", updatedMsg);
            });
          }
        });
      }
    } catch (error) {
      this.logger.error("[ConversationRepository]processResendSignal unable to process:", error, message);
    }
  }

  isOwnerOrAdmin(conversation: Conversation) {
    if (conversation.type === "chat") {
      return true;
    }

    let owner: string;
    let admins: string[];

    this.store.select(state => getConversationOwner(state, conversation.Target)).pipe(take(1)).subscribe(ow => {
      owner = ow;
    });

    this.store.select(state => getConversationAdmins(state, conversation.Target)).pipe(take(1)).subscribe(adms => {
      admins = adms;
    });

    if (owner && owner === this.userJID?.bare) {
      return true;
    } else if (admins && admins.length > 0) {
      return admins.find(bare => bare === this.userJID?.bare);
    } else {
      return false;
    }
  }

  async openBlockDialog(username: any, blockCallback: () => void) {
    let options: any = {
      width: "480px",
      height: "280px"
    };

    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }

    const { ConfirmationChannelComponent } = await import(
      "../../channels/confirmation-channel/confirmation-channel.component"
    );
    const translationObject = { name: username};
    const translatedDescription = this.translate.instant("BLOCK_USER_DESCIPTION", translationObject);

    this.dialog.open(ConfirmationChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "BLOCK_USER",
        bodyText: translatedDescription,
        okLabel: "Block"
      },
      autoFocus: true,
      ...options
    }).afterClosed().pipe(take(1)).subscribe((res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          blockCallback();
        }
      }
    });
  }

  turnNativeElectronContextMenuOn() {
    if (environment.isElectron) {
      this.electronService.turnNativeElectronContextMenuOn();
    }
  }
  turnNativeElectronContextMenuOff() {
    if (environment.isElectron) {
      this.electronService.turnNativeElectronContextMenuOff();
    }
  }

  shareContact(contactId, shareConversation, recipentTarget) {
    this.conversationService.getContactForShare(contactId).subscribe((res: any) => {
      const blob = new Blob([res], { type: "text/vcard" });
      let fileData = {
        file: blob,
        fileName :  shareConversation.Target.split("@")[0] + ".vcf",
        fileSize: blob.size,
        target: recipentTarget,
      };
      this.contactRepo.shareContact$.next(fileData);
    });
  }

  importContact(contactBlob) {
    this.conversationService.importContact(contactBlob).subscribe(res => {
      this.broadcaster.broadcast("vncdirectorycontactsbulkupdate");
      this.translate.get("CONTACT_IMPORT_SUCCESS_MSG").pipe(take(1)).subscribe(text => this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK));
    }, err => {
    });
  }

  handlePadError(err: any) {
    this.logger.info("[ConversationRepository]handlePadError", err);
    let unAuthState = (!!err.status && (err.status === 401 || err.status === 403)) ? true : false;
    if (!unAuthState || environment.isElectron || environment.isCordova) {
      this.logger.info("[ConversationRepository]handlePadError", err);
    } else {
      this.configService.clearStorage();
      this.configService.redirectToLoginScreen();
    }
  }
}
