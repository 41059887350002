
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { ConfigService } from "../../config.service";
import { DateTimeAdapter } from "ng-pick-datetime-ex";
import { CommonUtil } from "app/talk/utils/common.util";
import { environment } from "../../environments/environment";
import { SearchItem, SearchResponse } from "../models/search-item";
import { BehaviorSubject, map, Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class CommonService {
  xmppSecret = "";
  config: any = {};
  currentLanguage = new BehaviorSubject("en");
  highlightMessageCompleteRow: boolean = false;
  isShowLoading: boolean = false;
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;

  sidebarForBroadcast$ = new Subject<any>();

  fileToSaveOwnCloud$ = new Subject<any>();
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private dateTimeAdapter: DateTimeAdapter<any>) {
  }

  getConfig() {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    return this.http.get(this.configService.API_URL + "/api/config", { headers: headers });
  }

  set language(value) {
    this.currentLanguage.next(value);
    if (value !== "de") {
      this.dateTimeAdapter.setLocale("de-DE");
    } else {
      this.dateTimeAdapter.setLocale("en-EN");
    }
    localStorage.setItem("portalLanguage", value);
  }

  get language() {
    return CommonUtil.getDefaultLang();
  }

  private getTargetFromConversationKey(target) {
    if (target.indexOf("-") !== -1) {
      target = target.split("-").filter(v => v !== this.configService.getUserJid())[0];
    }
    return target;
  }

  public searchAutoComplete(body: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    if (this.configService.get("useOldSearch")) {
      return this.http.post(this.configService.API_URL + "/api/v2/autocomplete-talk", body, { headers: headers });
    } else {
      return this.http.post(this.configService.API_URL + "/api/v4/autocomplete", body, { headers: headers });
    }
  }

  public searchContacts(body: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }

    return this.http.post(this.configService.API_URL + "/api/v2/search-contacts", body, { headers: headers });

  }

  public getSavedSearch(params: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/queries", { headers: headers, params: params });
  }

  public searchDocs(params: any): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/search", { headers: headers, params: params })
      .pipe(map((res: any) => {
        let docs = [];
        let numFound = 0;
        let start = 0;
        let groups = {};
        if (res.response) {
          docs = res.response.docs.map(val => {
            return this.mapSearchItem(val);
          });
          numFound = res.response.numFound;
          start = res.response.start;
        } else if (res.grouped && res.grouped.type_s && res.grouped.type_s.groups) {
          numFound = res.grouped.type_s.matches;
          res.grouped.type_s.groups.forEach(group => {
            if (group.doclist.start) {
              start = group.doclist.start;
            }
            const _docs = group.doclist.docs.map(val => {
              return this.mapSearchItem(val);
            });
            groups[this.getTargetFromConversationKey(group.groupValue)] = _docs;
            docs = [...docs, ..._docs];
          });
        } else if (res.grouped && res.grouped.from_s && res.grouped.from_s.groups) {
          numFound = res.grouped.from_s.matches;
          res.grouped.from_s.groups.forEach(group => {
            if (group.doclist.start) {
              start = group.doclist.start;
            }
            const _docs = group.doclist.docs.map(val => {
              return this.mapSearchItem(val);
            });
            groups[this.getTargetFromConversationKey(group.groupValue)] = _docs;
            docs = [...docs, ..._docs];
          });
        } else if (res.grouped && res.grouped.talk_jid_s && res.grouped.talk_jid_s.groups) {
          numFound = res.grouped.talk_jid_s.matches;
          res.grouped.talk_jid_s.groups.forEach(group => {
            if (group.doclist.start) {
              start = group.doclist.start;
            }
            const _docs = group.doclist.docs.map(val => {
              return this.mapSearchItem(val);
            });
            groups[this.getTargetFromConversationKey(group.groupValue)] = _docs;
            docs = [...docs, ..._docs];
          });
        }
        if (res.facet_counts && res.facet_counts.facet_fields && res.facet_counts.facet_fields.type_s) {
          const apps = {};
          const type = res.facet_counts.facet_fields.type_s;
          for (let i = 0; i <= type.length; i++) {
            if (typeof type[i] === "string" && i < type.length - 1) {
              apps[type[i]] = type[i + 1];
            }
          }
        } else {
        }
        return { docs: docs, numFound: numFound, start: start, groups: groups } as SearchResponse;
      }));
  }

  private mapSearchItem(doc) {
    const content = doc.content_txt ? doc.content_txt[0] : "";
    const raw = doc.raw_txt ? doc.raw_txt[0] : "";
    let parsedContent = CommonUtil.replaceLinkToAnchor(content);
    const rawTxt = raw.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
    if (rawTxt !== "") {
      parsedContent = CommonUtil.beautifyMailContent(rawTxt);
    }
    const shortContent = CommonUtil.replaceLinkToAnchor(CommonUtil.limitContent(CommonUtil.HTMLToPlainText(parsedContent), 500));
    let id = doc.id;
    let objectId = doc.id;
    if (doc.type_s === "task") {
      id = doc.task_id_i;
    } else if (doc.type_s === "mail") {
      id = doc.mail_id_i;
      objectId = objectId.replace(`.${doc.mail_id_i}`, "");
    } else if (doc.type_s === "talk") {
      objectId = objectId.replace(`.${doc.owner_s}`, "").replace(`talk.${doc.talk_id_s}.`, "");
      objectId = objectId.replace(`talk.`, "");
    }
    return {
      id: id,
      objectId: objectId,
      talkId: doc.talk_id_s,
      owner: doc.owner_s,
      title: doc.title_s ? doc.title_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'") : "",
      contentTxt: content.replace(/\r?\n/g, "\\n")
        .replace(/\\n/g, "<br />"),
      parsedContent: parsedContent.replace(/\r?\n/g, "\\n")
        .replace(/\\n/g, "<br />"),
      shortContent: shortContent.replace(/\r?\n/g, "\\n")
        .replace(/\\n/g, "<br />"),
      chatType: doc.talk_type_s,
      rawTxt: raw,
      createdDt: doc.created_dt,
      modifiedDt: doc.modified_dt,
      from: doc.from_s,
      to: doc.to_ss,
      type: doc.type_s,
      version: doc._version_,
      mailFolderID: doc.mail_folder_id_i,
      unread: doc.mail_unread_b,
      mailAttachments: this.getMailAttachments(doc),
      flags: doc.mail_flags_s
    } as SearchItem;
  }

  private getMailAttachments(doc) {
    if (!doc.mail_attachments_s) {
      return [];
    }
    const attachments = JSON.parse(doc.mail_attachments_s);
    return attachments.map(v => {
      return {
        id: v.id.replace(doc.mail_message_id_s, "").replace("_", ""),
        name: v.name_s,
        type: v.type_s,
        contentType: v.contenttype_s,
        contentLength: v.contentlength_i,
        parentId: v.parent_id_s,
        part: v.part_s
      };
    });
  }

}
